import { set } from "idb-keyval";
import Paho from "paho-mqtt";
import { v1 as uuidv1 } from "uuid";

import { Tracing } from "../../../App.tracing";
import defaultSetup from "../../../Config/defaultSetupConfiguration";
import Logger from "./Logger";
import { configureStore, getEventTime, storeInsert } from "./SendEvent";
import SequenceIdGenerator from "./SequenceIdGenerator";

const logger = Logger.getInstance();

const idGenerator = SequenceIdGenerator.getInstance();

function formatClockTime(displayTime) {
  let clockParts = displayTime.split(":");
  return "PT" + parseInt(clockParts[0]) + "M" + parseInt(clockParts[1]) + "S";
}

export function sendPossessionEvent(entityId, value, props, saveToStore, action) {
  const { currentState, updateState } = props;
  const fixtureId = props.currentState.fixtureId;
  const eventStore = configureStore(props, "event");
  let csi = currentState.insertMode;

  const getEventType = () => {
    if (value === "hasArrow") {
      return "possessionArrow";
    }
    if (value === "hasPossession" || value?.type === "subTyped") {
      return "possession";
    }
    return "";
  };

  const getSubType = () => {
    if (value?.type === "subTyped") {
      return value.subType;
    }
    return null;
  };

  const eventType = getEventType();
  const subType = getSubType();

  const msg = {};
  msg.class = "sport";
  msg.eventType = eventType;
  if (!!subType) {
    msg.subType = subType;
  }
  msg.entityId = entityId;
  msg.eventId = uuidv1();
  msg.clock = formatClockTime(props.currentState.clock.displayTime);

  msg.status = "added";
  msg.periodId = currentState.period ? currentState.period.periodId : 1;

  if (props.panel?.options?.immutableDateTime) {
    try {
      msg.timestamp = props.currentState.events[action?.type].timestamp;
      msg.eventTime = props.currentState.events[action?.type].eventTime;
    } catch (error) {
      Tracing.capture(error);
      logger.log(
        `No event named "${action.type}" in current state.`,
        currentState.events,
        error
      );
      const timestamp = new Date().toISOString();
      msg.timestamp = timestamp;
      msg.eventTime = timestamp;
    }
  } else {
    let timestamp = new Date();
    msg.timestamp = timestamp.toISOString();
    msg.eventTime = getEventTime();
  }

  msg.sequence = idGenerator.getId();

  // !! Temporary fix, this is still required for hockey events but will be removed
  if (value?.type === "subTyped") {
    msg.options = {
      time: formatClockTime(props.currentState.clock.displayTime),
    };
    if (value?.playId) {
      msg.playId = value.playId;
      props.managePlays(msg.playId, msg);
    }
  }

  if (csi && csi.enabled) {
    msg.eventTime = csi.insertDate;
    msg.clock = csi.clock;
    msg.periodId = csi.play.periodId;
  }

  if (saveToStore) {
    const { events } = currentState;
    events.possession = msg;
    updateState("events", events);
  }

  let messageContent = JSON.stringify({
    type: "event",
    fixtureId: fixtureId,
    data: msg,
    clientType: defaultSetup.options.clientType,
  });

  if (csi && csi.enabled) {
    storeInsert(msg, props);
  } else {
    var message = new Paho.Message(messageContent);
    if (currentState.mqtt) {
      message.destinationName = currentState.mqtt.topic;
      if (currentState.connected && currentState.mqttConnected) {
        try {
          currentState.mqtt.client.send(message);
        } catch (err) {
          Tracing.capture(err);
          logger.log("Send Error", err);
        }
      }
      msg.pending = true;

      set(msg.eventId, msg, eventStore);
    }
  }
}
