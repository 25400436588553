const CardTypes = ["greenCard", "yellowCard", "redCard", "noCard"];

export const PenaltyStrokeCardSelect = {
  name: "ps-card-type",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "card.type",
  showOn: [{ state: "primary", value: "penalty-stroke-card-select", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: {},
  children: [
    {
      name: "card-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "eventType",
        children: CardTypes,
        layout: "type-button",
      },
      actionGroups: [
        {
          actions: [
            { action: "newEventId", value: null, type: "main" },
            {
              action: "clearData",
              value: ["subType"],
              type: "main",
            },
            { action: "modifyEvent", value: "swapEntities", type: "main" },
            {
              action: "saveData",
              value: [{ option: "fromPenaltyStroke", value: true }],
              type: "options",
            },
            {
              action: "nextState",
              value: "card-player",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "eventType",
              value: "greenCard",
              event: "main",
              operator: "is",
            },
            {
              item: "eventType",
              value: "yellowCard",
              event: "main",
              operator: "is",
            },
            {
              item: "eventType",
              value: "redCard",
              event: "main",
              operator: "is",
            },
          ],
          operator: "any",
        },
        {
          actions: [
            { action: "copyEvent", value: "penaltyStroke", type: "from" },
            {
              action: "nextState",
              value: "penalty-stroke-player-select",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "eventType",
              value: "noCard",
              event: "main",
              operator: "is",
            },
          ],
        },
      ],
    },
  ],
};
