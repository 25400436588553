export var TurnoverTypes = [
  "badPass",
  "ballHandling",
  "outOfBounds",
  "travel",
  "3Sec",
  "5Sec",
  "backCourt",
  // "offensive",
  "offensiveGoalTending",
  "doubleDribble",
  "carrying",
  "other",
];

export var TeamTurnoverTypes = ["5Sec", "8Sec", "shotClock", "other"];
