import React, { useEffect, useState, useCallback } from "react";
import { withRouter, useHistory } from "react-router-dom";

import { useFetch } from "../Components/Base/Hooks/basicFetch";
import { mergeObjects } from "../Components/Base/Utilities/MergeObjects";

const defaultFixtureConfigs = {
  basketball: {
    fixtureProfile: {
      foulsBeforeBonus: 5,
      maxFoulsPersonal: 5,
      maximumRosterSize: 17,
      maximumPlayersOnCourt: 5,
      resetFoulsAfterPeriods: [1, 2, 3],
      resetTimeoutsAfterPeriods: [2],
      switchPlayingDirection: [2],
      clockDirection: "COUNT_DOWN",
      foulsAllowedTechnical: 2,
      halfTimeDuration: 10,
      numberOfPeriods: 4,
      overtimeAllowed: true,
      overtimeLength: 5,
      periodBreakDuration: 2,
      periodLength: 10,
      shotClock: 24,
      initialExtraPeriodId: 11,
      simpleTargetScoreEndingAllowed: false,
      midGameJumpBallAllowed: false,
      powerPlayAllowedPeriods: [],
      enableBetting: false,
    },
    sport: "basketball",
    reports: ["boxscoreWithPeriods", "advancedBoxscore", "scoresheet", "playbyplay", "playbyplayfull", "quarterscore"],
  },
  ice3: {
    fixtureProfile: {
      maximumRosterSize: 8,
      maximumPlayersOnIce: 4,
      clockDirection: "COUNT_DOWN",
      numberOfPeriods: 2,
      periodLength: 8,
      initialExtraPeriodId: 11,
      shootOutPeriodId: 12,
      enableBetting: false,
      // only used to stop app breaking
      resetFoulsAfterPeriods: [],
      resetTimeoutsAfterPeriods: [],
      switchPlayingDirection: [],
    },
    sport: "ice3",
    reports: [],
  },
  hockey: {
    fixtureProfile: {
      maximumRosterSize: 18,
      maximumPlayersOnField: 11,
      clockDirection: "COUNT_DOWN",
      numberOfPeriods: 4,
      periodLength: 10,
      initialExtraPeriodId: 10,
      shootOutPeriodId: 12,
      halfTimeDuration: 10,
      enableBetting: false,
      // only used to stop app breaking
      resetFoulsAfterPeriods: [],
      resetTimeoutsAfterPeriods: [],
      switchPlayingDirection: [],
    },
    season: {
      discipline: "OUTDOOR",
    },
    sport: "hockey",
    reports: ["hockeyMatch", "hockeyCards"],
  },
  handball: {
    fixtureProfile: {
      maximumRosterSize: 5,
      maximumPlayersOnCourt: 5,
      clockDirection: "COUNT_UP",
      numberOfPeriods: 2,
      periodLength: 30,
      initialExtraPeriodId: 10,
      shootOutPeriodId: 14,
      enableBetting: true,
      // only used to stop app breaking
      resetFoulsAfterPeriods: [],
      resetTimeoutsAfterPeriods: [],
      switchPlayingDirection: [],
    },
    sport: "handball",
    reports: [],
  },
};

const FixtureDetailsContext = React.createContext({});

const getBaseFixtureConfigForSport = (sport) =>
  defaultFixtureConfigs[sport] ? defaultFixtureConfigs[sport] : defaultFixtureConfigs.basketball;

const processFixtureDetailsData = (detailsData) => {
  const fixtureProfile = detailsData?.data?.fixtureProfile?.profile
    ? { ...detailsData.data.fixtureProfile.profile }
    : {};
  const newDetailsData = mergeObjects(
    getBaseFixtureConfigForSport(detailsData.data.sport),
    { ...detailsData.data, fixtureProfile },
    { skipArrayMerge: true },
  );

  return newDetailsData;
};

const BaseFixtureDetailsProvider = ({ children, currentState, fixtureId }) => {
  const [fixtureDetails, setFixtureDetails] = useState({ hasLoaded: false });
  const [authKey, setAuthKey] = useState();
  const [refreshFlag, setRefreshFlag] = useState(false);
  const history = useHistory();

  const {
    loading: isLoading,
    data: detailsData,
    error: detailsFetchError,
  } = useFetch(
    "/v1/client/fixtures/" + fixtureId + "/detail",
    {
      method: "GET",
      values: null,
      key: authKey,
    },
    refreshFlag && !!fixtureId && !!authKey,
  );

  const forceRefresh = () => {
    setRefreshFlag(true);
  };

  const updateAuthKey = useCallback(
    (newAuthKey) => {
      if (newAuthKey && newAuthKey !== authKey) {
        setAuthKey(newAuthKey);
        setRefreshFlag(true);
      }
    },
    [authKey],
  );

  useEffect(() => {
    updateAuthKey(currentState.authKey);
  }, [currentState.authKey, updateAuthKey]);

  useEffect(() => {
    const newAuthKey = localStorage.getItem("authKey");
    updateAuthKey(newAuthKey);
  }, [updateAuthKey]);

  useEffect(() => {
    if (detailsData) {
      setRefreshFlag(false);
      setFixtureDetails({
        ...processFixtureDetailsData(detailsData),
        hasLoaded: !isLoading,
        errorMsg: null,
        forceRefresh: forceRefresh,
      });
    }
  }, [detailsData, isLoading]);

  useEffect(() => {
    if (detailsFetchError?.code === 404) {
      history.push("/fixtures");
    }

    setFixtureDetails((prevFixtureDetails) => {
      return {
        ...prevFixtureDetails,
        errorMsg: detailsFetchError,
        forceRefresh: forceRefresh,
      };
    });
  }, [detailsFetchError]);

  return (
    <FixtureDetailsContext.Provider value={fixtureDetails}>
      <>{children}</>
    </FixtureDetailsContext.Provider>
  );
};

const FixtureDetailsProvider = withRouter(BaseFixtureDetailsProvider);

export { FixtureDetailsContext, FixtureDetailsProvider };
