import { useState, useEffect } from "react";

export const useCanEdit = (props, events) => {
  const { workFlow } = props;
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    if (workFlow && workFlow.modify && workFlow.modify.types) {
      events.map((event) => {
        workFlow.modify.types.map((type) => {
          if (type.eventTypes.includes(event.eventType)) {
            if (
              (type.subTypeIgnore &&
                !type.subTypeIgnore.includes(event.subType)) ||
              !type.subTypeIgnore
            ) {
              if (event.success === type.success || type.success === null) {
                if (!type.subType) {
                  setCanEdit(true);
                } else {
                  switch (type.subType.operator) {
                    case "is":
                      if (type.subType.subType === event.subType) {
                        setCanEdit(true);
                      }
                      break;
                    case "not":
                      if (type.subType.subType !== event.subType) {
                        setCanEdit(true);
                      }
                      break;
                    default:
                      break;
                  }
                }
              }
            }
          }
          return true;
        });
        return true;
      });
    }
    // eslint-disable-next-line
  }, [props]);

  return [canEdit];
};
