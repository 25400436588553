import React from "react";
import { Spinner } from "reactstrap";

import "./PageBlocker.scss";

const PageBlocker = props => {
  const { blocked } = props;

  return (
    <React.Fragment>
      {blocked && (
        <div className="page-blocker">
          <Spinner size="md" color="orange" />
        </div>
      )}
    </React.Fragment>
  );
};

export default PageBlocker;
