function foulLedToTurnover(events) {
  const hasOffensiveTurnoverEvent = events.some(
    (event) => event.eventType === "turnover" && event.subType === "offensive"
  );

  if (!hasOffensiveTurnoverEvent) {
    return {};
  }

  const foulEvent = events.find(
    (event) => event.eventType === "foul" && event.subType !== "drawn"
  );

  if (!foulEvent) {
    return {};
  }

  return {
    foulLedToTurnover: foulEvent.subType,
  };
}

const extraPlayInfoExtractors = [foulLedToTurnover];

export function getExtraPlayInfo(events) {
  return extraPlayInfoExtractors.reduce(
    (extraInfo, extractor) => ({
      ...extraInfo,
      ...extractor(events),
    }),
    {}
  );
}
