const createCardClockActions = () => [
  { action: "copyEvent", value: "card-clock", type: "from" },
  { action: "newEventId", value: null, type: "main" },
  {
    action: "saveData",
    value: "start",
    type: "subType",
  },
  {
    action: "clearData",
    value: ["options"],
    type: "main",
  },
  { action: "sendEvent", value: "new", type: "main" },
  { action: "copyEvent", value: "card", type: "from" },
  {
    action: "clearData",
    value: ["options", "eventType"],
    type: "main",
  },
];

export const CardTimeStartSelect = {
  name: "card-timer-start-select",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "card.select-start-timer",
  showOn: [{ state: "primary", value: "card-timer-start-select", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "card-timer-start",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "shouldSend",
        children: ["yes", "no"],
        layout: "type-button",
      },
      actionGroups: [
        {
          actions: [
            ...createCardClockActions(),
            { action: "copyEvent", value: "possession", type: "from" },
            {
              action: "nextState",
              value: "circle-entry-select",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "shouldSend",
              value: "yes",
              event: "main",
              operator: "is",
            },
            {
              item: "options",
              value: "fromCircleEntry",
              event: "main",
              operator: "is",
            },
          ],
          operator: "all",
        },
        {
          actions: [
            ...createCardClockActions(),
            { action: "copyEvent", value: "penaltyStroke", type: "from" },
            {
              action: "nextState",
              value: "penalty-stroke-player-select",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "shouldSend",
              value: "yes",
              event: "main",
              operator: "is",
            },
            {
              item: "options",
              value: "fromPenaltyStroke",
              event: "main",
              operator: "is",
            },
          ],
          operator: "all",
        },
        {
          actions: [
            ...createCardClockActions(),
            {
              action: "nextState",
              value: null,
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "shouldSend",
              value: "yes",
              event: "main",
              operator: "is",
            },
            {
              item: "options",
              value: "fromCircleEntry",
              event: "main",
              operator: "not",
            },
            {
              item: "options",
              value: "fromPenaltyStroke",
              event: "main",
              operator: "not",
            },
          ],
          operator: "all",
        },
        {
          actions: [
            {
              action: "clearData",
              value: ["options"],
              type: "main",
            },
            {
              action: "nextState",
              value: null,
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "shouldSend",
              value: "no",
              event: "main",
              operator: "is",
            },
            {
              item: "options",
              value: "fromCircleEntry",
              event: "main",
              operator: "not",
            },
            {
              item: "options",
              value: "fromPenaltyStroke",
              event: "main",
              operator: "not",
            },
          ],
          operator: "all",
        },
        {
          actions: [
            { action: "copyEvent", value: "penaltyStroke", type: "from" },
            {
              action: "nextState",
              value: "penalty-stroke-player-select",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "options",
              value: "fromPenaltyStroke",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "clearData",
              value: ["options"],
              type: "main",
            },
            { action: "copyEvent", value: "possession", type: "from" },
            {
              action: "nextState",
              value: "circle-entry-select",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "options",
              value: "fromCircleEntry",
              event: "main",
              operator: "is",
            },
          ],
        },
      ],
    },
  ],
};
