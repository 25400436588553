import React from "react";
import { injectIntl } from "react-intl";

const Cancel = (props) => {
  const { updateState, intl } = props;
  const { formatMessage } = intl;

  const _setCancel = () => {
    updateState("showInfo", false);
  };

  return (
    <div className="cancel" onClick={() => _setCancel()}>
      <span>
        {formatMessage({
          id: "cancel",
          defaultMessage: "cancel",
        })}
      </span>
      <i className="fas fa-times"></i>
    </div>
  );
};

export default injectIntl(Cancel);
