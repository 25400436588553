import { JumpballOptions } from "../Options/JumpballOptions";

export var ModifyJumpBallType = {
  name: "jumpBall-type",
  component: "MainPanel",
  layout: "main-panel x-large",
  mode: "visible",
  text: "jumpBall.type",
  showOn: [{ state: "editState", value: "jumpBall-type", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    // Team Jumpball Types
    {
      name: "team-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.personId",
          value: null,
          operator: "is",
          event: "main",
        },
      ],
      startEvent: false,
      startPlay: false,
      options: {
        value: "options",
        children: JumpballOptions,
        layout: "type-button",
        prefix: "jumpball",
      },
      actionGroups: [
        {
          actions: [
            { action: "clearData", value: ["personId"], type: "main" },
            { action: "copyEvent", value: "jumpBall", type: "to" },
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
          ],
          conditions: [],
        },
        {
          actions: [{ action: "nextState", value: null, type: "editState" }],
          conditions: [],
        },
      ],
    },
  ],
};
