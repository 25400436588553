export const PenaltyChangeGoalKeeper = {
  name: "penalty-change-goal-keeper",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "changeGoalkeeper",
  showOn: [
    { state: "primary", value: "penalty-change-goal-keeper", operator: "is" },
  ],
  allowFlag: true,
  allowCancel: true,
  allowCancelPlay: true,
  showDetails: { events: ["main"] },
  children: [
    {
      name: "penalty-change-goal-keeper-panel",
      component: "ChangeGoalkeeper",
      layout: "player-select",
      mode: "visible",
      startEvent: false,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: false,
        selectOnly: true,
        addGoalkeeperIdToEvent: "shot",
        sendGoalKeeperChangeEvent: true,
      },
      actionGroups: [
        {
          actions: [
            { action: "copyEvent", value: "shot", type: "from" },
            {
              action: "nextState",
              value: "penalty-shot-outcome",
              type: "primary",
            },
          ],
        },
      ],
    },
  ],
};
