// Steal Player Select
export var StealPlayer = {
  name: "steal-player",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "steal.select-player",
  allowFlag: true,
  allowCancel: false,
  showDetails: { events: ["turnover"] },
  showOn: [
    {
      state: "primary",
      value: "steal-player",
      operator: "is",
    },
  ],
  // allowBack: { state: "primary", value: "turnover-type", event: "turnover" },
  children: [
    {
      name: "steal-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: true,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: false,
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "steal", type: "eventType" },
            {
              action: "clearData",
              value: ["options", "subType", "scores", "success"],
              type: "main",
            },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "nextState", value: null, type: "primary" },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "no-assist",
      component: "TypeButton",
      layout: "match-button center",
      mode: "enable",
      text: "steal.none",
      showOn: [true],
      actionGroups: [
        {
          actions: [
            { action: "nextState", value: null, type: "primary" },
            { action: "clearEvent", value: "turnover", type: "main" },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
