export const CardTypes = ["greenCard", "yellowCard", "redCard"];

export const CardTypeSelect = {
  name: "card-type",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "card.type",
  showOn: [{ state: "primary", value: "card-type", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: {},
  children: [
    {
      name: "card-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "eventType",
        children: CardTypes,
        layout: "type-button",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "nextState",
              value: "card-player",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
