export const ModifyTimeout = {
  name: "modify-timeout",
  component: "MainPanel",
  layout: "main-panel large padded align-center",
  mode: "visible",
  text: "timeout.select",
  allowFlag: true,
  allowCancel: true,
  showOn: [{ state: "editState", value: "modify-timeout", operator: "is" }],
  children: [
    {
      name: "team-0-timeout",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: { team: "name" },
      showOn: [true],
      options: { team: 0 },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "timeOut", type: "eventType" },
            { action: "saveData", value: "team", type: "subType" },
            { action: "sendEvent", value: "edit", type: "main" },
            { action: "nextState", value: null, type: "editState" },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "team-1-timeout",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: { team: "name" },
      showOn: [true],
      options: { team: 1 },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "timeOut", type: "eventType" },
            { action: "saveData", value: "team", type: "subType" },
            { action: "sendEvent", value: "edit", type: "main" },
            { action: "nextState", value: null, type: "editState" },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "official-timeout",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: "official",
      showOn: [true],
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "timeOut", type: "eventType" },
            { action: "saveData", value: "official", type: "subType" },
            { action: "clearData", value: ["entityId"], type: "main" },
            { action: "sendEvent", value: "edit", type: "main" },
            { action: "nextState", value: null, type: "editState" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
