import {
  Clock,
  Field,
  CardButton,
  PCOutsideCircleButton,
  SwitchTeamsButton,
  CountDownClock,
  VideoReviewButton,
  PenaltyStrokeButton,
} from "./includes/Base";

import {
  ModifyCardReason,
  ModifyCardTime,
  ModifyEntitySelect,
  ModifyPenaltyOutcome,
  ModifyPlayerSelect,
  ModifyCardType,
  ModifyShootoutAttemptOutcome,
  ModifyShootoutDefenceOutcome,
  ModifyVideoReviewType,
  ModifyVideoReviewOutcome,
} from "./includes/Modify";

import {
  CardTypeSelect,
  CardPlayerSelect,
  CardTimeSelect,
  CardReasonSelect,
  CardTimeStartSelect,
  CardTeamSelect,
  CircleEntrySelect,
  GoalPlayerSelect,
  PenaltyStrokeCardSelect,
  PenaltyStrokePlayerSelect,
  PenaltyStrokeOutcomeSelect,
  Shootout,
  VideoReviewTeamSelect,
  VideoReviewOutcomeSelect,
  VideoReviewTypeSelect,
  CentrePass,
  WarmupClock,
} from "./includes/Panels";

const HockeyWorkflow = {
  displayEventLog: true,
  sport: "hockey",
  panels: [
    {
      name: "main-area",
      component: "StackingPanel",
      showOn: [true],
      layout: "column hockey-layout",
      children: [
        {
          name: "role-selector-panel",
          component: "StackingPanel",
          layout: "fill-content",
          showOn: [true],
          children: [
            {
              name: "mode-selector",
              component: "ModeSelector",
              showOn: [true],
              mode: "visible",
            },
          ],
        },
        {
          name: "top-panel",
          component: "StackingPanel",
          layout: "fill-content",
          showOn: [true],
          children: [
            {
              name: "team-0-team-select",
              component: "Teams",
              showOn: [true],
              mode: "visible",
              showPossessionIcon: false,
              showSinBin: true,
              showArrow: false,
              showStats: false,
            },
            {
              name: "clock-and-buttons",
              component: "StackingPanel",
              showOn: [true],
              layout: "column fill-parent clock-and-buttons",
              children: [
                Clock,
                {
                  name: "match-buttons",
                  component: "ButtonPanel",
                  layout:
                    "match-buttons-stackable match-buttons-stackable--hockey",
                  mode: "visible",
                  showOn: [true],
                  children: [
                    VideoReviewButton,
                    SwitchTeamsButton,
                    CountDownClock,
                  ],
                },
              ],
            },
            {
              name: "team-1-team-select",
              component: "Teams",
              showOn: [true],
              mode: "visible",
              showPossessionIcon: false,
              showSinBin: true,
              showArrow: false,
              showStats: false,
            },
          ],
        },
        {
          name: "play-area",
          component: "StackingPanel",
          showOn: [true],
          layout: "fill-parent",
          children: [
            {
              name: "team-0-player-select",
              component: "Players",
              showOn: [true],
              mode: "visible",
            },
            {
              name: "middle-play-area",
              component: "StackingPanel",
              showOn: [true],
              layout: "column fill-content",
              children: [
                Field,
                {
                  name: "offside-timeout-buttons",
                  component: "TeamGroupedButtons",
                  layout:
                    "horizontal-buttons horizontal-buttons--reversed hockey-buttons",
                  mode: "visible",
                  showOn: [true],
                  children: [
                    CardButton,
                    PCOutsideCircleButton,
                    PenaltyStrokeButton,
                  ],
                },
              ],
            },
            {
              name: "team-1-player-select",
              component: "Players",
              showOn: [true],
              mode: "visible",
            },
          ],
        },
      ],
    },
    CardTeamSelect,
    CardTypeSelect,
    CardPlayerSelect,
    CardTimeSelect,
    CardReasonSelect,
    CardTimeStartSelect,
    CircleEntrySelect,
    GoalPlayerSelect,
    PenaltyStrokeCardSelect,
    PenaltyStrokePlayerSelect,
    PenaltyStrokeOutcomeSelect,
    VideoReviewTeamSelect,
    VideoReviewOutcomeSelect,
    VideoReviewTypeSelect,
    Shootout,
    CentrePass,
    WarmupClock,
    {
      name: "edit",
      component: "EditPanel",
      layout: "edit-base",
      mode: "visible",
      showOn: true,
    },
    {
      name: "insert",
      component: "InsertPanel",
      layout: "insert-base",
      mode: "visible",
      showOn: true,
    },
    {
      name: "delete",
      component: "DeletePanel",
      layout: "delete-base",
      mode: "visible",
      showOn: true,
    },
    {
      name: "insert-clock",
      component: "InsertClock",
      layout: "insert-clock",
      mode: "visible",
      options: { disablePreMatch: true },
      showOn: [{ state: "insertMode.enabled", value: true, operator: "is" }],
      actionGroups: [],
    },
  ],
  modify: {
    types: [
      {
        name: "goal_made",
        eventTypes: ["goal"],
        success: true,
        editable: [
          { type: "personId", text: "edit.shot.person", panel: "player" },
        ],
        delete: true,
        delete_all: true,
      },
      {
        name: "penalty",
        eventTypes: ["penaltyStroke"],
        success: null,
        editable: [
          { type: "personId", text: "edit.penalty.person", panel: "player" },
        ],
        delete: true,
        delete_all: true,
      },
      {
        name: "penaltyOutcome",
        eventTypes: ["penaltyStroke"],
        success: null,
        subTypeIgnore: [
          "awarded",
        ],
        editable: [
          { type: "personId", text: "edit.penalty.person", panel: "player" },
          {
            type: "success",
            text: "edit.penalty.outcome",
            panel: "penalty-outcome",
          },
        ],
        delete: true,
        delete_all: true,
      },
      {
        name: "card",
        eventTypes: ["greenCard"],
        success: null,
        editable: [
          {
            type: "personId",
            text: "edit.card.personAndEntity",
            panel: "entity",
          },
          {
            type: "eventType",
            text: "edit.card.type",
            panel: "card-type",
          },
        ],
        delete: true,
        delete_all: true,
      },
      {
        name: "card",
        eventTypes: ["redCard"],
        success: null,
        editable: [
          {
            type: "eventType",
            text: "edit.card.type",
            panel: "card-type",
          },
          {
            type: "personId",
            text: "edit.card.personAndEntity",
            panel: "entity",
          },
          { type: "options", text: "edit.card.reason", panel: "card-reason" },
        ],
        delete: true,
        delete_all: true,
      },
      {
        name: "card",
        eventTypes: ["yellowCard"],
        success: null,
        editable: [
          {
            type: "eventType",
            text: "edit.card.type",
            panel: "card-type",
          },
          {
            type: "personId",
            text: "edit.card.personAndEntity",
            panel: "entity",
          },
          { type: "subType", text: "edit.card.reason", panel: "card-reason" },
        ],
        delete: true,
        delete_all: true,
      },
      {
        name: "penaltyCorner",
        eventTypes: ["penaltyCorner"],
        success: null,
        editable: [{ type: "delete_only", text: null, panel: null }],
        delete: true,
        delete_all: true,
      },
      {
        name: "possession",
        eventTypes: ["possession"],
        success: null,
        editable: [
          { type: "entityId", text: "edit.possession.entity", panel: "entity" },
        ],
        delete: true,
        delete_all: true,
      },
      {
        name: "substitution",
        eventTypes: ["substitution"],
        success: null,
        editable: [
          {
            type: "personId",
            text: "edit.substitution.single",
            panel: "player",
          },
        ],
        delete: true,
      },
      {
        name: "centrePass",
        eventTypes: ["centrePass"],
        success: null,
        editable: [
          { type: "entityId", text: "edit.entity", panel: "entity" },
        ],
        delete: false,
      },
      {
        name: "shootOutAttempt",
        eventTypes: ["shootOutAttempt"],
        success: null,
        editable: [
          {
            type: "success",
            text: "edit.shootout.success",
            panel: "shootout-attempt-outcome",
          },
          { type: "personId", text: "edit.shootout.person", panel: "player" },
        ],
        delete: false,
      },
      {
        name: "shootOutDefence",
        eventTypes: ["shootOutDefence"],
        success: null,
        editable: [
          {
            type: "subType",
            text: "edit.shootout.success",
            panel: "shootout-defence-outcome",
          },
          { type: "personId", text: "edit.shootout.person", panel: "player" },
        ],
        delete: false,
      },
      {
        name: "videoReview",
        eventTypes: ["videoReview"],
        success: null,
        editable: [
          { type: "options", text: "edit.videoReview.type", panel: "video-review-type" },
          { type: "entityId", text: "edit.videoReview.entity", panel: "entity", conditions: [
            {
              item: "options",
              value: "team",
              operator: "is",
            },
          ], },
          { type: "subType", text: "edit.videoReview.outcome", panel: "video-review-outcome", conditions: [
            {
              item: "subType",
              value: "pending",
              operator: "not",
            },
          ], },
        ],
        delete: true,
        delete_all: true,
      },
    ],
    panels: [
      ModifyPlayerSelect,
      ModifyPenaltyOutcome,
      ModifyCardReason,
      ModifyCardTime,
      ModifyEntitySelect,
      ModifyCardType,
      ModifyShootoutAttemptOutcome,
      ModifyShootoutDefenceOutcome,
      ModifyVideoReviewType,
      ModifyVideoReviewOutcome,
    ],
  },
};

export default HockeyWorkflow;
