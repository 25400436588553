import React from "react";
import "./Button.scss";

/*
 * children: React.ReactNode;
 * onClick: (ev: React.MouseEvent<HTMLButton> => void)
 * variant: 'success' | 'danger' | 'transparent'
 */
function Button(props) {
  const { children, onClick, variant } = props;
  return (
    <button
      className={`app-btn ${variant ?? ""}`}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default Button;
