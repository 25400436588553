const Outcomes = ["scored", "missed"];

export const PenaltyStrokeOutcomeSelect = {
  name: "penalty-stroke-outcome-select",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "penaltyStroke.outcome",
  showOn: [
    {
      state: "primary",
      value: "penalty-stroke-outcome-select",
      operator: "is",
    },
  ],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: {},
  children: [
    {
      name: "outcome-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "subType",
        children: Outcomes,
        layout: "type-button penaltyStrokeOutcomeBtn",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "saveData",
              value: true,
              type: "success",
            },
            { action: "setPosTracking", value: "reset" },
            {
              action: "saveData",
              value: "made",
              type: "subType",
            },
          ],
          conditions: [
            {
              item: "subType",
              value: "scored",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "saveData",
              value: false,
              type: "success",
            },
            {
              action: "saveData",
              value: "missed",
              type: "subType",
            },
          ],
          conditions: [
            {
              item: "subType",
              value: "missed",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "saveData",
              value: "penaltyStroke",
              type: "eventType",
            },
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "clearData",
              value: ["subType", "eventType", "success"],
              type: "main",
            },
            { action: "processInserts", value: "all", type: "inserts" },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
