export const ModifyShotMissedToMade = {
  name: "modify-shot-missed-to-made",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "edit.shot.success",
  showOn: [
    { state: "editState", value: "shot-success-missed", operator: "is" },
  ],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    {
      name: "shot-missed-to-made-description",
      component: "PanelText",
      layout: "panel-text",
      mode: "visible",
      showOn: [
        {
          state: "event.subType",
          value: "shot",
          operator: "not",
          event: "main",
        },
      ],
      options: {
        layout: "description-text",
        text: "edit.shot.missed.description.handball",
      },
      actionGroups: [],
    },
    {
      name: "shot-missed-to-made-change",
      component: "ButtonGroup",
      layout: "button-group true-false",
      mode: "visible",
      showOn: [true],
      startPlay: false,
      options: {
        value: "success",
        children: [true],
        layout: "type-button",
        prefix: "edit.shot.made.success",
      },
      actionGroups: [
        {
          actions: [
            { action: "sendEvent", value: "edit", type: "main" },
            { action: "deleteFromPlay", value: "block", type: "main" },
            {
              action: "adjustTeamValue",
              value: 1,
              options: { currentEntity: true, item: "score" },
            },
            { action: "nextState", value: null, type: "editState" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
