import React from "react";
import { injectIntl } from "react-intl";

const InsertChildControl = (props) => {
  const { insert, intl, currentState, updateState } = props;
  const { formatMessage } = intl;

  function _doAction() {
    currentState.events["parent"] = JSON.parse(JSON.stringify(insert.parent));
    updateState("insertParent", insert.parent);
    let newEvent = JSON.parse(JSON.stringify(insert.parent));
    delete newEvent.personId;
    delete newEvent.options;
    delete newEvent.subType;
    delete newEvent.success;
    delete newEvent.eventId;
    delete newEvent.pending;
    delete newEvent.scores;
    delete newEvent.x;
    delete newEvent.y;
    newEvent.eventType = insert.type;
    if (insert.subType) {
      newEvent.subType = insert.subType;
    }

    currentState.events["main"] = JSON.parse(JSON.stringify(newEvent));
    currentState.events["edit"] = JSON.parse(JSON.stringify(newEvent));
    updateState("editState", insert.panel);
  }

  return (
    <div className="insert button action" onClick={() => _doAction()}>
      {formatMessage({
        id: insert.text,
        defaultMessage: insert.text,
      })}
      <i className="fas fa-arrow-right"></i>
    </div>
  );
};

export default injectIntl(InsertChildControl);
