import React, { useContext } from "react";
import { injectIntl } from "react-intl";

import { FixtureDetailsContext } from "../../Config/FixtureDetails";

const GameRules = ({ intl: { formatMessage } }) => {
  const { fixtureProfile, sport } = useContext(FixtureDetailsContext);
  return (
    <div className="game-details--game-rules">
      <div className="game-details--section-header">
        {formatMessage({
          id: "details.gameRules",
          defaultMessage: "Game Rules",
        })}
      </div>
      <ul className="game-details--game-rules-list">
        <li>
          {`${formatMessage({
            id: "details.gameRules.numberOfPeriods",
            defaultMessage: "No. of periods",
          })}:`}
          <span>{fixtureProfile.numberOfPeriods}</span>
        </li>
        <li>
          {`${formatMessage({
            id: "details.gameRules.periodLength",
            defaultMessage: "Period length",
          })}:`}
          <span>{`${fixtureProfile.periodLength} ${formatMessage({
            id: "time.minutes",
            defaultMessage: "minutes",
          })}`}</span>
        </li>
        {sport === "basketball" && (
          <>
            {fixtureProfile.overtimeAllowed && (
              <li>
                {`${formatMessage({
                  id: "details.gameRules.overtimeLength",
                  defaultMessage: "Overtime length",
                })}:`}
                <span>{`${fixtureProfile.overtimeLength} ${formatMessage({
                  id: "time.minutes",
                  defaultMessage: "minutes",
                })}`}</span>
              </li>
            )}
            <li>
              {`${formatMessage({
                id: "details.gameRules.foulsBeforeBonus",
                defaultMessage: "Fouls before bonus",
              })}:`}
              <span>{fixtureProfile.foulsBeforeBonus}</span>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default injectIntl(GameRules);
