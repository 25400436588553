export const WarmupClock = {
  name: "warmup-clock-panel",
  component: "MainPanel",
  layout: "warmup-clock-panel main-panel",
  mode: "visible",
  text: "warmupClock",
  showOn: [{ state: "primary", value: "warmup-clock", operator: "is" }],
  startEvent: false,
  startPlay: false,
  allowFlag: false,
  allowCancel: false,
  children: [
    {
      name: "warm-clock-panel-content",
      component: "WarmupClock",
      layout: "warmup-clock",
      mode: "visible",
      showOn: [true],
      startEvent: false,
      startPlay: false,
      actionGroups: [],
    },
  ],
};
