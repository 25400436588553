import React, { useContext } from "react";
import { injectIntl } from "react-intl";
import FieldSVG from "../../../../assets/hockey/field.svg?react";

import { useFieldHighlight } from "./useFieldHighlight";
import { lightOrDark } from "../../../Base/Utilities/LightOrDark";
import { useTrackingKeys } from "./useTrackingKeys";
import {
  createPossessionChangeHandler,
  trackingPositions,
  trackingPositionHotkeys,
} from "./Field.logic";
import ArrowSingle from "./arrow-single.svg?react";
import { hexToRgba } from "../../../Base/Utilities/Color";
import { FixtureDetailsContext } from "../../../../Config/FixtureDetails";

const Field = (props) => {
  const { currentState, intl } = props;
  const { formatMessage } = intl;
  const { competitors } = useContext(FixtureDetailsContext);

  useFieldHighlight(props);

  useTrackingKeys(
    props,
    trackingPositionHotkeys.default,
    trackingPositions.default
  );

  const renderButton = (position, idx) => {
    // fist 5 position is the first team, second 5 position is the second team
    const positionCountByTeam = 5;
    const entityIdx = Math.floor(idx / positionCountByTeam);
    const entity = currentState.entities[entityIdx];

    const { posTracking } = currentState;

    const onButtonClick = createPossessionChangeHandler({
      entity,
      position,
      props,
    });

    const isAnotherButtonActive =
      posTracking &&
      posTracking.entity.entityId === entity.entityId &&
      posTracking.type !== position;

    const competitor = competitors.find(
      (comp) => comp.entityId === entity.entityId
    );

    return (
      <div
        className={`hockey-field-button hockey-field-button--${position} hockey-field-button--entity-${entityIdx} ${lightOrDark(
          entity.primaryColor
        )}`}
        key={`${position}-${idx}`}
        style={{
          gridArea: `button${idx + 1}`,
          backgroundColor: hexToRgba(
            entity.primaryColor,
            isAnotherButtonActive ? 0.3 : 1
          ),
        }}
        onClick={onButtonClick}
      >
        <span
          className={`hockey-field-button--name  ${
            isAnotherButtonActive ? "is-inactive" : ""
          }`}
        >
          <span>{competitor?.codeLocal}</span>
          <br />
          {position === "circle" && (
            <span>
              {formatMessage({
                id: "event.possession.circle",
                defaultMessage: "Circle Entry",
              })}
            </span>
          )}
          <ArrowSingle
            className={entityIdx === 0 ? "arrow-normal" : "arrow-reversed"}
          />
        </span>
        <span
          className="hockey-field-button--hotkey"
          style={{
            backgroundColor: entity.primaryColor,
          }}
        >
          {trackingPositionHotkeys.default[idx]}
        </span>
      </div>
    );
  };

  return (
    <div className="hockey-area">
      <FieldSVG id="base-play-area" />
      <div className="hockey-area-buttons">
        {trackingPositions.default.map(renderButton)}
      </div>
    </div>
  );
};

export default injectIntl(Field);
