export const ThrowOff = {
  name: "throw-off-panel",
  component: "MainPanel",
  layout: "throw-off-panel main-panel x-large align-center",
  mode: "visible",
  text: "throwOff",
  showOn: [{ state: "primary", value: "throw-off", operator: "is" }],
  startEvent: false,
  startPlay: false,
  allowFlag: false,
  allowCancel: true,
  children: [
    {
      name: "throw-off-panel-content",
      component: "ThrowOff",
      layout: "throw-off",
      mode: "visible",
      showOn: [true],
      startEvent: false,
      startPlay: false,
      actionGroups: [],
    },
  ],
};
