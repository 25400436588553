import React, { useEffect, useMemo, useState } from "react";
import { injectIntl } from "react-intl";

import { useIsVisible } from "../../../Base/Hooks/IsVisible";
import { useDoActions } from "../../../Base/Hooks/doActions";

import "./ButtonGroup.scss";

const ButtonGroup = (props) => {
  const { currentState, intl, panel, style, updateState } = props;
  const { formatMessage } = intl;
  const [groupValue, setGroupValue] = useState(null);
  const [isVisible, isEnabled] = useIsVisible(panel, props);
  const [doAction, setDoAction] = useState(false);
  const [actionsDone] = useDoActions(panel, props, doAction);

  useEffect(() => {
    if (actionsDone) {
      setDoAction(false);
    }
    // eslint-disable-next-line
  }, [actionsDone]);

  useEffect(() => {
    if (groupValue !== null) {
      if (panel.options.value) {
        const tempEvents = props.currentState.events;
        const optionsType = panel.options.type ? panel.options.type : "main";
        if (panel.options.value === "options") {
          tempEvents[optionsType].options = {};
          tempEvents[optionsType].options[groupValue] = true;
        } else if (panel.options.value === "singleOption") {
          tempEvents[optionsType].options =
            tempEvents[optionsType].options ?? {};
          // ! Dirty !! for some reason these options have to be uppercase
          if (panel.options?.valueType === "integer") {
            tempEvents[optionsType].options[panel.options.optionName] =
              parseInt(groupValue, 10);
          } else if (panel.options?.valueType === "boolean") {
            tempEvents[optionsType].options[groupValue] = true;
          } else {
            tempEvents[optionsType].options[panel.options.optionName] =
              groupValue.toString().toUpperCase();
          }
        } else {
          tempEvents[optionsType][panel.options.value] = groupValue;
        }
        updateState("events", tempEvents);
      }
      setDoAction(true);
    }
    // eslint-disable-next-line
  }, [groupValue]);

  const layout = useMemo(() => {
    if (panel && panel.options && panel.options.children) {
      return panel.options.children.map((button) => (
        <div
          key={button}
          className={
            panel.options.layout +
            (groupValue === button ||
            currentState.events[
              panel.options.type ? panel.options.type : "main"
            ][panel.options.value] === button ||
            (currentState.events[
              panel.options.type ? panel.options.type : "main"
            ].options &&
              currentState.events[
                panel.options.type ? panel.options.type : "main"
              ].options[button])
              ? " active"
              : "") +
            " " +
            button
          }
          onClick={(e) => setGroupValue(button)}
          style={style}
        >
          {panel.options.prefix &&
            formatMessage({
              id: panel.options.prefix + "." + button,
              defaultMessage: panel.options.prefix + "." + button,
            })}
          {!panel.options.prefix &&
            formatMessage({
              id: button,
              defaultMessage: button,
            })}
        </div>
      ));
    }
    // eslint-disable-next-line
  }, [panel, props]);

  return (
    <React.Fragment>
      {isVisible && (
        <div className={panel.layout + " enabled-" + isEnabled}>{layout}</div>
      )}
    </React.Fragment>
  );
};

export default injectIntl(ButtonGroup);
