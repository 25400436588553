import React from "react";
import { injectIntl } from "react-intl";

const GoBack = (props) => {
  const { panel, updateState, intl } = props;
  const { formatMessage } = intl;

  const _goBack = (flag) => {
    let events = props.currentState.events;
    events["main"] = events[panel.allowBack.event];
    if (panel.allowBack.save) {
      events["main"].back = true;
    }
    updateState("events", events);
    updateState(panel.allowBack.state, panel.allowBack.value);
  };

  return (
    <div className="go-back" onClick={() => _goBack()}>
      <i className="fas fa-angle-left"></i>
      <span>
        {formatMessage({
          id: "back",
          defaultMessage: "back",
        })}
      </span>
    </div>
  );
};

export default injectIntl(GoBack);
