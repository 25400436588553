import { CardTimeOption, IndoorCardTimeOption } from "../subTypes";

const createCardClockActions = (cardType) => [
  { action: "copyEvent", value: "card", type: "to" },
  { action: "newEventId", value: null, type: "main" },
  {
    action: "saveData",
    value: "clock",
    type: "class",
  },
  {
    action: "saveData",
    value: cardType,
    type: "eventType",
  },
  {
    action: "saveData",
    value: "adjust",
    type: "subType",
  },
  {
    action: "modifyEvent",
    value: "remapOptions",
    type: "main",
    toField: "value",
    fromField: "time",
    as: "String",
  },
  { action: "sendEvent", value: "new", type: "main" },
  { action: "copyEvent", value: "card-clock", type: "to" },
  { action: "copyEvent", value: "card", type: "from" },
];

export const CardTimeSelect = {
  name: "card-time",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "card.time",
  showOn: [{ state: "primary", value: "card-time", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: {},
  children: [
    {
      name: "card-time",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "seasonDetails.discipline",
          value: "OUTDOOR",
          operator: "is",
        },
      ],
      options: {
        value: "singleOption",
        optionName: "time",
        children: CardTimeOption,
        layout: "type-button",
        valueType: "integer",
      },
      actionGroups: [
        {
          actions: [...createCardClockActions("yellowCard")],
          conditions: [
            {
              item: "eventType",
              value: "yellowCard",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "nextState",
              value: "card-reason",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "card-time",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        { state: "seasonDetails.discipline", value: "INDOOR", operator: "is" },
      ],
      options: {
        value: "singleOption",
        optionName: "time",
        children: IndoorCardTimeOption,
        layout: "type-button",
        valueType: "integer",
      },
      actionGroups: [
        {
          actions: [...createCardClockActions("yellowCard")],
          conditions: [
            {
              item: "eventType",
              value: "yellowCard",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "nextState",
              value: "card-reason",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "card-time",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "seasonDetails.discipline",
          value: "HOCKEY5S",
          operator: "is",
        },
      ],
      options: {
        value: "singleOption",
        optionName: "time",
        children: IndoorCardTimeOption,
        layout: "type-button",
        valueType: "integer",
      },
      actionGroups: [
        {
          actions: [...createCardClockActions("yellowCard")],
          conditions: [
            {
              item: "eventType",
              value: "yellowCard",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "nextState",
              value: "card-reason",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
