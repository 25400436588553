const defaultSetup = {
  options: {
    productCode: "ingame",
    clientType: "InGame:0.9",
  },
  copyright: "Synergy Sports",
  languages: [
    "es-AR",
    "en-AU",
    "pt-BR",
    // "zh-CN", commented out as requested in comment from Paul SDC-881
    "fr-FR",
    "ja",
    "ko",
    "lv",
    "pt-PT",
    "es-ES",
    "zh-TW",
    "en-US",
    "de",
  ],
  scoreTypes: {
    "2pt": 2,
    "3pt": 3,
    freeThrow: 1,
    goal: 1,
    penaltyStroke: 1,
    sevenMetrePenalty: 1,
  },
  scoreSubTypes: {
    sevenMetrePenalty: "shot",
  },
  foulTypes: ["foul", "greenCard", "yellowCard", "redCard"],
  sanctionTypes: ["blueCard", "redCard", "yellowCard", "suspension"],
};

export default defaultSetup;
