export const teamAssignEnabledEvents = {
  basketball: ["2pt", "3pt", "foul", "jumpBall", "powerPlay", "rebound", "turnover"],
  handball: [
    "coinToss",
    "sevenMetrePenalty",
    "yellowCard",
    "redCard",
    "blueCard",
    "technicalFault",
    "suspension",
    "goal",
    "assist",
    "block",
    "goalKeeperChange",
    "shootOutToss",
    "steal",
    "turnover",
    "throwOff",
    "passivePlay",
  ],
};
