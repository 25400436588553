import React, { useEffect, useState, useCallback } from "react";

const HoverEventContext = React.createContext({});

const HoverEventProvider = ({children}) => {

  const [hoverEvent, setHoverEvent] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);

  const hoverStart = useCallback((event) => {
    setTimeoutId(setTimeout(() => {
      setHoverEvent(event);
    }, 500));
  }, []);

  const hoverEnd = useCallback(() => {
    clearTimeout(timeoutId);
    setHoverEvent(null);
  });

  return (
    <HoverEventContext.Provider value={{hoverEvent, hoverStart, hoverEnd}}>
      {children}
    </HoverEventContext.Provider>
  );
}
 
export { HoverEventContext, HoverEventProvider };

