import { GetVar } from "./Components/Base/Utilities/GetQueryVariable";

import messagesEN from "./translations/en.json";
import messagesES from "./translations/es.json";
import messagesFR from "./translations/fr.json";
import messagesJA from "./translations/ja.json";
import messagesKO from "./translations/ko.json";
import messagesLV from "./translations/lv.json";
import messagesPT from "./translations/pt.json";
import messagesZH_CN from "./translations/zh_CN.json";
import messagesZH_TW from "./translations/zh_TW.json";
import messagesDE from "./translations/de.json";

const localeToMessages = {
  "en-AU": messagesEN,
  "en-US": messagesEN,
  "es-ES": messagesES,
  "es-AR": messagesES,
  "fr-FR": messagesFR,
  ja: messagesJA,
  ko: messagesKO,
  lv: messagesLV,
  "pt-PT": messagesPT,
  "pt-BR": messagesPT,
  "zh-CN": messagesZH_CN,
  "zh-TW": messagesZH_TW,
  de: messagesDE,
};

export function setupLanguage() {
  const storedLocale =
    localStorage.getItem("atrium_locale") ?? GetVar("locale");
  const locale = !!storedLocale ? storedLocale : "en-AU";
  return { locale, messages: localeToMessages[locale] };
}
