const BaseTypes = ["goal", "penaltyCorner", "penaltyStroke", "card", "circleExit"];

const HockeyCircleEntryTypes = [...BaseTypes, "videoReview"];

const actionsGroups = [
  {
    actions: [
      {
        action: "clearData",
        value: ["options", "subType"],
        type: "main",
      },
      {
        action: "saveData",
        value: [{ option: "goalOutcome", value: "FIELD_GOAL" }],
        type: "options",
      },
    ],
    conditions: [
      {
        item: "eventType",
        value: "goal",
        event: "main",
        operator: "is",
      },
      {
        item: "optionValue",
        event: "main",
        operator: "not",
        name: "goalOutcome",
        value: "PENALTY_CORNER",
      },
    ],
  },
  {
    actions: [
      { action: "newEventId", value: null, type: "main" },
      {
        action: "saveData",
        value: true,
        type: "success",
      },
      { action: "newPlayId", value: null, type: "main" },
      { action: "sendEvent", value: "new", type: "main" },
      {
        action: "nextState",
        value: "goal-player",
        type: "primary",
      },
    ],
    conditions: [
      {
        item: "eventType",
        value: "goal",
        event: "main",
        operator: "is",
      },
    ],
  },
  {
    actions: [
      { action: "newEventId", value: null, type: "main" },
      {
        action: "clearData",
        value: ["options", "subType"],
        type: "main",
      },
      { action: "sendEvent", value: "new", type: "main" },
      {
        action: "clearData",
        value: ["eventType"],
        type: "main",
      },
      {
        action: "saveData",
        value: [{ option: "goalOutcome", value: "PENALTY_CORNER" }],
        type: "options",
      },
      {
        action: "forceNextState",
        value: "circle-entry-select",
        delayInMiliseconds: 1000,
        type: "primary",
      },
    ],
    conditions: [
      {
        item: "eventType",
        value: "penaltyCorner",
        event: "main",
        operator: "is",
      },
    ],
  },
  {
    actions: [
      { action: "newEventId", value: null, type: "main" },
      { action: "newPlayId", value: null, type: "main" },
      {
        action: "clearData",
        value: ["options"],
        type: "main",
      },
      {
        action: "saveData",
        value: "awarded",
        type: "subType",
      },
      {
        action: "copyEvent",
        value: "penaltyStroke",
        type: "to",
      },
      {
        action: "nextState",
        value: "penalty-stroke-card-select",
        type: "primary",
      },
    ],
    conditions: [
      {
        item: "eventType",
        value: "penaltyStroke",
        event: "main",
        operator: "is",
      },
    ],
  },
  {
    actions: [
      { action: "newEventId", value: null, type: "main" },
      {
        action: "clearData",
        value: ["options", "subType"],
        type: "main",
      },
      { action: "copyEvent", value: "possession", type: "to" },
      {
        action: "saveData",
        value: "possession",
        type: "eventType",
      },
      { action: "copyEvent", value: "possession", type: "to" },
      { action: "newEventId", value: null, type: "main" },
      { action: "clearData", value: ["eventType", "options"], type: "main" },
      {
        action: "saveData",
        value: [{ option: "fromCircleEntry", value: true }],
        type: "options",
      },
      {
        action: "nextState",
        value: "card-team-select",
        type: "primary",
      },
    ],
    conditions: [
      {
        item: "eventType",
        value: "card",
        event: "main",
        operator: "is",
      },
    ],
  },
  {
    actions: [
      { action: "newEventId", value: null, type: "main" },
      {
        action: "clearData",
        value: ["options", "eventType", "subType"],
        type: "main",
      },
      { action: "setPosTracking", value: "reset" },
      {
        action: "nextState",
        value: null,
        type: "primary",
      },
    ],
    conditions: [
      {
        item: "eventType",
        value: "circleExit",
        event: "main",
        operator: "is",
      },
    ],
  },
  {
    actions: [
      { action: "newEventId", value: null, type: "main" },
      {
        action: "clearData",
        value: ["options", "subType"],
        type: "main",
      },
      {
        action: "saveData",
        value: [{ option: "fromCircleEntry", value: true }],
        type: "options",
      },
      {
        action: "nextState",
        value: "video-review-type-select",
        type: "secondary",
      },
    ],
    conditions: [
      {
        item: "eventType",
        value: "videoReview",
        event: "main",
        operator: "is",
      },
    ],
  },
];

export const CircleEntrySelect = {
  name: "circle-entry-type",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "circleEntry.type",
  showOn: [{ state: "primary", value: "circle-entry-select", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "possession",
  showDetails: {},
  children: [
    {
      name: "circle-entry-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "seasonDetails.discipline",
          value: "OUTDOOR",
          operator: "is",
        },
      ],
      startEvent: false,
      startPlay: true,
      options: {
        value: "eventType",
        children: HockeyCircleEntryTypes,
        layout: "type-button",
      },
      actionGroups: actionsGroups,
    },
    {
      name: "circle-entry-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [{ state: "seasonDetails.discipline", value: "INDOOR", operator: "is" }],
      startEvent: false,
      startPlay: true,
      options: {
        value: "eventType",
        children: BaseTypes,
        layout: "type-button",
      },
      actionGroups: actionsGroups,
    },
  ],
};
