import { CardTypes } from "../Panels/CardTypeSelect";

export const ModifyCardType = {
  name: "card-time",
  component: "MainPanel",
  layout: "main-panel x-large",
  mode: "visible",
  text: "card.type",
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  showOn: [{ state: "editState", value: "card-type", operator: "is" }],
  allowCancelEvent: "main",
  children: [
    {
      name: "card-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "eventType",
        children: CardTypes,
        layout: "type-button",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "clearData",
              value: ["options"],
              type: "main",
            },
          ],
          conditions: [],
        },
        {
          actions: [
            {
              action: "nextState",
              value: "card-time",
              type: "editState",
            },
          ],
          conditions: [
            {
              item: "eventType",
              value: "yellowCard",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "nextState",
              value: "card-reason",
              type: "editState",
            },
          ],
          conditions: [
            {
              item: "eventType",
              value: "redCard",
              event: "main",
              operator: "is",
            },
          ],
        },

        {
          actions: [
            {
              action: "saveData",
              value: [{ option: "time", value: 2 }],
              type: "options",
            },
            { action: "sendEvent", value: "edit", type: "main" },
            {
              action: "nextState",
              value: null,
              type: "editState",
            },
          ],
          conditions: [
            {
              item: "eventType",
              value: "greenCard",
              event: "main",
              operator: "is",
            },
          ],
        },
      ],
    },
  ],
};
