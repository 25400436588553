import {
  TurnoverTypes,
  TeamTurnoverTypes,
} from "../subTypes/EliteTurnoverTypes";

export var ModifyTurnoverType = {
  name: "turnover-type",
  component: "MainPanel",
  layout: "main-panel x-large",
  mode: "visible",
  text: "turnover.type",
  showOn: [{ state: "editState", value: "turnover-type", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    // Player Turnover Types
    {
      name: "player-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.personId",
          value: null,
          operator: "not",
          event: "main",
        },
      ],
      startEvent: false,
      startPlay: false,
      options: {
        value: "subType",
        children: TurnoverTypes,
        layout: "type-button",
      },
      actionGroups: [
        {
          actions: [
            { action: "copyEvent", value: "turnover", type: "to" },
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
          ],
          conditions: [],
        },
        {
          actions: [{ action: "nextState", value: null, type: "editState" }],
          conditions: [],
        },
      ],
    },
    // Team Turnover Types
    {
      name: "team-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.personId",
          value: null,
          operator: "is",
          event: "main",
        },
      ],
      startEvent: false,
      startPlay: false,
      options: {
        value: "subType",
        children: TeamTurnoverTypes,
        layout: "type-button",
      },
      actionGroups: [
        {
          actions: [
            { action: "clearData", value: ["personId"], type: "main" },
            { action: "copyEvent", value: "turnover", type: "to" },
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
          ],
          conditions: [],
        },
        {
          actions: [{ action: "nextState", value: null, type: "editState" }],
          conditions: [],
        },
      ],
    },
  ],
};
