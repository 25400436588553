import { RedCardReasons, YellowCardReasons, BaseYellowCardReasons } from "../subTypes";

const sharedCardActions = [
  { action: "sendEvent", value: "new", type: "main" },
  { action: "newEventId", value: null, type: "main" },
  { action: "clearData", value: [{ option: "time" }, { option: "cardReason" }], type: "main" },
];

const yellowCardActions = [
  {
    actions: [{ action: "saveData", value: true, type: "flagged" }],
    conditions: [
      {
        item: "optionValue",
        event: "main",
        operator: "is",
        name: "cardReason",
        value: "CONFIRM_LATER",
      },
    ],
  },
  {
    actions: [
      ...sharedCardActions,
      { action: "processInserts", value: "all", type: "inserts" },
      {
        action: "nextState",
        value: "card-timer-start-select",
        type: "primary",
      },
    ],
  },
];

export const CardReasonSelect = {
  name: "card-reason",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "card.reason",
  showOn: [{ state: "primary", value: "card-reason", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: {},
  children: [
    {
      name: "card-reason-yellow",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.eventType",
          value: "yellowCard",
          operator: "is",
          event: "main",
        },
        {
          state: "seasonDetails.discipline",
          value: "OUTDOOR",
          operator: "is",
        },
      ],
      options: {
        value: "singleOption",
        optionName: "cardReason",
        children: YellowCardReasons,
        layout: "type-button",
        prefix: "cardReason",
      },
      actionGroups: yellowCardActions,
    },
    {
      name: "card-reason-yellow",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.eventType",
          value: "yellowCard",
          operator: "is",
          event: "main",
        },
        { state: "seasonDetails.discipline", value: "INDOOR", operator: "is" },
      ],
      options: {
        value: "singleOption",
        optionName: "cardReason",
        children: BaseYellowCardReasons,
        layout: "type-button",
        prefix: "cardReason",
      },
      actionGroups: yellowCardActions,
    },
    {
      name: "card-reason-yellow",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.eventType",
          value: "yellowCard",
          operator: "is",
          event: "main",
        },
        {
          state: "seasonDetails.discipline",
          value: "HOCKEY5S",
          operator: "is",
        },
      ],
      options: {
        value: "singleOption",
        optionName: "cardReason",
        children: BaseYellowCardReasons,
        layout: "type-button",
        prefix: "cardReason",
      },
      actionGroups: yellowCardActions,
    },
    {
      name: "card-reason-red",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.eventType",
          value: "redCard",
          operator: "is",
          event: "main",
        },
      ],
      options: {
        value: "singleOption",
        optionName: "cardReason",
        children: RedCardReasons,
        layout: "type-button",
        prefix: "cardReason",
      },
      actionGroups: [
        {
          actions: [{ action: "saveData", value: true, type: "flagged" }],
          conditions: [
            {
              item: "optionValue",
              event: "main",
              operator: "is",
              name: "cardReason",
              value: "CONFIRM_LATER",
            },
          ],
        },
        {
          actions: [
            ...sharedCardActions,
            { action: "processInserts", value: "all", type: "inserts" },
            {
              action: "nextState",
              value: null,
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "options",
              value: "fromCircleEntry",
              event: "main",
              operator: "not",
            },
            {
              item: "options",
              value: "fromPenaltyStroke",
              event: "main",
              operator: "not",
            },
          ],
          operator: "all",
        },
        {
          actions: [
            ...sharedCardActions,
            { action: "copyEvent", value: "penaltyStroke", type: "from" },
            {
              action: "nextState",
              value: "penalty-stroke-player-select",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "options",
              value: "fromPenaltyStroke",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            ...sharedCardActions,
            { action: "copyEvent", value: "possession", type: "from" },
            {
              action: "nextState",
              value: "circle-entry-select",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "options",
              value: "fromCircleEntry",
              event: "main",
              operator: "is",
            },
          ],
        },
      ],
    },
  ],
};
