export const PenaltyDrawnPlayerSelect = {
  name: "penalty-drawn-player",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "penalty.drawn-select-player",
  showOn: [{ state: "primary", value: "penalty-drawn-player", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["main"] },
  children: [
    {
      name: "penalty-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: true,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: false,
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "drawn", type: "subType" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "newEventId", value: null, type: "main" },
            { action: "saveData", value: "penalty", type: "eventType" },
            { action: "saveData", value: "major", type: "subType" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "copyEvent", value: "penalty", type: "from" },
            { action: "newEventId", value: null, type: "main" },
            { action: "saveData", value: "drawn", type: "subType" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [
            {
              item: "subType",
              value: "offsettingMajor",
              event: "penalty",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "saveData", value: "drawn", type: "subType" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "newEventId", value: null, type: "main" },
            { action: "saveData", value: "penalty", type: "eventType" },
            { action: "saveData", value: "minor", type: "subType" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "copyEvent", value: "penalty", type: "from" },
            { action: "newEventId", value: null, type: "main" },
            { action: "saveData", value: "drawn", type: "subType" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [
            {
              item: "subType",
              value: "offsettingMinor",
              event: "penalty",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "saveData", value: "penalty", type: "eventType" },
            { action: "saveData", value: "drawn", type: "subType" },
            { action: "copyEvent", value: "penaltyDrawn", type: "to" },
            {
              action: "clearData",
              value: ["options", "scores", "success"],
              type: "main",
            },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "newEventId", value: null, type: "main" },
            {
              action: "nextState",
              value: "penalty-shot-outcome",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "subType",
              value: ["offsettingMajor", "offsettingMinor"],
              event: "penalty",
              operator: "not",
            },
          ],
        },
      ],
    },
  ],
};
