import React, { useState, useEffect } from "react";

const SpeedTest = (props) => {
  const [networkSpeed, setNetworkSpeed] = useState(null);
  const [error, setError] = useState(false);
  const imageUrl =
    "https://s3-eu-west-1.amazonaws.com/tvgraphics/test-logo.png?" +
    Math.random();
  const downloadSize = 5616998;

  useEffect(() => {
    let downloadImgSrc = new Image();
    let time_start = new Date().getTime();
    downloadImgSrc.src = imageUrl;
    downloadImgSrc.onload = function () {
      let end_time = new Date().getTime();
      let timeDuration = (end_time - time_start) / 1000;
      let loadedBits = downloadSize * 8;
      let bps = (loadedBits / timeDuration).toFixed(2);
      let speedInKbps = (bps / 1024).toFixed(2);
      let speedInMbps = (speedInKbps / 1024).toFixed(2);

      setNetworkSpeed(speedInMbps);
      props.setHasInternet(true);
    };
    downloadImgSrc.onerror = function () {
      setError(true);
      props.setHasInternet(false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!error && (
        <>
          {!networkSpeed && <i className="fas fa-cog fa-spin"></i>}
          {networkSpeed && (
            <>
              <i className="fas fa-check" style={{ color: "green" }}></i>{" "}
              <span>{networkSpeed} mb</span>
            </>
          )}
        </>
      )}
      {error && (
        <>
          <i className="fas fa-ban" style={{ color: "red" }}></i>
          <span>No Internet</span>
        </>
      )}
    </>
  );
};

export default SpeedTest;
