import React, { useContext, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import { Button, Col, Container, Input, InputGroup, Row, Spinner } from "reactstrap";
import { v1 as uuidv1 } from "uuid";
import { ADMIN_APP_URL, APP_API_URL, SMP_API_URL, SMP_CLIENT_ID } from "../../Config/Environment";
import { GetVar } from "../Base/Utilities/GetQueryVariable";

import "./BasicForm.scss";
import { AuthContext } from "../../Config/Auth";

const BasicLogin = (props) => {
  const { updateState, intl, currentState } = props;
  const { formatMessage } = intl;
  const [credentials, setCredentials] = useState({
    username: null,
    password: null,
  });
  const [formError, setFormError] = useState(null);
  const [loginInProgress, setLoginInProgress] = useState(false);
  const [showInitialLoader, setShowInitialLoader] = useState(false);
  const { updateTokens, redirectUrl } = useContext(AuthContext);

  async function doSmpLogin(authCode, authType, state) {
    const responseReq = await fetch(`${APP_API_URL}/v1/client/login?authenticationType=${authType}`, {
      method: "POST",
      body: JSON.stringify({
        code: authCode,
        state: state,
      }),
    });
    const response = await responseReq.json();
    setShowInitialLoader(false);
    if (response.data) {
      const newAuthKey = response.data.access_token;
      const newRefreshToken = response.data.refresh_token;
      updateTokens({
        accessToken: newAuthKey,
        refreshToken: newRefreshToken,
      });
    } else {
      setFormError("Something went wrong with your SMP login. Please try again or contact support.");
    }
  }

  useEffect(() => {
    const authCode = GetVar("code");
    const authType = GetVar("authenticationType");
    if (authType === "smp" && authCode) {
      const state = GetVar("state");
      setShowInitialLoader(true);
      doSmpLogin(authCode, authType, state);
    }
  }, []);

  function handleCredentials(e, type) {
    let tempCredentials = credentials;
    tempCredentials[type] = e.target.value.trim();
    setCredentials(tempCredentials);
    if (tempCredentials.password && tempCredentials.username) {
      setFormError(null);
    }
  }

  async function doLogin() {
    setFormError(null);
    if (!credentials.password || !credentials.username) {
      setFormError("User Name or Password cannot be blank");
    } else {
      setLoginInProgress(true);
      const responseReq = await fetch(`${APP_API_URL}/v1/client/login`, {
        method: "POST",
        body: JSON.stringify({
          username: credentials.username,
          password: credentials.password,
        }),
      });
      const response = await responseReq.json();
      setLoginInProgress(false);
      if (response.error) {
        setFormError("User doesn't exist or the password is incorrect");
      } else if (response.data) {
        const newAuthKey = response.data.access_token;
        const newRefreshToken = response.data.refresh_token;
        updateTokens({
          accessToken: newAuthKey,
          refreshToken: newRefreshToken,
        });
      } else {
        setFormError("Something went wrong. Please try again.");
      }
    }
  }

  const onSubmit = (ev) => {
    ev.preventDefault();
    doLogin();
  };

  const getUrlToSMP = () => {
    const redirectUri = encodeURIComponent(
      `${window.location.origin}${window.location.pathname}?authenticationType=smp`,
    );
    const urlToSmp = `${SMP_API_URL}/oidc/authorize?response_type=code&client_id=${SMP_CLIENT_ID}&redirect_uri=${redirectUri}&state=${uuidv1()}`;
    return urlToSmp;
  };

  if (currentState.authKey) return <Redirect to={redirectUrl ? redirectUrl : "/fixtures"} />;

  if (showInitialLoader)
    return (
      <div
        style={{
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner size="md" color="orange" />
      </div>
    );

  return (
    <form onSubmit={onSubmit}>
      <Container className="BasicLogin setup-page">
        <Row>
          <Col sm="12" md={{ size: 6, offset: 3 }}>
            <h3>
              {formatMessage({
                id: "login",
                defaultMessage: "Login",
              })}
            </h3>
            {currentState.globalErrorMessage && (
              <div className="login-error-message">
                {formatMessage({
                  id: currentState.globalErrorMessage,
                  defaultMessage: "Something went wrong",
                })}
              </div>
            )}
            {formError && <div className="formError">{formError}</div>}
            <InputGroup>
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-user-tie"></i>
                </span>
              </div>
              <Input
                type="text"
                name=""
                className="form-control"
                placeholder={formatMessage({
                  id: "name.user",
                  defaultMessage: "User Name",
                })}
                onChange={(e) => handleCredentials(e, "username")}
              />
            </InputGroup>
            <br />
            <InputGroup>
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fa fa-key icon"></i>
                </span>
              </div>
              <Input
                type="Password"
                name=""
                className="form-control"
                placeholder={formatMessage({
                  id: "password",
                  defaultMessage: "Password",
                })}
                onChange={(e) => handleCredentials(e, "password")}
              />
            </InputGroup>
            <br />
            {loginInProgress ? (
              <Spinner size="md" color="orange" />
            ) : (
              <Button type="submit" color="success" className="float-right">
                {formatMessage({
                  id: "login",
                  defaultMessage: "Login",
                })}
              </Button>
            )}
          </Col>
          <Col sm="12" md={{ size: 6, offset: 3 }}>
            <hr />
          </Col>
          <Col sm="12" md={{ size: 6, offset: 3 }}>
            <a href={ADMIN_APP_URL} className="admin-link" target="_blank" rel="noopener noreferrer">
              {formatMessage({
                id: "login.admin",
                defaultMessage: "Admin Login",
              })}
              <i className="fas fa-arrow-right"></i>
            </a>
            <a href={getUrlToSMP()} className="admin-link" rel="noopener noreferrer" style={{ marginTop: "20px" }}>
              Login with SMP
              <i className="fas fa-arrow-right"></i>
            </a>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default injectIntl(BasicLogin);
