import "./ClockEditorSegment.scss";

const inputValidKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Backspace"];

const ClockEditorSegment = (props) => {
  const { value, rollover, onChange } = props;

  const handleValueChange = (delta) => {
    const newValue = parseInt(value) + delta;
    if (rollover) {
      if (newValue < rollover[0]) {
        onChange(rollover[1] - 1, -1);
      } else if (newValue > rollover[1]) {
        onChange(rollover[0] + 1, 1);
      } else {
        onChange(newValue, 0);
      }
    } else {
      onChange(newValue);
    }
  };

  const handleValueInput = (e) => {
    let newValue;
    if (rollover) {
      newValue = Math.max(rollover[0], Math.min(rollover[1] - 1, parseInt(e.target.value)));
    } else {
      newValue = parseInt(e.target.value);
    }
    onChange(newValue);
  };

  return (
    <div className="clock-editor-segment-root">
      <input
        value={value}
        inputMode="numeric"
        type="text"
        className="clock-editor-segment-time"
        onClick={(event) => {
          const { value } = event.target;
          const position = value.length;
          event.target.setSelectionRange(position, position);
        }}
        onChange={handleValueInput}
        onKeyDown={(event) => {
          if (!inputValidKeys.includes(event.key)) {
            event.preventDefault();
          }
        }}
      />
      <div className="clock-editor-segment-buttons">
        <div
          className="clock-editor-segment-button"
          onClick={() => {
            handleValueChange(1);
          }}
        >
          <i className="fas fa-caret-up" />
        </div>
        <div
          className="clock-editor-segment-button"
          onClick={() => {
            handleValueChange(-1);
          }}
        >
          <i className="fas fa-caret-down" />
        </div>
      </div>
    </div>
  );
};

export default ClockEditorSegment;
