export const CardButton = {
  name: "card-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "card",
  showForInsert: true,
  showOn: [true],
  actionGroups: [
    {
      actions: [{ action: "nextState", value: "card-type", type: "primary" }],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const PenaltyStrokeButton = {
  name: "penalty-stroke-button",
  component: "TypeButton",
  layout: "match-button pcOutsideCircleButton",
  mode: "visible",
  text: "penaltyStroke",
  showForInsert: false,
  showOn: [
    { state: "seasonDetails.discipline", value: "HOCKEY5S", operator: "is" },
  ],
  actionGroups: [
    {
      actions: [
        { action: "newEventId", value: null, type: "main" },
        {
          action: "clearData",
          value: ["options"],
          type: "main",
        },
        {
          action: "saveData",
          value: "penaltyStroke",
          type: "eventType",
        },
        {
          action: "saveData",
          value: "awarded",
          type: "subType",
        },
        {
          action: "nextState",
          value: "penalty-stroke-player-select",
          type: "primary",
        },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const PCOutsideCircleButton = {
  name: "penalty-corner-23m-button",
  component: "TypeButton",
  layout: "match-button pcOutsideCircleButton",
  mode: "visible",
  text: "pcOutsideCircle",
  showForInsert: false,
  showOn: [
    { state: "seasonDetails.discipline", value: "HOCKEY5S", operator: "not" },
  ],
  actionGroups: [
    {
      actions: [
        { action: "saveData", value: "penaltyCorner", type: "eventType" },
        { action: "sendEvent", value: "new", type: "main" },
        {
          action: "saveData",
          value: [{ option: "goalOutcome", value: "PENALTY_CORNER" }],
          type: "options",
        },
        { action: "clearData", value: ["eventType"], type: "main" },
        { action: "processInserts", value: "all", type: "inserts" },
        { action: "nextState", value: "circle-entry-select", type: "primary" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const SwitchTeamsButton = {
  name: "switch-teams-button",
  component: "TeamSwitch",
  layout: "match-button match-button--hockey small",
  mode: "enable",
  sendUpdateArrow: false,
  showOn: [{ state: "clock.clockRunning", value: false, operator: "is" }],
  startEvent: false,
  startPlay: false,
};

export const VideoReviewButton = {
  name: "video-review-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "videoReview",
  showForInsert: true,
  showOn: [
    { state: "period.periodStatus", value: "inProgress", operator: "is" },
  ],
  actionGroups: [
    {
      actions: [
        {
          action: "clearData",
          value: ["options"],
          type: "main",
        },
        {
          action: "nextState",
          value: "video-review-type-select",
          type: "secondary",
        },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};
