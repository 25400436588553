export const ShootoutClockController = {
  name: "shootout-clock-panel",
  component: "MainPanel",
  layout: "shootout-clock-panel main-panel",
  mode: "visible",
  text: "shootOutClockController",
  showOn: [{ state: "primary", value: "shootOut-clock", operator: "is" }],
  tartEvent: false,
  startPlay: false,
  allowFlag: false,
  allowCancel: true,
  children: [
    {
      name: "shootout-clock-panel-content",
      component: "ShootoutClockController",
      layout: "shootout-clock",
      mode: "visible",
      showOn: [true],
      startEvent: false,
      startPlay: false,
      actionGroups: [],
    },
  ],
};
