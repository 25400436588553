import React from "react";
import { injectIntl } from "react-intl";

const Bookmark = (props) => {
  const { currentState, updateState, intl } = props;
  const { formatMessage } = intl;

  const _setFlagged = (flag) => {
    let tempEvents = currentState.events;
    tempEvents.main.flagged = flag;
    updateState("events", tempEvents);
  };

  return (
    <div
      className={"book-mark marked-" + currentState?.events?.main?.flagged}
      onClick={() => _setFlagged(!currentState?.events?.main?.flagged)}
    >
      <span>
        {formatMessage({
          id: "bookmark",
          defaultMessage: "bookmark",
        })}
      </span>
      <i className="fas fa-bookmark"></i>
      <i className="star fas fa-star"></i>
    </div>
  );
};

export default injectIntl(Bookmark);
