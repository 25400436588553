export const ignoreSubkeyEventTypes = ["2pt", "3pt", "turnover", "freeThrow", "goal", "technicalFault", "steal"];
export const includeSuccessEventTypes = ["2pt", "3pt", "freeThrow", "goal", "sevenMetrePenalty"];

export const includeOptionField = [
  {
    eventType: "goal",
    optionField: "goalOutcome",
  },
];

export const eventsWithCustomTranslations = {
  turnover: "foulLedToTurnover",
};

export function getEventTypeCountForEntity(eventType, entityId, events) {
  return events.filter(
    (event) => event.class === "sport" && event.entityId === entityId && eventType === event.eventType,
  ).length;
}

export function getEventTypeEventsForEntity(eventType, entityId, events) {
  return events.filter(
    (event) => event.class === "sport" && event.entityId === entityId && eventType === event.eventType,
  );
}
