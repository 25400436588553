export const ModifyThrowOff = {
  name: "modify-throw-off",
  component: "MainPanel",
  layout: "throw-off-panel main-panel x-large align-center",
  mode: "visible",
  text: "throwOff.select",
  allowFlag: true,
  allowCancel: true,
  showOn: [{ state: "editState", value: "modify-throw-off", operator: "is" }],
  children: [
    {
      name: "throw-off-modify-description",
      component: "PanelText",
      layout: "panel-text",
      mode: "visible",
      showOn: [true],
      options: {
        layout: "description-text",
        text: "Changing the throw off team",
      },
    },
    {
      name: "throw-off-modify",
      component: "ButtonGroup",
      layout: "button-group true-false",
      mode: "visible",
      showOn: [true],
      startPlay: false,
      options: {
        value: "success",
        children: [false],
        layout: "type-button",
        prefix: "throwOff.teamChange",
      },
      actionGroups: [
        {
          actions: [
            { action: "modifyEvent", value: "swapEntities", type: "main" },
            { action: "sendEvent", value: "edit", type: "main" },
            {
              action: "setPossession",
              value: { currentEntity: true },
              type: "main",
            },
            { action: "nextState", value: null, type: "editState" },
          ],
        },
      ],
    },
  ],
};
