import React, { useState, useRef, useEffect, useContext } from "react";
import { injectIntl } from "react-intl";
import { ConnectionContext } from "../../../Config/Connection";
import ClockQuery from "../../Collect/ClockQuery/ClockQuery";
import ScoreQuery from "../../Collect/ScoreQuery/ScoreQuery";
import Button from "../Button/Button";
import { useOnClickOutside } from "../Hooks/useOnClickOutside";
import { sendNotification } from "../Utilities/SendEvent";
import { sendClockEvent } from "../Utilities/ClockEvents";
import "./Notification.scss";
import { createNotificationEvent } from "./Notification.utils";
import { APP_API_URL } from "../../../Config/Environment";
import { useLocation } from "react-router-dom";
import Logger from "../Utilities/Logger";
import EventQuery from "../../Collect/EventQuery/EventQuery";

const logger = Logger.getInstance();

let timeoutId = null;

/*
 * notifications: Array<{
     clientId: string;
     fixtureId: string;
     id: string;
     notificationType: "clock" | "score"
     organizationId: string;
     received: number; // timestamp
     requester: string;
     sport: string;
     timestamp: string;
     topic: string;
     type: string;
 * }>
 */
function Notification(props) {
  const location = useLocation();
  const { currentState, updateState, intl } = props;
  const { formatMessage } = intl;
  const { sigToken, clientId, hasLoaded } = useContext(ConnectionContext);
  const { notifications, clock, entities, fixtureId } = currentState;
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const isOnline = currentState.connected && currentState.mqttConnected;
  const notificationRef = useRef();

  const outsideClickHandler = () => setDropdownOpen(false);

  const fetchNotifications = async () => {
    try {
      const response = await fetch(
        `${APP_API_URL}/v1/basketball/stream_notifications/${fixtureId}?token=${sigToken}`
      ).then((resp) => resp.json());

      const unansweredNotifications = (response?.data ?? [])
        .filter((notification) => !notification.response)
        .filter((notification) =>
          notifications.every(
            (savedNotification) => savedNotification.id !== notification.id
          )
        )
        .map((notification) => ({
          fixtureId: notification.fixture_id,
          id: notification.id,
          notificationType: notification.notification_type,
          organizationId: notification.organization_id,
          requester: notification.requester,
          response: notification.response,
          sport: notification.sport,
          topic: `s/b/${notification.organization_id}/${notification.fixture_id}/w/sn`,
          metadata: notification.metadata,
        }));

      updateState("notifications", [
        ...notifications,
        ...unansweredNotifications,
      ]);
    } catch (err) {
      logger.log(err);
    }
  };

  useEffect(() => {
    if (sigToken && isOnline) {
      fetchNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sigToken, isOnline]);

  useOnClickOutside(notificationRef, outsideClickHandler);

  if (!hasLoaded || !location.pathname.includes("/game")) {
    return null;
  }

  const onBellClick = () => {
    if (isDropdownOpen) {
      setDropdownOpen(false);
      return;
    }

    if (notifications.length === 1) {
      setSelectedNotification(notifications[0]);
    } else {
      setDropdownOpen(true);
    }
  };

  const renderNotification = (notification) => {
    if (notification.notificationType === "score") {
      return renderScoreNotification(notification);
    }

    if (notification.notificationType === "clock") {
      return renderClockNotification(notification);
    }

    if (notification.notificationType === "event") {
      return renderEventNotification(notification);
    }

    return null;
  };

  const renderEventNotification = (notification) => {
    return (
      <div className="notification-dropdown__list-item">
        <div>
          <i className="fas fa-exclamation-triangle"></i>
          <span>
            {formatMessage({
              id: "scoreQuery.eventQuery",
              defaultMessage: "Event query request",
            })}
          </span>
        </div>
        <Button
          onClick={() => setSelectedNotification(notification)}
          variant="success"
        >
          {formatMessage({
            id: "resolve",
            defaultMessage: "Resolve",
          })}
        </Button>
      </div>
    );
  };

  const renderScoreNotification = (notification) => {
    return (
      <div className="notification-dropdown__list-item">
        <div>
          <i className="fas fa-exclamation-triangle"></i>
          <span>
            {formatMessage({
              id: "scoreQuery.scoreRequest",
              defaultMessage: "Score query request",
            })}
          </span>
        </div>
        <Button
          onClick={() => setSelectedNotification(notification)}
          variant="success"
        >
          {formatMessage({
            id: "resolve",
            defaultMessage: "Resolve",
          })}
        </Button>
      </div>
    );
  };

  const renderClockNotification = (notification) => {
    return (
      <div className="notification-dropdown__list-item">
        <div>
          <i className="fas fa-exclamation-triangle"></i>
          <span>
            {formatMessage({
              id: "scoreQuery.clockRequest",
              defaultMessage: "Game Clock request",
            })}
          </span>
        </div>
        <Button
          onClick={() => setSelectedNotification(notification)}
          variant="success"
        >
          {formatMessage({
            id: "resolve",
            defaultMessage: "Resolve",
          })}
        </Button>
      </div>
    );
  };

  const sendScoreNotification = (entity1Score, entity2Score) => {
    const payload = createNotificationEvent(
      currentState,
      selectedNotification,
      clientId,
      {
        [entities[0].entityId]: entity1Score,
        [entities[1].entityId]: entity2Score,
      }
    );

    sendNotification(props, payload, selectedNotification.topic);
    clearNotification();

    updateState("entities", [
      {
        ...entities[0],
        score: entity1Score,
      },
      {
        ...entities[1],
        score: entity2Score,
      },
    ]);
  };

  const sendClockNotification = (mins, secs) => {
    const technicalFormat = `PT${mins}M${secs}S`;
    const payload = createNotificationEvent(
      currentState,
      selectedNotification,
      clientId,
      {
        clock: technicalFormat,
      }
    );

    sendNotification(props, payload, selectedNotification.topic);
    clearNotification();
    sendClockEvent("clock", "main", "adjust", props, "value", technicalFormat);
    updateState("clock", {
      ...clock,
      displayTime: `${String(mins).padStart(2, "0")}:${String(secs).padStart(
        2,
        "0"
      )}`,
    });
  };

  const sendEventNotification = () => {
    const payload = createNotificationEvent(
      currentState,
      selectedNotification,
      clientId
    );

    updateState("eventToReview", selectedNotification);

    sendNotification(props, payload, selectedNotification.topic);
    clearNotification();

    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }

    timeoutId = window.setTimeout(
      () => updateState("eventToReview", null),
      30 * 1000
    );
  };

  const clearNotification = () => {
    const notificationIndex = notifications.findIndex(
      (notification) => selectedNotification.id === notification.id
    );
    if (notificationIndex > -1) {
      updateState("notifications", [
        ...notifications.slice(0, notificationIndex),
        ...notifications.slice(notificationIndex + 1),
      ]);
      setSelectedNotification(null);
    }
  };

  return (
    <>
      <div
        className={`notification ${isDropdownOpen ? "open" : ""}`}
        ref={notificationRef}
        onClick={onBellClick}
      >
        <div className="notification-bell-container">
          <i className="fas fa-bell"></i>
          {notifications.length > 0 && (
            <span className="notification-bell-counter">
              <span>{notifications.length}</span>
            </span>
          )}
        </div>
        {isDropdownOpen && (
          <div className="notification-dropdown">
            <h2 className="notification-dropdown__title">Notifications</h2>
            <div className="notification-dropdown__list">
              {notifications.map((notification) =>
                renderNotification(notification)
              )}
            </div>
          </div>
        )}
      </div>
      {selectedNotification?.notificationType === "score" && (
        <ScoreQuery
          entities={entities}
          onSubmit={sendScoreNotification}
          onClose={() => setSelectedNotification(null)}
        />
      )}
      {selectedNotification?.notificationType === "clock" && (
        <ClockQuery
          clock={clock}
          onSubmit={sendClockNotification}
          onClose={() => setSelectedNotification(null)}
        />
      )}

      {selectedNotification?.notificationType === "event" && (
        <EventQuery
          notification={selectedNotification}
          onClose={sendEventNotification}
        />
      )}
      {notifications?.length > 0 && !isDropdownOpen > 0 && (
        <div className="notification-warning">
          <strong>
            {formatMessage({
              id: "scoreQuery",
              defaultMessage: "Score query",
            })}
            :
          </strong>
          <span>
            {formatMessage({
              id: "scoreQuery.warningMessage",
              defaultMessage:
                "Please check the score against the scoreboard and confirm they are correct at your earliest convenience",
            })}
          </span>
          <Button variant="transparent" onClick={onBellClick}>
            {formatMessage({
              id: "scoreQuery.warningButtonLabel",
              defaultMessage: "Check now",
            })}
          </Button>
        </div>
      )}
    </>
  );
}

export default injectIntl(Notification);
