import React, { useState, useEffect } from "react";
import { Spinner, Container } from "reactstrap";
import { Link } from "react-router-dom";

import { useFetch } from "../Base/Hooks/basicFetch";

import DebugData from "./DebugData";

const StreamDebug = (props) => {
  const { match, updateState } = props;
  //   const [isSending, setIsSending] = useState();
  const [connectionData, setConnectionData] = useState();
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [authKey, setAuthKey] = useState(null);
  const { error, loading, data } = useFetch(
    "/v1/client/fixtures/" + match.params.fixtureId + "/connection",
    {
      method: "GET",
      values: null,
      key: authKey,
    },
    refreshFlag
  );

  useEffect(() => {
    setAuthKey(localStorage.getItem("authKey"));
    setRefreshFlag(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      setConnectionData(data.data);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <React.Fragment>
      <Container className="setup-page">
        {loading && <Spinner size="md" color="orange" />}
        {!error && !loading && (
          <div>
            <h3>Stream Details Loaded</h3>
            <Link
              to={"/game/" + match.params.fixtureId}
              className="link btn btn-sm btn-success"
            >
              Go to game <i className="fas fa-angle-right"></i>
            </Link>
            <div>{JSON.stringify(connectionData)}</div>
            {connectionData && (
              <DebugData
                updateState={updateState}
                connectUrl={connectionData.url}
                clientId={connectionData.clientId}
                topics={connectionData.topics}
              />
            )}
          </div>
        )}
      </Container>
    </React.Fragment>
  );
};

export default StreamDebug;
