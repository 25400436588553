import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { v1 as uuidv1 } from "uuid";

import TypeButton from "../ButtonPanel/TypeButton/TypeButton";

import { createBaseEvent } from "../../Base/Utilities/CreateBaseEvent";
import { sendEvent } from "../../Base/Utilities/SendEvent";

import "./CoinToss.scss";

const CoinToss = (props) => {
  const { currentState, intl, updateState } = props;
  const { formatMessage } = intl;
  const [teamSelects, setTeamSelects] = useState();

  useEffect(() => {
    if (!teamSelects?.length) {
      buildTeams(onTossWinnerSelect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, currentState.entities]);

  function buildTeams(onClickHandler) {
    setTeamSelects(
      currentState.entities.map((team, index) => {
        const panel = {
          options: { freeText: team.fullNameLatin },
          showOn: [true],
          layout: "team-button",
        };

        return (
          <TypeButton
            {...props}
            key={team.entityId}
            panel={panel}
            team={index}
            style={{
              background: team.primaryColor,
            }}
            onClick={() => onClickHandler(team)}
          />
        );
      })
    );
  }

  function onTossWinnerSelect(team) {
    sendTossEvent(team.entityId);
    updateState("primary", null);
  }

  function sendTossEvent(winnerId) {
    const id = uuidv1();
    currentState.entities.forEach((entity) => {
      const action = createBaseEvent(currentState, id);

      action.event.eventType = "toss";
      action.event.entityId = entity.entityId;
      action.event.subType = entity.entityId === winnerId ? "won" : "lost";
      action.event.success = entity.entityId === winnerId;

      if (entity.entityId === winnerId) {
        updateState("posTracking", { type: "defensive", entity });
      }

      sendEvent(action, props);
    });
  }

  return (
    <React.Fragment>
      <h2 className="align-center">
        {formatMessage({
          id: "coinToss.winner",
          defaultMessage: "Select Toss winner",
        })}
      </h2>

      <div className="team-select-buttons">{teamSelects}</div>
    </React.Fragment>
  );
};

export default injectIntl(CoinToss);
