import React, { useEffect, useState, useCallback } from "react";
import { withRouter } from "react-router-dom";

import { useFetch } from "../Components/Base/Hooks/basicFetch";
import Logger from "../Components/Base/Utilities/Logger";
import { extractErrorReason } from "../Components/Base/Utilities/Network";
import SequenceIdGenerator from "../Components/Base/Utilities/SequenceIdGenerator";
import DataConnector from "../Components/Data/Data2";

const logger = Logger.getInstance();

const ConnectionContext = React.createContext({});

const RECONNECTION_TIMEOUT = 3000;

let reconnectionInterval = undefined;

const sequenceIdGenetor = SequenceIdGenerator.getInstance();

const BaseConnectionProvider = ({
  children,
  currentState,
  updateState,
  manageEvents,
  managePlays,
  fixtureId,
}) => {
  const [connectionState, setConnectionState] = useState({ hasLoaded: false });
  const [authKey, setAuthKey] = useState();
  const [refreshFlag, setRefreshFlag] = useState(false);

  const {
    loading: isLoading,
    data: connectionData,
    error: connectionDataError,
  } = useFetch(
    "/v1/client/fixtures/" + fixtureId + "/connection",
    {
      method: "GET",
      values: null,
      key: authKey,
    },
    refreshFlag && !!fixtureId
  );

  const forceRefresh = () => {
    setRefreshFlag(true);
  };

  const updateAuthKey = useCallback(
    (newAuthKey) => {
      if (newAuthKey && newAuthKey !== authKey) {
        setAuthKey(newAuthKey);
        setRefreshFlag(true);
      }
    },
    [authKey]
  );

  useEffect(() => {
    updateAuthKey(currentState.authKey);
  }, [currentState.authKey, updateAuthKey]);

  useEffect(() => {
    const newAuthKey = localStorage.getItem("authKey");
    updateAuthKey(newAuthKey);
  }, [updateAuthKey]);

  useEffect(() => {
    if (isLoading) {
      logger.log("Conn:", "Getting Connection Details");
    }
  }, [isLoading]);

  useEffect(() => {
    if (!connectionData) {
      setConnectionState({ hasLoaded: false });
    } else {
      const hasLoaded = connectionData && !isLoading && !connectionDataError;

      if (hasLoaded && reconnectionInterval !== undefined) {
        clearTimeout(reconnectionInterval);
        reconnectionInterval = undefined;
      }

      setConnectionState({
        hasLoaded: hasLoaded,
        forceRefresh: forceRefresh,
        ...connectionData?.data,
      });
    }
  }, [connectionData, isLoading, connectionDataError]);

  useEffect(() => {
    if (connectionDataError) {
      const reason = extractErrorReason(connectionDataError);

      if (reason === "NOT_AUTHORISED") {
        updateState("authKey", null);
        // TODO: ask AG for error message
        updateState("globalErrorMessage", "permissionError");
        localStorage.removeItem("authKey");
      }
    }
  }, [connectionDataError, updateState]);

  const tryReconnect = useCallback(() => {
    // there is no `refetch` callback in the `useFetch` hook
    // so this is the only way to trigger an instant refetch
    setRefreshFlag(false);
    updateState("reconnecting", true);
    setTimeout(() => setRefreshFlag(true), 0);
  }, [updateState]);

  useEffect(() => {
    if (connectionDataError) {
      logger.log("Conn:", "Could not get connection details");
      updateState("reconnecting", false);
      updateState("forceReconnect", false);

      reconnectionInterval = setTimeout(tryReconnect, RECONNECTION_TIMEOUT);
    }
  }, [connectionDataError, updateState, tryReconnect]);

  useEffect(() => {
    if (connectionState.clientId) {
      sequenceIdGenetor.setClientId(connectionState.clientId);
    }
  }, [connectionState.clientId]);

  useEffect(() => {
    logger.log("Conn:", "Refresh Flag -", refreshFlag.toString());
  }, [refreshFlag]);

  return (
    <ConnectionContext.Provider value={connectionState}>
      <>{children}</>
      {connectionState.hasLoaded && (
        <DataConnector
          updateState={updateState}
          connectUrl={connectionState.url}
          clientId={connectionState.clientId}
          currentState={currentState}
          topics={connectionState.topics}
          manageEvents={manageEvents}
          managePlays={managePlays}
          setRefreshFlag={setRefreshFlag}
          fixtureId={fixtureId}
        />
      )}
    </ConnectionContext.Provider>
  );
};

const ConnectionProvider = withRouter(BaseConnectionProvider);

export { ConnectionContext, ConnectionProvider };
