import { sendEvent } from "../../../../Components/Base/Utilities/SendEvent";

export function modifyTimeOutTeam(props, team, ctx) {
  let changeEvent = { ...props.event };

  changeEvent.status = "updated";
  if (team === "home") {
    changeEvent.entityId = ctx.competitors[0].isHome ? ctx.competitors[0].entityId : ctx.competitors[1].entityId;
  } else {
    changeEvent.entityId = ctx.competitors[0].isHome ? ctx.competitors[1].entityId : ctx.competitors[0].entityId;
  }

  let updateAction = {
    action: "sendEvent",
    event: changeEvent,
    type: "custom",
  };
  sendEvent(updateAction, props);
}
