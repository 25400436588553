import { ShotTypes } from "./../subTypes";

export const ShotTypeSelect = {
  name: "shot-type",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "shot.type",
  showOn: [{ state: "primary", value: "shot-type", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "shot-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "subType",
        children: ShotTypes,
        layout: "type-button",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "sendEvent",
              value: "new",
              type: "main",
            },
            { action: "copyEvent", value: "shot", type: "to" },
            {
              action: "nextState",
              value: "shot-player",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "made-shot-options",
      component: "OptionGroup",
      layout: "option-group",
      mode: "visible",
      showOn: [
        {
          state: "event.success",
          value: true,
          operator: "is",
          event: "main",
        },
      ],
      options: {
        value: "options",
        children: ["emptyNetGoal"],
        layout: "option-button",
      },
    },
  ],
};
