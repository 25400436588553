import React, { useEffect, useState } from "react";
import { v1 as uuidv1 } from "uuid";
import { injectIntl } from "react-intl";

import { getEventTime, sendEvent } from "../../../../Base/Utilities/SendEvent";

const FreeThrowResult = (props) => {
  const {
    item,
    total,
    intl,
    updateState,
    updateResults,
    entityId,
    playId,
    currentState,
    fastBreak,
    wasSuccess,
    shooter,
  } = props;
  const { formatMessage } = intl;
  const [success, setSuccess] = useState(null);
  const [eventId, setEventId] = useState();
  const [sendMode, setSendMode] = useState("new");

  function _doAction(doAction, force = false) {
    if (success !== doAction || force) {
      setSuccess(doAction);
      updateResults(item - 1, doAction);

      const uuid = uuidv1();
      if (sendMode === "new") {
        setEventId(uuid);
      }

      let currentEvents = currentState.events;

      currentEvents.main["playId"] = playId;
      currentEvents.main["eventId"] = sendMode === "new" ? uuid : eventId;
      currentEvents.main["eventType"] = "freeThrow";
      currentEvents.main["personId"] = shooter;
      currentEvents.main["subType"] = item + "Of" + total;
      currentEvents.main["success"] = doAction;
      currentEvents.main["entityId"] = entityId;
      currentEvents.main["timestamp"] = new Date().toISOString();
      currentEvents.main["eventTime"] = getEventTime();

      if (currentState.insertMode && currentState.insertMode.enabled) {
        let insertDate = new Date(currentEvents.foul["eventTime"]);
        insertDate.setMilliseconds(insertDate.getMilliseconds() + 10);
        currentEvents.main["eventTime"] = insertDate.toISOString();
      }

      if (currentEvents.main["status"] === "insert") {
        if (currentState.insertParent) {
          let clearDate = currentState.insertParent["eventTime"].replace(
            "Z",
            ""
          );
          currentEvents["parent"] = currentState.insertParent;
          let insertDate = new Date(clearDate + "Z");
          insertDate.setMilliseconds(insertDate.getMilliseconds() + 10);
          currentEvents.main["eventTime"] = insertDate.toISOString();
          currentEvents.main["clock"] = currentEvents.foul["clock"];
          currentEvents.main["periodId"] = currentEvents.foul["periodId"];
        }
      }
      if (currentEvents.main.options) {
        const deleteOptions = [
          "shooting",
          "looseBall",
          "bloodRule",
          "mandatory",
          "blocked",
          "pointsInThePaint",
          "heldBall",
        ];
        deleteOptions.forEach((option) => {
          delete currentEvents.main.options[option];
        });
        currentEvents.main.options.successes[item - 1] = doAction;
        if (fastBreak) {
          currentEvents.main.options.fastBreak = true;
        } else {
          if (currentEvents.main.options.fastBreak)
            delete currentEvents.main.options.fastBreak;
        }
      }

      updateState("events", currentEvents);

      const action = {
        action: "sendEvent",
        value: sendMode,
        type: "main",
      };
      sendEvent(action, props);

      if (doAction) {
        if (currentEvents.main["status"] !== "insert") {
          _adjustScore(entityId, 1);
        }
      } else {
        if (sendMode === "edit") {
          _adjustScore(entityId, -1);
        }
      }

      setSendMode("edit");
    }
  }

  function _adjustScore(entityId, value) {
    if (
      !props.currentState.insertMode ||
      (props.currentState.insertMode && !props.currentState.insertMode.enabled)
    ) {
      let tempEntities = currentState.entities;
      let team = tempEntities.findIndex((el) => el.entityId === entityId);
      tempEntities[team]["score"] =
        parseInt(tempEntities[team]["score"]) + parseInt(value);
      updateState("entities", tempEntities);
    }
  }

  useEffect(() => {
    _doAction(success, success !== null);
    // eslint-disable-next-line
  }, [fastBreak, shooter]);

  return (
    <React.Fragment>
      <div
        className={
          "freethrow-result success-" +
          success +
          " enabled-" +
          (shooter !== null &&
            (wasSuccess === null || wasSuccess === undefined))
        }
      >
        <div>
          {item} of {total}
        </div>
        <div
          className={"enabled-true match-button made"}
          onClick={(e) => _doAction(true)}
        >
          {formatMessage({
            id: "made",
            defaultMessage: "made",
          })}
        </div>
        <div
          className={"enabled-true match-button missed"}
          onClick={(e) => _doAction(false)}
        >
          {formatMessage({
            id: "missed",
            defaultMessage: "missed",
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default injectIntl(FreeThrowResult);
