import React, { useContext } from "react";

import { FixtureDetailsContext } from "../../../../Config/FixtureDetails";
import Player from "./Player";

import "./Players.scss";

function Players(props) {
  const { players, entity, teamColor } = props;
  const { season } = useContext(FixtureDetailsContext);

  const { starters, subs } = players.reduce(
    (soFar, current) => {
      if (current.starter) {
        soFar.starters.push(current);
      } else {
        soFar.subs.push(current);
      }

      return soFar;
    },
    { starters: [], subs: [] }
  );

  return (
    <div className="hockey-players-container">
      {season.discipline !== "HOCKEY5S" ? (
        <>
          <h1 className="hockey-players-title">Starters</h1>
          <div className="hockey-players-layout">
            {starters.map((player) => (
              <Player
                key={player.personId}
                entity={entity}
                player={player}
                teamColor={teamColor}
                {...props}
              >
                {player.bib}
              </Player>
            ))}
          </div>

          {!!subs.length && (
            <>
              <h1 className="hockey-players-title">Substitutes</h1>
              <div className="hockey-players-layout">
                {subs.map((player) => (
                  <Player
                    key={player.personId}
                    entity={entity}
                    player={player}
                    teamColor={teamColor}
                    {...props}
                  >
                    {player.bib}
                  </Player>
                ))}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <h1 className="hockey-players-title">Players</h1>
          <div className="hockey-players-layout">
            {players.map((player) => (
              <Player
                key={player.personId}
                entity={entity}
                player={player}
                teamColor={teamColor}
                variant="hockey5s"
                {...props}
              >
                {player.bib}
              </Player>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Players;
