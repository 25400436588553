import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { injectIntl } from "react-intl";

import Substitutions from "./Substitutions";

const JointSubstitutions = (props) => {
  const { currentState, intl, setIsSubsVisible, preSubs, togglePreSubs } =
    props;
  const { formatMessage } = intl;
  const [done, setDone] = useState(false);
  const [subsValidity, setSubsValidity] = useState(null);

  useEffect(() => {
    if (done) {
      setIsSubsVisible(null);
    }
    // eslint-disable-next-line
  }, [done]);

  const onKeyDown = useCallback(
    (event) => {
      if (event.code === "Escape") {
        setIsSubsVisible(null);
      }
    },
    [setIsSubsVisible]
  );

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [onKeyDown]);

  function handleSubsValidity(entityId, isValid) {
    setSubsValidity((prev) => {
      return { ...prev, [entityId]: isValid };
    });
  }

  function isSubActionValid() {
    return (
      subsValidity === null || Object.values(subsValidity).every((e) => !!e)
    );
  }

  function getPresubsForEntity(personsFromEntity = []) {
    return personsFromEntity
      .filter((person) => preSubs.includes(person.personId))
      .map((person) => person.personId);
  }

  return ReactDOM.createPortal(
    <div className="subs-main-panel main-panel enabled-true substitutions">
      <div>
        <h3>
          {formatMessage({
            id: "substitutions",
            defaultMessage: "Substitutions",
          })}
          <div className="panel-extras">
            <div className="cancel" onClick={() => setIsSubsVisible(null)}>
              <span>
                {formatMessage({
                  id: "cancel",
                  defaultMessage: "cancel",
                })}
              </span>
              <i className="fas fa-times"></i>
            </div>
          </div>
        </h3>
        <div className="subs-container">
          <Substitutions
            team={currentState.entities[0].entityId}
            setIsSubsVisible={setIsSubsVisible}
            done={done}
            reportSubsValidity={handleSubsValidity}
            {...props}
            preSubs={getPresubsForEntity(currentState.entities?.[0]?.persons)}
            togglePreSubs={togglePreSubs}
          />
          <Substitutions
            team={currentState.entities[1].entityId}
            setIsSubsVisible={setIsSubsVisible}
            done={done}
            reportSubsValidity={handleSubsValidity}
            {...props}
            preSubs={getPresubsForEntity(currentState.entities?.[1]?.persons)}
            togglePreSubs={togglePreSubs}
          />
        </div>
        <div>
          <div
            className={`match-button action sub-done-button ${
              isSubActionValid() ? "" : "disabled"
            }`}
            onClick={() => setDone(true)}
          >
            {formatMessage({
              id: "done",
              defaultMessage: "Done",
            })}
          </div>
        </div>
      </div>
    </div>,
    document.querySelector("#SubsContainer")
  );
};

export default injectIntl(JointSubstitutions);
