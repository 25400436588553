import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { SketchPicker } from "react-color";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { StaffRoles } from "../../../Config/StaffRoles";
import { sendPlayer, sendStaffRole } from "../../Base/Utilities/ManagePlayers";
import { scrollToTop } from "../../Base/Utilities/ScrollToTop";
import Roster from "./Roster";
import Staff from "./Staff";
import "./Team.scss";
import { useOnClickOutside } from "../../Base/Hooks/useOnClickOutside.js";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";

const COLOR_PICKER_PRESET_COLORS = [
  "#ffffff",
  "#001f3f",
  "#0074d9",
  "#7fdbff",
  "#39cccc",
  "#3d9970",
  "#2ecc40",
  "#01ff70",
  "#ffdc00",
  "#ff851b",
  "#ff4136",
  "#85144b",
  "#f012be",
  "#b10dc9",
  "#111111",
  "#aaaaaa",
];

const Team = ({ data: teamData, intl, updateTeam, match, currentState }) => {
  const { formatMessage } = intl;
  const { sport } = useContext(FixtureDetailsContext);
  const [primaryColor, setPrimaryColor] = useState(null);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const colorPickerRef = useRef();

  const handleClickOutsideColorPicker = useCallback(() => {
    setIsColorPickerOpen(false);
  }, []);
  useOnClickOutside(colorPickerRef, handleClickOutsideColorPicker);

  useEffect(() => {
    const color = teamData.primaryColor;
    setPrimaryColor(color);
  }, [teamData]);

  useEffect(() => scrollToTop(), [currentTab]);

  useEffect(() => {
    if (primaryColor !== null) {
      localStorage.setItem(teamData.entityId + "-color", primaryColor);
      updateTeam({ ...teamData, primaryColor });
    }
    // eslint-disable-next-line
  }, [teamData.entityId, primaryColor]);

  // Compute and update local roster data and dispatch an message to update it globally
  // expects two person data objects
  const updateRosterData = useCallback(
    (afterUpdateData, beforeUpdateData) => {
      const newTeam = { ...teamData };
      newTeam.persons = teamData.persons.map((person) =>
        person.personId === afterUpdateData.personId
          ? { ...afterUpdateData, desiredPlayingState: afterUpdateData.playing }
          : { ...person }
      );

      let status = "";
      if (
        beforeUpdateData.playing === true &&
        afterUpdateData.playing === false
      ) {
        status = "deleted";
      }
      if (
        beforeUpdateData.playing === false &&
        afterUpdateData.playing === true
      ) {
        status = "added";
      }
      if (
        beforeUpdateData.playing === true &&
        afterUpdateData.playing === true
      ) {
        status = "updated";
      }
      afterUpdateData.confirmed = false;

      if (status !== "") {
        sendPlayer(
          afterUpdateData,
          newTeam,
          currentState.mqtt,
          match.params.fixtureId,
          status,
          sport
        );
      }
      updateTeam(newTeam);
    },
    [teamData, currentState.mqtt, match, updateTeam]
  );

  // Compute and update local staff data and dispatch an message to update it globally
  // expects two staff data objects
  const updateStaffData = (afterUpdateData, beforeUpdateData, roleName) => {
    const newTeam = { ...teamData };
    newTeam.staffState[roleName] = { ...afterUpdateData };

    // We don't need to inform Streaming API if both staff persons aren't active
    if (afterUpdateData.active || beforeUpdateData.active) {
      if (
        afterUpdateData.personId !== beforeUpdateData.personId &&
        beforeUpdateData.person !== ""
      ) {
        const staffRole = StaffRoles[roleName];
        const personToBeDeleted = teamData.staff.find(
          (p) => p.personId === beforeUpdateData.personId
        );
        sendStaffRole(
          {
            ...beforeUpdateData,
            name: personToBeDeleted.name,
            role: staffRole.role,
            number: staffRole.number,
          },
          teamData,
          currentState.mqtt,
          match.params.fixtureId,
          "deleted"
        );
      }

      const shouldBeDeleted =
        beforeUpdateData.active === true && afterUpdateData.active === false;
      const person = teamData.staff.find((p) =>
        shouldBeDeleted
          ? p.personId === beforeUpdateData.personId
          : p.personId === afterUpdateData.personId
      );

      const hasBeenNewlyAdded =
        beforeUpdateData.active === false && afterUpdateData.active === true;
      const status = hasBeenNewlyAdded
        ? "added"
        : shouldBeDeleted
        ? "deleted"
        : "updated";
      const staffRole = StaffRoles[roleName];
      person.role = staffRole.role;
      person.number = staffRole.number;
      sendStaffRole(
        person,
        teamData,
        currentState.mqtt,
        match.params.fixtureId,
        status
      );
    }

    newTeam.staff = teamData.staff.map((staffMember) =>
      staffMember.personId === afterUpdateData.personId
        ? { ...staffMember, confirmed: false }
        : staffMember
    );
    updateTeam(newTeam);
  };

  return (
    <div>
      <h2 className="team-header">
        <div className="team-header-name-row">
          <span>{teamData.nameFullLocal}</span>
          {primaryColor && (
            <div ref={colorPickerRef}>
              <div
                className="inputColor"
                style={{
                  backgroundColor: primaryColor,
                }}
                onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
              ></div>
              {isColorPickerOpen && (
                <>
                  <i
                    className="close-color fas fa-times"
                    onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}
                  ></i>
                  <SketchPicker
                    color={primaryColor}
                    onChange={(color) => setPrimaryColor(color.hex)}
                    disableAlpha={true}
                    presetColors={COLOR_PICKER_PRESET_COLORS}
                  />
                </>
              )}
            </div>
          )}
        </div>
        <div className="stats-team-tab-buttons">
          <button
            className={currentTab === 0 ? "active" : ""}
            onClick={() => setCurrentTab(0)}
          >
            {formatMessage({
              id: "setup.roster",
              defaultMessage: "Roster",
            })}
          </button>
          <button
            className={currentTab === 1 ? "active" : ""}
            onClick={() => setCurrentTab(1)}
          >
            {formatMessage({
              id: "setup.staff",
              defaultMessage: "Staff",
            })}
          </button>
        </div>
      </h2>
      {currentTab === 0 && (
        <Roster
          currentState={currentState}
          teamData={teamData}
          updateRosterData={updateRosterData}
        />
      )}
      {currentTab === 1 && (
        <Staff teamData={teamData} updateStaffData={updateStaffData} />
      )}
    </div>
  );
};

export default injectIntl(withRouter(Team));
