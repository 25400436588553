class SequenceIdGenerator {
  static instance = null;
  id;
  clientId;

  constructor() {
    this.id = 0;
  }

  static getInstance() {
    if (!SequenceIdGenerator.instance) {
      SequenceIdGenerator.instance = new SequenceIdGenerator();
    }

    return SequenceIdGenerator.instance;
  }

  readId() {
    return this.id;
  }

  getId() {
    this.id += 1;
    return this.id;
  }

  setClientId(clientId) {
    if (this.clientId !== clientId) {
      this.clientId = clientId;
      this.id = 0;
    }
  }
}

export default SequenceIdGenerator;
