import { useState, useEffect } from "react";

export const useCanEditDropdown = (props) => {
  const { workFlow, event } = props;
  const [canEdit, setCanEdit] = useState(false);
  const [editFlow, setEditFlow] = useState(null);

  useEffect(() => {
    if (workFlow?.modifyDropdown?.types) {
      workFlow.modifyDropdown.types.forEach((type) => {
        const eventTypeMatching = type.eventTypes.includes(event.eventType);
        const successMatching = type.success === undefined || type.success === event.success;
        if (eventTypeMatching && successMatching) {
          setCanEdit(true);
          setEditFlow(type);
        }
      });
    }
  }, [workFlow?.modifyDropdown?.types, event.eventType]);

  return [canEdit, editFlow];
};
