export var MadeToMissed = {
  name: "shot_success_missed",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "edit.shot.success",
  showOn: [{ state: "editState", value: "shot_success_made", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    {
      name: "shot_made_description",
      component: "PanelText",
      layout: "panel-text",
      mode: "visible",
      showOn: [true],
      options: {
        layout: "description-text",
        text: "edit.shot.made.desription",
      },
      actionGroups: [],
    },
    {
      name: "shot_made_change_missed",
      component: "ButtonGroup",
      layout: "button-group true-false",
      mode: "visible",
      showOn: [true],
      startPlay: false,
      options: {
        value: "success",
        children: [false],
        layout: "type-button",
        prefix: "edit.shot.made.success",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            {
              action: "copyEvent",
              value: "shot",
              type: "to",
            },
            {
              action: "deleteFromPlay",
              value: "assist",
              type: "shot",
            },
            {
              action: "nextState",
              value: "shot-rebound-insert",
              type: "editState",
            },
          ],
          conditions: [],
        },
        {
          actions: [
            {
              action: "adjustTeamValue",
              value: -2,
              options: { currentEntity: true, item: "score" },
            },
          ],
          conditions: [
            {
              item: "eventType",
              value: "2pt",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "adjustTeamValue",
              value: -3,
              options: { currentEntity: true, item: "score" },
            },
          ],
          conditions: [
            {
              item: "eventType",
              value: "3pt",
              event: "main",
              operator: "is",
            },
          ],
        },
      ],
    },
  ],
};
