import { Clock, CountDownClock } from "./includes/Base";
import {
  CentrePass,
  ShootoutClockController,
  WarmupClock,
} from "./includes/Panels";

const HockeyClockOperatorWorkflow = {
  displayEventLog: false,
  sport: "hockey",
  panels: [
    {
      name: "main-area",
      component: "StackingPanel",
      showOn: [true],
      layout: "column hockey-layout",
      children: [
        {
          name: "role-selector-panel",
          component: "StackingPanel",
          layout: "fill-content",
          showOn: [true],
          children: [
            {
              name: "mode-selector",
              component: "ModeSelector",
              showOn: [
                { state: "clock.clockRunning", value: false, operator: "is" },
              ],
              mode: "visible",
            },
          ],
        },
        {
          name: "top-panel",
          component: "StackingPanel",
          layout: "fill-content",
          showOn: [true],
          children: [
            {
              name: "team-0-team-select",
              component: "Teams",
              showOn: [true],
              mode: "visible",
              showPossessionIcon: false,
              showSinBin: true,
              showArrow: false,
              showStats: false,
              hideScore: true,
            },
            {
              name: "clock-and-buttons",
              component: "StackingPanel",
              showOn: [true],
              layout: "column grow clock-and-buttons",
              children: [
                Clock,
                {
                  name: "match-buttons",
                  component: "ButtonPanel",
                  layout:
                    "match-buttons-stackable match-buttons-stackable--hockey",
                  mode: "visible",
                  showOn: [true],
                  children: [CountDownClock],
                },
              ],
            },
            {
              name: "team-1-team-select",
              component: "Teams",
              showOn: [true],
              mode: "visible",
              showPossessionIcon: false,
              showSinBin: true,
              showArrow: false,
              showStats: false,
              hideScore: true,
            },
          ],
        },
      ],
    },
    CentrePass,
    ShootoutClockController,
    WarmupClock,
  ],
  modify: {
    panels: [],
  },
};

export default HockeyClockOperatorWorkflow;
