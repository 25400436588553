import * as React from "react";
const SvgLeftPosArrow = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Group_18903", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 394.5 511", style: {
  enableBackground: "new 0 0 394.5 511"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("style", { type: "text/css" }, "\n	.st0{opacity:0.4;fill:url(#SVGID_1_);enable-background:new    ;}\n	.st2{fill:#FFF;}\n"), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_1_", gradientUnits: "userSpaceOnUse", x1: -632.36084, y1: 795.4787, x2: -632.88275, y2: 795.47766, gradientTransform: "matrix(395 0 0 -511 250177 406744)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "var(--entity-1-color)",
  stopOpacity: 0
}, id: "stop1" }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "var(--entity-1-color)"
}, id: "stop2" })), /* @__PURE__ */ React.createElement("path", { className: "st0", d: "m 394.5,-0.5 h -204 l -140,255.9 140,255.1 h 204 L 392,255.4 Z m -158,260.2 c 0,2.6 -2.1,4.7 -4.7,4.7 h -64.1 v 10.9 c 0,1.8 -1.1,3.5 -2.7,4.3 -1.7,0.7 -3.7,0.4 -5.1,-0.8 L 138,258.5 c -0.2,-0.1 -0.3,-0.3 -0.5,-0.5 -1.7,-1.9 -1.5,-4.9 0.5,-6.5 l 21.9,-20.3 c 1.4,-1.2 3.4,-1.5 5.1,-0.8 1.7,0.8 2.7,2.5 2.7,4.3 v 10.9 h 64.1 c 2.6,0 4.7,2.1 4.7,4.7 z", id: "path2", style: {
  fill: "url(#SVGID_1_)"
} }), /* @__PURE__ */ React.createElement("polygon", { className: "st2", points: "-0.5,254.8 139.5,510.5 140.5,510.5 0.5,255.4 140.5,-0.5 139.5,-0.5 ", id: "polygon3", transform: "translate(50)" }, /* @__PURE__ */ React.createElement("animate", { attributeName: "points", dur: "1s", repeatCount: "indefinite", from: "-0.5,254.8 139.5,510.5 140.5,510.5 0.5,255.4 140.5,-0.5 139.5,-0.5", to: "-50.5,254.8 89.5,510.5 90.5,510.5 -49.5,255.4 90.5,-0.5 89.5,-0.5" }))));
export default SvgLeftPosArrow;
