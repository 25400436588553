import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";

import { useDoActions } from "../../../../../Base/Hooks/doActions";

const FreeThrowCancelActions = [
  {
    actions: [{ action: "clearEvent", value: "freethrow", type: "main" }],
    conditions: [],
  },
  {
    actions: [{ action: "popStash", type: "main" }],
    conditions: [],
  },
];

const Cancel = (props) => {
  const {
    updateState,
    intl: { formatMessage },
    enabled,
    panel,
  } = props;
  const [doAction, setDoAction] = useState(false);
  const [actionsDone] = useDoActions(
    panel,
    props,
    doAction,
    FreeThrowCancelActions
  );

  useEffect(() => {
    if (actionsDone) {
      setDoAction(false);
    }
    // eslint-disable-next-line
  }, [actionsDone]);

  const _setCancel = () => {
    setDoAction(true);
    updateState("secondary", null);
    updateState("editState", null);
  };

  return (
    <div className={"cancel enabled-" + enabled} onClick={() => _setCancel()}>
      <span>
        {formatMessage({
          id: "cancel",
          defaultMessage: "cancel",
        })}
      </span>
      <i className="fas fa-times"></i>
    </div>
  );
};

export default injectIntl(Cancel);
