import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import {
  getEventTimeForUpdatedDeletedEvents,
  sendEvent,
} from "../../../Base/Utilities/SendEvent";

const Bookmark = (props) => {
  const { currentState, updateState, intl } = props;
  const { formatMessage } = intl;
  const [isFlagged, setIsFlagged] = useState(false);

  useEffect(() => {
    if (currentState.modify) {
      let flaggedEvents = props.eventStore.filter(
        (ev) => ev.playId === currentState.modify.playId && ev.flagged === true
      );
      if (flaggedEvents.length > 0) {
        setIsFlagged(true);
      }
    }
    // eslint-disable-next-line
  }, [props]);

  const _setFlagged = (flag) => {
    if (currentState.modify) {
      let playEvents = props.eventStore.filter(
        (ev) => ev.playId === currentState.modify.playId
      );
      playEvents.forEach((event) => {
        event.eventTime = getEventTimeForUpdatedDeletedEvents(event);
        event.status = "updated";
        event.flagged = flag;
        let action = {
          action: "sendEvent",
          event: event,
          type: "custom",
        };
        sendEvent(action, props);
      });
      let tempEvents = currentState.events;
      tempEvents.main.flagged = flag;
      updateState("events", tempEvents);
      setIsFlagged(flag);
    }
  };

  return (
    <div
      className={"book-mark marked-" + isFlagged}
      onClick={() => _setFlagged(!isFlagged)}
    >
      <span>
        {formatMessage({
          id: "bookmark",
          defaultMessage: "bookmark",
        })}
      </span>
      <i className="fas fa-bookmark"></i>
      <i className="star fas fa-star"></i>
    </div>
  );
};

export default injectIntl(Bookmark);
