import React, { useContext, useMemo, useState } from "react";
import { injectIntl } from "react-intl";
import { Input } from "reactstrap";

import { FixtureDetailsContext } from "../../Config/FixtureDetails";

const buildCompetitorTitle = (competitor) => {
  const competitorConference =
    competitor.fixtureConference || competitor.seasonConference || null;
  const competitorDivision =
    competitor.fixtureDivision || competitor.seasonDivision || null;
  if (competitorConference || competitorDivision) {
    const competitorSuffix = [
      competitorConference?.nameLocal || competitorConference?.nameLatin,
      competitorDivision?.nameLocal || competitorDivision?.nameLatin,
    ]
      .filter((e) => !!e)
      .join(" / ");
    return `${competitor.nameFullLocal} (${competitorSuffix})`;
  }
  return competitor.nameFullLocal;
};

const GameInfo = ({ updateAttendance, intl: { formatMessage } }) => {
  const ctx = useContext(FixtureDetailsContext);
  const [attendanceCount, setAttendanceCount] = useState(ctx?.attendance || 0);

  const competitionTitle = [
    ctx?.competition?.nameLocal || ctx?.competition?.nameLatin,
    ctx?.league?.nameLocal || ctx?.league?.nameLatin,
    ctx?.season?.name,
    ctx.roundNumber &&
      `${formatMessage({
        id: "details.info.round",
        defaultMessage: "Round",
      })}: ${ctx.roundNumber}`,
  ]
    .filter((e) => !!e)
    .join(" - ");

  const [homeTeamName, awayTeamName] = useMemo(() => {
    if (ctx.competitors) {
      if (ctx.competitors[0].isHome) {
        return [
          buildCompetitorTitle(ctx.competitors[0]),
          buildCompetitorTitle(ctx.competitors[1]),
        ];
      } else {
        return [
          buildCompetitorTitle(ctx.competitors[1]),
          buildCompetitorTitle(ctx.competitors[0]),
        ];
      }
    }
    return [null, null];
  }, [ctx]);

  const handleAttendanceChange = (e) => {
    const newAttendanceCount = e.target.value;
    if (newAttendanceCount !== attendanceCount) {
      setAttendanceCount(newAttendanceCount);
      if (newAttendanceCount) {
        updateAttendance(newAttendanceCount);
      } else {
        updateAttendance("0");
      }
    }
  };

  return (
    <div className="game-details--game-info">
      <div className="game-details--section-header">
        {formatMessage({
          id: "details.info",
          defaultMessage: "Competition Info",
        })}
      </div>
      <div className="game-details--competition-title">{competitionTitle}</div>
      <div className="game-details--participants">
        {homeTeamName} vs {awayTeamName}
      </div>
      <div className="game-details--venue-name">
        {`${formatMessage({
          id: "details.info.venue",
          defaultMessage: "Venue",
        })}:`}
        <span>{ctx.venue.name}</span>
      </div>
      <div className="game-details--attendance-input">
        {`${formatMessage({
          id: "details.info.attendance",
          defaultMessage: "Attendance",
        })}:`}
        <Input
          type="text"
          name="attendance"
          bsSize="xs"
          value={attendanceCount}
          onChange={handleAttendanceChange}
        />
      </div>
    </div>
  );
};

export default injectIntl(GameInfo);
