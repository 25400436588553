import Logger from "./Logger";

const fetchWithTimeout = (url, ms, options = {}) => {
  const controller = new AbortController();
  const promise = fetch(url, { signal: controller.signal, ...options });
  const timeout = setTimeout(() => controller.abort(), ms);
  return promise.finally(() => clearTimeout(timeout));
};

const DEFAULT_ONLINE_CHECK_TIMEOUT = 5000;

const logger = Logger.getInstance();

/**
 * Verifies if the app is connected to the Internet. Has two variants, with and without a test URL.
 * Using a test URL is recommended. Preferably it should be the connect URL for WebSocket connection.
 * @param {String} testUrl
 * @param {integer} fetchTimeout - time in ms to abort fetch request after
 * @return {boolean}
 */
const checkOnlineStatus = async (
  testUrl = null,
  fetchTimeout = DEFAULT_ONLINE_CHECK_TIMEOUT
) => {
  if (testUrl) {
    try {
      const cleanUrl = testUrl.split("/mqtt")[0].replace("wss://", "");
      await fetchWithTimeout(`https://${cleanUrl}`, fetchTimeout, {
        method: "HEAD",
      });
      return true;
    } catch (err) {
      return false;
    }
  } else {
    logger.log(`You haven't provided a test URL for checkOnlineStatus!`);
    try {
      const online = await fetchWithTimeout("index.html", fetchTimeout);
      return online.status >= 200 && online.status < 300;
    } catch (err) {
      return false;
    }
  }
};

export { checkOnlineStatus };
