// Rebound Follow On
export var ReboundFollowOn = {
  name: "rebound-follow-on",
  component: "MainPanel",
  layout: "main-panel large align-center padded",
  mode: "visible",
  text: "rebound.follow-on",
  allowFlag: true,
  allowCancel: false,
  showOn: [{ state: "primary", value: "rebound-follow-on", operator: "is" }],
  children: [
    {
      name: "tipin-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "tipinType",
        children: [
          "tipIn.made",
          "tipIn.made.fastBreak",
          "tipIn.made.foul",
          "tipIn.made.foul.fastBreak",
          "tipIn.missed",
          "rebound.follow-on.none",
        ],
        layout: "type-button",
      },
      actionGroups: [
        // Tip In made
        {
          actions: [
            // Copy Rebound from temp to Shot made 2pt, x and y from defaults based on team index
            { action: "copyEvent", value: "rebound", type: "from" },
            { action: "newEvent", value: null, type: "main" },
            { action: "newPlayId", value: null, type: "main" },
            {
              action: "saveData",
              value: ["periodId", "clock", "eventTime", "scores"],
              type: "variables",
            },
            { action: "saveData", value: "2pt", type: "eventType" },
            { action: "saveData", value: "tipIn", type: "subType" },
            { action: "saveData", value: true, type: "defaultLocations" },
            {
              action: "adjustTeamValue",
              value: 2,
              options: { currentEntity: true, item: "score" },
            },
            { action: "saveData", value: true, type: "success" },
            {
              action: "saveData",
              value: [
                { option: "secondChance", value: true },
                { option: "pointsInThePaint", value: true },
              ],
              type: "options",
            },
            // Send action
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "clearData",
              value: ["personId"],
              type: "main",
            },
            // Switch possession
            {
              action: "setPossession",
              value: { currentEntity: false },
              type: "main",
            },
            { action: "nextState", value: null, type: "primary" },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [
            { item: "tipinType", value: ["tipIn.made"], operator: "is" },
          ],
        },
        // Tip In made - with fast break
        {
          actions: [
            { action: "stopClock", value: "stop", type: "main" },
            { action: "copyEvent", value: "rebound", type: "from" },
            { action: "newEvent", value: null, type: "main" },
            { action: "newPlayId", value: null, type: "main" },
            {
              action: "saveData",
              value: ["periodId", "clock", "eventTime", "scores"],
              type: "variables",
            },
            { action: "saveData", value: "2pt", type: "eventType" },
            { action: "saveData", value: "tipIn", type: "subType" },
            { action: "saveData", value: true, type: "defaultLocations" },
            {
              action: "adjustTeamValue",
              value: 2,
              options: { currentEntity: true, item: "score" },
            },
            { action: "saveData", value: true, type: "success" },
            {
              action: "saveData",
              value: [
                { option: "secondChance", value: true },
                { option: "pointsInThePaint", value: true },
                { option: "fastBreak", value: true },
              ],
              type: "options",
            },
            { action: "sendEvent", value: "new", type: "main" },
            // Switch possession
            {
              action: "setPossession",
              value: { currentEntity: false },
              type: "main",
            },
            { action: "nextState", value: null, type: "primary" },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [
            {
              item: "tipinType",
              value: ["tipIn.made.fastBreak"],
              operator: "is",
            },
          ],
        },
        // Tip In made - with foul (and 1)
        {
          actions: [
            // Copy Rebound from temp to Shot made 2pt, x and y from defaults based on team index
            { action: "stopClock", value: "stop", type: "main" },
            { action: "copyEvent", value: "rebound", type: "from" },
            { action: "newEvent", value: null, type: "main" },
            { action: "newPlayId", value: null, type: "main" },
            {
              action: "saveData",
              value: ["periodId", "clock", "eventTime", "scores"],
              type: "variables",
            },
            { action: "saveData", value: "2pt", type: "eventType" },
            { action: "saveData", value: "tipIn", type: "subType" },
            { action: "saveData", value: true, type: "defaultLocations" },
            {
              action: "adjustTeamValue",
              value: 2,
              options: { currentEntity: true, item: "score" },
            },
            { action: "saveData", value: true, type: "success" },
            {
              action: "saveData",
              value: [
                { option: "secondChance", value: true },
                { option: "pointsInThePaint", value: true },
                { option: "foulOnShot", value: true },
              ],
              type: "options",
            },
            // Send action
            { action: "sendEvent", value: "new", type: "main" },
            { action: "copyEvent", value: "shot", type: "to" },
            {
              action: "clearData",
              value: ["options", "personId"],
              type: "main",
            },
            // Switch possession
            {
              action: "setPossession",
              value: { currentEntity: true },
              type: "main",
            },
            {
              action: "copyData",
              value: "x",
              type: "shot",
            },
            {
              action: "copyData",
              value: "y",
              type: "shot",
            },
            {
              action: "clearData",
              value: ["personId"],
              type: "main",
            },
            { action: "newEvent", value: null, type: "main" },
            { action: "saveData", value: "foul", type: "eventType" },
            { action: "modifyEvent", value: "swapEntities", type: "main" },
            { action: "copyEvent", value: "foul", type: "to" },
            {
              action: "nextState",
              value: "and1-foul-player",
              type: "primary",
            },
          ],
          conditions: [
            { item: "tipinType", value: ["tipIn.made.foul"], operator: "is" },
          ],
        },
        // Tip In made - with foul + fast break
        {
          actions: [
            // Copy Rebound from temp to Shot made 2pt, x and y from defaults based on team index
            { action: "stopClock", value: "stop", type: "main" },
            { action: "copyEvent", value: "rebound", type: "from" },
            { action: "newEvent", value: null, type: "main" },
            { action: "newPlayId", value: null, type: "main" },
            {
              action: "saveData",
              value: ["periodId", "clock", "eventTime", "scores"],
              type: "variables",
            },
            { action: "saveData", value: "2pt", type: "eventType" },
            { action: "saveData", value: "tipIn", type: "subType" },
            { action: "saveData", value: true, type: "defaultLocations" },
            {
              action: "adjustTeamValue",
              value: 2,
              options: { currentEntity: true, item: "score" },
            },
            { action: "saveData", value: true, type: "success" },
            {
              action: "saveData",
              value: [
                { option: "secondChance", value: true },
                { option: "pointsInThePaint", value: true },
                { option: "foulOnShot", value: true },
                { option: "fastBreak", value: true },
              ],
              type: "options",
            },
            // Send action
            { action: "sendEvent", value: "new", type: "main" },
            { action: "copyEvent", value: "shot", type: "to" },
            {
              action: "clearData",
              value: ["options", "personId"],
              type: "main",
            },
            // Switch possession
            {
              action: "setPossession",
              value: { currentEntity: true },
              type: "main",
            },
            {
              action: "copyData",
              value: "x",
              type: "shot",
            },
            {
              action: "copyData",
              value: "y",
              type: "shot",
            },
            {
              action: "clearData",
              value: ["personId"],
              type: "main",
            },
            { action: "newEvent", value: null, type: "main" },
            { action: "saveData", value: "foul", type: "eventType" },
            { action: "modifyEvent", value: "swapEntities", type: "main" },
            { action: "copyEvent", value: "foul", type: "to" },
            {
              action: "nextState",
              value: "and1-foul-player",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "tipinType",
              value: ["tipIn.made.foul.fastBreak"],
              operator: "is",
            },
          ],
        },
        // Tip In Missed
        {
          actions: [
            // Copy Rebound from temp to Shot made 2pt, x and y from sefaults based on team index
            { action: "copyEvent", value: "rebound", type: "from" },
            { action: "newEvent", value: null, type: "main" },
            { action: "newPlayId", value: null, type: "main" },
            {
              action: "saveData",
              value: ["periodId", "clock", "eventTime", "scores"],
              type: "variables",
            },
            { action: "saveData", value: "2pt", type: "eventType" },
            { action: "saveData", value: "tipIn", type: "subType" },
            { action: "saveData", value: true, type: "defaultLocations" },
            { action: "saveData", value: false, type: "success" },
            {
              action: "saveData",
              value: [
                { option: "secondChance", value: true },
                { option: "pointsInThePaint", value: true },
              ],
              type: "options",
            },
            // Send action
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "clearData",
              value: [{ option: "blocked" }],
              type: "shot",
            },
            { action: "nextState", value: "shot-rebound", type: "primary" },
          ],
          conditions: [
            { item: "tipinType", value: ["tipIn.missed"], operator: "is" },
          ],
        },
        // No follow on
        {
          actions: [
            { action: "clearData", value: ["tipinType"], type: "main" },
            { action: "nextState", value: null, type: "primary" },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [
            {
              item: "tipinType",
              value: ["rebound.follow-on.none"],
              operator: "is",
            },
          ],
        },
      ],
    },
  ],
};
