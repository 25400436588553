export const GoalPlayerSelect = {
  name: "goal-player",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "goal.select-player",
  showOn: [{ state: "primary", value: "goal-player", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "goal-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      options: { currentEntity: true, showBench: false, showCoach: false },
      actionGroups: [
        {
          actions: [
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "clearData",
              value: ["eventType", "personId", "success", "options"],
              type: "main",
            },
            { action: "setPosTracking", value: "reset" },
            { action: "processInserts", value: "all", type: "inserts" },
            { action: "nextState", value: null, type: "primary" },
          ],
        },
      ],
    },
    {
      name: "confirm-later-shot-player",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: "confirmLater",
      showOn: [true],
      actionGroups: [
        {
          actions: [
            {
              action: "saveData",
              value: [{ option: "goalOutcome", value: "FIELD_GOAL" }],
              type: "options",
            },
          ],
          conditions: [
            {
              item: "optionValue",
              event: "main",
              operator: "not",
              name: "goalOutcome",
              value: "PENALTY_CORNER",
            },
          ],
        },
        {
          actions: [
            { action: "newPlayId", value: null, type: "main" },
            { action: "saveData", type: "flagged", value: true },
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "clearData",
              value: ["eventType", "personId", "success", "options"],
              type: "main",
            },
            { action: "setPosTracking", value: "reset" },
            { action: "processInserts", value: "all", type: "inserts" },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [],
        },
      ],
      startEvent: false,
      startPlay: false,
    },
  ],
};
