const createCardClockActions = (cardType) => [
  { action: "copyEvent", value: "card", type: "to" },
  {
    action: "clearData",
    value: ["options"],
    type: "main",
  },
  { action: "newEvent", value: null, type: "main" },
  {
    action: "saveData",
    value: "clock",
    type: "class",
  },
  {
    action: "saveData",
    value: cardType,
    type: "eventType",
  },
  {
    action: "saveData",
    value: "adjust",
    type: "subType",
  },
  {
    action: "saveData",
    value: [{ option: "value", value: "2" }],
    type: "options",
  },
  { action: "sendEvent", value: "new", type: "main" },
  { action: "copyEvent", value: "card-clock", type: "to" },
  { action: "copyEvent", value: "card", type: "from" },
];

const sharedGreenCardActions = [
  {
    action: "saveData",
    value: [{ option: "time", value: 2 }],
    type: "options",
  },
  { action: "sendEvent", value: "new", type: "main" },
  ...createCardClockActions("greenCard"),
];

export const CardPlayerSelect = {
  name: "card-player",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "card.select-player",
  showOn: [{ state: "primary", value: "card-player", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "card-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      options: { currentEntity: true, showBench: false, showCoach: false },

      actionGroups: [
        {
          actions: [
            {
              action: "nextState",
              value: "card-time",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "eventType",
              value: "yellowCard",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "nextState",
              value: "card-reason",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "eventType",
              value: "redCard",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            ...sharedGreenCardActions,
            { action: "processInserts", value: "all", type: "inserts" },
            {
              action: "nextState",
              value: "card-timer-start-select",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "eventType",
              value: "greenCard",
              event: "main",
              operator: "is",
            },
          ],
          operator: "all",
        },
      ],
    },
  ],
};
