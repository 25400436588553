import React, { useMemo } from "react";

import { useIsVisible } from "../../Base/Hooks/IsVisible";
import TypeButton from "../ButtonPanel/TypeButton/TypeButton";

import "./TeamGroupedButtons.scss";

const TeamGroupedButtons = (props) => {
  const { panel, currentState } = props;
  const [isVisible] = useIsVisible(panel, props);

  const buttons = useMemo(() => {
    if (panel && panel.children) {
      if (currentState.entities && currentState.entities.length > 0) {
        return currentState.entities.map((team, index) => (
          <div
            className={`team-grouped-buttons--team-group team-${index}`}
            style={{
              borderColor: team.primaryColor,
            }}
            key={team.entityId}
          >
            {panel.children.map((button, i) => (
              <React.Fragment key={i}>
                {(function () {
                  switch (button.component) {
                    case "TypeButton":
                      return (
                        <TypeButton
                          {...props}
                          panel={button}
                          team={index}
                          style={{
                            background: team.primaryColor,
                          }}
                        />
                      );
                    default:
                      return null;
                  }
                })()}
              </React.Fragment>
            ))}
          </div>
        ));
      }
    }
    // eslint-disable-next-line
  }, [panel, props]);
  return isVisible ? <div className={panel.layout}>{buttons}</div> : null;
};

export default TeamGroupedButtons;
