import React, { useCallback, useContext, useMemo } from "react";
import { injectIntl } from "react-intl";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";
import { StaffRoles } from "../../../Config/StaffRoles";
import GridTableDisplay from "../../Base/Shared/GridTableDisplay";
import TeamSetupCheckbox from "./TeamSetupCheckbox";

const NONE_OPTION = "";
const HandballOfficialRoles = ["official_a", "official_b", "official_c", "official_d"];

const Staff = ({ intl, teamData, updateStaffData }) => {
  const { sport } = useContext(FixtureDetailsContext);
  const { formatMessage } = intl;

  const columns = useMemo(() => {
    return [
      formatMessage({
        id: "person.role",
        defaultMessage: "Role",
      }),
      formatMessage({
        id: "person.playing",
        defaultMessage: "Playing",
      }),
      formatMessage({
        id: "person.name",
        defaultMessage: "Name",
      }),
    ];
  }, [formatMessage]);

  const possibleStaffRoles = useMemo(() => {
    if (sport === "basketball") {
      return ["coach", "assistantCoach1", "assistantCoach2"];
    }
    if (sport === "hockey") {
      return ["coach_head", "coach_assistant", "manager", "physiotherapist", "doctor", "other"];
    }

    if (sport === "handball") {
      return [...HandballOfficialRoles];
    }
    // by default only return coach as assignable staff role
    return ["coach"];
  }, [sport]);

  const getCoaches = useCallback(
    (currentRole) => {
      if (teamData) {
        return teamData.staff
          .filter((staffPerson) => staffPerson?.role.includes("COACH"))
          .map((staffPerson) => (
            <option key={`coach-${currentRole}-${staffPerson.personId}`} value={staffPerson.personId}>
              {staffPerson.name}
            </option>
          ));
      }
    },
    [teamData],
  );

  const getManagers = useCallback(() => {
    if (teamData) {
      return teamData.staff
        .filter((staffPerson) => staffPerson?.role === StaffRoles.manager.role)
        .map((staffPerson) => (
          <option key={`manager-${staffPerson.personId}`} value={staffPerson.personId}>
            {staffPerson.name}
          </option>
        ));
    }
  }, [teamData]);

  const getPhysiotherapists = useCallback(() => {
    if (teamData) {
      return teamData.staff
        .filter((staffPerson) => staffPerson?.role === StaffRoles.physiotherapist.role)
        .map((staffPerson) => (
          <option key={`physiotherapist-${staffPerson.personId}`} value={staffPerson.personId}>
            {staffPerson.name}
          </option>
        ));
    }
  }, [teamData]);

  const getDoctors = useCallback(() => {
    if (teamData) {
      return teamData.staff
        .filter((staffPerson) => staffPerson?.role === StaffRoles.doctor.role)
        .map((staffPerson) => (
          <option key={`doctor-${staffPerson.personId}`} value={staffPerson.personId}>
            {staffPerson.name}
          </option>
        ));
    }
  }, [teamData]);

  const getRoleOthers = useCallback(() => {
    if (teamData) {
      return teamData.staff
        .filter((staffPerson) => StaffRoles.other.roles.includes(staffPerson?.role))
        .map((staffPerson) => (
          <option key={`other-${staffPerson.personId}`} value={staffPerson.personId}>
            {staffPerson.name}
          </option>
        ));
    }
  }, [teamData]);

  const getHandballOfficials = useCallback(() => {
    if (teamData) {
      return [
        teamData.staff.map((staffPerson) => (
          <option key={`official-${staffPerson.personId}`} value={staffPerson.personId}>
            {staffPerson.name}
          </option>
        )),
      ];
    }
  }, [teamData]);

  const handlePropertyChange = useCallback(
    (propertyName, person, roleName, e) => {
      const newPerson = { ...person };
      if (propertyName === "active") {
        newPerson.active = e.target.checked;
      }
      if (propertyName === "personId") {
        newPerson.personId = e.target.value;
      }
      if (newPerson.personId === "") {
        newPerson.active = false;
      }
      updateStaffData(newPerson, person, roleName);
    },
    [updateStaffData],
  );

  const rows = useMemo(() => {
    const getRoleOptions = (roleName) => {
      if (roleName.toUpperCase() === StaffRoles.manager.role) {
        return getManagers();
      } else if (roleName.toUpperCase().includes("COACH")) {
        return getCoaches(roleName);
      } else if (roleName.toUpperCase() === StaffRoles.physiotherapist.role) {
        return getPhysiotherapists();
      } else if (roleName.toUpperCase() === StaffRoles.doctor.role) {
        return getDoctors();
      } else if (roleName === "other") {
        return getRoleOthers();
      } else if (HandballOfficialRoles.includes(roleName)) {
        return getHandballOfficials();
      }

      return null;
    };

    return possibleStaffRoles.map((roleName) => {
      const staffPerson = teamData.staffState?.[roleName]?.personId
        ? teamData.staffState[roleName]
        : { personId: NONE_OPTION, active: false };

      if (staffPerson.personId !== NONE_OPTION) {
        const person = teamData.staff.find((p) => p.personId === staffPerson.personId);
        staffPerson.confirmed = person.confirmed || false;
      } else {
        staffPerson.confirmed = false;
      }

      return [
        <span>
          {formatMessage({
            id: `role.${roleName}`,
            defaultMessage: `role.${roleName}`,
          })}
        </span>,
        <span className={"bib-background playing-" + staffPerson.active + " confirmed-" + staffPerson.confirmed}>
          <TeamSetupCheckbox
            checked={staffPerson.active}
            onChange={(e) => handlePropertyChange("active", staffPerson, roleName, e)}
            enabled={staffPerson.personId !== ""}
          />
        </span>,

        <select
          value={staffPerson.personId}
          onChange={(e) => handlePropertyChange("personId", staffPerson, roleName, e)}
        >
          <option value={NONE_OPTION}></option>
          {getRoleOptions(roleName)}
        </select>,
      ];
    });
  }, [teamData, formatMessage, handlePropertyChange, possibleStaffRoles, getCoaches]);

  return (
    <div>
      <GridTableDisplay
        columns={columns}
        rows={rows}
        headerClass="staff-table-header"
        rowCellClass="roster-cell"
        tableKeyPrefix={`staff_${teamData?.entityId}`}
      />
    </div>
  );
};

export default injectIntl(Staff);
