import React, { useContext } from "react";
import { checkGameStarted } from "../../../Base/Utilities/Game";
import { lightOrDark } from "../../../Base/Utilities/LightOrDark";
import {
  getActiveSuspensionForPerson,
  getCardsForPerson,
} from "../../../Base/Utilities/Suspension";
import { FixtureDetailsContext } from "../../../../Config/FixtureDetails";

const HandballPlayer = (props) => {
  const {
    player,
    teamColor,
    entity,
    currentState,
    eventStore,
    onPlayerClickHandler,
  } = props;
  const { fixtureProfile } = useContext(FixtureDetailsContext);

  const isGameStarted = checkGameStarted(props);

  const onPlayerClick = (player) => {
    if (!isGameStarted) {
      return;
    }

    onPlayerClickHandler(player);
  };

  const activeCardEvent = getCardsForPerson({
    entity,
    personId: player.personId,
    events: eventStore,
    fixtureProfile,
  });

  const isSuspended =
    activeCardEvent && activeCardEvent.eventType !== "yellowCard";

  return (
    <div
      className={`player-tile ${player.active ? "active" : ""} ${lightOrDark(
        teamColor
      )}${!isGameStarted ? " player-tile--disabled" : ""}${
        isSuspended ? " player-tile--suspended" : ""
      }`}
      style={{
        backgroundColor: player.active ? teamColor : "#3B444C",
      }}
      onClick={() => onPlayerClick(player)}
    >
      <>
        {player.bib}
        {player.captain && " (C)"}
      </>
    </div>
  );
};

export default HandballPlayer;
