import React from "react";
import { injectIntl } from "react-intl";

import "./AspectCheck.scss";
import expand from "./window-expand.png";
import rotate from "./device-rotate.png";

const AspectCheck = (props) => {
  const { intl } = props;
  const { formatMessage } = intl;

  return (
    <div className="aspect-check-container">
      <div className="aspect-rotate">
        <img src={rotate} alt="Rotate" />
        <span>
          {formatMessage({
            id: "window.rotate",
            defaultMessage: "rotate this",
          })}
        </span>
      </div>
      <div className="aspect-expand">
        <img src={expand} alt="Expand" />
        <span>
          {formatMessage({
            id: "window.expand",
            defaultMessage: "expand this",
          })}
        </span>
      </div>
    </div>
  );
};

export default injectIntl(AspectCheck);
