import { v1 as uuidv1 } from "uuid";
import defaultSetup from "../../../Config/defaultSetupConfiguration";

export const createNotificationEvent = (
  currentState,
  notification,
  clientId,
  payload
) => {
  return {
    type: "notification_response",
    sport: notification.sport,
    organizationId: notification.organizationId,
    clientId,
    clientType: defaultSetup.options.clientType,
    data: {
      id: uuidv1(),
      streamNotificationId: notification.id,
      notificationType: notification.notificationType,
      eventTime: new Date(),
      clock: notification.notificationType === "clock" ? payload.clock : null,
      shotClock: null,
      scores: notification.notificationType === "score" ? payload : null,
      periodId: currentState?.period?.periodId ?? 0,
      flags: null,
      message: null,
      responder: "unknown",
      ...(notification.notificationType === "score" && {
        additionalData: {
          previousScore: currentState.entities.reduce(
            (acc, team) => ({
              ...acc,
              [team.entityId]: team.score,
            }),
            {}
          ),
        },
      }),
      ...(notification.notificationType === "event" && {
        additionalData: {
          ack: true,
        },
      }),
    },
  };
};
