const VideoReviewOptions = ["upheld", "rejected"];

export const VideoReviewOutcomeSelect = {
  name: "video-review-outcome",
  component: "MainPanel",
  layout: "main-panel",
  mode: "visible",
  text: "videoReview.outcome",
  showOn: [{ state: "primary", value: "video-review-outcome", operator: "is" }],
  startEvent: false,
  startPlay: false,
  allowFlag: true,
  allowCancel: true,
  children: [
    {
      name: "video-review-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "subType",
        children: VideoReviewOptions,
        layout: "type-button",
      },
      actionGroups: [
        {
          actions: [
            { action: "newEventId", value: null, type: "main" },
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "clearData",
              value: ["eventType", "subType"],
              type: "main",
            },
            {
              action: "nextState",
              value: "circle-entry-select",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
