export const PenaltyStrokePlayerSelect = {
  name: "penalty-stroke-player-select",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "penaltyStroke.select-player",
  showOn: [
    { state: "primary", value: "penalty-stroke-player-select", operator: "is" },
  ],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "penalty-stroke-players",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      options: { currentEntity: true, showBench: false, showCoach: false },
      actionGroups: [
        {
          actions: [
            { action: "sendEvent", value: "new", type: "main" },
            { action: "newEventId", value: null, type: "main" },
            {
              action: "nextState",
              value: "penalty-stroke-outcome-select",
              type: "primary",
            },
          ],
        },
      ],
    },
  ],
};
