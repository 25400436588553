export const FloatingActionPanel = {
  name: "main-buttons",
  component: "TeamButtons",
  layout: "team-buttons-base",
  mode: "visible",
  showOn: [{ state: "primary", value: "main-buttons", operator: "is" }],
  startEvent: false,
  startPlay: false,
  children: [
    {
      name: "goal-button",
      component: "TypeButton",
      layout: "team-button",
      text: "goal",
      mode: "enable",
      showForInsert: true,
      showOn: [
        {
          state: "period.periodStatus",
          value: "inProgress",
          operator: "is",
        },
      ],
      actionGroups: [
        {
          actions: [
            { action: "clearData", value: ["options"], type: "main" },
            {
              action: "saveData",
              value: ["x", "y", "periodId", "clock", "eventTime", "scores"],
              type: "variables",
            },
            { action: "saveData", value: "goal", type: "eventType" },
            { action: "saveData", value: true, type: "success" },
            { action: "clearData", value: ["subType"], type: "main" },
            { action: "nextState", value: "shot-type", type: "primary" },
          ],
          conditions: [],
        },
      ],
      startEvent: true,
      startPlay: true,
    },
    {
      name: "shot-attempt-button",
      component: "TypeButton",
      layout: "team-button",
      mode: "enable",
      text: "shot",
      showForInsert: true,
      showOn: [
        {
          state: "period.periodStatus",
          value: "inProgress",
          operator: "is",
        },
      ],
      actionGroups: [
        {
          actions: [
            { action: "clearData", value: ["options"], type: "main" },
            {
              action: "saveData",
              value: ["x", "y", "periodId", "clock", "eventTime", "scores"],
              type: "variables",
            },
            { action: "saveData", value: "goal", type: "eventType" },
            { action: "saveData", value: false, type: "success" },
            {
              action: "clearData",
              value: ["subType"],
              type: "main",
            },
            {
              action: "nextState",
              value: "shot-outcome-type",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
      startEvent: true,
      startPlay: true,
    },
    {
      name: "icing-button",
      component: "TypeButton",
      layout: "team-button",
      mode: "enable",
      text: "icing",
      showForInsert: true,
      showOn: [
        {
          state: "period.periodStatus",
          value: "inProgress",
          operator: "is",
        },
      ],
      actionGroups: [
        {
          actions: [
            { action: "stopClock", value: "stop", type: "main" },
            { action: "saveData", value: "icing", type: "eventType" },
            {
              action: "clearData",
              value: ["subType", "options", "personId"],
              type: "main",
            },
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "nextState",
              value: "penalty-shot-outcome",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
      startEvent: true,
      startPlay: true,
    },
    {
      name: "penalty-button",
      component: "TypeButton",
      layout: "team-button",
      mode: "enable",
      text: "penalty",
      showForInsert: true,
      showOn: [
        {
          state: "period.periodStatus",
          value: "inProgress",
          operator: "is",
        },
      ],
      actionGroups: [
        {
          actions: [
            { action: "stopClock", value: "stop", type: "main" },
            { action: "clearData", value: ["options"], type: "main" },
            { action: "saveData", value: "penalty", type: "eventType" },
            {
              action: "saveData",
              value: [
                "x",
                "y",
                "periodId",
                "clock",
                "eventTime",
                "scores",
                "subType",
              ],
              type: "variables",
            },
            { action: "nextState", value: "penalty-player", type: "primary" },
          ],
          conditions: [],
        },
      ],
      startEvent: true,
      startPlay: true,
    },
  ],
  actionGroups: [
    {
      actions: [{ action: "nextState", value: null, type: "primary" }],
    },
  ],
};
