import React from "react";

import { useIsVisible } from "../../../Base/Hooks/IsVisible";
import { sendPossessionEvent } from "../../../Base/Utilities/PossessionEvents";

const TeamSwitch = (props) => {
  const { currentState, panel, updateState } = props;
  const [isVisible, isEnabled] = useIsVisible(panel, props);

  function switchTeams() {
    let tempEntities = currentState.entities;
    if (panel.sendUpdateArrow) {
      if (
        typeof tempEntities[0].hasArrow !== "undefined" &&
        tempEntities[0].hasArrow + tempEntities[1].hasArrow !== 0
      ) {
        tempEntities[0].hasArrow = !tempEntities[0].hasArrow;
      }
      if (
        typeof tempEntities[1].hasArrow !== "undefined" &&
        tempEntities[0].hasArrow + tempEntities[1].hasArrow !== 0
      ) {
        tempEntities[1].hasArrow = !tempEntities[1].hasArrow;
      }
      sendPossessionEvent(
        tempEntities[0].hasArrow
          ? tempEntities[0].entityId
          : tempEntities[1].entityId,
        "hasArrow",
        props
      );
    }

    updateState("entities", [tempEntities[1], tempEntities[0]]);
  }

  return (
    <React.Fragment>
      {isVisible && (
        <div
          className={panel.layout + " enabled-" + isEnabled}
          onClick={(e) => switchTeams()}
        >
          <i className="fas fa-exchange-alt"></i>
        </div>
      )}
    </React.Fragment>
  );
};

export default TeamSwitch;
