export const PenaltyFoulDrawnPlayer = {
  name: "penalty-foul-drawn-player",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "fouldrawn.select-player",
  showOn: [{ state: "primary", value: "penalty-foul-drawn-player", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "foul-drawn-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: false,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: true,
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "nextState",
              value: "penalty-foul-player",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "card-buttons",
      component: "ButtonPanel",
      layout: "button-group centered",
      mode: "visible",
      showOn: [true],
      children: [
        {
          name: "confirm-later",
          component: "TypeButton",
          layout: "match-button",
          mode: "enable",
          text: "confirmLater",
          showOn: [true],
          startEvent: false,
          startPlay: false,
          actionGroups: [
            {
              actions: [
                { action: "saveData", type: "flagged", value: true },
                { action: "sendEvent", value: "new", type: "main" },
                {
                  action: "nextState",
                  value: "penalty-foul-player",
                  type: "primary",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
