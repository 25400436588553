import React from "react";
import { injectIntl } from "react-intl";
import Button from "../../Base/Button/Button";
import Modal from "../../Base/Modal/Modal";
import { convertClock } from "../../Base/Utilities/ConvertClock";
import "./EventQuery.scss";

/*
 * onClose: () => void;
 */
function EventQuery(props) {
  const { onClose, intl, notification } = props;
  const { formatMessage } = intl;

  const { metadata } = notification;

  return (
    <Modal
      shouldTrapFocus
      title={formatMessage({
        id: "eventQuery",
        defaultMessage: "Event Query",
      })}
      onClose={onClose}
    >
      <p className="event-query-text">
        {formatMessage({
          id: "eventQuery.text",
          defaultMessage:
            "There has been an issue identified with a specific event that has been input or is missing, it has been highlighted in your game log.",
        })}
      </p>
      <p className="event-query-details">
        <span>
          {formatMessage({
            id: `eventQuery.period.${metadata.periodId}`,
            defaultMessage: "periodId",
          })}
        </span>

        <span>-</span>
        <span>{convertClock(metadata.clock)}</span>
        <span>-</span>
        <span>
          {formatMessage({
            id: `eventQuery.eventType.${metadata.eventType}`,
            defaultMessage: "eventType",
          })}
        </span>
      </p>
      {metadata?.changeTo && (
        <p className="event-query-details">
          <span>
            Change to:{" "}
            {formatMessage({
              id: `eventQuery.eventType.${metadata.changeTo}`,
              defaultMessage: "Change to",
            })}
          </span>
        </p>
      )}
      {metadata?.comments && (
        <p className="event-query-details">
          <span>Comments: {metadata.comments}</span>
        </p>
      )}
      <div className="clock-query-button">
        <Button onClick={onClose} variant="danger">
          {formatMessage({
            id: "confirm",
            defaultMessage: "Confirm",
          })}
        </Button>
      </div>
    </Modal>
  );
}

export default injectIntl(EventQuery);
