const PenaltyShotOutcomeTypes = ["goal", "miss", "save"];

export const PenaltyShotOutcomeSelect = {
  name: "penalty-shot-outcome",
  component: "MainPanel",
  layout: "main-panel large no-group",
  mode: "visible",
  text: "penalty.shot-outcome",
  showOn: [{ state: "primary", value: "penalty-shot-outcome", operator: "is" }],
  startEvent: true,
  startPlay: false,
  allowFlag: true,
  allowCancel: true,
  children: [
    {
      name: "penalty-shot-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: true,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: true,
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [],
          conditions: [],
        },
      ],
    },
    {
      name: "penalty-shot-outcome-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "subType",
        children: PenaltyShotOutcomeTypes,
        layout: "type-button",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "saveData",
              value: "penaltyShotAwarded",
              type: "eventType",
            },
            { action: "copyEvent", value: "penaltyShotAwarded", type: "to" },
            {
              action: "clearData",
              value: ["options", "subType", "x", "y"],
              type: "main",
            },
            { action: "sendEvent", value: "new", type: "main" },
          ],
          conditions: [],
        },
        {
          actions: [
            { action: "newEventId", value: null, type: "main" },
            { action: "saveData", value: "goal", type: "eventType" },
            { action: "saveData", value: true, type: "success" },
            {
              action: "saveData",
              value: [{ option: "fromPenaltyShot", value: true }],
              type: "options",
            },
            { action: "sendEvent", value: "new", type: "main" },
          ],
          conditions: [
            {
              item: "subType",
              value: "goal",
              event: "penaltyShotAwarded",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "newEventId", value: null, type: "main" },
            { action: "saveData", value: "goal", type: "eventType" },
            { action: "saveData", value: false, type: "success" },
            {
              action: "saveData",
              value: [
                { option: "fromPenaltyShot", value: true },
                { option: "failureReason", value: "MISS" },
              ],
              type: "options",
            },
            { action: "sendEvent", value: "new", type: "main" },
          ],
          conditions: [
            {
              item: "subType",
              value: "miss",
              event: "penaltyShotAwarded",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "newEventId", value: null, type: "main" },
            { action: "saveData", value: "goal", type: "eventType" },
            { action: "saveData", value: false, type: "success" },
            {
              action: "saveData",
              value: [
                { option: "fromPenaltyShot", value: true },
                { option: "failureReason", value: "SAVE" },
              ],
              type: "options",
            },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "newEventId", value: null, type: "main" },
            { action: "saveData", value: "save", type: "eventType" },
            { action: "saveData", value: true, type: "success" },
            {
              action: "copyData",
              value: "entityId",
              type: "penaltyShotAwarded",
            },
            { action: "modifyEvent", value: "swapEntities", type: "main" },
            {
              action: "modifyEvent",
              value: "assignPlayerWithRole::Goalie",
              type: "main",
            },
            {
              action: "clearData",
              value: ["subType", "options"],
              type: "main",
            },
            { action: "sendEvent", value: "new", type: "main" },
          ],
          conditions: [
            {
              item: "subType",
              value: "save",
              event: "penaltyShotAwarded",
              operator: "is",
            },
          ],
        },
        {
          actions: [{ action: "nextState", value: null, type: "primary" }],
          conditions: [],
        },
      ],
    },
  ],
};
