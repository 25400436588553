import {
  ModifyPlayerSelect,
  ModifySubstitutionsSelect,
  ModifyShotType,
  ModifyTurnoverType,
  ModifyFoulType,
  ModifyTimeoutType,
  InsertChildPlayerSameTeam,
  InsertChildPlayerOtherTeam,
  InsertRebound,
  ModifyJumpBallType,
  MadeToMissed,
  MissedToMade,
  FreeThrowSuccess,
  JumpBallSuccess,
  ModifyRebound,
  InsertFreeThrowsAwarded,
  InsertFreeThrows,
  InsertFreeThrowSetup,
  ModifyShotValue2,
  ModifyShotValue3,
} from "./includes/Modify";
import {
  Court,
  Clock,
  InsertClock,
  TimeoutButton,
  SwitchTeamsButton,
  JumpBallButton,
  EventEditor,
  EventInsert,
  EventDelete,
  DebugPanel,
  FloatingActionPanel,
  ArrowConfirm,
  StartPowerPlayButton,
  EndPowerPlayButton,
} from "./includes/Base";
import {
  ShotPlayerSelect,
  ShotTypePanel,
  AssistPlayerSelect,
  ReboundPlayer,
  BlockPlayer,
  ReboundFollowOn,
  TurnoverPlayer,
  TurnoverType,
  StealPlayer,
  FoulPlayer,
  And1FoulPlayer,
  FoulType,
  FoulDrawnPlayer,
  FreeThrowsAwarded,
  FreeThrows,
  TimeoutSelect,
  TimeoutDetails,
  JumpballDetails,
  JumpballTurnoverPlayer,
  JumpballStealPlayer,
  JumpballStartGame,
  TechnicalFoulFromFT,
  TechnicalFoulFromFTPlayerSelect,
  JumpballFromRebound,
  ReboundPlayerFromJumpball,
  DoubleFoulType,
  DoubleFoulDrawnPlayer,
  DoubleFoulDrawnType,
  CancellationFoulType,
  CancellationFoulDrawn,
  CancellationFoulDrawnType,
  FullJumpballDetails,
  FullJumpballFromReboundDetails,
  FullJumpballResult,
  FullJumpballFromReboundResult,
} from "./includes/Panels";
import { modifyFreeThrowMade } from "./includes/ModifyDropdown/ModifyFreeThrowMade";
import { modifyRebound } from "./includes/ModifyDropdown/ModifyRebound";
import { modifyTimeOut } from "./includes/ModifyDropdown/ModifyTimeOut";

const FIBAWorkflow = {
  name: "full",
  sport: "basketball",
  managePossession: true,
  manageAlternatingArrow: true,
  displayEventLog: true,
  defaultLocations: [
    { x: 5, y: 50 },
    { x: 95, y: 50 },
  ],
  panels: [
    {
      name: "main-area",
      component: "StackingPanel",
      showOn: [true],
      layout: "column",
      children: [
        {
          name: "top-panel",
          component: "StackingPanel",
          layout: "fill-content",
          showOn: [true],
          children: [
            {
              name: "team-0-team-select",
              component: "Teams",
              showOn: [true],
              mode: "visible",
              possessionIcon: "basketball-ball",
              showPossessionIcon: true,
              showArrow: true,
              showStats: true,
            },
            {
              name: "clock-and-buttons",
              component: "StackingPanel",
              showOn: [true],
              layout: "column fill-parent clock-and-buttons",
              children: [
                Clock,
                {
                  name: "match-buttons",
                  component: "ButtonPanel",
                  layout: "match-buttons-stackable",
                  mode: "visible",
                  showOn: [true],
                  children: [TimeoutButton, SwitchTeamsButton, JumpBallButton],
                },
              ],
            },
            {
              name: "team-1-team-select",
              component: "Teams",
              showOn: [true],
              mode: "visible",
              possessionIcon: "basketball-ball",
              showPossessionIcon: true,
              showArrow: true,
              showStats: true,
            },
          ],
        },
        {
          name: "play-area",
          component: "StackingPanel",
          showOn: [true],
          layout: "fill-parent",
          children: [
            {
              name: "players-0",
              component: "StackingPanel",
              showOn: [true],
              layout: "column fit-content court-margin",
              children: [
                {
                  name: "power-play-buttons-team-0",
                  component: "ButtonPanel",
                  layout: "match-buttons-stackable",
                  mode: "visible",
                  showOn: [
                    { state: "fixture.isPowerPlayEnabled", operator: "is" },
                    {
                      state: "period.periodStatus",
                      value: "inProgress",
                      operator: "is",
                    },
                  ],
                  children: [
                    { ...StartPowerPlayButton, options: { team: 0 } },
                    { ...EndPowerPlayButton, options: { team: 0 } },
                  ],
                },
                {
                  name: "team-0-player-select",
                  component: "Players",
                  showOn: [true],
                  mode: "visible",
                },
              ],
            },
            {
              name: "middle-play-area",
              component: "StackingPanel",
              showOn: [true],
              layout: "column fill-content",
              children: [Court],
            },
            {
              name: "players-1",
              component: "StackingPanel",
              showOn: [true],
              layout: "column fit-content court-margin",
              children: [
                {
                  name: "power-play-buttons-team-1",
                  component: "ButtonPanel",
                  layout: "match-buttons-stackable",
                  mode: "visible",
                  showOn: [
                    { state: "fixture.isPowerPlayEnabled", operator: "is" },
                    {
                      state: "period.periodStatus",
                      value: "inProgress",
                      operator: "is",
                    },
                    {
                      state: "insertMode.enabled",
                      value: false,
                      operator: "is",
                    },
                  ],
                  children: [
                    { ...StartPowerPlayButton, options: { team: 1 } },
                    { ...EndPowerPlayButton, options: { team: 1 } },
                  ],
                },
                {
                  name: "team-1-player-select",
                  component: "Players",
                  showOn: [true],
                  mode: "visible",
                },
              ],
            },
          ],
        },
      ],
    },
    //Debug Panel
    DebugPanel,
    // InsertClock
    InsertClock,
    //EventEditor
    EventEditor,
    //Insert Confirm
    EventInsert,
    //Delete Confirm
    EventDelete,
    //Arrow Confirm
    ArrowConfirm,
    // First action select panel
    FloatingActionPanel,
    // Shot player select
    ShotPlayerSelect,
    // Shot Type Panel
    ShotTypePanel,
    // Assist Player Select
    AssistPlayerSelect,
    // Rebound Player /  shot clock
    ReboundPlayer,
    // Block Player Select
    BlockPlayer,
    // Rebound Follow On
    ReboundFollowOn,
    // Turnover Player Select
    TurnoverPlayer,
    // Turnover Type Panel
    TurnoverType,
    // Steal Player Select
    StealPlayer,
    // Foul Player Select
    FoulPlayer,
    // And1 Foul Player Select
    And1FoulPlayer,
    // Foul Type Panel
    FoulType,
    // Foul Drawn Player Select
    FoulDrawnPlayer,
    // Free Throws awarded
    FreeThrowsAwarded,
    // Free Throws Panel
    FreeThrows,
    // Timeout Select
    TimeoutSelect,
    // Timeout Details Panel
    TimeoutDetails,
    // Jumpball Details Panel
    JumpballDetails,
    // Jumpball Turnover Player Select
    JumpballTurnoverPlayer,
    // Jumpball Steal Player Select
    JumpballStealPlayer,
    // Jumpball Start Game
    JumpballStartGame,
    // Technical Foul from free throws
    TechnicalFoulFromFT,
    // Technical Foul from free throws - Player Select
    TechnicalFoulFromFTPlayerSelect,
    // Jumpball selection from Rebound
    JumpballFromRebound,
    // Player selection for rebound after jumpball from rebound
    ReboundPlayerFromJumpball,
    // Cancellation foul panels
    CancellationFoulType,
    CancellationFoulDrawn,
    CancellationFoulDrawnType,
    // Double foul panels
    DoubleFoulType,
    DoubleFoulDrawnPlayer,
    DoubleFoulDrawnType,
    FullJumpballDetails,
    FullJumpballFromReboundDetails,
    FullJumpballResult,
    FullJumpballFromReboundResult,
  ],
  modify: {
    types: [
      {
        name: "shot_made_2",
        eventTypes: ["2pt"],
        success: true,
        editable: [
          { type: "personId", text: "edit.shot.person", panel: "player" },
          { type: "subType", text: "edit.shot.type", panel: "shot-type" },
          { type: "options", text: "edit.shot.options", panel: "shot-type" },
          {
            type: "success",
            text: "edit.shot.success.missed",
            panel: "shot_success_made",
          },
          {
            type: "shot-value",
            text: "edit.shot.value.2",
            panel: "shot_value_2",
          },
        ],
        delete: true,
        delete_all: true,
        confirm_possession: true,
        child_inserts: [
          {
            type: "assist",
            text: "insert.child.assist",
            panel: "child-insert-same",
          },
        ],
      },
      {
        name: "shot_made_3",
        eventTypes: ["3pt"],
        success: true,
        editable: [
          { type: "personId", text: "edit.shot.person", panel: "player" },
          { type: "subType", text: "edit.shot.type", panel: "shot-type" },
          { type: "options", text: "edit.shot.options", panel: "shot-type" },
          {
            type: "success",
            text: "edit.shot.success.missed",
            panel: "shot_success_made",
          },
          {
            type: "shot-value",
            text: "edit.shot.value.3",
            panel: "shot_value_3",
          },
        ],
        delete: true,
        delete_all: true,
        confirm_possession: true,
        child_inserts: [
          {
            type: "assist",
            text: "insert.child.assist",
            panel: "child-insert-same",
          },
        ],
      },
      {
        name: "shot_missed",
        eventTypes: ["2pt", "3pt"],
        success: false,
        editable: [
          { type: "personId", text: "edit.shot.person", panel: "player" },
          { type: "subType", text: "edit.shot.type", panel: "shot-type" },
          { type: "options", text: "edit.shot.options", panel: "shot-type" },
          {
            type: "success",
            text: "edit.shot.success.made",
            panel: "shot_success_missed",
          },
          // {
          //   type: "location",
          //   text: "edit.shot.location",
          //   panel: "shot_location",
          // },
        ],
        delete: true,
        delete_all: true,
        confirm_possession: true,
        child_inserts: [
          {
            type: "block",
            text: "insert.child.block",
            panel: "child-insert-other",
          },
        ],
      },
      {
        name: "assist",
        eventTypes: ["assist"],
        success: null,
        editable: [
          {
            type: "personId",
            text: "edit.assist.person",
            panel: "player",
          },
        ],
        delete: true,
        delete_all: false,
        confirm_possession: false,
      },
      {
        name: "free_throw_made",
        eventTypes: ["freeThrow"],
        success: true,
        editable: [
          { type: "personId", text: "edit.freethrow.person", panel: "player" },
          {
            type: "success",
            text: "edit.freethrow.success.missed",
            panel: "free-throw-success",
          },
        ],
        delete: true,
        delete_all: true,
        confirm_possession: true,
        child_inserts: [
          {
            type: "assist",
            text: "insert.child.assist",
            panel: "child-insert-same",
          },
        ],
      },
      {
        name: "free_throw_missed",
        eventTypes: ["freeThrow"],
        success: false,
        editable: [
          { type: "personId", text: "edit.freethrow.person", panel: "player" },
          {
            type: "success",
            text: "edit.freethrow.success.made",
            panel: "free-throw-success",
          },
        ],
        delete: true,
        delete_all: true,
        confirm_possession: true,
      },
      {
        name: "timeOut",
        eventTypes: ["timeOut"],
        success: null,
        editable: [
          { type: "subType", text: "edit.timeout.type", panel: "timeout-type" },
        ],
        delete: true,
        delete_all: true,
        confirm_possession: false,
      },
      {
        name: "block",
        eventTypes: ["block"],
        success: null,
        editable: [
          {
            type: "personId",
            text: "edit.block.person",
            panel: "player",
          },
        ],
        delete: true,
        delete_all: false,
        confirm_possession: false,
      },
      {
        name: "rebound",
        eventTypes: ["rebound"],
        success: null,
        editable: [
          {
            type: "personId",
            text: "edit.rebound.person",
            panel: "shot-rebound-modify",
          },
        ],
        delete: true,
        delete_all: false,
        confirm_possession: false,
      },
      {
        name: "foul",
        eventTypes: ["foul"],
        subType: { subType: "drawn", operator: "is" },
        subTypeIgnore: [
          "benchTechnical",
          "coachDisqualifying",
          "coachTechnical",
        ],
        success: null,
        editable: [
          {
            type: "personId",
            text: "edit.fouldrawn.person",
            panel: "player",
          },
        ],
        delete: true,
        delete_all: true,

        confirm_possession: false,
      },
      {
        name: "foul",
        eventTypes: ["foul"],
        success: null,
        subTypeIgnore: [
          "benchTechnical",
          "coachDisqualifying",
          "coachTechnical",
        ],
        subType: { subType: "drawn", operator: "not" },
        editable: [
          {
            type: "personId",
            text: "edit.foul.person",
            panel: "player",
            linkedEventType: { type: "turnover", value: "personId" },
          },
          // { type: "subType", text: "edit.foul.type", panel: "foul-type" },
        ],
        delete: true,
        delete_all: true,
        hidden_children: ["turnover"],
        update_children: [{ eventType: "turnover", type: "personId" }],
        child_inserts: [
          {
            type: "freethrows",
            text: "insert.child.freethrows",
            panel: "child-insert-freethrows",
            ignoreSubType: "drawn",
          },
        ],
        confirm_possession: false,
      },
      {
        name: "foul-bench-coach",
        eventTypes: ["foul"],
        // subType: { subType: "drawn", operator: "not" },
        subTypeIgnore: [
          "disqualifying",
          "offensive",
          "personal",
          "technical",
          "unsportsmanlike",
          "drawn",
        ],
        success: null,
        editable: [
          {
            type: "delete_only",
            text: null,
            panel: null,
          },
        ],
        delete: true,
        delete_all: true,
        child_inserts: [
          {
            type: "freethrows",
            text: "insert.child.freethrows",
            panel: "child-insert-freethrows",
            ignoreSubType: "drawn",
            // showAlways: true,
          },
        ],
        confirm_possession: false,
      },
      {
        name: "substitution",
        eventTypes: ["substitution"],
        success: null,
        editable: [
          {
            type: "personId",
            text: "edit.substitutions",
            panel: "edit-substitutions",
          },
        ],
        delete: false,
        delete_children: [],
        confirm_possession: false,
        skipShowList: {
          panel: "edit-substitutions",
        },
      },
      {
        name: "timeout",
        eventTypes: ["timeout"],
        success: null,
        editable: [
          {
            type: "subType",
            text: "edit.timeout",
            panel: "timeout",
          },
        ],
        delete: true,
        delete_all: true,
        confirm_possession: false,
      },
      {
        name: "turnover",
        eventTypes: ["turnover"],
        subTypeIgnore: ["offensive"],
        success: null,
        editable: [
          {
            type: "personId",
            text: "edit.turnover.person",
            panel: "player",
            conditions: [
              {
                item: "personId",
                value: null,
                event: "main",
                operator: "not",
              },
            ],
          },
          {
            type: "subType",
            text: "edit.turnover.type",
            panel: "turnover-type",
          },
        ],
        delete: true,
        delete_all: true,
        confirm_possession: false,
        child_inserts: [
          {
            type: "steal",
            text: "insert.child.steal",
            panel: "child-insert-other",
            team: false,
          },
        ],
      },
      {
        name: "steal",
        eventTypes: ["steal"],
        success: null,
        editable: [
          { type: "personId", text: "edit.steal.person", panel: "player" },
        ],
        delete: true,
        delete_all: false,
        confirm_possession: false,
      },
      {
        name: "jumpBall",
        eventTypes: ["jumpBall"],
        success: null,
        editable: [
          {
            type: "personId",
            text: "edit.jumpBall.person",
            panel: "player",
            conditions: [
              {
                item: "personId",
                value: null,
                event: "main",
                operator: "not",
              },
            ],
          },
          {
            type: "options",
            text: "edit.jumpBall.type",
            panel: "jumpBall-type",
            conditions: [
              {
                item: "personId",
                value: null,
                event: "main",
                operator: "is",
              },
            ],
          },
          {
            type: "success",
            text: "edit.jumpBall.success",
            panel: "jumpball_success",
            conditions: [
              {
                item: "personId",
                value: null,
                event: "main",
                operator: "not",
              },
            ],
          },
        ],
        delete: true,
        delete_all: true,
        delete_conditions: [
          {
            item: "options",
            value: undefined,
            operator: "not",
          },
        ],
        confirm_possession: false,
      },
      {
        name: "scoreAdjustment",
        eventTypes: ["scoreAdjustment"],
        success: null,
        editable: [{ type: "delete_only", text: null, panel: null }],
        delete: true,
        delete_all: true,
        confirm_possession: false,
      },
      {
        name: "powerPlay",
        eventTypes: ["powerPlay"],
        success: null,
        editable: [{ type: "delete_only", text: null, panel: null }],
        delete: true,
        delete_all: true,
        confirm_possession: false,
      },
    ],
    panels: [
      // Player select
      ModifyPlayerSelect,
      // Substitution Players
      ModifySubstitutionsSelect,
      //Child Insert
      InsertChildPlayerSameTeam,
      InsertChildPlayerOtherTeam,
      // Shot Type Panel
      ModifyShotType,
      // Shot Type Panel
      ModifyTimeoutType,
      // //Turnover Player
      // ModifyTurnoverPlayerSelect,
      // Jumpball Types
      ModifyJumpBallType,
      //Foul Types
      ModifyTurnoverType,
      //Foul Types
      ModifyFoulType,
      // Change made to missed
      MadeToMissed,
      // Change missed to made
      MissedToMade,
      // Modufy Shot Value
      ModifyShotValue2,
      ModifyShotValue3,
      // Mody Rebound
      ModifyRebound,
      // Insert Rebound
      InsertRebound,
      // Switch jumpball result
      JumpBallSuccess,
      // Free Throw change success
      FreeThrowSuccess,
      // Insert Standalone Freethrows
      InsertFreeThrowSetup,
      InsertFreeThrowsAwarded,
      InsertFreeThrows,
      // Timeout Select
      {
        name: "timeout",
        component: "MainPanel",
        layout: "main-panel large padded align-center",
        mode: "visible",
        text: "timeout.select",
        allowFlag: true,
        allowCancel: true,
        showDetails: { events: ["main"] },
        showOn: [{ state: "editState", value: "timeout", operator: "is" }],
        children: [
          {
            name: "team-0-timeout",
            component: "TypeButton",
            layout: "match-button",
            mode: "enable",
            text: "team",
            showOn: [true],
            options: { team: 0 },
            actionGroups: [
              {
                actions: [
                  {
                    action: "sendEvent",
                    value: "edit",
                    type: "main",
                  },
                  {
                    action: "nextState",
                    value: "timeout-details-edit",
                    type: "editState",
                  },
                ],
                conditions: [],
              },
            ],
          },
          {
            name: "official-timeout",
            component: "TypeButton",
            layout: "match-button",
            mode: "enable",
            text: "official",
            showOn: [true],
            actionGroups: [
              {
                actions: [
                  {
                    action: "sendEvent",
                    value: "edit",
                    type: "main",
                  },
                  { action: "saveData", value: "official", type: "entityId" },
                  {
                    action: "nextState",
                    value: "timeout-details-edit",
                    type: "editState",
                  },
                ],
                conditions: [],
              },
            ],
          },
          {
            name: "team-1-timeout",
            component: "TypeButton",
            layout: "match-button",
            mode: "enable",
            text: "team",
            showOn: [true],
            options: { team: 1 },
            actionGroups: [
              {
                actions: [
                  {
                    action: "sendEvent",
                    value: "edit",
                    type: "main",
                  },
                  {
                    action: "nextState",
                    value: "timeout-details-edit",
                    type: "editState",
                  },
                ],
                conditions: [],
              },
            ],
          },
        ],
      },
      // Timeout Details Panel
      {
        name: "timeout-details",
        component: "MainPanel",
        layout: "main-panel large",
        mode: "visible",
        text: "timeout.details",
        showOn: [
          { state: "editState", value: "timeout-details-edit", operator: "is" },
        ],
        allowFlag: true,
        allowCancel: true,
        showDetails: { events: ["main"] },
        children: [
          // Team Timeout Types
          {
            name: "team-timeout-types",
            component: "ButtonGroup",
            layout: "button-group",
            mode: "visible",
            showOn: [
              {
                state: "event.entityId",
                value: "official",
                operator: "not",
                event: "main",
              },
            ],
            options: {
              value: "subType",
              children: ["full", "short", "commercial"],
              layout: "type-button",
              prefix: "timeout",
            },
            actionGroups: [],
          },
          // Official Timeout Types
          {
            name: "team-timeout-types",
            component: "ButtonGroup",
            layout: "button-group",
            mode: "visible",
            showOn: [
              {
                state: "event.entityId",
                value: "official",
                operator: "is",
                event: "main",
              },
            ],
            options: {
              value: "subType",
              children: ["officials", "commercial", "media"],
              layout: "type-button",
              prefix: "timeout",
            },
            actionGroups: [],
          },
          // Timeout Options
          {
            name: "timeout-options",
            component: "OptionGroup",
            layout: "option-group",
            mode: "visible",
            showOn: [true],
            options: {
              value: "options",
              children: ["bloodRule", "mandatory"],
              layout: "option-button",
              prefix: "timeout",
            },
          },

          // Timeout confirm
          {
            name: "ok-button",
            component: "TypeButton",
            layout: "match-button center action",
            mode: "enable",
            text: "ok",
            showOn: [
              {
                state: "event.subType",
                value: null,
                operator: "not",
                event: "main",
              },
            ],
            actionGroups: [
              {
                actions: [
                  {
                    action: "sendEvent",
                    value: "edit",
                    type: "main",
                  },
                  {
                    action: "nextState",
                    value: null,
                    type: "editState",
                  },
                ],
                conditions: [],
              },
            ],
          },
        ],
      },
    ],
  },
  modifyDropdown: {
    types: [
      {
        name: "freeThrow",
        eventTypes: ["freeThrow"],
        edit: {
          type: "customFn",
          customFn: modifyFreeThrowMade,
          options: {
            made: "edit.dropdown.freethrow.success.made",
            missed: "edit.dropdown.freethrow.success.missed",
          },
        },
      },
      {
        name: "steal",
        eventTypes: ["steal"],
        edit: {
          type: "delete",
          options: {
            false: "edit.dropdown.steal.delete.no",
            true: "edit.dropdown.steal.delete.yes",
          },
        },
      },
      {
        name: "rebound",
        eventTypes: ["rebound"],
        edit: {
          type: "customFn",
          customFn: modifyRebound,
          options: {
            offensive: "edit.dropdown.rebound.subtype.offensive",
            defensive: "edit.dropdown.rebound.subtype.defensive",
          }
        },
      },
      {
        name: "assist",
        eventTypes: ["assist"],
        edit: {
          type: "delete",
          options: {
            false: "edit.dropdown.assist.delete.no",
            true: "edit.dropdown.assist.delete.yes",
          },
        },
      },
      {
        name: "block",
        eventTypes: ["block"],
        edit: {
          type: "delete",
          options: {
            false: "edit.dropdown.block.delete.no",
            true: "edit.dropdown.block.delete.yes",
          },
        },
      },
      {
        name: "turnover",
        eventTypes: ["turnover"],
        edit: {
          type: "subType",
          options: {
            badPass: "edit.dropdown.turnover.subtype.badPass",
            ballHandling: "edit.dropdown.turnover.subtype.ballHandling",
            outOfBounds: "edit.dropdown.turnover.subtype.outOfBounds",
            travel: "edit.dropdown.turnover.subtype.travel",
            "3sec": "edit.dropdown.turnover.subtype.3sec",
            "5sec": "edit.dropdown.turnover.subtype.5sec",
            "8sec": "edit.dropdown.turnover.subtype.8sec",
            backCourt: "edit.dropdown.turnover.subtype.backCourt",
            offensiveGoalTending: "edit.dropdown.turnover.subtype.offensiveGoalTending",
            doubleDribble: "edit.dropdown.turnover.subtype.doubleDribble",
            carrying: "edit.dropdown.turnover.subtype.carrying",
            other: "edit.dropdown.turnover.subtype.other",
          },
        },
      },
      {
        name: "jumpBall",
        eventTypes: ["jumpBall"],
        edit: {
          type: "options",
          options: {
            heldBall: "edit.dropdown.jumpBall.subtype.heldBall",
            unclearPossession: "edit.dropdown.jumpBall.subtype.unclearPossession",
            lodgedBall: "edit.dropdown.jumpBall.subtype.lodgedBall",
            outOfBounds: "edit.dropdown.jumpBall.subtype.outOfBounds",
            doubleViolation: "edit.dropdown.jumpBall.subtype.doubleViolation",
            contestedRebound: "edit.dropdown.jumpBall.subtype.contestedRebound",
          },
        },
      },
      {
        name: "timeOut",
        eventTypes: ["timeOut"],
        edit: {
          type: "customFn",
          customFn: modifyTimeOut,
          options: {
            full: "edit.dropdown.timeOut.subtype.full",
            short: "edit.dropdown.timeOut.subtype.short",
            commercial: "edit.dropdown.timeOut.subtype.commercial",
            officials: "edit.dropdown.timeOut.subtype.officials",
            media: "edit.dropdown.timeOut.subtype.media",
          },
        },
      },
    ],
  },
};
export default FIBAWorkflow;
