import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";

import PlayerSelect from "../../PlayerSelect/PlayerSelect";
import ButtonPanel from "../../ButtonPanel/ButtonPanel";
import ButtonGroup from "../../ButtonPanel/ButtonGroup/ButtonGroup";
import OptionGroup from "../../ButtonPanel/OptionGroup/OptionGroup";
import TypeButton from "../../ButtonPanel/TypeButton/TypeButton";
import { useIsVisible } from "../../../Base/Hooks/IsVisible";

// import "./MainPanel.scss";

const SubPanel = (props) => {
  const { panel, intl } = props;
  const { formatMessage } = intl;
  const [layout, setLayout] = useState();
  const [isVisible, isEnabled] = useIsVisible(panel, props);

  useEffect(() => {
    if (panel && panel.children) {
      setLayout(
        panel.children.map((panel, index) => (
          <React.Fragment key={index}>
            {panel.component === "PlayerSelect" && (
              <PlayerSelect {...props} panel={panel} />
            )}
            {panel.component === "ButtonPanel" && (
              <ButtonPanel {...props} panel={panel} />
            )}
            {panel.component === "ButtonGroup" && (
              <ButtonGroup {...props} panel={panel} />
            )}
            {panel.component === "OptionGroup" && (
              <OptionGroup {...props} panel={panel} />
            )}
            {panel.component === "TypeButton" && (
              <TypeButton {...props} panel={panel} />
            )}
          </React.Fragment>
        ))
      );
    }
    // eslint-disable-next-line
  }, [panel, props]);

  return (
    <React.Fragment>
      {isVisible && (
        <div className={panel.layout + " enabled-" + isEnabled}>
          <div>
            {panel.text && (
              <h4>
                {formatMessage({
                  id: panel.text,
                  defaultMessage: panel.text,
                })}
                :
              </h4>
            )}
            {layout}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default injectIntl(SubPanel);
