import React, { useContext, useEffect, useState } from "react";
import { Tooltip } from "reactstrap";
import { injectIntl } from "react-intl";
import { lightOrDark } from "../../../Base/Utilities/LightOrDark";
import { getDistanceBetweenTwoColors } from "../../../Base/Utilities/Color";
import { FixtureDetailsContext } from "../../../../Config/FixtureDetails";
import { getIsPlayerDisqualifiedBecauseOfFoulTypes } from "../../../Base/Utilities/Player";

const redColor = "rgb(255, 0, 0)";
const MAX_PERSONAL_FOUL_PER_ROW = 6;

// we use yellow color instead of red, if the team color is too close to red
function getFoulColor(teamColor) {
  const distance = getDistanceBetweenTwoColors(redColor, teamColor);
  const isDistanceAcceptable = distance > 350;

  return isDistanceAcceptable ? "red" : "yellow";
}

const Player = (props) => {
  const {
    player,
    entityId,
    teamColor,
    currentState,
    eventStore,
    intl,
    isPreSubbed,
    forcedActiveState,
  } = props;
  const { formatMessage } = intl;
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const {
    fixtureProfile: { maxFoulsPersonal },
  } = useContext(FixtureDetailsContext);

  const getIsPlayerActive = () => {
    if (forcedActiveState !== undefined) {
      return forcedActiveState;
    }

    return player.desiredActiveState !== undefined
      ? player.desiredActiveState
      : player.active;
  };

  const isPlayerActive = getIsPlayerActive();

  const foulCount =
    currentState?.statistics?.[entityId]?.persons?.[player.personId]
      ?.foulsTotal ?? 0;

  const isPlayerDisqualifiedBecauseOfFoulTypes =
    getIsPlayerDisqualifiedBecauseOfFoulTypes(player.personId, eventStore);

  const isPlayerDisqualified =
    isPlayerDisqualifiedBecauseOfFoulTypes || foulCount >= maxFoulsPersonal;

  const foulColor = getFoulColor(teamColor);

  useEffect(() => () => setTooltipOpen(false), []);

  const getStyleObject = () => {
    if (isPlayerDisqualified) {
      return {
        backgroundColor: teamColor,
        border: "1px solid transparent",
      };
    }

    if (!isPlayerActive && isPreSubbed) {
      return {
        backgroundColor: teamColor,
        border: `3px solid ${teamColor}`,
      };
    }

    if (foulCount + 1 === maxFoulsPersonal) {
      return {
        backgroundColor: teamColor,
        border: `2px solid ${foulColor}`,
      };
    }

    if (isPlayerActive) {
      return {
        backgroundColor: teamColor,
        border: `1px solid ${
          lightOrDark(teamColor) === "light" ? "#202020" : teamColor
        }`,
      };
    } else {
      return {
        backgroundColor: teamColor,
        borderColor: teamColor,
      };
    }
  };

  const getFoulBoxBackground = () => {
    if (isPlayerDisqualified) {
      return foulColor;
    }

    if (lightOrDark(teamColor) === "light") {
      return "#202020";
    }

    return "#fff";
  };

  const handleClick = (e) => {
    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <div
      id={`player-tile-${player.personId}`}
      className={`player active-${isPlayerActive} ${lightOrDark(teamColor)}`}
      key={player.personId}
      style={getStyleObject()}
      onClick={handleClick}
    >
      <span>{player.bib}</span>
      <div
        className="foul-box-row"
        style={{
          gridTemplateColumns: `repeat(${Math.min(
            maxFoulsPersonal,
            MAX_PERSONAL_FOUL_PER_ROW
          )}, 0.3vw)`,
        }}
      >
        {Array.from(Array(Math.min(foulCount, maxFoulsPersonal))).map(
          (_, idx) => (
            <>
              <div
                className={`foul-box${
                  idx < foulCount ? " foul-box--ticked" : ""
                }`}
                key={`player-foul-${player.personId}-${idx}`}
                style={{
                  backgroundColor: getFoulBoxBackground(),
                }}
              />
            </>
          )
        )}
        {(isPlayerDisqualified || isPreSubbed) && (
          <Tooltip
            isOpen={isTooltipOpen}
            target={`player-tile-${player.personId}`}
            toggle={() => setTooltipOpen(!isTooltipOpen)}
          >
            {formatMessage({
              id: isPlayerDisqualified ? "disqualified" : "preSubbed",
              defaultMessage: isPlayerDisqualified ? "Disqualified" : "SUB",
            })}
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default injectIntl(Player);
