import { useEffect, useState } from "react";

import { useIsVisible } from "../../../Base/Hooks/IsVisible";
import { useDoActions } from "../../../Base/Hooks/doActions";

const PreAction = (props) => {
  const { panel } = props;
  const [isVisible] = useIsVisible(panel, props);
  const [doAction, setDoAction] = useState(false);
  const [actionsDone] = useDoActions(panel, props, doAction);

  useEffect(() => {
    if (actionsDone) {
      setDoAction(false);
    }
    // eslint-disable-next-line
  }, [actionsDone]);

  useEffect(() => {
    setDoAction(true);
    // eslint-disable-next-line
  }, [isVisible]);

  return null;
};

export default PreAction;
