import React, { useContext } from "react";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";
import useEntityDataByPanelName from "../../Base/Hooks/useEntityDataByPanelName";
import BasketballPlayers from "./basketball";
import Ice3Players from "./ice3";
import HockeyPlayers from "./hockey";
import HandballPlayers from "./handball";

const Players = (props) => {
  const { currentState, panel } = props;

  const { sport } = useContext(FixtureDetailsContext);

  const { players, teamColor, entity, entityIndex } = useEntityDataByPanelName(
    panel.name,
    currentState
  );

  if (sport === "ice3") {
    return (
      <Ice3Players
        players={players}
        teamColor={teamColor}
        entity={entity}
        {...props}
      />
    );
  }

  if (sport === "basketball") {
    return <BasketballPlayers entity={entity} index={entityIndex} {...props} />;
  }

  if (sport === "hockey") {
    return (
      <HockeyPlayers
        players={players}
        teamColor={teamColor}
        entity={entity}
        {...props}
      />
    );
  }

  if (sport === "handball") {
    return (
      <HandballPlayers
        players={players}
        teamColor={teamColor}
        entity={entity}
        {...props}
      />
    );
  }

  return null;
};

export default Players;
