import React, { useMemo } from "react";
import { injectIntl } from "react-intl";
import { useIsVisible } from "../../Base/Hooks/IsVisible";
import TypeButton from "../ButtonPanel/TypeButton/TypeButton";

import "./TeamButtonGroup.scss";

const TeamButtonGroup = (props) => {
  const { panel, currentState, intl } = props;
  const [isVisible] = useIsVisible(panel, props);
  const { formatMessage } = intl;

  const buttons = useMemo(() => {
    if (panel && panel.children) {
      if (currentState.entities && currentState.entities.length > 0) {
        const teamIndex = panel.options.team;
        const thisEntity = currentState.entities[teamIndex];
        return (
          <div className="team-button-group">
            <h1 className="team-button-group--title">
              {formatMessage({ id: panel.text })}
            </h1>
            <div
              className="team-button-group--content"
              style={{
                borderColor: thisEntity.primaryColor,
              }}
              key={thisEntity.entityId}
            >
              {panel.children.map((button, i) => (
                <React.Fragment key={i}>
                  {(function () {
                    switch (button.component) {
                      case "TypeButton":
                        return (
                          <TypeButton
                            {...props}
                            panel={button}
                            team={teamIndex}
                            style={{
                              background: thisEntity.primaryColor,
                            }}
                          />
                        );
                      default:
                        return null;
                    }
                  })()}
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      }
    }
    // eslint-disable-next-line
  }, [panel, props]);
  return isVisible ? <div className={panel.layout}>{buttons}</div> : null;
};

export default injectIntl(TeamButtonGroup);
