import { TechnicalFaultTypes } from "../Constants";

export const TechnicalFaultType = {
  name: "technical-fault-type",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "technicalFault.type",
  showOn: [{ state: "primary", value: "technical-fault-type", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: {},
  children: [
    {
      name: "technical-fault-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "subType",
        children: TechnicalFaultTypes,
        layout: "type-button",
        prefix: "technicalFault",
      },
      actionGroups: [
        {
          actions: [
            { action: "copyEvent", value: "fault", type: "to" },
            { action: "copyEvent", value: "steal", type: "to" },
            { action: "clearData", value: ["subType"], type: "steal" },
            { action: "modifyEvent", value: "swapEntities", type: "steal" },
            {
              action: "nextState",
              value: "steal-player",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "subType",
              value: "steal",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "nextState",
              value: "technical-fault-player",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "match-buttons",
      component: "ButtonPanel",
      layout: "match-buttons-stackable centered",
      mode: "visible",
      showOn: [true],
      children: [
        {
          name: "confirm-later-technical-fault-type",
          component: "TypeButton",
          layout: "match-button",
          mode: "enable",
          text: "confirmLater",
          showOn: [true],
          actionGroups: [
            {
              actions: [
                { action: "saveData", type: "flagged", value: true },
                {
                  action: "nextState",
                  value: "technical-fault-player",
                  type: "primary",
                },
              ],
              conditions: [],
            },
          ],
          startEvent: false,
          startPlay: false,
        },
      ],
    },
  ],
};
