import React, { useContext, useEffect, useState } from "react";

import "./LockScoreBanner.scss";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";

const LockScoreBanner = (props) => {
  const { currentState, updateState } = props;
  const [scoreBannerLocked, setScoreBannerLocked] = useState(true);
  const { sport } = useContext(FixtureDetailsContext);
  const location = useLocation();

  useEffect(() => {
    let tempEntities = currentState?.entities;
    if (sport === "handball" && tempEntities) {
      if (scoreBannerLocked) {
        if (!currentState.entityScoreBanner) {
          updateState("entityScoreBanner", [tempEntities[0].entityId, tempEntities[1].entityId]);
        }
      } else {
        updateState("entityScoreBanner", null);
      }
    }
  }, [sport, scoreBannerLocked, currentState.entities]);

  function handleLockClick() {
    setScoreBannerLocked(!scoreBannerLocked);
  }

  return (
    <React.Fragment>
      {sport === "handball" && location.pathname.startsWith("/game") && (
        <div className="lock-banner-button" onClick={(e) => handleLockClick()}>
          {scoreBannerLocked ? <i className="fas fa-lock"></i> : <i className="fas fa-lock-open"></i>}
        </div>
      )}
    </React.Fragment>
  );
};

export default LockScoreBanner;
