import React, { useState, useEffect, useMemo } from "react";
import { injectIntl } from "react-intl";

import { sendPossessionEvent } from "../../Base/Utilities/PossessionEvents";

import "./PossessionConfirm.scss";

const PossessionConfirm = (props) => {
  const { currentState, updateState, intl } = props;
  const { entities } = currentState;
  const { formatMessage } = intl;
  const [currentEntities, setCurrentEntities] = useState();

  useEffect(() => {
    if (entities) {
      setCurrentEntities(entities);
    }
    // eslint-disable-next-line
  }, [entities]);

  const teamList = useMemo(() => {
    if (currentEntities && currentEntities.length > 0) {
      return currentEntities.map((team, index) => (
        <div
          className={
            "team team-" +
            index +
            " hasPossession-" +
            team.hasPossession +
            " hasArrow-" +
            team.hasArrow
          }
          style={{
            borderColor: team.primaryColor,
          }}
          key={team.entityId}
        >
          <div className="team-name">
            <span>{team.fullNameLatin}</span>
          </div>
          <div className="team-notes">
            <div className="arrow" onClick={(e) => setValue(team, "hasArrow")}>
              <i className="fas fa-caret-left left-arrow"></i>
              <i className="fas fa-caret-right right-arrow"></i>
            </div>
            <div
              className="possession"
              onClick={(e) => setValue(team, "hasPossession")}
            >
              <i className="fas fa-basketball-ball"></i>
            </div>
          </div>
        </div>
      ));
    }
    // eslint-disable-next-line
  }, [JSON.stringify(currentEntities)]);

  function doArrows() {
    currentEntities.forEach((team) => {
      if (team.hasArrow) {
        sendPossessionEvent(team.entityId, "hasArrow", props);
      }
      if (team.hasPossession) {
        sendPossessionEvent(team.entityId, "hasPossession", props);
      }
    });
    updateState("entities", currentEntities);
    updateState("confirmArrows", null);
  }

  function setValue(entity, variable) {
    let tempEntities = [...currentEntities];
    let currentEntityId = entity.entityId;

    tempEntities[
      tempEntities.findIndex((el) => el.entityId !== currentEntityId)
    ][variable] = false;
    tempEntities[
      tempEntities.findIndex((el) => el.entityId === currentEntityId)
    ][variable] = true;
    setCurrentEntities(tempEntities);
    props.forceUpdate();
  }

  return (
    <React.Fragment>
      {currentState.confirmArrows && (
        <div className="confirm-panel">
          <div>
            <h3>
              {formatMessage({
                id: "confirm.possession",
                defaultMessage: "Confirm Possession / Possession Arrow",
              })}
            </h3>
            <h4>
              {formatMessage({
                id: "confirm.possession.description",
                defaultMessage: "Please confirm arrows ",
              })}
            </h4>
            <div className="team-list">{teamList}</div>
            <div className="match-buttons">
              <div
                className="enabled-true match-button made"
                onClick={() => doArrows()}
              >
                {formatMessage({
                  id: "confirm",
                  defaultMessage: "Confirm",
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default injectIntl(PossessionConfirm);
