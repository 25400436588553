import React, { useState, useEffect, useContext } from "react";

import { FixtureDetailsContext } from "../../../../Config/FixtureDetails";

import { convertClockToSeconds } from "../InsertClock";

const InsertClockAdjust = (props) => {
  const { insertObject, timeSeconds, setTimeSeconds, period } = props;
  const { periodId, periodStatus } = period;
  const [adjustSeconds, setAdjustSeconds] = useState(timeSeconds);
  const { fixtureProfile } = useContext(FixtureDetailsContext);

  const isBoundaryIgnored = () => {
    if (!insertObject.isLastPlayInPeriod) {
      return false;
    }

    if (insertObject.play.periodId === periodId && periodStatus === "inProgress") {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (timeSeconds && timeSeconds !== adjustSeconds) {
      setAdjustSeconds(timeSeconds);
    }
    // eslint-disable-next-line
  }, [timeSeconds]);

  useEffect(() => {
    let lower, upper;
    if (fixtureProfile.clockDirection === "COUNT_DOWN") {
      lower = isBoundaryIgnored() ? 0 : convertClockToSeconds(insertObject.range[0]);
      upper = insertObject.isFirstPlayInPeriod
        ? fixtureProfile.periodLength * 60
        : (convertClockToSeconds(insertObject.range[1]) ?? fixtureProfile.periodLength * 60);
    } else {
      lower = insertObject.isFirstPlayInPeriod ? 0 : (convertClockToSeconds(insertObject.range[1]) ?? 0);
      upper = isBoundaryIgnored()
        ? fixtureProfile.periodLength * 60
        : (convertClockToSeconds(insertObject.range[0]) ?? fixtureProfile.periodLength * 60);
    }
    if (adjustSeconds > lower - 1 && adjustSeconds < upper + 1) {
      setTimeSeconds(adjustSeconds);
    } else {
      setAdjustSeconds(timeSeconds);
    }
    // eslint-disable-next-line
  }, [adjustSeconds]);

  return (
    <React.Fragment>
      <div className={"adjuster minutes"}>
        <i className="up fas fa-caret-square-up" onClick={() => setAdjustSeconds(adjustSeconds + 60)}></i>
        <i className="down fas fa-caret-square-down" onClick={() => setAdjustSeconds(adjustSeconds - 60)}></i>
      </div>
      <div className={"adjuster seconds"}>
        <i className="up fas fa-caret-square-up" onClick={() => setAdjustSeconds(adjustSeconds + 1)}></i>
        <i className="down fas fa-caret-square-down" onClick={() => setAdjustSeconds(adjustSeconds - 1)}></i>
      </div>
    </React.Fragment>
  );
};

export default InsertClockAdjust;
