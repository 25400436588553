const canBeMerged = (val) => {
  const nonNullObject = val && typeof val === "object";
  return (
    nonNullObject &&
    Object.prototype.toString.call(val) !== "[object RegExp]" &&
    Object.prototype.toString.call(val) !== "[object Date]"
  );
};

const emptyTarget = (val) => {
  return Array.isArray(val) ? [] : {};
};

const cloneIfNecessary = (value, optionsArgument) => {
  const clone = optionsArgument && optionsArgument.clone === true;
  return clone && canBeMerged(value)
    ? mergeObjects(emptyTarget(value), value, optionsArgument)
    : value;
};

const defaultArrayMerge = (target, source, optionsArgument) => {
  const destination = [...target];
  source.forEach(function (e, i) {
    if (typeof destination[i] === "undefined") {
      destination[i] = cloneIfNecessary(e, optionsArgument);
    } else if (canBeMerged(e)) {
      destination[i] = mergeObjects(target[i], e, optionsArgument);
    } else if (target.indexOf(e) === -1) {
      destination.push(cloneIfNecessary(e, optionsArgument));
    }
  });
  return destination;
};

const mergeObject = (target, source, optionsArgument) => {
  const destination = {};
  if (canBeMerged(target)) {
    Object.keys(target).forEach(function (key) {
      destination[key] = cloneIfNecessary(target[key], optionsArgument);
    });
  }
  Object.keys(source).forEach(function (key) {
    if (!canBeMerged(source[key]) || !target[key]) {
      destination[key] = cloneIfNecessary(source[key], optionsArgument);
    } else {
      destination[key] = mergeObjects(
        target[key],
        source[key],
        optionsArgument
      );
    }
  });
  return destination;
};

const mergeObjects = (source, target, optionsArgument) => {
  const array = Array.isArray(source);
  const options = optionsArgument || { arrayMerge: defaultArrayMerge };
  const arrayMerge = options.arrayMerge || defaultArrayMerge;

  if (array) {
    if (Array.isArray(target)) {
      return optionsArgument?.skipArrayMerge
        ? target
        : arrayMerge(target, source, optionsArgument);
    } else {
      cloneIfNecessary(source, optionsArgument);
    }
  } else {
    return mergeObject(target, source, optionsArgument);
  }
};

const mergeArrayOfObjects = (array, optionsArgument) => {
  if (!Array.isArray(array) || array.length < 2) {
    throw new Error(
      "first argument should be an array with at least two elements"
    );
  }
  // we are sure there are at least 2 values, so it is safe to have no initial value
  return array.reduce((prev, next) =>
    mergeObjects(prev, next, optionsArgument)
  );
};

export { mergeObjects, mergeArrayOfObjects };
