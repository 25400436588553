import * as FoulTypes from "../../subTypes/EliteFoulTypes";

const nextStepAction = {
  actions: [
    { action: "sendEvent", value: "new", type: "main" },
    { action: "copyEvent", value: "foul", type: "to" },
    // Because we need to clear `bench` and `coach` properties before next step
    { action: "newEvent", value: null, type: "main" },
    {
      action: "nextState",
      value: "cancellation-foul-drawn-player",
      type: "primary",
    },
  ],
  conditions: [],
};

export const CancellationFoulType = {
  name: "cancellation-foul-type",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "foul.type",
  showOn: [
    { state: "primary", value: "cancellation-foul-type", operator: "is" },
  ],
  startEvent: false,
  startPlay: false,
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["main"] },
  allowBack: { state: "primary", value: "foul-type", event: "main" },
  children: [
    {
      name: "cancellation-player-foul-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.bench",
          value: false,
          operator: "is",
          event: "main",
        },
        {
          state: "event.coach",
          value: false,
          operator: "is",
          event: "main",
        },
      ],
      options: {
        value: "subType",
        children: FoulTypes.CancellationPlayerFoulTypes,
        layout: "type-button",
        prefix: "foul",
      },
      actionGroups: [
        {
          actions: [{ action: "saveData", value: "personal", type: "subType" }],
          conditions: [
            {
              item: "subType",
              value: "throwIn",
              event: "main",
              operator: "is",
            },
          ],
        },
        nextStepAction,
      ],
    },
    {
      name: "cancellation-coach-foul-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.coach",
          value: true,
          operator: "is",
          event: "main",
        },
      ],
      options: {
        value: "subType",
        children: FoulTypes.CancellationCoachFoulTypes,
        layout: "type-button",
        prefix: "foul",
      },
      actionGroups: [
        {
          actions: [
            { action: "sendEvent", value: "new", type: "main" },
            { action: "newEventId", value: null, type: "main" },
            { action: "modifyEvent", value: "swapEntities", type: "main" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [
            {
              item: "subType",
              value: "coachDisqualifying",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: nextStepAction.actions,
          conditions: [
            {
              item: "subType",
              value: "coachDisqualifying",
              event: "main",
              operator: "not",
            },
          ],
        },
      ],
    },
    {
      name: "cancellation-bench-foul-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.bench",
          value: true,
          operator: "is",
          event: "main",
        },
      ],
      startEvent: false,
      startPlay: false,
      options: {
        value: "subType",
        children: FoulTypes.CancellationBenchFoulTypes,
        layout: "type-button",
        prefix: "foul",
      },
      actionGroups: [nextStepAction],
    },
  ],
};
