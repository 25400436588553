import { useEffect } from "react"
import { FRESHCHAT_TOKEN, FRESHCHAT_HOST, FRESHCHAT_WIDGET_UUID } from "../../Config/Environment";


const settings = {
  token: FRESHCHAT_TOKEN,
  host: FRESHCHAT_HOST,
  widgetUuid: FRESHCHAT_WIDGET_UUID,
  tags: ["hblscout"],
}

const FreshChat = (props) => {
  const { onInit } = props;

  const loadScript = () => {
    let id = 'Freshchat-js-sdk';
    if (document.getElementById(id) || window.fcWidget) return;
    let script = document.createElement('script');
    script.async = 'true';
    script.src = `${FRESHCHAT_HOST}/js/widget.js`;
    script.id = id;
    document.head.appendChild(script);
  }

  const lazyInit = () => {
    loadScript();
    const interval = setInterval(() => {
      if (window.fcWidget) {
        clearInterval(interval);
        window.fcWidget.init({
          ...settings,
          ...props
        });
        onInit && onInit();
      }
    }, 1000);
  }

  const handleUnmount = () => {
    if (window.fcWidget) {
      window.fcWidget.close();
    }
  }

  useEffect(() => {
    if (window.fcWidget) {
      window.fcWidget.init({
        ...settings,
        ...props
      });
      onInit && onInit();
    } else {
      lazyInit();
    }
  }, []);

  useEffect(() => {
    return handleUnmount;
  }, [])

  return <></>
}

export default FreshChat;

