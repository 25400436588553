import React, { useEffect, useState, useCallback, useContext } from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import { useInterval } from "../../Base/Hooks/Interval";
import { useIsVisible } from "../../Base/Hooks/IsVisible";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";
import "../Clock/Clock.scss";
import Button from "../../Base/Button/Button";

const DisplayClock = (props) => {
  const { currentState, updateState, panel, intl } = props;
  const { formatMessage } = intl;
  const { clock, period } = currentState;
  const [totalSeconds, setTotalSeconds] = useState();
  const [displayTime, setDisplayTime] = useState();
  const [isVisible, isEnabled] = useIsVisible(panel, props);

  const displayTimeInState = currentState?.clock?.displayTime;

  const { fixtureProfile } = useContext(FixtureDetailsContext);

  useInterval(() => {
    _isClockRunning();
  }, 1000);

  useEffect(() => {
    let seconds = fixtureProfile.periodLength * 60;
    if (fixtureProfile.clockDirection === "COUNT_DOWN") {
      setTotalSeconds(seconds);
    } else {
      setTotalSeconds(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (displayTimeInState !== displayTime) {
      let clockParts = displayTimeInState.split(":");
      let seconds = parseInt(clockParts[0]) * 60 + parseInt(clockParts[1]);
      setTotalSeconds(seconds);
    }
  }, [displayTimeInState, displayTime]);

  useEffect(() => {
    let min = Math.floor(totalSeconds / 60);
    let sec = totalSeconds - min * 60;

    // guard & break if NaN happen
    // TODO: debug this issue, they shouldn't ever be NaN
    if (Number.isNaN(min) || Number.isNaN(sec)) {
      return;
    }

    let timeDisplay = _buildDisplayTime(min, sec);
    setDisplayTime(timeDisplay);
    updateState("clock", {
      ...clock,
      displayTime: timeDisplay,
    });
    // eslint-disable-next-line
  }, [totalSeconds, clock?.clockRunning]);

  const _isClockRunning = useCallback(() => {
    if (clock?.clockRunning) {
      if (fixtureProfile.clockDirection === "COUNT_DOWN") {
        setTotalSeconds(totalSeconds - 1);
      } else {
        setTotalSeconds(totalSeconds + 1);
      }
    }
    // eslint-disable-next-line
  }, [clock?.clockRunning, totalSeconds, fixtureProfile]);

  function _buildDisplayTime(mins, secs) {
    let Time = [mins, secs];
    if (secs < 10) {
      Time[1] = "0" + secs;
    }
    if (mins < 10) {
      Time[0] = "0" + mins;
    }
    return Time[0] + ":" + Time[1];
  }

  if (!isVisible) {
    return null;
  }

  return (
    <div className={panel.layout + " enabled-" + isEnabled}>
      <div className="period-display">
        {currentState &&
          currentState.period &&
          currentState.period.periodStatus && (
            <span className={"recovery-" + currentState.recovery}>
              <FormattedMessage
                id={
                  currentState.period.periodId >= fixtureProfile.initialExtraPeriodId
                    ? "period.overtime.full"
                    : "period.full"
                }
                defaultMessage={
                  currentState.period.periodId >= fixtureProfile.initialExtraPeriodId
                    ? "Overtime {period}"
                    : "Period {period}"
                }
                values={{
                  period:
                    currentState.period.periodId >= fixtureProfile.initialExtraPeriodId
                      ? currentState.period.periodId - (fixtureProfile.initialExtraPeriodId - 1)
                      : currentState.period.periodId,
                }}
              />{" "}
            </span>
          )}
      </div>
      <div className="display-clock-bottom-container">
        <div className={"time-display recovery-" + currentState.recovery}>
          {displayTime}
        </div>
        {!clock?.clockRunning &&
          period &&
          period.periodStatus === "pendingExtra" && (
            <Button onClick={() => updateState("primary", "shootOut")}>
              {formatMessage({
                id: "clock.shootout",
                defaultMessage: "Shoot-out",
              })}
            </Button>
          )}
        {!clock?.clockRunning &&
          period &&
          period.periodStatus === "fixtureComplete" && (
            <span className="fixture-complete-message">
              {formatMessage({
                id: "clock.complete.fixture",
                defaultMessage: "Complete",
              })}
            </span>
        )}
      </div>
    </div>
  );
};

export default injectIntl(DisplayClock);
