export function getLastValidPlayFromPeriod(plays, events, idx = 0) {
  const currentPlay = plays[idx];

  if (!currentPlay) {
    return null;
  }

  const relatedEvent = events.find(
    (event) => event.playId === currentPlay.playId
  );

  if (!!relatedEvent) {
    return currentPlay;
  }

  return getLastValidPlayFromPeriod(plays, events, idx + 1);
}

export function getPeriodEndEvent(plays, periodId) {
  return plays.find(
    (play) =>
      periodId === play.periodId &&
      play.eventType === "period" &&
      play.subType === "end"
  );
}
