export var JumpballTurnoverPlayer =
  // Jumpball Turnover Player Select
  {
    name: "jumpball-turnover-player",
    component: "MainPanel",
    layout: "main-panel large",
    mode: "visible",
    text: "turnover.select-player",
    allowFlag: true,
    allowCancel: false,
    showDetails: { events: ["jumpball"] },
    showOn: [
      { state: "primary", value: "jumpball-turnover-player", operator: "is" },
    ],
    children: [
      // Select Player
      {
        name: "jb-turnover-player-select",
        component: "PlayerSelect",
        layout: "player-select",
        mode: "visible",
        showOn: [true],
        startEvent: true,
        startPlay: false,
        options: {
          currentEntity: true,
          showBench: false,
          showCoach: false,
        },
        actionGroups: [
          {
            actions: [
              { action: "saveData", value: "turnover", type: "eventType" },
              { action: "saveData", value: "other", type: "subType" },
              {
                action: "clearData",
                value: ["options", "scores", "success"],
                type: "main",
              },
              { action: "sendEvent", value: "new", type: "main" },
              { action: "copyEvent", value: "turnover", type: "to" },
              {
                action: "nextState",
                value: "jumpball-steal-player",
                type: "primary",
              },
              { action: "clearData", value: ["options"], type: "main" },
            ],
          },
        ],
        conditions: [],
      },
    ],
  };
