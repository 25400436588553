import { useEffect } from "react";

const BrowserUtilities = (props) => {
  const { debug } = props;

  useEffect(() => {
    if (!debug && window.location.hostname !== "localhost") {
      // Warn on Unload / Reload
      window.addEventListener("beforeunload", (event) => {
        event.preventDefault();
        event.returnValue = "";
      });
      document.onkeydown = function (e) {
        if (e.key === "F12") {
          return false;
        }
        if (e.key === "ALT") {
          return false;
        }
        if (e.ctrlKey) {
          return false;
        }
      };
      // prevents right clicking
      document.addEventListener("contextmenu", (e) => e.preventDefault());
    }
    return () => {
      window.removeEventListener("beforeunload", (event) => {
        event.preventDefault();
        event.returnValue = "";
      });
      document.removeEventListener("contextmenu", (e) => e.preventDefault());
    };
    // eslint-disable-next-line
  }, []);

  return null;
};

export default BrowserUtilities;
