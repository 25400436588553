
import { useMemo } from "react";
import "./ResultEntry.scss";

export const ResultEntry = (props) => {
  const {idx, status, bib, isSuccess} = props;

  const rootClassName = useMemo(() => {
    if (status === 'waiting') return '';
    else if (status === 'active') return 'shootout-resultentry-root--active';
    else return `shootout-resultentry-root--${isSuccess ? 'success' : 'fail'}`;
  }, [status, isSuccess])

  return (
    <div className={`shootout-resultentry-root ${rootClassName}`}>
      <div className="shootout-resultentry-shootcounter">
        {idx}
      </div>
      {status === 'handled' && (
        <div className="shootout-resultentry-player">
          {bib}
        </div>
      )}
      {status === 'handled' && (
        <div className={`shootout-resultentry-mark shootout-resultentry-mark--${isSuccess ? 'success' : 'fail'}`}>
          <i className={`fas ${isSuccess ? 'fa-check' : 'fa-times'}`} />
        </div>
      )}
    </div>
  )
}