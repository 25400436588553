import React from "react";
import { useIsVisible } from "../../Base/Hooks/IsVisible";
import "./ModeSelector.scss";

function ModeSelector(props) {
  const { currentState, updateState, panel } = props;
  const [isVisible] = useIsVisible(panel, props);

  return (
    <div className="mode-selector-container">
      <select
        className="mode-selector"
        onChange={(ev) => updateState("userMode", ev.target.value)}
        value={currentState.userMode}
        disabled={!isVisible}
      >
        <option value="full">Full</option>
        <option value="clockOperator">Clock</option>
        <option value="scoreOperator">Score</option>
      </select>
    </div>
  );
}

export default ModeSelector;
