import {
  TeamTimeoutTypes,
  OfficialTimeoutTypes,
} from "../../subTypes/EliteTimeoutTypes";
import { TimeoutOptions } from "../../Options/TimeoutOptions";

// Timeout Details Panel
export var TimeoutDetails = {
  name: "timeout-details",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "timeout.details",
  showOn: [{ state: "primary", value: "timeout-details", operator: "is" }],
  startEvent: false,
  startPlay: false,
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["main"] },
  allowBack: { state: "primary", value: "timeout", event: "main" },
  children: [
    // Team Timeout Types
    {
      name: "team-timeout-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.entityId",
          value: "official",
          operator: "not",
          event: "main",
        },
      ],
      startEvent: false,
      startPlay: false,
      options: {
        value: "subType",
        children: TeamTimeoutTypes,
        layout: "type-button",
        prefix: "timeout",
      },
      actionGroups: [],
    },
    // Official Timeout Types
    {
      name: "team-timeout-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.entityId",
          value: "official",
          operator: "is",
          event: "main",
        },
      ],
      startEvent: false,
      startPlay: false,
      options: {
        value: "subType",
        children: OfficialTimeoutTypes,
        layout: "type-button",
        prefix: "timeout",
      },
      actionGroups: [],
    },
    // Timeout Options
    {
      name: "timeout-options",
      component: "OptionGroup",
      layout: "option-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "options",
        children: TimeoutOptions,
        layout: "option-button",
        prefix: "timeout",
      },
    },
    // Timeout confirm
    {
      name: "ok-button",
      component: "TypeButton",
      layout: "match-button center action",
      mode: "enable",
      text: "ok",
      startEvent: false,
      startPlay: false,
      showOn: [
        {
          state: "event.subType",
          value: null,
          operator: "not",
          event: "main",
        },
      ],
      actionGroups: [
        {
          actions: [{ action: "clearData", value: ["entityId"], type: "main" }],
          conditions: [
            {
              item: "entityId",
              value: "official",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "adjustTeamValue",
              value: 1,
              options: { currentEntity: true, item: "timeouts" },
            },
          ],
          conditions: [
            {
              item: "entityId",
              value: "official",
              event: "main",
              operator: "not",
            },
          ],
        },
        {
          actions: [
            { action: "newEventId", value: null, type: "main" },
            {
              action: "sendEvent",
              value: "new",
              type: "main",
            },
            { action: "copyEvent", value: "timeout", type: "to" },
            { action: "saveData", value: "", type: "entityId" },
            {
              action: "nextState",
              value: null,
              type: "primary",
            },
            { action: "clearData", value: ["options"], type: "main" },
            { action: "processInserts", value: "all", type: "inserts" },
            { action: "popStash", type: "main" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
