import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";

import { useIsVisible } from "../../../Base/Hooks/IsVisible";
import { useDoActions } from "../../../Base/Hooks/doActions";

import "./OptionGroup.scss";

const OptionGroup = (props) => {
  const { panel, intl, updateState, style } = props;
  const { formatMessage } = intl;
  const [layout, setLayout] = useState();
  const [optionsValue, setOptionsValue] = useState([]);
  const [updateOptions, setUpdateOptions] = useState(false);
  const [isVisible, isEnabled] = useIsVisible(panel, props);
  const [doAction, setDoAction] = useState(false);
  const [actionsDone] = useDoActions(panel, props, doAction);

  useEffect(() => {
    if (actionsDone) {
      setDoAction(false);
    }
    // eslint-disable-next-line
  }, [actionsDone]);

  function _updateOptions(option) {
    let tempOptions =
      props.currentState.events[
        panel.options.type ? panel.options.type : "main"
      ].options;
    if (Object.keys(optionsValue).includes(option)) {
      delete tempOptions[option];
    } else {
      if (typeof tempOptions === "undefined") {
        tempOptions = {};
      }
      tempOptions[option] = true;
    }
    setOptionsValue(tempOptions);

    if (panel.options.value) {
      let tempEvents = props.currentState.events;
      tempEvents[panel.options.type ? panel.options.type : "main"][
        panel.options.value
      ] = tempOptions;
      updateState("events", tempEvents);
    }
  }

  useEffect(() => {
    setUpdateOptions(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      props.currentState.events[
        panel.options.type ? panel.options.type : "main"
      ].options
    ) {
      setOptionsValue(
        props.currentState.events[
          panel.options.type ? panel.options.type : "main"
        ].options
      );
      setUpdateOptions(true);
    }
    // eslint-disable-next-line
  }, [props]);

  useEffect(() => {
    if (panel && panel.options && panel.options.children && updateOptions) {
      setLayout(
        panel.options.children.map((button) => (
          <div
            key={button}
            className={
              panel.options.layout +
              (optionsValue && Object.keys(optionsValue).includes(button)
                ? " active"
                : "")
            }
            onClick={(e) => _updateOptions(button)}
            style={style}
          >
            {optionsValue && Object.keys(optionsValue).includes(button) ? (
              <i className="far fa-check-square"></i>
            ) : (
              <i className="far fa-square"></i>
            )}
            <span>
              {panel.options.prefix &&
                formatMessage({
                  id: panel.options.prefix + "." + button,
                  defaultMessage: panel.options.prefix + "." + button,
                })}
              {!panel.options.prefix &&
                formatMessage({
                  id: button,
                  defaultMessage: button,
                })}
            </span>
          </div>
        ))
      );
      setUpdateOptions(false);
    }
    // eslint-disable-next-line
  }, [panel, updateOptions]);

  return (
    <React.Fragment>
      {isVisible && (
        <div className={panel.layout + " enabled-" + isEnabled}>{layout}</div>
      )}
    </React.Fragment>
  );
};

export default injectIntl(OptionGroup);
