export const PenaltyPlayerSelect = {
  name: "penalty-player",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "penalty.select-player",
  showOn: [{ state: "primary", value: "penalty-player", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  children: [
    {
      name: "penalty-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: false,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: true,
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [
            {
              action: "clearData",
              value: ["options", "subType", "scores", "success"],
              type: "main",
            },
            { action: "nextState", value: "penalty-type", type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
