import { CardTimeOption } from "../subTypes";

export var ModifyCardTime = {
  name: "card-time",
  component: "MainPanel",
  layout: "main-panel x-large",
  mode: "visible",
  text: "card.time",
  showOn: [{ state: "editState", value: "card-time", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    {
      name: "card-time",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "singleOption",
        optionName: "time",
        children: CardTimeOption,
        layout: "type-button",
        valueType: "integer",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
          ],
          conditions: [],
        },
        {
          actions: [
            {
              action: "nextState",
              value: "card-reason",
              type: "editState",
            },
          ],
          conditions: [
            {
              item: "eventType",
              value: "yellowCard",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [{ action: "nextState", value: null, type: "editState" }],
          conditions: [
            {
              item: "eventType",
              value: "yellowCard",
              event: "main",
              operator: "not",
            },
          ],
        },
      ],
    },
  ],
};
