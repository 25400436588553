import { JumpballOptions } from "../../Options/JumpballOptions";

// Jumpball Details Panel
export var JumpballDetails = {
  name: "jumpball-details",
  component: "MainPanel",
  layout: "main-panel large padded",
  mode: "visible",
  text: "jumpball.details",
  showOn: [{ state: "primary", value: "jumpball-details", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  startEvent: false,
  startPlay: false,
  children: [
    // Jumpball Types
    {
      name: "jumpball-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      startEvent: false,
      startPlay: true,
      options: {
        value: "options",
        children: JumpballOptions,
        layout: "type-button",
        prefix: "jumpball",
      },
      actionGroups: [
        // Set up jumpball details
        {
          actions: [
            {
              action: "clearData",
              value: ["personId", "x", "y"],
              type: "main",
            },
            { action: "saveData", value: "jumpBall", type: "eventType" },
            { action: "saveData", value: "", type: "temp" },
            { action: "saveData", value: "won", type: "subType" },
            { action: "copyEvent", value: "jumpball", type: "to" },
          ],
          conditions: [],
        },
        // If team has arrow and possession
        {
          actions: [
            {
              action: "saveData",
              value: ["hasPossession"],
              type: "variables",
            },
            { action: "saveData", value: "same", type: "temp" },
            { action: "copyEvent", value: "jumpball", type: "to" },
            {
              action: "sendEvent",
              value: "new",
              type: "main",
            },
          ],
          conditions: [
            {
              item: "hasPossession",
              value: true,
              event: "main",
              operator: "is",
            },
            {
              item: "hasArrow",
              value: true,
              event: "main",
              operator: "is",
            },
          ],
          operator: "all",
        },
        // Team not has arrow
        {
          actions: [
            {
              action: "saveData",
              value: ["notHasPossession"],
              type: "variables",
            },
            { action: "saveData", value: "split", type: "temp" },
            { action: "copyEvent", value: "jumpball", type: "to" },
            {
              action: "sendEvent",
              value: "new",
              type: "main",
            },
            { action: "clearData", value: ["options"], type: "main" },
            {
              action: "saveData",
              value: ["hasPossession"],
              type: "variables",
            },
          ],
          conditions: [
            {
              item: "temp",
              value: "same",
              event: "main",
              operator: "not",
            },
          ],
          operator: "all",
        },
        // Contested rebound
        {
          actions: [
            { action: "copyEvent", value: "jumpball", type: "from" },
            {
              action: "nextState",
              value: "rebound-player-from-jumpball",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "options",
              value: "contestedRebound",
              event: "jumpball",
              operator: "is",
            },
          ],
        },
        // Go to turnover if split arrow and held ball
        {
          actions: [
            {
              action: "setArrow",
              value: { currentEntity: false },
              type: "main",
              name: "hasPoss & hasArrow",
              options: { queued: true, event: "clockStart" },
            },
            {
              action: "nextState",
              value: "jumpball-turnover-player",
              type: "primary",
            },
            { action: "clearData", value: ["options"], type: "main" },
          ],
          conditions: [
            {
              item: "temp",
              value: "split",
              event: "main",
              operator: "is",
            },
            {
              item: "options",
              value: "heldBall",
              event: "main",
              operator: "is",
            },
          ],
          operator: "all",
        },
        // Switch Arrow when same and heldball
        {
          actions: [
            {
              action: "setArrow",
              value: { currentEntity: false },
              type: "main",
              name: "hasPoss & hasArrow",
              options: { queued: true, event: "clockStart" },
            },
            {
              action: "nextState",
              value: null,
              type: "primary",
            },
            { action: "clearData", value: ["options"], type: "main" },

            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [
            {
              item: "temp",
              value: "same",
              event: "main",
              operator: "is",
            },
            // {
            //   item: "options",
            //   value: "heldBall",
            //   event: "main",
            //   operator: "not",
            // },
          ],
          operator: "all",
        },
        // Switch ball and arrow
        {
          actions: [
            {
              action: "setPossession",
              value: { currentEntity: false },
              type: "main",
              name: "hasPoss & hasArrow",
            },
            {
              action: "setArrow",
              value: { currentEntity: true },
              type: "main",
              name: "hasPoss & hasArrow",
              options: { queued: true, event: "clockStart" },
            },
            {
              action: "nextState",
              value: null,
              type: "primary",
              name: "same",
            },
            { action: "clearData", value: ["options"], type: "main" },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [
            {
              item: "temp",
              value: "split",
              event: "main",
              operator: "is",
            },
          ],
          operator: "all",
        },
      ],
    },
  ],
};
