import isEqual from "lodash/isEqual";

import { ENVIRONMENT } from "../../../Config/Environment";

const excludedEventsTypesFromLog = ["heartbeat"];
const excludedMessagesFromLog = ["received-statistics"];

class Logger {
  static instance = null;

  logs = [];
  isSilentHeartbeat = window.localStorage.getItem("silent-heartbeat");
  constructor() {
    this.id = 0;
    this.exposeMethods();
    this.setSilentLogTypes();
  }

  static getInstance() {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }

    return Logger.instance;
  }

  setSilentLogTypes() {
    if (window.localStorage.getItem("silent-heartbeat")) {
      this.isSilentHeartbeat = true;
    }
  }

  exposeMethods() {
    window.toggleHeartbeatLogging = () => {
      const isSilent = window.localStorage.getItem("silent-heartbeat");
      if (isSilent) {
        window.localStorage.removeItem("silent-heartbeat");
        this.isSilentHeartbeat = false;
      } else {
        window.localStorage.setItem("silent-heartbeat", true);
        this.isSilentHeartbeat = true;
      }
    };
  }

  isSilentLog(params) {
    if (this.isSilentHeartbeat) {
      if (params?.[1]?.data?.class === "heartbeat") {
        return true;
      }
    }
    return false;
  }

  log(...params) {
    if (ENVIRONMENT !== "prod") {
      if (!this.isSilentLog(params)) {
        console.log(...params);
      }
    }

    const [message, payload] = params;

    if (
      !excludedEventsTypesFromLog.includes(payload?.data?.class) &&
      !excludedMessagesFromLog.includes(message)
    ) {
      if (params[0] === "received-play_by_play") {
        const playByPlays = [...this.logs]
          .filter((item) => item[0] === "received-play_by_play")
          .map((item) => item[1])
          .flat();

        if (playByPlays) {
          const diff = params[1].filter((event) =>
            playByPlays.every((existingEvent) => !isEqual(existingEvent, event))
          );

          this.logs.push([
            "received-play_by_play",
            window.structuredClone(diff),
          ]);
        } else {
          this.logs.push(window.structuredClone(params));
        }

        return;
      }

      this.logs.push(window.structuredClone(params));
    }
  }

  getAllLogs() {
    return JSON.stringify(this.logs);
  }

  resetLogs() {
    this.logs = [];
  }
}

export default Logger;
