import React, { useEffect, useState, useCallback } from "react";
import { APP_API_URL } from "./Environment";

const AuthContext = React.createContext();

const AuthProvider = ({ children, currentState, updateState }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [redirectUrl, setRedirectUrl] = useState("");

  const updateTokens = ({ accessToken, refreshToken }) => {
    if (accessToken) {
      setAccessToken(accessToken);
      localStorage.setItem("authKey", accessToken);
      updateState("authKey", accessToken);
    }
    if (refreshToken) {
      setRefreshToken(refreshToken);
      localStorage.setItem("refreshToken", refreshToken);
    }
  };

  const refreshAccessToken = useCallback(async () => {
    const refreshResponse = await fetch(`${APP_API_URL}/v1/client/login?authenticationType=refresh_token`, {
      method: "POST",
      body: JSON.stringify({
        refresh_token: refreshToken,
      }),
    });
    if (refreshResponse.ok) {
      const body = await refreshResponse.json();
      updateTokens({
        accessToken: body.data?.access_token,
        refreshToken: body.data?.refresh_token,
      });
      return { ok: true, accessToken: body.data?.access_token };
    }

    const body = await refreshResponse.json();
    return { ok: false, error: body.error };
  }, [refreshToken]);

  useEffect(() => {
    setAccessToken(localStorage.getItem("authKey"));
    setRefreshToken(localStorage.getItem("refreshToken"));
    setLoading(false);
  }, []);

  useEffect(() => {
    // on logout
    if (accessToken && !currentState.authKey) {
      setAccessToken(null);
      setRefreshToken(null);
    }
  }, [currentState.authKey]);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        refreshToken,
        updateTokens,
        refreshAccessToken,
        loading,
        redirectUrl,
        setRedirectUrl,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
