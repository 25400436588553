import {
  JumpballOptions,
  JumpballFromReboundOptions,
} from "../../Options/JumpballOptions";

const FullJumpballDetails = {
  name: "full-jumpball-details",
  component: "MainPanel",
  layout: "main-panel large padded",
  mode: "visible",
  text: "jumpball.details",
  showOn: [
    { state: "primary", value: "full-jumpball-details", operator: "is" },
  ],
  allowFlag: true,
  allowCancel: true,
  startEvent: false,
  startPlay: false,
  children: [
    {
      name: "jumpball-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      startEvent: true,
      startPlay: true,
      options: {
        value: "options",
        children: JumpballOptions,
        layout: "type-button",
        prefix: "jumpball",
      },
      actionGroups: [
        {
          actions: [
            { action: "copyEvent", value: "jumpball", type: "to" },
            {
              action: "nextState",
              value: "full-jumpball-result",
              type: "primary",
            },
          ],
        },
      ],
    },
  ],
};

const FullJumpballFromReboundDetails = {
  name: "full-jumpball-from-rebound-details",
  component: "MainPanel",
  layout: "main-panel large padded",
  mode: "visible",
  text: "jumpball.details",
  showOn: [
    {
      state: "primary",
      value: "full-jumpball-from-rebound-details",
      operator: "is",
    },
  ],
  allowFlag: true,
  allowCancel: true,
  startEvent: false,
  startPlay: false,
  children: [
    {
      name: "jumpball-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      startEvent: true,
      startPlay: false,
      options: {
        value: "options",
        children: JumpballFromReboundOptions,
        layout: "type-button",
        prefix: "jumpball",
      },
      actionGroups: [
        {
          actions: [
            { action: "copyEvent", value: "jumpball", type: "to" },
            { action: "clearData", value: ["personId"], type: "main" },
            {
              action: "nextState",
              value: "full-jumpball-from-rebound-result",
              type: "primary",
            },
          ],
        },
      ],
    },
  ],
};

const FullJumpballResult = {
  name: "full-jumpball-result",
  component: "MainPanel",
  layout: "jumpball-start main-panel x-large",
  mode: "visible",
  text: "jumpball.result",
  showOn: [{ state: "primary", value: "full-jumpball-result", operator: "is" }],
  startEvent: false,
  startPlay: false,
  allowFlag: false,
  allowCancel: false,
  children: [
    {
      name: "full-jumpball-select-result",
      component: "Jumpball",
      layout: "jumpball",
      mode: "visible",
      showOn: [true],
      startEvent: false,
      startPlay: false,
      options: {
        isMidgame: true,
      },
      actionGroups: [],
    },
  ],
};

const FullJumpballFromReboundResult = {
  name: "full-jumpball-from-rebound-result",
  component: "MainPanel",
  layout: "jumpball-start main-panel x-large",
  mode: "visible",
  text: "jumpball.result",
  showOn: [
    {
      state: "primary",
      value: "full-jumpball-from-rebound-result",
      operator: "is",
    },
  ],
  startEvent: false,
  startPlay: false,
  allowFlag: false,
  allowCancel: false,
  children: [
    {
      name: "full-jumpball-select-result",
      component: "Jumpball",
      layout: "jumpball",
      mode: "visible",
      showOn: [true],
      startEvent: false,
      startPlay: false,
      options: {
        isMidgame: true,
        fromRebound: true,
      },
      actionGroups: [],
    },
  ],
};

export {
  FullJumpballDetails,
  FullJumpballFromReboundDetails,
  FullJumpballResult,
  FullJumpballFromReboundResult,
};
