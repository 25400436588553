export var MissedToMade = {
  name: "shot_success_made",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "edit.shot.success",
  showOn: [
    { state: "editState", value: "shot_success_missed", operator: "is" },
  ],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    {
      name: "shot_missed_description",
      component: "PanelText",
      layout: "panel-text",
      mode: "visible",
      showOn: [true],
      options: {
        layout: "description-text",
        text: "edit.shot.missed.desription",
      },
      actionGroups: [],
    },
    {
      name: "shot_missed_change_made",
      component: "ButtonGroup",
      layout: "button-group true-false",
      mode: "visible",
      showOn: [true],
      startPlay: false,
      options: {
        value: "success",
        children: [true],
        layout: "type-button",
        prefix: "edit.shot.made.success",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            {
              action: "copyEvent",
              value: "shot",
              type: "to",
            },
            {
              action: "deleteFromPlay",
              value: "rebound",
              type: "shot",
            },
            {
              action: "deleteFromPlay",
              value: "block",
              type: "shot",
            },
            {
              action: "nextState",
              value: null,
              type: "editState",
            },
            { action: "setState", value: true, type: "confirmArrows" },
          ],
          conditions: [],
        },
        {
          actions: [
            {
              action: "adjustTeamValue",
              value: 2,
              options: { currentEntity: true, item: "score" },
            },
          ],
          conditions: [
            {
              item: "eventType",
              value: "2pt",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "adjustTeamValue",
              value: 3,
              options: { currentEntity: true, item: "score" },
            },
          ],
          conditions: [
            {
              item: "eventType",
              value: "3pt",
              event: "main",
              operator: "is",
            },
          ],
        },
      ],
    },
  ],
};
