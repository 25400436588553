const VideoReviewTypes = ["team", "refereeDelegate"];

export const VideoReviewTypeSelect = {
  name: "video-review-types",
  component: "MainPanel",
  layout: "main-panel",
  mode: "visible",
  text: "videoReview.type",
  showOn: [
    { state: "primary", value: "video-review-type-select", operator: "is" },
  ],
  startEvent: true,
  startPlay: true,
  allowFlag: true,
  allowCancel: true,
  children: [
    {
      name: "video-review-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "singleOption",
        valueType: "boolean",
        children: VideoReviewTypes,
        layout: "type-button",
        prefix: "videoReview.type",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "nextState",
              value: "video-review-team-select",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "options",
              value: "team",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "newPlayId", value: null, type: "main" },
            { action: "newEventId", value: null, type: "main" },
            { action: "clearData", value: ["entityId"], type: "main" },
            { action: "saveData", value: "videoReview", type: "eventType" },
            {
              action: "nextState",
              value: "video-review-outcome",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "options",
              value: "refereeDelegate",
              event: "main",
              operator: "is",
            },
          ],
        },
      ],
    },
  ],
};
