import { ShotAttackTypes } from "../Constants";

export const ModifyShotAttackType = {
  name: "shot-attack-type",
  component: "MainPanel",
  layout: "main-panel x-large",
  mode: "visible",
  text: "shot.attackType",
  showOn: [{ state: "editState", value: "shot-attack-type", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    {
      name: "shot-attack-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "singleOption",
        optionName: "attackType",
        children: ShotAttackTypes,
        layout: "type-button",
        prefix: "attackType",
        type: "edit",
      },
      actionGroups: [
        {
          actions: [
            { action: "sendEvent", value: "edit", type: "edit" },
            { action: "nextState", value: null, type: "editState" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
