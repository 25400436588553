const allOptions = [
  "bib",
  "playerName",
  "playing",
  "starter",
  "captain",
  "position",
];
export const getTeamSetupFields = (sport) => {
  if (sport === "handball") {
    return ["bib", "playerName", "playing", "position"];
  }

  return allOptions;
};
