import React, { useMemo } from "react";

import TeamSwitch from "./TeamSwitch/TeamSwitch";
import TypeButton from "./TypeButton/TypeButton";

import { useIsVisible } from "../../Base/Hooks/IsVisible";

import "./Buttons.scss";

import CountDownClock from "../CountDownClock/CountDownClock";

const ButtonPanel = (props) => {
  const { panel } = props;
  const [isVisible, isEnabled] = useIsVisible(panel, props);

  const layout = useMemo(() => {
    if (panel && panel.children) {
      return panel.children.map((button, index) => (
        <React.Fragment key={index}>
          {(function () {
            switch (button.component) {
              case "TeamSwitch":
                return <TeamSwitch {...props} panel={button} />;
              case "TypeButton":
                return <TypeButton {...props} panel={button} />;
              case "CountDownClock":
                return <CountDownClock {...props} panel={button} />;
              default:
                return null;
            }
          })()}
        </React.Fragment>
      ));
    }
    // eslint-disable-next-line
  }, [panel, props]);

  return (
    <React.Fragment>
      {isVisible && (
        <div className={panel.layout + " enabled-" + isEnabled}>{layout}</div>
      )}
    </React.Fragment>
  );
};

export default ButtonPanel;
