import { v1 as uuidv1 } from "uuid";
import { sendPossessionEvent } from "../../../Base/Utilities/PossessionEvents";
import { getEventTime, sendEvent } from "../../../Base/Utilities/SendEvent";

export const trackingPositions = {
  default: [
    "defensiveSafe",
    "defensive",
    "attack",
    "attackDangerous",
    "circle",
    "circle",
    "attackDangerous",
    "attack",
    "defensive",
    "defensiveSafe",
  ],
  indoor: ["defensive", "attack", "circle", "circle", "attack", "defensive"],
  hockey5s: ["defensive", "attack", "goal", "challenge", "goal", "challenge", "attack", "defensive"],
};

const decorateEvent = (event, entity, currentState) => {
  const timestamp = new Date();

  event.entityId = entity.entityId;
  event.timestamp = timestamp.toISOString();
  event.eventTime = getEventTime();
  event.periodId = currentState.period.periodId;
  event.playId = uuidv1();
  event.eventId = uuidv1();
};

export const trackingPositionHotkeys = {
  default: ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
  indoor: ["q", "w", "e", "i", "o", "p"],
  hockey5s: ["q", "w", "e", "r", "d", "f", "a", "s"],
};

const prepareAndShowCircleEntryModal = (entity, props) => {
  const { currentState, updateState } = props;

  const tempEvents = currentState.events;
  decorateEvent(tempEvents.main, entity, currentState);

  updateState("events", tempEvents);
  updateState("primary", "circle-entry-select");
};

const isPreviousEventChallenge = (events) => {
  const sortedSportEvents = events
    .filter((e) => e.class === "sport")
    .toSorted((a, b) => (new Date(a.eventTime.replace("Z", "")) > new Date(b.eventTime.replace("Z", "")) ? -1 : 1));

  return sortedSportEvents[0].eventType === "challenge";
};

export const createPossessionChangeHandler =
  ({ entity, position, props, cb, eventStore }) =>
  (ev) => {
    const { updateState, currentState } = props;
    const playId = uuidv1();

    ev.stopPropagation();

    const { entityId } = entity;

    const isCircleEvent = position === "circle";

    if (position === "goal") {
      const mainEvent = currentState.events?.main;
      if (!mainEvent) {
        return;
      }

      delete mainEvent.options;
      delete mainEvent.subType;
      decorateEvent(mainEvent, entity, currentState);
      mainEvent.success = true;
      mainEvent.eventType = "goal";
      if (!mainEvent?.options) mainEvent.options = {};
      mainEvent.options["goalOutcome"] = isPreviousEventChallenge(eventStore) ? "CHALLENGE" : "FIELD_GOAL";

      const action = {
        action: "sendEvent",
        event: mainEvent,
        type: "custom",
      };
      sendEvent(action, props);

      updateState("events", {
        ...currentState.events,
        main: mainEvent,
      });
      updateState("primary", "goal-player");
      return;
    }

    if (position === "challenge") {
      const mainEvent = currentState.events?.main;
      if (!mainEvent) {
        return;
      }

      decorateEvent(mainEvent, entity, currentState);
      mainEvent.eventType = "challenge";
      mainEvent.subType = "awarded";

      const action = {
        action: "sendEvent",
        event: mainEvent,
        type: "custom",
      };
      sendEvent(action, props);
      updateState("events", {
        ...currentState.events,
        main: mainEvent,
      });
      cb();
    } else {
      sendPossessionEvent(
        entityId,
        {
          type: "subTyped",
          subType: position,
          playId: isCircleEvent ? playId : undefined,
        },
        props,
        true,
      );
    }

    updateState("posTracking", { type: position, entity });

    if (isCircleEvent) {
      prepareAndShowCircleEntryModal(entity, props);
    }
  };
