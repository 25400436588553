const PenaltyTypes = ["major", "minor", "offsettingMajor", "offsettingMinor"];

export const PenaltyTypeSelect = {
  name: "penalty-type",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "penalty.type",
  showOn: [{ state: "primary", value: "penalty-type", operator: "is" }],
  startEvent: false,
  startPlay: false,
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["main"] },
  allowBack: { state: "primary", value: "penalty-player", event: "main" },
  children: [
    {
      name: "penalty-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "subType",
        children: PenaltyTypes,
        layout: "type-button",
      },
      actionGroups: [
        {
          actions: [
            { action: "copyEvent", value: "penalty", type: "to" },
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "nextState",
              value: "penalty-drawn-player",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "subType",
              value: ["offsettingMajor", "offsettingMinor"],
              event: "main",
              operator: "not",
            },
          ],
        },
        {
          actions: [
            { action: "copyEvent", value: "penalty", type: "to" },
            { action: "saveData", value: "major", type: "subType" },
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "nextState",
              value: "penalty-drawn-player",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "subType",
              value: "offsettingMajor",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "copyEvent", value: "penalty", type: "to" },
            { action: "saveData", value: "minor", type: "subType" },
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "nextState",
              value: "penalty-drawn-player",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "subType",
              value: "offsettingMinor",
              event: "main",
              operator: "is",
            },
          ],
        },
      ],
    },
  ],
};
