import React from "react";
import { injectIntl } from "react-intl";

import "./ConfirmModal.scss";

const ConfirmModal = (props) => {
  const { text, onCancel, onConfirm, intl } = props;
  const { formatMessage } = intl;

  return (
    <React.Fragment>
      <div className="main-panel shootout-confirm-main-panel">
        <div>
          <div className="panel-extras">
            <div className="cancel" onClick={onCancel}>
              <span>
                {formatMessage({
                  id: "cancel",
                  defaultMessage: "cancel",
                })}
              </span>
              <i className="fas fa-times"></i>
            </div>
          </div>
          <div className="shootout-confirm-sub-panel">
            <div className="panel-text">{text}</div>
            <div
              className="match-button action"
              onClick={onConfirm}
            >
              {formatMessage({
                id: "ok",
                defaultMessage: "OK",
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default injectIntl(ConfirmModal);
