import { sendEvent } from "../../../../Components/Base/Utilities/SendEvent";

export function modifyTimeOut(props, result) {
  let changeEvent = {...props.event};

  changeEvent.status = "updated";
  changeEvent.subType = result;

  if(["officials", "media"].includes(result)) {
    delete changeEvent.entityId;
  }

  let updateAction = {
    action: "sendEvent",
    event: changeEvent,
    type: "custom",
  };
  sendEvent(updateAction, props);
}
