export const ModifySanctionsType = {
  name: "modify-sanctions-type",
  component: "MainPanel",
  layout: "main-panel large padded align-center",
  mode: "visible",
  text: "edit.sanctions.type",
  showOn: [
    { state: "editState", value: "sanctions-type", operator: "is" }
  ],
  allowFlag: true,
  allowCancel: true,
  children: [
    {
      name: "suspension-buttons",
      component: "ButtonPanel",
      layout: "button-group centered",
      mode: "visible",
      showOn: [true],
      children: [
        {
          name: "2min-suspension",
          component: "TypeButton",
          layout: "type-button",
          mode: "enable",
          text: "suspension.2min",
          showOn: [true],
          actionGroups: [
            {
              actions: [
                { action: "deleteFromPlay", value: "suspension", type: "main" },
                { action: "saveData", value: "suspension", type: "eventType" },
                { action: "saveData", value: "twoMinutes", type: "subType" },
                { action: "sendEvent", value: "edit", type: "main" },
                { action: "nextState", value: null, type: "editState" },
              ]
            }
          ]
        },
        {
          name: "4min-suspension",
          component: "TypeButton",
          layout: "type-button",
          mode: "enable",
          text: "suspension.4min",
          showOn: [true],
          actionGroups: [
            {
              actions: [
                { action: "deleteFromPlay", value: "suspension", type: "main" },
                { action: "saveData", value: "suspension", type: "eventType" },
                { action: "saveData", value: "fourMinutes", type: "subType" },
                { action: "sendEvent", value: "edit", type: "main" },
                { action: "nextState", value: null, type: "editState" },
              ]
            }
          ]
        }
      ]
    },
    {
      name: "card-buttons",
      component: "ButtonPanel",
      layout: "button-group centered",
      mode: "visible",
      showOn: [true],
      children: [
        {
          name: "yellow-card",
          component: "TypeButton",
          layout: "type-button yellowCard",
          mode: "enable",
          text: "yellowCard",
          showOn: [true],
          actionGroups: [
            {
              actions: [
                { action: "deleteFromPlay", value: "suspension", type: "main" },
                { action: "clearData", value: ["subType"] },
                { action: "saveData", value: "yellowCard", type: "eventType" },
                { action: "sendEvent", value: "edit", type: "main" },
                { action: "nextState", value: null, type: "editState" }
              ]
            }
          ]
        },
        {
          name: "red-card",
          component: "TypeButton",
          layout: "type-button redCard",
          mode: "enable",
          text: "redCard",
          showOn: [true],
          actionGroups: [
            {
              actions: [
                { action: "deleteFromPlay", value: "suspension", type: "main" },
                { action: "clearData",  value: ["subType"]},
                { action: "saveData", value: "redCard", type: "eventType" },
                { action: "sendEvent", value: "edit", type: "main" },

                { action: "newEvent", value: null, type: "main" },
                { action: "saveData", value: "suspension", type: "eventType" },
                { action: "saveData", value: "twoMinutes", type: "subType" },
                { action: "sendEvent", value: "new", type: "main" },

                { action: "nextState", value: null, type: "editState" }
              ]
            }
          ]
        },
        {
          name: "blue-card",
          component: "TypeButton",
          layout: "type-button blueCard",
          mode: "enable",
          text: "blueCard",
          showOn: [true],
          actionGroups: [
            {
              actions: [
                { action: "deleteFromPlay", value: "suspension", type: "main" },
                { action: "clearData", value: ["subType"] },
                { action: "saveData", value: "blueCard", type: "eventType" },
                { action: "sendEvent", value: "edit", type: "main" },
                { action: "nextState", value: null, type: "editState" }
              ]
            }
          ]
        }
      ]
    }
  ]
}
