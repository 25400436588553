export const allowedValues = {
  "2pt": {
    options: [
      "blocked",
      "defensiveGoalTending",
      "fastBreak",
      "fromTurnover",
      "secondChance",
      "pointsInThePaint",
    ],
    subTypes: [
      "jumpShot",
      "layup",
      "fadeAway",
      "floatingJumpShot",
      "pullUpJumpShot",
      "stepBackJumpShot",
      "turnAroundJumpShot",
      "hookShot",
      "alleyOop",
      "dunk",
    ],
  },
  "3pt": {
    options: [
      "blocked",
      "defensiveGoalTending",
      "fastBreak",
      "fromTurnover",
      "secondChance",
    ],
    subTypes: [
      "jumpShot",
      "fadeAway",
      "floatingJumpShot",
      "pullUpJumpShot",
      "stepBackJumpShot",
      "turnAroundJumpShot",
    ],
  },
};
