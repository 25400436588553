import React from "react";
import { injectIntl } from "react-intl";

const Close = (props) => {
  const { updateState, intl } = props;
  const { formatMessage } = intl;

  const _setClose = () => {
    updateState("modify", null);
  };

  // Close Panel
  // document.onkeydown = function (e) {
  //   if (e.key === "Escape") {
  //     _setClose();
  //   }
  // };

  return (
    <div className="close" onClick={() => _setClose()}>
      <span>
        {formatMessage({
          id: "close",
          defaultMessage: "close",
        })}
      </span>
      <i className="fas fa-check"></i>
    </div>
  );
};

export default injectIntl(Close);
