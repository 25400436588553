import { useEffect } from "react";
import { createPossessionChangeHandler } from "./Field.logic";

export function useTrackingKeys(props, hotkeys, positions, callback) {
  useEffect(() => {
    const entities = props?.currentState?.entities;

    if (!entities) {
      return;
    }

    const listener = (ev) => {
      const keyIndex = hotkeys.indexOf(ev.key);

      if (keyIndex > -1) {
        const hotKeysCountPerTeam = hotkeys.length / 2;
        const entityIdx = Math.floor(keyIndex / hotKeysCountPerTeam);
        const entity = entities[entityIdx];
        const position = positions[keyIndex];

        createPossessionChangeHandler({
          entity,
          position,
          props,
          cb: callback,
        })(ev);
      }
    };

    document.addEventListener("keydown", listener);
    return () => document.removeEventListener("keydown", listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positions, props?.currentState?.entities]);
}
