export const TimeoutButton = {
  name: "timeout-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "timeout",
  showForInsert: true,
  showOn: [
    {
      state: "period.periodStatus",
      value: "inProgress",
      operator: "is",
    },
  ],
  actionGroups: [
    {
      actions: [
        { action: "stopClock", value: "stop", type: "main" },
        { action: "saveData", value: "timeOut", type: "eventType" },
        { action: "saveData", value: null, type: "subType" },
        { action: "saveData", value: "official", type: "entityId" },
        {
          action: "nextState",
          value: "timeout",
          type: "primary",
        },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const SwitchTeamsButton = {
  name: "switch-teams",
  component: "TeamSwitch",
  layout: "match-button small",
  mode: "enable",
  sendUpdateArrow: true,
  showOn: [{ state: "clock.clockRunning", value: false, operator: "is" }],
  startEvent: false,
  startPlay: false,
};

export const JumpBallButton = {
  name: "jumpball-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "jumpball",
  showForInsert: true,
  showOn: [{ state: "clock.displayTime", value: "00:00", operator: "not" }],
  actionGroups: [
    {
      actions: [
        { action: "stopClock", value: "stop", type: "main" },
        { action: "saveData", value: "jumpBall", type: "eventType" },
        {
          action: "saveData",
          value: ["periodId", "clock", "eventTime", "scores", "hasPossession"],
          type: "variables",
        },
        {
          action: "nextState",
          value: "jumpball-details",
          type: "primary",
        },
      ],
      conditions: [
        {
          item: "period",
          value: "pending",
          event: "periodStatus",
          operator: "not",
        },
        {
          item: "fixtureProfile",
          value: true,
          event: "midGameJumpBallAllowed",
          operator: "not",
        },
        // {
        //   item: "period",
        //   value: 1,
        //   event: "periodId",
        //   operator: "not",
        // },
      ],
    },
    {
      actions: [
        { action: "stopClock", value: "stop", type: "main" },
        { action: "saveData", value: "jumpBall", type: "eventType" },
        { action: "clearData", value: ["options"], type: "main" },
        {
          action: "saveData",
          value: [
            "periodId",
            "clock",
            "eventTime",
            "scores",
            "hasPossession",
            "options",
          ],
          type: "variables",
        },
        {
          action: "nextState",
          value: "full-jumpball-details",
          type: "primary",
        },
      ],
      conditions: [
        {
          item: "fixtureProfile",
          value: true,
          event: "midGameJumpBallAllowed",
          operator: "is",
        },
        {
          item: "period",
          value: "pending",
          event: "periodStatus",
          operator: "not",
        },
      ],
    },
    {
      actions: [
        {
          action: "nextState",
          value: "jumpball-start",
          type: "primary",
        },
      ],
      conditions: [
        {
          item: "period",
          value: "pending",
          event: "periodStatus",
          operator: "is",
        },
        {
          item: "period",
          value: 1,
          event: "periodId",
          operator: "is",
        },
      ],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const StartPowerPlayButton = {
  name: "start-power-play-button",
  component: "TypeButton",
  layout: "match-button wide team court-side",
  mode: "visible",
  text: "powerPlay",
  showOn: [{ state: "fixture.isPowerPlayActive", operator: "not" }],
  actionGroups: [
    {
      actions: [
        { action: "saveData", value: "powerPlay", type: "eventType" },
        { action: "saveData", value: "start", type: "subType" },
        { action: "sendEvent", value: "new", type: "main" },
        { action: "nextState", value: null, type: "primary" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const EndPowerPlayButton = {
  name: "start-power-play-button",
  component: "TypeButton",
  layout: "match-button wide team court-side",
  mode: "visible",
  text: "powerPlay.end",
  showOn: [{ state: "fixture.isPowerPlayActive", operator: "is" }],
  actionGroups: [
    {
      actions: [
        { action: "saveData", value: "powerPlay", type: "eventType" },
        { action: "saveData", value: "end", type: "subType" },
        { action: "sendEvent", value: "new", type: "main" },
        { action: "nextState", value: null, type: "primary" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};
