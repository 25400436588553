export var TwoPtTypes = [
  "jumpShot",
  "pullUpJumpShot",
  "floatingJumpShot",
  "layup",
  "drivingLayup",
  "reverseLayup",
  "stepBackJumpShot",
  "fadeAway",
  "turnAroundJumpShot",
  "euroStep",
  "dunk",
  "hookShot",
  "alleyOop",
  "alleyOopDunk",
];

export var ThreePtTypes = [
  "jumpShot",
  "fadeAway",
  "floatingJumpShot",
  "pullUpJumpShot",
  "stepBackJumpShot",
  "turnAroundJumpShot",
];
