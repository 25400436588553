import { ShotMissedOutcomeTypes } from "../Constants";

export const ShotMissedOutcomeTypeSelect = {
  name: "shot-missed-outcome-type",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "shot.outcome-type",
  showOn: [
    {
      state: "primary",
      value: "shot-missed-outcome-type",
      operator: "is",
    },
  ],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: {},
  children: [
    {
      name: "shot-missed-outcome-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "singleOption",
        optionName: "failureReason",
        children: ShotMissedOutcomeTypes,
        layout: "type-button",
        prefix: "failureReason",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "setPossession",
              value: { currentEntity: false },
              type: "main",
            },
          ],
          conditions: [
            {
              item: "optionValue",
              event: "main",
              operator: "is",
              name: "failureReason",
              value: "OFF_TARGET",
            },
          ],
        },
        {
          actions: [
            { action: "sendEvent", value: "new", type: "main" },
            { action: "nextState", value: "shot-attack-type", type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "card-buttons",
      component: "ButtonPanel",
      layout: "button-group centered",
      mode: "visible",
      showOn: [true],
      children: [
        {
          name: "confirm-later",
          component: "TypeButton",
          layout: "match-button",
          mode: "enable",
          text: "confirmLater",
          showOn: [true],
          startEvent: false,
          startPlay: false,
          actionGroups: [
            {
              actions: [
                { action: "saveData", type: "flagged", value: true },
                { action: "nextState", value: "shot-attack-type", type: "primary" },
                { action: "processInserts", value: "all", type: "inserts" },
              ],
            },
          ],
        },
      ],
    },
  ],
};
