import React, { useEffect, useRef } from "react";
import "./Modal.scss";

/*
 *
 * children: React.ReactNode;
 * title: string;
 * onClose: () => void;
 * shouldTrapFocus?: boolean;
 * className?: string;
 */
function Modal(props) {
  const { children, title, onClose, shouldTrapFocus, className } = props;
  const modalRef = useRef();

  const trapFocus = (ev) => {
    const isTabPressed = ev.key === "Tab" || ev.keyCode === 9;

    if (!isTabPressed) {
      return;
    }
    const focusableElements = "button, [href], input, [tabindex]";
    const focusableContent =
      modalRef.current.querySelectorAll(focusableElements);
    const firstFocusableElement = focusableContent[0];
    const lastFocusableElement = focusableContent[focusableContent.length - 1];

    if (ev.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        ev.preventDefault();
      }
    } else {
      if (document.activeElement === lastFocusableElement) {
        firstFocusableElement.focus();
        ev.preventDefault();
      }
    }
  };

  useEffect(() => {
    const handler = (ev) => {
      if (shouldTrapFocus) {
        ev.stopPropagation();
        trapFocus(ev);
      }
    };

    document.addEventListener("keydown", handler);
    return () => document.removeEventListener("keydown", handler);
  }, [shouldTrapFocus]);

  return (
    <div className="modal__backdrop">
      <div className={`modal__main ${className ?? ""}`} ref={modalRef}>
        <div className="modal__header">
          <h2>{title}</h2>
          <i className="fas fa-times" onClick={onClose} tabIndex="0"></i>
        </div>
        <div className="modal__body">{children}</div>
      </div>
    </div>
  );
}

export default Modal;
