import React from "react";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";

import "./OnlineCheck.scss";

import connected from "./connected.png";
import disconnected from "./disconnected.png";
import reconnect from "./reconnect.png";

const OnlineCheck = (props) => {
  const { currentState, location, onClick, intl } = props;
  const { formatMessage } = intl;

  const isOnline = currentState.connected && currentState.mqttConnected;
  const isDisconnected = currentState.connected && !currentState.mqttConnected;
  const isReconnecting = currentState.reconnecting;

  let pageType = "fixtures";
  if (location.pathname.includes("/game/")) {
    pageType = "game";
  }
  if (location.pathname.includes("/details/")) {
    pageType = "details";
  }
  if (location.pathname.includes("/setup/")) {
    pageType = "setup";
  }

  function renderOnlineState() {
    if (isReconnecting) {
      return (
        <div className="reconnecting">
          <img src={reconnect} alt="connected" />
          <span>
            {formatMessage({
              id: "status.connecting",
              defaultMessage: "Connecting",
            })}
          </span>
        </div>
      );
    }
    if (isOnline) {
      return (
        <div className="online">
          <img src={connected} alt="connected" />
          <span>
            {formatMessage({
              id: "status.connected",
              defaultMessage: "Connected",
            })}
          </span>
        </div>
      );
    } else if (isDisconnected) {
      return (
        <div className="disconnected" onClick={onClick}>
          <img src={reconnect} alt="connected" />
          <span>
            {formatMessage({
              id: "status.disconnected",
              defaultMessage: "Disconnected",
            })}
          </span>
          <i className="fas fa-sync"></i>
        </div>
      );
    } else {
      return (
        <div className="offline" onClick={onClick}>
          <img src={disconnected} alt="disconnected" />
          <span>
            {formatMessage({
              id: "status.offline",
              defaultMessage: "Offline",
            })}
          </span>
          <i className="fas fa-sync"></i>
        </div>
      );
    }
  }

  return (
    <>
      {pageType !== "fixtures" && (
        <>
          <div className="online-state">{renderOnlineState()}</div>
        </>
      )}
    </>
  );
};

export default withRouter(injectIntl(OnlineCheck));
