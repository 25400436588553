import React from "react";
import { injectIntl } from "react-intl";
import InsertChildControl from "../InsertChildControl/InsertChildControl";

const InsertChildItem = (props) => {
  const { insert, play, intl } = props;
  const { formatMessage } = intl;

  return (
    <div className="event">
      <span className="event-type insert">
        {formatMessage({
          id: insert.text + ".none",
          defaultMessage: insert.text + ".none",
        })}
      </span>
      <span className="event-controls">
        <InsertChildControl insert={insert} play={play} {...props} />
      </span>
    </div>
  );
};

export default injectIntl(InsertChildItem);
