export const JumpballOptions = [
  "heldBall",
  "unclearPossession",
  "lodgedBall",
  "outOfBounds",
  "doubleViolation",
  "contestedRebound",
];

export const JumpballFromReboundOptions = [
  "heldBall",
  "unclearPossession",
  "lodgedBall",
  "contestedRebound",
];
