import { createBaseEvent } from "../../../../Components/Base/Utilities/CreateBaseEvent";
import { sendEvent } from "../../../../Components/Base/Utilities/SendEvent";

export function modifyGoalFailureReason(props, failureReason) {
  const deleteBlockEvent = () => {
    const blockEvent = props.events?.find((ev) => ev.eventType === "block");
    sendEvent(
      {
        action: "sendEvent",
        event: blockEvent,
        type: "custom",
        value: "delete",
      },
      props,
    );
  };

  const getOtherEntity = (entityId) => {
    return props.currentState?.entities?.find((entity) => entity.entityId != entityId);
  };

  const createBlockEvent = () => {
    const blockEventAction = createBaseEvent(props.currentState, props.event.playId);
    blockEventAction.event["eventType"] = "block";
    blockEventAction.event["timestamp"] = props.event.timestamp;
    blockEventAction.event["eventTime"] = props.event.eventTime;
    blockEventAction.event["entityId"] = getOtherEntity(props.event.entityId)?.entityId;
    sendEvent(blockEventAction, props);
  };

  let changeEvent = { ...props.event };

  if (changeEvent.options["failureReason"] === "SHOT_BLOCKED" && failureReason !== "SHOT_BLOCKED") {
    deleteBlockEvent();
  } else if (changeEvent.options["failureReason"] !== "SHOT_BLOCKED" && failureReason === "SHOT_BLOCKED") {
    createBlockEvent();
  }

  changeEvent.status = "updated";
  changeEvent.options["failureReason"] = failureReason;

  let updateAction = {
    action: "sendEvent",
    event: changeEvent,
    type: "custom",
  };
  sendEvent(updateAction, props);
}
