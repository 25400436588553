import React from "react";
import { withRouter, Link } from "react-router-dom";
import { injectIntl } from "react-intl";

import Alert from "../Base/Alert";

const GameDetailsControls = ({
  officialsData,
  location,
  match,
  intl: { formatMessage },
}) => {
  const allRequiredNotEmpty = officialsData
    .filter((person) => person.required)
    .every((person) => person.role !== null && person.personId !== null);

  const noUnassignedRoles = officialsData
    .filter((person) => person.role !== null)
    .every((person) => person.personId !== null);

  const isNextBtnEnabled = allRequiredNotEmpty && noUnassignedRoles;

  return (
    <div className="game-details-controls-container">
      {!allRequiredNotEmpty && (
        <Alert error>
          {formatMessage({
            id: "details.emptyOfficialRole.error",
            defaultMessage: "Some required officials roles are empty",
          })}
        </Alert>
      )}
      {!noUnassignedRoles && (
        <Alert error>
          {formatMessage({
            id: "details.notAssignedOfficialRole.error",
            defaultMessage: "Some official roles don't have assignments",
          })}
        </Alert>
      )}
      <Link
        to={{
          pathname: `/setup/${match.params.fixtureId}`,
          search: location.search,
        }}
        className={`link btn btn-sm btn-success btn-next btn-lg enabled-${isNextBtnEnabled}`}
      >
        {formatMessage({
          id: "next",
          defaultMessage: "Next",
        })}{" "}
        <i className="fas fa-angle-right"></i>
      </Link>
    </div>
  );
};

export default injectIntl(withRouter(GameDetailsControls));
