import { createStore } from "idb-keyval";

const STORES = {};

export function getFixtureStore(fixtureId, entityType) {
  const singletonKey = `${fixtureId}-${entityType}`;
  if (STORES[singletonKey]) {
    // console.debug(singletonKey, entityType, 'Singleton hit');
    return STORES[singletonKey];
  }
  // console.debug(singletonKey, entityType, 'Singleton miss');
  STORES[singletonKey] = createStore(
    "ig-" + entityType.charAt(0) + "-" + fixtureId,
    "ingame-" + entityType + "-store"
  );
  return STORES[singletonKey];
}
