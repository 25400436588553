import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";

import { v1 as uuidv1 } from "uuid";

import PlayerSelect from "../PlayerSelect/PlayerSelect";
import TypeButton from "../ButtonPanel/TypeButton/TypeButton";

import { getEventTime, sendEvent } from "../../Base/Utilities/SendEvent";
import { sendClockEvent } from "../../Base/Utilities/ClockEvents";

import "./FaceOff.scss";

const FaceOff = (props) => {
  const { currentState, intl, updateState } = props;
  const { formatMessage } = intl;
  const [selected, setSelected] = useState([null, null]);
  const [selectionUpdated, setSelectionUpdated] = useState(false);
  const [teamPlayers, setTeamPlayers] = useState();
  const [teamSelects, setTeamSelects] = useState();
  const [startClock, setStartClock] = useState(false);
  const [clock, setClock] = useState(currentState.clock.displayTime);

  useEffect(() => {
    setTeamPlayers(
      currentState.entities.map((team, index) => {
        const panel = {
          options: { team: index, onCourtOnly: true },
          showOn: [true],
          layout: "player-select",
        };
        return (
          <PlayerSelect
            key={team.entityId}
            {...props}
            panel={panel}
            update={updateSelected}
            selectedPlayer={selected[index]}
          />
        );
      })
    );
    setTeamSelects(
      currentState.entities.map((team, index) => {
        const panel = {
          options: { freeText: team.fullNameLatin },
          showOn: [true],
          layout: "team-button",
        };
        return (
          <TypeButton
            {...props}
            key={team.entityId}
            panel={panel}
            team={index}
            style={{
              background: team.primaryColor,
            }}
            onClick={() => _sendEvents(team, index)}
          />
        );
      })
    );
    // eslint-disable-next-line
  }, [selectionUpdated, props]);

  useEffect(() => {
    if (selectionUpdated) {
      setSelectionUpdated(false);
    }
    // eslint-disable-next-line
  }, [selected, selectionUpdated]);

  useEffect(() => {
    if (startClock) {
      if (currentState.period.periodStatus === "pending") {
        if (currentState.period.periodId === 1) {
          sendClockEvent("sport", "fixture", "start", props);
        }
        sendClockEvent("sport", "period", "start", props);
      }
      let clock = currentState.clock;
      clock.clockRunning = true;
      setClock(currentState.clock.displayTime);
      updateState("clock", clock);
      sendClockEvent("clock", "main", "start", props);
    }
    // eslint-disable-next-line
  }, [startClock]);

  function updateSelected(person, entityId) {
    let newSelected = selected;
    let teamIndex = currentState.entities.findIndex(
      (el) => el.entityId === entityId
    );

    newSelected[teamIndex] = person.personId;
    setSelected(newSelected);
    setSelectionUpdated(true);
  }

  function _sendEvents(team, index) {
    let playId = uuidv1();
    let sendMode = "new";
    let event = {};

    let timestamp = new Date();
    event["timestamp"] = timestamp.toISOString();
    event["eventTime"] = getEventTime();
    let clockParts = clock.split(":");
    event["clock"] =
      "PT" + parseInt(clockParts[0]) + "M" + parseInt(clockParts[1]) + "S";

    event["periodId"] = currentState.period.periodId;
    event["playId"] = playId;
    event["eventType"] = "faceOff";
    event["success"] = true;

    let action = {
      action: "sendEvent",
      value: sendMode,
      type: "custom",
      event: event,
    };

    let tempEntities = currentState.entities;

    tempEntities.map((entity) => {
      event["eventId"] = uuidv1();
      event["entityId"] = entity.entityId;
      if (entity.entityId === team.entityId) {
        event["personId"] = selected[index];
        event["subType"] = "won";
      } else {
        let lostIndex = index === 0 ? "1" : 0;
        event["personId"] = selected[lostIndex];
        event["subType"] = "lost";
      }
      sendEvent(action, props);
      return true;
    });
    updateState("entities", tempEntities);
    updateState("primary", null);
  }

  return (
    <React.Fragment>
      <h2 className="align-center">
        {formatMessage({
          id: "faceOff.competitors",
          defaultMessage: "Select Face-off Competitors",
        })}
      </h2>
      {teamPlayers}
      <div
        className={
          "match-button action center enabled-" +
          (selected[0] !== null && selected[1] !== null ? "true" : "false")
        }
        onClick={() => setStartClock(true)}
      >
        {formatMessage({
          id: "clock.start.clock",
          defaultMessage: "Start Clock",
        })}
      </div>
      <h2 className="align-center">
        {formatMessage({
          id: "faceOff.winner",
          defaultMessage: "Select the team that won the face-off",
        })}
      </h2>
      <div
        className={
          "team-select-buttons enabled-" +
          (currentState.clock && currentState.clock.clockRunning
            ? "true"
            : "false")
        }
      >
        {teamSelects}
      </div>
    </React.Fragment>
  );
};

export default injectIntl(FaceOff);
