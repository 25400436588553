import { useEffect } from "react";
import { hexToRgba } from "../../../Base/Utilities/Color";

export function useFieldHighlight(appProps) {
  const { currentState } = appProps;

  // set the border color of the field in insert mode
  useEffect(() => {
    if (currentState.insertMode && currentState.insertMode.enabled === true) {
      const r = document.querySelector(":root");
      r.style.setProperty("--field-bounds-color", "#00FF00");
    } else {
      const r = document.querySelector(":root");
      r.style.setProperty("--field-bounds-color", "#555C63");
    }
  }, [currentState.insertMode]);

  // handle field highlight
  useEffect(() => {
    const r = document.querySelector(":root");

    resetAllAreas();

    if (!currentState.posTracking) {
      return;
    }

    const { type, entity } = currentState.posTracking;
    const { primaryColor } = entity;

    const index = currentState.entities.findIndex(
      (oneEntity) => oneEntity.entityId === entity.entityId
    );

    const section = getSectionFromType(type, index, currentState.entities);

    r.style.setProperty(
      `--field-${section}-color`,
      hexToRgba(primaryColor, 0.2)
    );
  }, [currentState.posTracking, currentState.entities]);
}

const sectionMapping = {
  "0.defensiveSafe": "left",
  "0.defensive": "center-left",
  "0.attack": "center-right",
  "0.attackDangerous": "right",
  "0.circle": "right-circle",
  "1.defensiveSafe": "right",
  "1.defensive": "center-right",
  "1.attack": "center-left",
  "1.attackDangerous": "left",
  "1.circle": "left-circle",
};

function getSectionFromType(subType, entityIdx) {
  return sectionMapping[`${entityIdx}.${subType}`];
}

const resetAllAreas = () => {
  const r = document.querySelector(":root");
  r.style.setProperty("--field-left-color", "transparent");
  r.style.setProperty("--field-left-circle-color", "transparent");
  r.style.setProperty("--field-left-color", "transparent");
  r.style.setProperty("--field-center-left-color", "transparent");
  r.style.setProperty("--field-center-right-color", "transparent");
  r.style.setProperty("--field-right-color", "transparent");
  r.style.setProperty("--field-right-circle-color", "transparent");
};
