import React from "react";
import { lightOrDark } from "../../Base/Utilities/LightOrDark";
import { sendPossessionEvent } from "../../Base/Utilities/PossessionEvents";
import "./FieldButtons.scss";

const sections = ["left", "center-left", "center-right", "right"];

function FieldButtons(props) {
  const { currentState, updateState } = props;

  const getLabel = (sectionIndex, entityIdx) => {
    if (sectionIndex <= 1) {
      return <span>{entityIdx === 0 ? "Def" : "Att"}</span>;
    }
    if (sectionIndex >= 2) {
      return <span>{entityIdx === 0 ? "Att" : "Def"}</span>;
    }
  };

  const getSubType = (section, entityIdx) => {
    if (section === "left") {
      return entityIdx === 0 ? "defensiveSafe" : "attackDangerous";
    }

    if (section === "center-left") {
      return entityIdx === 0 ? "defensive" : "attack";
    }

    if (section === "center-right") {
      return entityIdx === 0 ? "attack" : "defensive";
    }

    if (section === "right") {
      return entityIdx === 0 ? "attackDangerous" : "defensiveSafe";
    }

    return "";
  };

  const handleOnClick = ({ entity, entityIdx, section }) => {
    const { entityId } = entity;
    const subType = getSubType(section, entityIdx);

    updateState("posTracking", { type: subType, entity });
    sendPossessionEvent(entityId, { type: "subTyped", subType }, props, true);
  };

  return (
    <div className="field-buttons">
      {sections.map((section, sectionIndex) => (
        <div className="field-buttons--block" key={section}>
          {currentState.entities.map((entity, entityIdx) => (
            <div
              className={`field-buttons--tile ${lightOrDark(
                entity.primaryColor
              )}`}
              key={`${section}-${entity.entityId}`}
              style={{
                backgroundColor: entity.primaryColor,
              }}
              onClick={() => handleOnClick({ entity, section, entityIdx })}
            >
              {getLabel(sectionIndex, entityIdx)}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default FieldButtons;
