import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { IntlProvider } from "react-intl";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { GetVar } from "./Components/Base/Utilities/GetQueryVariable";
import { ENVIRONMENT } from "./Config/Environment";
import { setupLanguage } from "./App.i18n";
import { Tracing, AppTracingErrorBoundary } from "./App.tracing";
import App from "./App";

function renderApplication() {
  const { locale, messages } = setupLanguage();
  const debugMode = GetVar("debug") === "true" ? true : false;
  ReactDOM.render(
    <AppTracingErrorBoundary>
      <React.StrictMode>
        <IntlProvider locale={locale} messages={messages}>
          <BrowserRouter>
            <App
              environment={ENVIRONMENT}
              locale={locale}
              debug={debugMode}
              className="App"
            />
          </BrowserRouter>
        </IntlProvider>
      </React.StrictMode>
    </AppTracingErrorBoundary>,
    document.getElementById("root")
  );
}

function entryPoint() {
  try {
    Tracing.init();
    renderApplication();
  } catch (error) {
    console.error("Application bootstrap error", error);
    Tracing.capture(error);
  }
}

entryPoint();
