import { sendEvent } from "../../../../Components/Base/Utilities/SendEvent";

export function modifyRebound(props, result) {
  let changeEvent = { ...props.event };

  const currentEntityId = changeEvent.entityId;
  const otherEntityId = props.currentState.entities.find((e) => e.entityId !== currentEntityId)?.entityId;

  changeEvent.status = "updated";
  changeEvent.subType = result;
  changeEvent.entityId = otherEntityId;
  changeEvent.personId = "";
  changeEvent.flagged = true;

  let updateAction = {
    action: "sendEvent",
    event: changeEvent,
    type: "custom",
  };
  sendEvent(updateAction, props);
}
