import React, { useState } from "react";
import { injectIntl } from "react-intl";
import Button from "../../Base/Button/Button";
import CounterInput from "../../Base/CounterInput/CounterInput";
import Modal from "../../Base/Modal/Modal";
import "./ClockQuery.scss";

/*
 * clock: Currentstate.Clock
 * onSubmit: (period: number, clock: string) => void;
 * onClose: () => void;
 *
 */
function ClockQuery(props) {
  const { onClose, onSubmit, clock, intl } = props;
  const { formatMessage } = intl;
  const [defaultMins, defaultSecs] = clock.displayTime.split(":");
  const [mins, setMins] = useState(parseInt(defaultMins, 10));
  const [secs, setSecs] = useState(parseInt(defaultSecs, 10));

  return (
    <Modal shouldTrapFocus title="Clock query" onClose={onClose}>
      <h2 className="clock-query-title">Confirm correct clock time</h2>
      <div className="clock-query-inputs">
        <CounterInput
          min={0}
          max={9}
          value={mins}
          onChange={(value) => setMins(value)}
          teamName={formatMessage({
            id: "mins",
            defaultMessage: "Mins",
          })}
          autoFocus
        />
        <CounterInput
          min={0}
          max={59}
          value={secs}
          onChange={(value) => setSecs(value)}
          teamName={formatMessage({
            id: "secs",
            defaultMessage: "Secs",
          })}
        />
      </div>
      <div className="clock-query-button">
        <Button onClick={() => onSubmit(mins, secs)} variant="danger">
          {formatMessage({
            id: "confirm",
            defaultMessage: "Confirm",
          })}
        </Button>
      </div>
    </Modal>
  );
}

export default injectIntl(ClockQuery);
