const ShotOutcomeTypes = ["block", "miss", "save"];

export const ShotOutcomeTypeSelect = {
  name: "shot-outcome-type",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "shot.outcome-type",
  showOn: [{ state: "primary", value: "shot-outcome-type", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "shot-outcome-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "singleOption",
        optionName: "failureReason",
        children: ShotOutcomeTypes,
        layout: "type-button",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "sendEvent",
              value: "new",
              type: "main",
            },
            { action: "copyEvent", value: "shotOutcome", type: "to" },
            {
              action: "nextState",
              value: "shot-player",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
