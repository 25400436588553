// Free Throws awarded
export var FreeThrowsAwarded = {
  name: "free-throw-buttons",
  component: "MainPanel",
  layout: "main-panel large center no-group",
  mode: "visible",
  text: "freethrows.awarded",
  showDetails: { events: ["foul", "fouldrawn"] },
  showOn: [{ state: "primary", value: "free-throw-buttons", operator: "is" }],
  children: [
    {
      name: "0-freethrows",
      component: "TypeButton",
      layout: "match-button small",
      mode: "enable",
      text: "freethrows.0",
      showOn: [true],
      actionGroups: [
        {
          actions: [
            { action: "nextState", value: null, type: "primary" },
            { action: "processInserts", value: "all", type: "inserts" },
            { action: "popStash", type: "main" },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "1-freethrows",
      component: "TypeButton",
      layout: "match-button small",
      mode: "enable",
      text: "freethrows.1",
      showOn: [true],
      actionGroups: [
        {
          actions: [
            {
              action: "setPossession",
              value: { currentEntity: true },
              type: "main",
            },
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
          ],
          conditions: [
            {
              item: "subType",
              value: "drawn",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "copyEvent", value: "foul", type: "from" },
            {
              action: "saveData",
              value: [{ option: "freeThrows", value: 1 }],
              type: "options",
            },
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            {
              action: "nextState",
              value: "free-throws",
              type: "secondary",
            },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "2-freethrows",
      component: "TypeButton",
      layout: "match-button small",
      mode: "enable",
      text: "freethrows.2",
      showOn: [true],
      actionGroups: [
        {
          actions: [
            {
              action: "setPossession",
              value: { currentEntity: true },
              type: "main",
            },
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
          ],
          conditions: [
            {
              item: "subType",
              value: "drawn",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "copyEvent", value: "foul", type: "from" },
            {
              action: "saveData",
              value: [{ option: "freeThrows", value: 2 }],
              type: "options",
            },
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            {
              action: "nextState",
              value: "free-throws",
              type: "secondary",
            },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "3-freethrows",
      component: "TypeButton",
      layout: "match-button small",
      mode: "enable",
      text: "freethrows.3",
      showOn: [true],
      actionGroups: [
        {
          actions: [
            {
              action: "setPossession",
              value: { currentEntity: true },
              type: "main",
            },
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
          ],
          conditions: [
            {
              item: "subType",
              value: "drawn",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "copyEvent", value: "foul", type: "from" },
            {
              action: "saveData",
              value: [{ option: "freeThrows", value: 3 }],
              type: "options",
            },
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            {
              action: "nextState",
              value: "free-throws",
              type: "secondary",
            },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
