import React, { useState, useCallback, useRef } from "react";
import { Input } from "reactstrap";
import debounce from "lodash/debounce";

const BibInput = ({ person, onBibChanged, personClassName }) => {
  const [value, setValue] = useState(person.bib || "");
  const bibRef = useRef(onBibChanged);
  bibRef.current = onBibChanged;

  // eslint-disable-next-line
  const updateBib = useCallback(
    debounce(
      (newBib, personUpdated) => bibRef.current(newBib, personUpdated),
      1000
    ),
    []
  );

  const handleBibChange = (e) => {
    const newBib = e.target.value.substr(0, 2);
    setValue(newBib);
    updateBib(newBib, person);
  };

  return (
    <span className={`bib-background ${personClassName}`}>
      <Input
        className={`bib ${personClassName}`}
        type="text"
        name="bib"
        bsSize="xs"
        value={value}
        onChange={handleBibChange}
      />
    </span>
  );
};

export default BibInput;
