import { TwoPtTypes, ThreePtTypes } from "../subTypes/EliteShotTypes";
import { MadeShotOptions, MissedShotOptions } from "../Options/ShotOptions";

export var ModifyShotType = {
  name: "shot-type",
  component: "MainPanel",
  layout: "main-panel x-large",
  mode: "visible",
  text: "shot.type",
  showOn: [{ state: "editState", value: "shot-type", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    // 2pt
    {
      name: "2pt-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.eventType",
          value: "2pt",
          operator: "is",
          event: "edit",
        },
      ],
      options: {
        value: "subType",
        children: TwoPtTypes,
        layout: "type-button",
        type: "edit",
      },
      actionGroups: [],
    },
    // 3pt
    {
      name: "3pt-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.eventType",
          value: "3pt",
          operator: "is",
          event: "edit",
        },
      ],
      options: {
        value: "subType",
        children: ThreePtTypes,
        layout: "type-button",
        type: "edit",
      },
      actionGroups: [
        {
          actions: [{ action: "copyEvent", value: "shot", type: "to" }],
          conditions: [],
        },
      ],
    },
    // Made Shot Options
    {
      name: "made-shot-options",
      component: "OptionGroup",
      layout: "option-group",
      mode: "visible",
      showOn: [
        {
          state: "event.success",
          value: true,
          operator: "is",
          event: "edit",
        },
      ],
      options: {
        value: "options",
        children: MadeShotOptions,
        layout: "option-button",
        type: "edit",
      },
    },
    // Missed Shot Options
    {
      name: "missed-shot-options",
      component: "OptionGroup",
      layout: "option-group",
      mode: "visible",
      showOn: [
        {
          state: "event.success",
          value: false,
          operator: "is",
          event: "edit",
        },
      ],
      options: {
        value: "options",
        children: MissedShotOptions,
        layout: "option-button",
        type: "edit",
      },
    },
    // Shot Type / Options confirm
    {
      name: "confirm-panel",
      component: "SubPanel",
      layout: "sub-panel",
      mode: "visible",
      text: null,
      showOn: [true],
      children: [
        {
          name: "ok-button",
          component: "TypeButton",
          layout: "match-button center action",
          mode: "enable",
          text: "ok",
          showOn: [
            {
              state: "event.subType",
              value: null,
              operator: "not",
              event: "edit",
            },
          ],
          actionGroups: [
            {
              actions: [
                {
                  action: "sendEvent",
                  value: "edit",
                  type: "edit",
                },
                {
                  action: "nextState",
                  value: null,
                  type: "editState",
                },
              ],
              conditions: [],
            },
          ],
        },
      ],
    },
  ],
};
