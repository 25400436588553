export const ModifyDefenceGoalkeeperSelect = {
  name: "modify-goalkeeper",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "goalkeeper.select",
  showOn: [{ state: "editState", value: "modify-defence-goalkeeper", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    {
      name: "player-select",
      component: "ChangeGoalkeeper",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      options: {
        currentEntity: false,
        selectOnly: true,
        goalKeeperUpdate: true,
        currentEntityEvent: "goal",
      },
      actionGroups: [
        {
          actions: [
            { action: "nextState", value: null, type: "editState" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};