export function eventLogCatchup(events, manageEvents, managePlays) {
  events.forEach((event) => {
    if (event.playId) {
      event.pending = false;
      manageEvents(event.eventId, event);

      let playObject = {
        playId: event.playId,
        eventTime: event.eventTime,
      };

      if(event.class === "sport") {
        playObject.clock = event.clock;
        playObject.periodId = event.periodId;
      }

      managePlays(event.playId, playObject);
    }
  });
}
