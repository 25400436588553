export const TechnicalFoulFromFT = {
  name: "technical-foul-from-ft",
  component: "MainPanel",
  layout: "main-panel padded align-center",
  mode: "visible",
  text: "foul.select-team",
  showOn: [
    { state: "primary", value: "technical-foul-from-ft", operator: "is" },
  ],
  allowCancel: true,
  children: [
    {
      name: "team-0-technical",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: { team: "name" },
      showOn: [true],
      options: { team: 0 },
      startPlay: true,
      startEvent: true,
      actionGroups: [
        {
          actions: [
            {
              action: "nextState",
              value: "technical-foul-from-ft-player-select",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "team-1-technical",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: { team: "name" },
      showOn: [true],
      options: { team: 1 },
      startPlay: true,
      startEvent: true,
      actionGroups: [
        {
          actions: [
            {
              action: "nextState",
              value: "technical-foul-from-ft-player-select",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
  ],
};

export const TechnicalFoulFromFTPlayerSelect = {
  name: "technical-foul-from-ft-player-select",
  component: "MainPanel",
  layout: "main-panel padded align-center",
  mode: "visible",
  text: "foul.select-player",
  showOn: [
    {
      state: "primary",
      value: "technical-foul-from-ft-player-select",
      operator: "is",
    },
  ],
  allowCancel: true,
  allowBack: {
    state: "primary",
    value: "technical-foul-from-ft",
    event: "main",
  },
  children: [
    {
      name: "foul-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      options: {
        currentEntity: true,
        showBench: true,
        showCoach: true,
        noSelectedOptionOnStart: true,
      },
      actionGroups: [
        {
          actions: [
            {
              action: "clearData",
              value: ["options", "subType", "scores", "success"],
              type: "main",
            },
            { action: "clearEvent", value: "fouldrawn", type: "main" },
            { action: "clearEvent", value: "freethrow", type: "main" },
            {
              action: "saveData",
              value: [{ option: "isCalledFromFT", value: true }],
              type: "options",
            },
            { action: "saveData", value: "foul", type: "eventType" },
          ],
          conditions: [],
        },
        {
          actions: [
            { action: "saveData", value: "technical", type: "subType" },
          ],
          conditions: [],
        },
        {
          actions: [
            { action: "saveData", value: "benchTechnical", type: "subType" },
          ],
          conditions: [
            {
              item: "bench",
              value: true,
              operator: "is",
              event: "main",
            },
          ],
        },
        {
          actions: [
            { action: "saveData", value: "coachTechnical", type: "subType" },
          ],
          conditions: [
            {
              item: "coach",
              value: true,
              operator: "is",
              event: "main",
            },
          ],
        },
        {
          actions: [
            { action: "copyEvent", value: "foul", type: "to" },
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            {
              action: "nextState",
              value: "free-throw-buttons",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
