export var GoalkeeperChangeSelect = {
  name: "goalkeeper-change-select",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "changeGoalkeeper",
  showOn: [
    { state: "editState", value: "goalkeeper-change-select", operator: "is" },
  ],
  allowFlag: false,
  allowCancel: true,
  showDetails: { events: ["main"] },
  children: [
    {
      name: "goalkeeper-change-select",
      component: "ChangeGoalkeeper",
      layout: "player-select",
      mode: "visible",
      startEvent: false,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: true,
        selectOnly: true,
        ignoreFlag: true,
        alwaysActive: true,
      },
      actionGroups: [
        {
          actions: [
            { action: "sendEvent", value: "edit", type: "main" },
            { action: "nextState", value: null, type: "editState" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
