import {
  PlayerFoulTypes,
  // CoachFoulTypes,
  // BenchFoulTypes,
} from "../subTypes/EliteFoulTypes";

import // PersonalFoulOptions,
// TechnicalFoulOptions,
"../Options/FoulOptions";

export var ModifyFoulType = {
  name: "foul-type",
  component: "MainPanel",
  layout: "main-panel x-large",
  mode: "visible",
  text: "foul.type",
  showOn: [{ state: "editState", value: "foul-type", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    // Defensive Fouls
    {
      name: "defensive-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.subType",
          value: "personal",
          operator: "is",
          event: "main",
        },
      ],
      options: {
        value: "subType",
        children: PlayerFoulTypes,
        layout: "type-button",
        prefix: "foul",
      },
      actionGroups: [
        {
          actions: [
            { action: "copyEvent", value: "turnover", type: "to" },
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
          ],
          conditions: [],
        },
        {
          actions: [{ action: "nextState", value: null, type: "editState" }],
          conditions: [],
        },
      ],
    },
    // Offensive Foul
    {
      name: "offensive-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.subType",
          value: "offensive",
          operator: "is",
          event: "main",
        },
      ],
      options: {
        value: "subType",
        children: PlayerFoulTypes,
        layout: "type-button",
        prefix: "foul",
      },
      actionGroups: [],
    },
  ],
};
