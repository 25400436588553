import React from "react";

import "./Alert.scss";

const Alert = ({ children, warning, error }) => {
  let intent = !!error && "error";
  intent = intent || (!!warning && "warning");
  intent = intent || "info";
  return <div className={`stats-alert--${intent}`}>{children}</div>;
};

export default Alert;
