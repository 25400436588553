import React, { useEffect, useRef, useState, useContext } from "react";
import { injectIntl } from "react-intl";

import { lightOrDark } from "../../Base/Utilities/LightOrDark";
import { calculateOnCourt } from "../../Base/Utilities/CalculateOnCourt";
import { useIsVisible } from "../../Base/Hooks/IsVisible";
import { useDoActions } from "../../Base/Hooks/doActions";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";
import { getMaximumPlayersActiveForSport } from "../../Base/Utilities/EntityUtilities";

import "./PlayerSelect.scss";
import { Tracing } from "../../../App.tracing";
import Logger from "../../Base/Utilities/Logger";

const logger = Logger.getInstance();

const PlayerSelect = (props) => {
  const { panel, updateState, selectedPlayer, intl } = props;
  const [activePlayers, setActivePlayers] = useState();
  const [inactivePlayers, setInactivePlayers] = useState();
  const [activeStaff, setActiveStaff] = useState();
  const [entityId, setEntityId] = useState();
  const [inactiveWidth, setInactiveWidth] = useState(0);
  const [doAction, setDoAction] = useState(false);
  const [actionsDone] = useDoActions(panel, props, doAction);
  const [isVisible, isEnabled] = useIsVisible(panel, props);
  const { fixtureProfile, sport } = useContext(FixtureDetailsContext);
  const { formatMessage } = intl;

  const activeRef = useRef(null);

  useEffect(() => {
    window.addEventListener("resize", _reportSize);
    return () => window.removeEventListener("resize", _reportSize);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (actionsDone) {
      setDoAction(false);
    }
  }, [actionsDone, props]);

  useEffect(() => {
    let tempEntity = {};
    let currentEntityEvent = panel.options.currentEntityEvent
      ? panel.options.currentEntityEvent
      : "main";
    if (!props.currentState.events[currentEntityEvent]) {
      currentEntityEvent = panel.options.currentEntityEvent = "main";
    }

    if (panel.options.currentEntity === true) {
      tempEntity = props.currentState.entities.filter(
        (team) =>
          team.entityId ===
          props.currentState.events[currentEntityEvent].entityId
      )[0];
    } else if (panel.options.currentEntity === false) {
      tempEntity = props.currentState.entities.filter(
        (team) =>
          team.entityId !==
          props.currentState.events[currentEntityEvent].entityId
      )[0];
    } else {
      tempEntity = props.currentState.entities.filter(
        (team) => team.entityId !== null
      )[panel.options.team];
    }

    let persons = [];
    if (tempEntity) {
      persons = tempEntity.persons;
      if (panel.options.calculateOnCourt) {
        let tempPersons = [];
        if (panel.options.excludePlay) {
          // Build player lists before this event
          const currentPlayEventTime = props.currentState.events.edit.eventTime;
          tempPersons = calculateOnCourt(
            panel.options.calculateOnCourt,
            props,
            currentPlayEventTime
          );
        } else {
          // Build Player lists including this event
          tempPersons = calculateOnCourt(panel.options.calculateOnCourt, props);
        }
        persons = tempPersons.find(
          (entity) => entity.entityId === tempEntity.entityId
        ).persons;
      }
    } else {
      const availableEntities = props.currentState.entities
        .map((entity) => entity.entityId)
        .join(", ");
      const requestedEntity =
        props.currentState.events[currentEntityEvent].entityId;

      Tracing.capture(
        new Error(
          `No temp entity! Available entities: ${availableEntities}, requestedEntity: ${requestedEntity}`
        )
      );
      logger.log("No temp entity!", panel, props.currentState);
    }

    let csi = props.currentState.insertMode;
    if (csi && csi.enabled) {
      let tempPersons = calculateOnCourt("notall", props, csi.insertDate);
      persons = tempPersons.find(
        (entity) => entity.entityId === tempEntity.entityId
      ).persons;
    }

    // Build Excluded list
    let excluded = [];
    if (panel.options.excludePlayer) {
      if (props.currentState.events[panel.options.excludeFrom]) {
        excluded.push(
          props.currentState.events[panel.options.excludeFrom].personId
        );
      }
    }
    if (panel.options.excludeOtherEvents) {
      props.eventStore
        .filter(
          (event) =>
            event.playId ===
              props.currentState.events[panel.options.excludeFrom].playId &&
            event.status !== "deleted"
        )
        .map((event) => {
          if (panel.options.excludePlay) {
            if (
              event.eventId !==
              props.currentState.events[panel.options.excludeFrom].eventId
            ) {
              excluded.push(event.personId);
            }
          } else {
            if (
              event.eventId !==
                props.currentState.events[panel.options.excludeFrom].eventId &&
              event.eventType !==
                props.currentState.events[panel.options.excludeFrom].eventType
            ) {
              excluded.push(event.personId);
            }
          }
          return true;
        });
    }
    setEntityId(tempEntity.entityId);
    const maximumActivePlayers = getMaximumPlayersActiveForSport(
      fixtureProfile,
      sport
    );

    if (!persons.length) {
      logger.log("Player select modal is blank", panel, props.currentState);
    }

    setActivePlayers(
      <React.Fragment>
        {persons
          .filter((person) => person.active === true)
          .sort((a, b) => (parseInt(a.bib) < parseInt(b.bib) ? -1 : 1))
          .map((player) => {
            const isPlayerExcluded =
              excluded.includes(player.personId) ||
              props.excludedPlayer === player.personId ||
              panel.options.excludeActive;
            const excludedStyling = isPlayerExcluded ? "excluded" : "";
            const isPlayerSelected =
              (player.personId === selectedPlayer ||
                player.personId ===
                  props.currentState.events["main"].personId) &&
              panel.options?.noSelectedOptionOnStart !== true;
            const selectedStyling = isPlayerSelected ? "selected" : "";
            const elemStyling = `player active-${player.active} ${lightOrDark(
              tempEntity.primaryColor
            )} ${excludedStyling} ${selectedStyling}`;
            return (
              <div
                className={elemStyling}
                key={player.personId}
                style={Object.assign(
                  player.personId === selectedPlayer
                    ? {
                        borderColor: tempEntity.primaryColor,
                        backgroundColor: "#ffffff",
                        color: "#202020",
                      }
                    : {
                        borderColor: tempEntity.primaryColor,
                        backgroundColor: tempEntity.primaryColor,
                      },
                  props.playerStyle ?? {}
                )}
                onClick={() => _playerSelect(player, true, tempEntity.entityId)}
              >
                {player.bib}
              </div>
            );
          })}
        {[
          ...Array(
            maximumActivePlayers -
              tempEntity.persons.filter((person) => person.active === true)
                .length >
              -1
              ? maximumActivePlayers -
                  tempEntity.persons.filter((person) => person.active === true)
                    .length
              : 0
          ),
        ].map((_, i) => (
          <div
            key={i}
            className="player active-false"
            style={{
              borderColor: "transparent",
            }}
          >
            &nbsp;
          </div>
        ))}
      </React.Fragment>
    );

    setActiveStaff(
      <React.Fragment>
        {Object.keys(tempEntity.staffState)
        .filter(role => tempEntity.staffState[role].active === true)
        .map((role) => {
          const selected = tempEntity.staffState[role].personId
            === props.currentState.events.main?.personId
            ? "selected"
            : "";
          return (
            <div
              className={`player staff active-${!tempEntity.staffState[role].active} ${selected}`}
              key={role}
              onClick={() => _playerSelect(
               tempEntity.staffState[role],
               true,
               tempEntity.entityId
              )}
            >
              {formatMessage({
                id: `role.abbrev.${role}`,
                defaultMessage: `role.abbrev.${role}`,
              })}
            </div>
          );
        })}
      </React.Fragment>
    )

    if (!panel.options.onCourtOnly) {
      setInactivePlayers(
        persons
          .filter((person) => person.active === false)
          .sort((a, b) => (parseInt(a.bib) < parseInt(b.bib) ? -1 : 1))
          .map((player) => {
            let playerExcluded = excluded.includes(player.personId)
              ? " excluded"
              : "";
            return (
              <div
                key={player.personId}
                className={
                  "player active-false " +
                  (props.excludedPlayer === player.personId ||
                  panel.options.excludeBench
                    ? " excluded "
                    : "") +
                  playerExcluded +
                  (player.personId === selectedPlayer ? " selected" : "") +
                  (player.personId ===
                  props.currentState.events["main"].personId
                    ? " selected"
                    : "")
                }
                onClick={(e) =>
                  _playerSelect(player, false, tempEntity.entityId)
                }
              >
                {player.bib}
              </div>
            );
          })
      );
    }
    // eslint-disable-next-line
  }, [panel, props]);

  useEffect(() => {
    _reportSize();
    // eslint-disable-next-line
  }, [activePlayers]);

  function _reportSize() {
    if (activeRef.current) {
      let width = activeRef.current.offsetWidth + 1;
      if (inactiveWidth !== width) {
        setInactiveWidth(activeRef.current.offsetWidth + 1);
      }
    }
  }

  function _playerSelect(player, active, entity) {
    if (player) {
      if (!props.update) {
        let tempEvents = props.currentState.events;
        tempEvents["main"]["personId"] = player.personId;
        if (!tempEvents["main"]["flagged"]) {
          if (!active && !panel.options.ignoreFlag) {
            tempEvents["main"]["flagged"] = true;
          } else {
            tempEvents["main"]["flagged"] = false;
          }
        }
        tempEvents["main"]["entityId"] = entity;

        if (panel?.options?.resetBench) {
          tempEvents["main"]['bench'] = false;
        }

        if (panel?.options?.resetCoach) {
          tempEvents["main"]['coach'] = false;
        }

        updateState("events", tempEvents);
        setDoAction(true);
      } else {
        props.update(player, entity);
      }
    }
  }
  function _staffSelect(person) {
    if (person) {
      let tempEvents = props.currentState.events;
      tempEvents["main"][person] = true;
      tempEvents["main"]["entityId"] = entityId;
      updateState("events", tempEvents);
      setDoAction(true);
    }
  }

  return (
    <React.Fragment>
      {isVisible && (
        <div
          className={panel.layout + " enabled-" + isEnabled}
          style={props.containerStyle}
        >
          <div className="active-players" ref={activeRef}>
            {activePlayers}
          </div>
          {inactiveWidth > 0 && (
            <div
              className="inactive-players"
              style={{ width: inactiveWidth + "px" }}
            >
              {inactivePlayers}
              {panel.options && panel.options.showBench && (
                <div
                  className={"player staff active-false"}
                  onClick={(e) => _staffSelect("bench")}
                >
                  Bench
                </div>
              )}
              {panel.options && panel.options.showCoach && sport !== "handball" && (
                <div
                  className={"player staff active-false"}
                  onClick={(e) => _staffSelect("coach")}
                >
                  Coach
                </div>
              )}
              {panel.options && panel.options.showCoach && sport === "handball" && (
                <div>
                  {activeStaff}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default injectIntl(PlayerSelect);
