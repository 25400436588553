export const InsertClock = {
  name: "insert-clock",
  component: "InsertClock",
  layout: "insert-clock",
  mode: "visible",
  options: { disablePreMatch: true },
  showOn: [{ state: "insertMode.enabled", value: true, operator: "is" }],
  actionGroups: [
    {
      actions: [
      ],
      conditions: [
      ],
    },
  ],
};
