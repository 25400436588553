import React, { useState } from "react";

const InactivityTrackerContext = React.createContext({});

const InactivityTrackerProvider = ({ children }) => {
  const [lastActionTime, setLastActionTime] = useState(Infinity);

  return (
    <InactivityTrackerContext.Provider
      value={{
        lastActionTime,
        setLastActionTime,
      }}
    >
      {children}
    </InactivityTrackerContext.Provider>
  );
};

export { InactivityTrackerContext, InactivityTrackerProvider };
