export const Clock = {
  name: "clock",
  component: "Clock",
  layout: "clock-display-stackable",
  mode: "visible",
  options: { disablePreMatch: true, disableOnPeriodStart: true },
  showOn: [{ state: "clock.displayTime", value: null, operator: "not" }],
  actionGroups: [
    {
      actions: [
        {
          action: "setPossession",
          value: { currentEntity: true },
          type: "main",
        },
      ],
      conditions: [
        {
          item: "clock.clockStatus",
          value: "periodStart",
          event: "main",
          operator: "is",
        },
      ],
    },
    {
      actions: [
        {
          action: "checkResets",
          value: true,
          type: "clock",
        },
      ],
      conditions: [
        {
          item: "period.periodStatus",
          value: "pending",
          event: "periodStatus",
          operator: "is",
        },
      ],
    },
  ],
};
