import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";

import { useIsVisible } from "../../../Base/Hooks/IsVisible";
import { useDoActions } from "../../../Base/Hooks/doActions";
import { lightOrDark } from "../../../Base/Utilities/LightOrDark";

const TypeButton = (props) => {
  const {
    panel,
    style = {},
    intl,
    updateState,
    currentState,
    onClick,
    team,
  } = props;
  const { formatMessage } = intl;
  const [buttonClass, setButtonClass] = useState();
  const [buttonStyle, setButtonStyle] = useState({});
  const [isVisible, isEnabled] = useIsVisible(panel, props);
  const [doAction, setDoAction] = useState(false);
  const [actionsDone] = useDoActions(panel, props, doAction);
  const [buttonText, setButtonText] = useState();

  useEffect(() => {
    if (actionsDone) {
      setDoAction(false);
    }
    // eslint-disable-next-line
  }, [actionsDone]);

  useEffect(() => {
    let teamIndex =
      panel.options && panel.options.team > -1 ? panel.options.team : team;
    if (teamIndex > -1) {
      let entity = currentState.entities[teamIndex];
      setButtonClass(lightOrDark(entity.primaryColor));
      setButtonStyle({
        ...buttonStyle,
        backgroundColor: entity.primaryColor,
      });
      if (panel.text) {
        if (typeof panel.text === "object") {
          setButtonText(entity.fullNameLatin);
        }
      }
    }

    // eslint-disable-next-line
  }, [panel, currentState.entities]);

  function _doAction(doAction) {
    if (!onClick) {
      let team =
        panel.options && panel.options.team > -1 ? panel.options.team : -1;
      team = props.team > -1 ? props.team : team;
      if (team > -1) {
        let entity = currentState.entities[team].entityId;
        let tempEvents = currentState.events;
        tempEvents.main["entityId"] = entity;
        updateState("events", tempEvents);
      }
      setDoAction(doAction);
    } else {
      onClick();
    }
  }

  return (
    <React.Fragment>
      {isVisible && (
        <div
          className={panel.layout + " enabled-" + isEnabled + " " + buttonClass}
          onClick={(e) => _doAction(true)}
          style={{ ...style, ...buttonStyle }}
        >
          {!buttonText &&
            panel.text &&
            formatMessage({
              id: panel.text,
              defaultMessage: panel.text,
            })}
          {!buttonText && !panel.text && <span>{panel.options.freeText}</span>}
          {buttonText && <span>{buttonText}</span>}
        </div>
      )}
    </React.Fragment>
  );
};

export default injectIntl(TypeButton);
