import { TeamTimeoutTypes } from "../subTypes/EliteTimeoutTypes";
import { TimeoutOptions } from "../Options/TimeoutOptions";

export var ModifyTimeoutType = {
  name: "timeout-type",
  component: "MainPanel",
  layout: "main-panel x-large",
  mode: "visible",
  text: "timeout.type",
  showOn: [{ state: "editState", value: "timeout-type", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    {
      name: "timeout-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "subType",
        children: TeamTimeoutTypes,
        layout: "type-button",
      },
      actionGroups: [],
    },
    {
      name: "timeout-options",
      component: "OptionGroup",
      layout: "option-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "options",
        children: TimeoutOptions,
        layout: "option-button",
        prefix: "timeout",
      },
    },

    // Timeout confirm
    {
      name: "ok-button",
      component: "TypeButton",
      layout: "match-button center action",
      mode: "enable",
      text: "ok",
      startEvent: false,
      startPlay: false,
      showOn: [
        {
          state: "event.subType",
          value: null,
          operator: "not",
          event: "main",
        },
      ],
      actionGroups: [
        {
          actions: [
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            {
              action: "nextState",
              value: null,
              type: "editState",
            },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
