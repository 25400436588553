import React, { useCallback, useMemo, useState, useContext } from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import GridTableDisplay from "../../Base/Shared/GridTableDisplay";
import { getPlayingPositions } from "../../../Config/PlayingPositions";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";
import { getMaximumPlayersActiveForSport } from "../../Base/Utilities/EntityUtilities";

import TeamSetupCheckbox from "./TeamSetupCheckbox";
import BibInput from "./BibInput";
import Modal from "../../Base/Modal/Modal";
import {
  getEventTimeForUpdatedDeletedEvents,
  sendUpdatedEvent,
} from "../../Base/Utilities/SendEvent";
import Button from "../../Base/Button/Button";
import { getTeamSetupFields } from "../../../Config/TeamSetupOptions";

const sortOrders = [
  { key: "NUMBER", textId: "sort.number", defaultText: "Player Number" },
  { key: "NAME", textId: "sort.name", defaultText: "Player Name" },
  { key: "ACTIVE", textId: "sort.active", defaultText: "Active" },
  { key: "STARTER", textId: "sort.starter", defaultText: "Starting" },
];

const Roster = (props) => {
  const { intl, teamData, updateRosterData, currentState } = props;
  const { formatMessage } = intl;
  const [tempPlayer, setTempPlayer] = useState(null);
  const [replacingStep, setReplacingStep] = useState(1);
  const [selectedPerson, setSelectedPerson] = useState("");
  const [sortOrder, setSortOrder] = useState("NUMBER");
  const { fixtureProfile, sport } = useContext(FixtureDetailsContext);
  const teamSetupFields = getTeamSetupFields(sport);

  const sharedColumns = useMemo(() => {
    return [
      {
        id: "person.bib",
        defaultMessage: "Number",
      },
      {
        id: "person.playerName",
        defaultMessage: "Player Name",
      },
      {
        id: "person.playing",
        defaultMessage: "Playing",
      },
      {
        id: "person.starter",
        defaultMessage: "Starter",
      },
      {
        id: "person.captain",
        defaultMessage: "Captain",
      },
      {
        id: "person.position",
        defaultMessage: "Position",
      },
    ]
      .filter((field) => teamSetupFields.includes(field.id.split(".")[1]))
      .map(formatMessage);
  }, [formatMessage, teamSetupFields]);

  const starterCount = useMemo(
    () => teamData.persons.filter((person) => person.starter).length,
    [teamData]
  );

  const playingCount = useMemo(
    () => teamData.persons.filter((person) => person.playing).length,
    [teamData]
  );

  const columns = useMemo(() => {
    return sharedColumns;
  }, [sharedColumns]);

  const positions = useMemo(() => {
    return getPlayingPositions(sport).map((position) => (
      <option key={position.position} value={position.position}>
        {formatMessage({
          id: `position.${sport}.${position.position}`,
          defaultMessage: position.position,
        })}
      </option>
    ));
  }, [sport, formatMessage]);

  const handleBibChange = useCallback(
    (newBib, person) => {
      updateRosterData({ ...person, bib: newBib }, person);
    },
    [updateRosterData]
  );

  const handlePlayingChange = useCallback(
    (person, e) => {
      if (e.target.checked) {
        const currentPlayingCount = teamData.persons.filter(
          (el) => el.playing === true
        ).length;
        if (currentPlayingCount < fixtureProfile.maximumRosterSize) {
          updateRosterData({ ...person, playing: true }, person);
        }
      } else {
        const hasEvents = currentState?.mqtt?.plays?.some(
          (play) => play.personId === person.personId
        );

        if (hasEvents) {
          setTempPlayer(person);
          return;
        }

        updateRosterData({ ...person, playing: false, starter: false }, person);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teamData, updateRosterData, fixtureProfile]
  );

  const handleStarterChange = useCallback(
    (person, e) => {
      if (e.target.checked) {
        const startersSelectedCount = teamData.persons.filter(
          (el) => el.starter === true
        ).length;
        const currentPlayingCount = teamData.persons.filter(
          (el) => el.playing === true
        ).length;
        // allow only when there isn't enough starters and if the player is eligible to play
        // (is already playing, or there's still enough space in the roster)
        if (
          startersSelectedCount <
            getMaximumPlayersActiveForSport(fixtureProfile, sport) &&
          (currentPlayingCount < fixtureProfile.maximumRosterSize ||
            person.playing)
        ) {
          updateRosterData({ ...person, starter: true, playing: true }, person);
        }
      } else {
        updateRosterData({ ...person, starter: false }, person);
      }
    },
    [teamData, updateRosterData, fixtureProfile, sport]
  );

  const handleCaptainChange = useCallback(
    (person, e) => {
      const newPerson = { ...person };
      newPerson.captain = e.target.checked;
      updateRosterData(newPerson, person);
    },
    [updateRosterData]
  );

  const handlePositionChange = useCallback(
    (person, e) => {
      const newPerson = { ...person };
      newPerson.position = e.target.value;
      updateRosterData(newPerson, person);
    },
    [updateRosterData]
  );

  const sortPersons = useCallback(
    (persons) => {
      return persons.sort((a, b) => {
        if (sortOrder === "NUMBER") {
          const aBib = parseInt(a.bib);
          const bBib = parseInt(b.bib);
          if (isNaN(aBib)) {
            return 1;
          }
          if (isNaN(bBib)) {
            return -1;
          }
          return aBib < bBib ? -1 : 1;
        } else if (sortOrder === "NAME") {
          const aLastName = a.nameFullLocal.split(" ").reverse()[0];
          const bLastName = b.nameFullLocal.split(" ").reverse()[0];
          //TODO: use locale from state once it's tracked there
          const locale = localStorage.getItem("atrium_locale") || "en";
          return aLastName.localeCompare(bLastName, locale);
        } else if (sortOrder === "ACTIVE") {
          return b.playing - a.playing || parseInt(a.bib) - parseInt(b.bib);
        } else if (sortOrder === "STARTER") {
          return (
            b.starter - a.starter ||
            b.playing - a.playing ||
            parseInt(a.bib) - parseInt(b.bib)
          );
        }
        return 1;
      });
    },
    [sortOrder]
  );

  const updateEvents = () => {
    if (!selectedPerson) {
      return;
    }

    const relatedEvents = currentState?.mqtt?.plays?.filter(
      (play) => play.personId === tempPlayer.personId
    );

    relatedEvents.forEach((event) => {
      const eventToSend = window.structuredClone(event);
      eventToSend.status = "updated";
      eventToSend.personId = selectedPerson;
      eventToSend.flagged = true;
      eventToSend.eventTime = getEventTimeForUpdatedDeletedEvents(eventToSend);

      delete eventToSend.pending;

      sendUpdatedEvent(eventToSend, props);
    });

    setTempPlayer(null);
    setReplacingStep(1);
  };

  const startReplacingPlayer = () => {
    updateRosterData(
      { ...tempPlayer, playing: false, starter: false },
      tempPlayer
    );
    setReplacingStep(2);
  };

  const rows = useMemo(() => {
    return sortPersons(teamData.persons).map((person) => {
      const canPersonBeEnabled = person.bib !== null && person.bib !== "";
      const personClassName = `duplicate-${person.duplicate} playing-${person.playing} confirmed-${person.confirmed}`;

      const fields = [
        {
          key: "bib",
          field: (
            <BibInput
              person={person}
              onBibChanged={handleBibChange}
              personClassName={personClassName}
              key={`bib_${person.personId}`}
            />
          ),
        },
        {
          key: "playerName",
          field: (
            <span
              style={{ marginLeft: "10px", marginRight: "10px" }}
              key={`name_${person.personId}`}
            >
              {person.nameFullLocal}
            </span>
          ),
        },
        {
          key: "playing",
          field: (
            <TeamSetupCheckbox
              checked={person.desiredPlayingState ?? person.playing}
              onChange={(e) => handlePlayingChange(person, e)}
              enabled={canPersonBeEnabled}
              key={`playing_${person.personId}`}
            />
          ),
        },
        {
          key: "starter",
          field: (
            <TeamSetupCheckbox
              checked={person.starter}
              onChange={(e) => handleStarterChange(person, e)}
              enabled={canPersonBeEnabled}
              key={`starter_${person.personId}`}
            />
          ),
        },
        {
          key: "captain",
          field: (
            <TeamSetupCheckbox
              checked={person.captain}
              onChange={(e) => handleCaptainChange(person, e)}
              enabled={canPersonBeEnabled}
              key={`captain_${person.personId}`}
            />
          ),
        },
        {
          key: "position",
          field: (
            <select
              value={person.position ? person.position : ""}
              onChange={(e) => handlePositionChange(person, e)}
              key={`position_${person.personId}`}
            >
              <option value=""></option>
              {positions}
            </select>
          ),
        },
      ];

      return fields
        .filter((field) => teamSetupFields.includes(field.key))
        .map((field) => field.field);
    });
  }, [
    teamData,
    positions,
    sortPersons,
    handleBibChange,
    handlePlayingChange,
    handleStarterChange,
    handleCaptainChange,
    handlePositionChange,
    teamSetupFields,
  ]);

  const onCancelReplacing = () => {
    setReplacingStep(1);
    if (replacingStep === 2) {
      updateRosterData(
        { ...tempPlayer, playing: true, starter: false },
        tempPlayer
      );
    }
    setTempPlayer(null);
  };

  return (
    <>
      <div className="controls-container" style={{ top: "175px" }}>
        <div className="player-counts">
          <div>
            <h5>
              <FormattedMessage id="person.playing" defaultMessage="Active" />:
            </h5>
            <span>{playingCount}</span>
          </div>
          {sport !== "handball" &&
            <div>
              <h5>
                <FormattedMessage id="person.starter" defaultMessage="Starter"/>:
              </h5>
              <span>{starterCount}</span>
            </div>
          }
        </div>
        <div className="player-sort-dropdown">
          <h5>
            <FormattedMessage id="sort.title" defaultMessage="Sort By" />:
          </h5>
          <select
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
          >
            {sortOrders.map((order) => (
              <option key={order.key} value={order.key}>
                {formatMessage({
                  id: order.textId,
                  defaultMessage: order.defaultText,
                })}
              </option>
            ))}
          </select>
        </div>
      </div>
      <GridTableDisplay
        columns={columns}
        rows={rows}
        headerClass="roster-table-header"
        rowCellClass="roster-cell"
        tableKeyPrefix={`roster_${teamData?.entityId}`}
      />
      {tempPlayer && (
        <Modal
          className="subs-warning-modal"
          title={formatMessage({
            id: "setup.roster.remove",
            defaultMessage: "Remove Player from Roster",
          })}
          onClose={onCancelReplacing}
        >
          {replacingStep === 1 && (
            <>
              <p className="subs-warning-modal-text">
                {formatMessage({
                  id: "setup.roster.replace",
                  defaultMessage:
                    "The following player will be removed from the roster and their match statistics will be assigned to a different player, who you will select on the next screen. Are you sure you wish to continue?",
                })}
              </p>

              <strong className="subs-warning-modal-selected-player">
                {formatMessage({
                  id: "player",
                  defaultMessage: "Player",
                })}{" "}
                #{tempPlayer.bib}
              </strong>
              <div className="subs-warning-modal-button-row">
                <Button onClick={onCancelReplacing} variant="danger">
                  {formatMessage({
                    id: "setup.roster.replace.cancel",
                    defaultMessage: "No, return to Team setup",
                  })}
                </Button>
                <Button onClick={startReplacingPlayer} variant="success">
                  {formatMessage({
                    id: "setup.roster.replace.continue",
                    defaultMessage: "Yes, continue to change player",
                  })}
                </Button>
              </div>
            </>
          )}
          {replacingStep === 2 && (
            <>
              <p className="subs-warning-modal-text">
                {formatMessage({
                  id: "player.replace",
                  defaultMessage: "Select the player who will replace",
                })}{" "}
                <strong>
                  {formatMessage({
                    id: "player",
                    defaultMessage: "Player",
                  })}{" "}
                  #{tempPlayer.bib}
                </strong>
              </p>
              <div className="subs-warning-modal-select-container">
                <select
                  className="subs-warning-modal-select"
                  value={selectedPerson}
                  onChange={(ev) => setSelectedPerson(ev.target.value)}
                >
                  <option>
                    {formatMessage({
                      id: "roster.selectPlayer",
                      defaultMessage: "Please select a player",
                    })}
                  </option>
                  {teamData.persons
                    .filter(
                      (person) =>
                        person.playing &&
                        person.personId !== tempPlayer.personId
                    )
                    .map((person) => (
                      <option value={person.personId}>
                        {formatMessage({
                          id: "player",
                          defaultMessage: "Player",
                        })}
                        #{person.bib}
                      </option>
                    ))}
                </select>
              </div>
              <div className="subs-warning-modal-button-row">
                <Button onClick={onCancelReplacing} variant="danger">
                  {formatMessage({
                    id: "cancel",
                    defaultMessage: "Cancel",
                  })}
                </Button>
                <Button onClick={updateEvents} variant="success">
                  {formatMessage({
                    id: "confirm",
                    defaultMessage: "Confirm",
                  })}
                </Button>
              </div>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default injectIntl(Roster);
