import { useEffect, useState } from "react";
import Paho from "paho-mqtt";
import { inflate } from "pako";

const DebugData = (props) => {
  const { connectUrl, clientId, topics } = props;
  const [reconnect, setReconnect] = useState();

  useEffect(() => {
    if ((connectUrl, clientId)) {
      console.log("connecting:", connectUrl, clientId, topics);
      var client = new Paho.Client(
        connectUrl.split("/mqtt")[0].replace("wss://", ""),
        443,
        "/mqtt" + connectUrl.split("/mqtt")[1],
        clientId
      );
      var connectOptions = {
        useSSL: true,
        timeout: 3,
        mqttVersion: 4,
        onSuccess: function () {
          console.log("Connect: - " + clientId, topics);
          client.subscribe(
            topics.find((topic) => topic.scope === "read:stream_play_by_play")
              .topic
          );
        },
        onFailure: function (error) {
          // connect failed
          console.log("failed to connect to IOT", error);
        },
      };
      client.onMessageArrived = onMessageArrived;
      client.onMessageDelivered = onMessageDelivered;
      client.onConnectionLost = onConnectionLost;

      client.connect(connectOptions);

      //   }
    }
    // eslint-disable-next-line
  }, [connectUrl, reconnect]);

  function onMessageArrived(message) {
    console.log("received", message, message.payloadString);
    console.log(JSON.parse(deCompressMessage(message)));
    // const { content, type, demo } = JSON.parse(message.payloadString);
    // processMessage(type, content, demo);
  }

  function onMessageDelivered(message) {
    console.log("delivered", message.payloadString);
  }

  function onConnectionLost(responseObject) {
    if (responseObject.errorCode !== 0) {
      console.log(
        "onConnectionLost:" + responseObject.errorMessage,
        responseObject
      );
      setReconnect(true);
    }
  }

  function deCompressMessage(message) {
    // Decode base64 string
    let compressed = atob(JSON.parse(message.payloadString).compressedData);
    // Convert binary string to character array
    var characterDataArray = compressed.split("").map(function (x) {
      return x.charCodeAt(0);
    });
    // Turn character code array into byte-array
    var binaryDataArray = new Uint8Array(characterDataArray);
    // Decompress base64 binary data utf-8 array
    var decompressedData = inflate(binaryDataArray);
    // Convert buffer / byteArray back to ascii string (utf-16)
    var decompressedMessage = String.fromCharCode.apply(
      null,
      new Uint16Array(decompressedData)
    );

    return decompressedMessage;
  }

  return null;
};
export default DebugData;
