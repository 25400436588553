export const Shootout = {
  name: "shootout-panel",
  component: "MainPanel",
  layout: "shootout-panel main-panel x-large",
  mode: "visible",
  text: "shootOut",
  showOn: [{ state: "primary", value: "shootOut", operator: "is" }],
  startEvent: false,
  startPlay: false,
  allowFlag: false,
  allowCancel: true,
  children: [
    {
      name: "shootout-panel-content",
      component: "ShootOut",
      layout: "shootout",
      mode: "visible",
      showOn: [true],
      startEvent: false,
      startPlay: false,
      actionGroups: [],
    },
  ],
};
