import React, { useState, useContext } from "react";

import { FixtureDetailsContext } from "../../../../Config/FixtureDetails";
import { checkGameStarted } from "../../../Base/Utilities/Game";
import { useIsVisible } from "../../../Base/Hooks/IsVisible";

import JointSubstitutions from "./Substitutions/JointSubstitutions";
import PlayerStats from "./PlayerStats/PlayerStats";
import Player from "./Player";

import "./Players.scss";

function BasketballPlayers(props) {
  const { currentState, panel, entity, index, eventStore, updateState } = props;
  const { preSubs = [] } = currentState;
  const [isSubsVisible, setIsSubsVisible] = useState(null);
  const [isStatsVisible, setIsStatsVisible] = useState(null);
  const [isVisible, isEnabled] = useIsVisible(panel, props);
  const {
    fixtureProfile: { maximumRosterSize },
  } = useContext(FixtureDetailsContext);

  const isGameStarted = checkGameStarted(props);

  if (!isVisible) {
    return null;
  }

  const updatePreSubs = (person) => {
    const { personId, active } = person;

    if (active) {
      return;
    }

    const idx = preSubs.indexOf(personId);
    if (idx > -1) {
      updateState("preSubs", [
        ...preSubs.slice(0, idx),
        ...preSubs.slice(idx + 1),
      ]);
      return;
    }

    updateState("preSubs", [...preSubs, personId]);
  };

  function getObjectsToRender() {
    const { persons } = entity;
    const sortedPersons = persons.sort((a, b) =>
      parseInt(a.bib) < parseInt(b.bib) ? -1 : 1
    );

    const renderableObjects = Array.from(Array(maximumRosterSize)).map(
      (_, idx) => {
        const personAtIndex = sortedPersons[idx];

        return personAtIndex ?? "placeholder";
      }
    );

    if (maximumRosterSize % 2 === 0) {
      return [renderableObjects, "invisible-placeholder"];
    }

    return [
      renderableObjects.slice(0, renderableObjects.length - 1),
      renderableObjects[renderableObjects.length - 1],
    ];
  }

  function renderPlayerOrPlaceholder(renderable, idx) {
    if (
      renderable === "placeholder" ||
      renderable === "invisible-placeholder"
    ) {
      return (
        <div
          key={`placeholder-${idx}`}
          className="player active-false person"
          style={{
            borderColor: "transparent",
            visibility: renderable === "placeholder" ? "visible" : "hidden",
          }}
        />
      );
    }

    const isPreSubbed = preSubs.includes(renderable.personId);

    if (renderable.personId) {
      return (
        <Player
          key={`player-${renderable.personId}`}
          entityId={entity.entityId}
          player={renderable}
          teamColor={entity.primaryColor}
          currentState={currentState}
          eventStore={eventStore}
          onClick={() => updatePreSubs(renderable)}
          isPreSubbed={isPreSubbed}
        />
      );
    }

    return null;
  }

  const [playersOrPlaceholder, objectToRenderIndividually] =
    getObjectsToRender();

  return (
    <div className={"players-base enabled-" + isEnabled}>
      <div
        className={`subs-button enabled-${String(isGameStarted)}`}
        onClick={() => setIsSubsVisible(entity.entityId)}
      >
        <i className="fas fa-random"></i>
      </div>
      <div className={`players entity-${index}`}>
        {playersOrPlaceholder.map(renderPlayerOrPlaceholder)}
      </div>
      <div className="player-stats-icon-row">
        {renderPlayerOrPlaceholder(objectToRenderIndividually)}
        <div
          className={`player stats entity-${index}`}
          style={{
            borderColor: "transparent",
          }}
          onClick={() => setIsStatsVisible(entity.entityId)}
        />
      </div>

      {isStatsVisible && currentState.statistics && (
        <React.Fragment>
          <PlayerStats
            teamIndex={index}
            team={entity.entityId}
            setIsStatsVisible={setIsStatsVisible}
            {...props}
          />
        </React.Fragment>
      )}

      {isSubsVisible === entity.entityId && (
        <React.Fragment>
          <JointSubstitutions
            {...props}
            setIsSubsVisible={setIsSubsVisible}
            preSubs={preSubs}
            togglePreSubs={updatePreSubs}
          />
        </React.Fragment>
      )}
    </div>
  );
}

export default BasketballPlayers;
