export var ModifyShotValue2 = {
  name: "change_shot_value_2",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "edit.shot.value.2",
  showOn: [{ state: "editState", value: "shot_value_2", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    {
      name: "change_shot_location_description",
      component: "PanelText",
      layout: "panel-text",
      mode: "visible",
      showOn: [true],
      options: {
        layout: "description-text",
        text: "edit.shot.location",
      },
      actionGroups: [],
    },
    {
      name: "location_map",
      component: "MiniLocation",
      layout: "basketball",
      mode: "visible",
      showOn: [true],
      options: {
        layout: "description-text",
        text: "edit.shot.location",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "nextState",
              value: "shot-type",
              type: "editState",
            },
          ],
          conditions: [],
        },
      ],
    },
  ],
};

export var ModifyShotValue3 = {
  name: "change_shot_value_3",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "edit.shot.value.3",
  showOn: [{ state: "editState", value: "shot_value_3", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    {
      name: "change_shot_location_description",
      component: "PanelText",
      layout: "panel-text",
      mode: "visible",
      showOn: [true],
      options: {
        layout: "description-text",
        text: "edit.shot.location",
      },
      actionGroups: [],
    },
    {
      name: "location_map",
      component: "MiniLocation",
      layout: "basketball",
      mode: "visible",
      showOn: [true],
      options: {
        layout: "description-text",
        text: "edit.shot.location",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "nextState",
              value: "shot-type",
              type: "editState",
            },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
