// Rebound Player /  shot clock
export var InsertRebound = {
  name: "shot-rebound",
  component: "MainPanel",
  layout: "main-panel x-large align-center",
  mode: "visible",
  text: "rebound.select-player",
  showOn: [
    { state: "editState", value: "shot-rebound-insert", operator: "is" },
  ],
  allowFlag: true,
  allowCancel: false,
  showDetails: { events: ["shot", "block"] },
  children: [
    // Left Team
    {
      name: "rebound-player-select-team-0",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      startEvent: true,
      startPlay: false,
      inheritPlay: "shot",
      options: {
        team: 0,
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "rebound", type: "eventType" },
            { action: "saveData", value: "defensive", type: "subType" },
            {
              action: "clearData",
              value: ["x", "y", "options", "scores", "success"],
              type: "main",
            },
          ],
          conditions: [],
        },
        {
          actions: [
            {
              action: "saveData",
              value: "offensive",
              type: "subType",
            },
          ],
          conditions: [
            {
              item: "entityId",
              value: { type: "shot" },
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "clearData",
              value: ["flagged"],
              type: "main",
            },
            {
              action: "copyEvent",
              value: "rebound",
              type: "to",
            },
            { action: "clearEvent", value: "turnover", type: "main" },
            {
              action: "nextState",
              value: null,
              type: "editState",
            },
            { action: "setState", value: true, type: "confirmArrows" },
          ],
          conditions: [
            {
              item: "subType",
              value: "offensive",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [{ action: "sendEvent", value: "new", type: "main" }],
          conditions: [],
        },
        {
          actions: [
            { action: "clearEvent", value: "turnover", type: "main" },
            {
              action: "nextState",
              value: null,
              type: "editState",
            },
            { action: "setState", value: true, type: "confirmArrows" },
          ],
          conditions: [
            {
              item: "subType",
              value: "defensive",
              event: "main",
              operator: "is",
            },
          ],
        },
      ],
    },
    // Right Team
    {
      name: "rebound-player-select-team-1",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      startEvent: true,
      startPlay: false,
      inheritPlay: "shot",
      options: {
        team: 1,
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "rebound", type: "eventType" },
            { action: "saveData", value: "defensive", type: "subType" },
            {
              action: "clearData",
              value: ["x", "y", "options", "scores", "success"],
              type: "main",
            },
          ],
          conditions: [],
        },
        {
          actions: [
            {
              action: "saveData",
              value: "offensive",
              type: "subType",
            },
          ],
          conditions: [
            {
              item: "entityId",
              value: { type: "shot" },
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [{ action: "sendEvent", value: "new", type: "main" }],
          conditions: [],
        },
        {
          actions: [
            {
              action: "clearData",
              value: ["flagged"],
              type: "main",
            },
            {
              action: "copyEvent",
              value: "rebound",
              type: "to",
            },
            { action: "clearEvent", value: "turnover", type: "main" },
            {
              action: "nextState",
              value: null,
              type: "editState",
            },
            { action: "setState", value: true, type: "confirmArrows" },
          ],
          conditions: [
            {
              item: "subType",
              value: "offensive",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "clearEvent", value: "turnover", type: "main" },
            {
              action: "nextState",
              value: null,
              type: "editState",
            },
            { action: "setState", value: true, type: "confirmArrows" },
          ],
          conditions: [
            {
              item: "subType",
              value: "defensive",
              event: "main",
              operator: "is",
            },
          ],
        },
      ],
    },
    // Team Rebounds
    {
      name: "team-rebounds-panel",
      component: "SubPanel",
      layout: "sub-panel align-center",
      mode: "visible",
      text: null,
      showOn: [true],
      children: [
        // Team 0 Button
        {
          name: "team-0-button",
          component: "TypeButton",
          layout: "match-button wide team  team-0",
          mode: "enable",
          text: "rebound.team",
          options: { team: 0 },
          showOn: [true],
          startEvent: true,
          startPlay: false,
          inheritPlay: "shot",
          actionGroups: [
            {
              actions: [
                { action: "saveData", value: "rebound", type: "eventType" },
                { action: "saveData", value: "defensive", type: "subType" },
                {
                  action: "clearData",
                  value: ["personId", "x", "y", "success", "options", "scores"],
                  type: "main",
                },
              ],
              conditions: [],
            },
            {
              actions: [
                {
                  action: "saveData",
                  value: "offensive",
                  type: "subType",
                },
              ],
              conditions: [
                {
                  item: "entityId",
                  value: { type: "shot" },
                  event: "main",
                  operator: "is",
                },
              ],
            },
            {
              actions: [
                {
                  action: "saveData",
                  value: [{ option: "team", value: true }],
                  type: "options",
                },
                { action: "sendEvent", value: "new", type: "main" },
                { action: "clearEvent", value: "turnover", type: "main" },
                { action: "nextState", value: null, type: "editState" },
                { action: "setState", value: true, type: "confirmArrows" },
              ],
              conditions: [],
            },
          ],
          actions: [],
        },
        // Team 1 Button
        {
          name: "team-1-button",
          component: "TypeButton",
          layout: "match-button wide team team-1",
          mode: "enable",
          text: "rebound.team",
          options: { team: 1 },
          showOn: [true],
          startEvent: true,
          startPlay: false,
          inheritPlay: "shot",
          actionGroups: [
            {
              actions: [
                { action: "saveData", value: "rebound", type: "eventType" },
                { action: "saveData", value: "defensive", type: "subType" },
                {
                  action: "clearData",
                  value: ["personId", "x", "y", "success", "options", "scores"],
                  type: "main",
                },
              ],
              conditions: [],
            },
            {
              actions: [
                {
                  action: "saveData",
                  value: "offensive",
                  type: "subType",
                },
              ],
              conditions: [
                {
                  item: "entityId",
                  value: { type: "shot" },
                  event: "main",
                  operator: "is",
                },
              ],
            },
            {
              actions: [
                {
                  action: "saveData",
                  value: [{ option: "team", value: true }],
                  type: "options",
                },
                { action: "sendEvent", value: "new", type: "main" },
                { action: "clearEvent", value: "turnover", type: "main" },
                { action: "nextState", value: null, type: "editState" },
                { action: "setState", value: true, type: "confirmArrows" },
              ],
              conditions: [],
            },
          ],
        },
      ],
    },
    //No rebound / end of period
    {
      name: "no-rebound",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: "rebound.none",
      showOn: [true],
      startEvent: true,
      startPlay: false,
      actionGroups: [
        {
          actions: [
            { action: "clearEvent", value: "turnover", type: "main" },
            { action: "nextState", value: null, type: "editState" },
            { action: "setState", value: true, type: "confirmArrows" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
