// Shot player select
export var ShotPlayerSelect = {
  name: "shot-player",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "shot.select-player",
  showOn: [{ state: "primary", value: "shot-player", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    // Select Player
    {
      name: "shot-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      options: { currentEntity: true, showBench: false, showCoach: false },
      actionGroups: [
        {
          actions: [
            { action: "sendEvent", value: "edit", type: "main" },
            { action: "nextState", value: "shot-type", type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
