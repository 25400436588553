export const FaceOff = {
  name: "faceoff-panel",
  component: "MainPanel",
  layout: "faceoff-panel main-panel x-large",
  mode: "visible",
  text: "faceOff",
  showOn: [{ state: "primary", value: "faceOff", operator: "is" }],
  startEvent: false,
  startPlay: false,
  allowFlag: false,
  allowCancel: true,
  children: [
    {
      name: "faceOff-panel-content",
      component: "FaceOff",
      layout: "faceoff",
      mode: "visible",
      showOn: [true],
      startEvent: false,
      startPlay: false,
      actionGroups: [],
    },
  ],
};
