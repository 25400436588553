const sharedActions = [
  { action: "newEventId", value: null, type: "main" },
  { action: "saveData", value: "videoReview", type: "eventType" },
  { action: "saveData", value: "pending", type: "subType" },
  { action: "sendEvent", value: "new", type: "main" },
];

export const VideoReviewTeamSelect = {
  name: "video-review-team-select",
  component: "MainPanel",
  layout: "main-panel padded align-center",
  mode: "visible",
  text: "videoReview.teamSelect",
  showOn: [
    { state: "secondary", value: "video-review-team-select", operator: "is" },
  ],
  allowFlag: true,
  allowCancel: true,
  children: [
    {
      name: "team-0",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: { team: "name" },
      showOn: [true],
      options: { team: 0 },
      startPlay: true,
      startEvent: false,
      actionGroups: [
        {
          actions: [
            ...sharedActions,
            {
              action: "nextState",
              value: "video-review-outcome",
              type: "secondary",
            },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "team-1",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: { team: "name" },
      showOn: [true],
      options: { team: 1 },
      startPlay: true,
      startEvent: false,
      actionGroups: [
        {
          actions: [
            ...sharedActions,
            {
              action: "nextState",
              value: "video-review-outcome",
              type: "secondary",
            },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
