import React, { useCallback } from "react";

const ClockAdjust = (props) => {
  const { className, up, down } = props;

  const _sendKey = useCallback((keypress) => {
    let keyObject = {
      code: keypress,
      key: keypress,
    };
    let evt = new KeyboardEvent("keydown", keyObject);
    window.dispatchEvent(evt);
  }, []);

  return (
    <div className={"clock-adjust " + className}>
      <i className="up fas fa-caret-square-up" onClick={() => _sendKey(up)}></i>
      <i
        className="down fas fa-caret-square-down"
        onClick={() => _sendKey(down)}
      ></i>
    </div>
  );
};

export default ClockAdjust;
