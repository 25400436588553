import "./Checkbox.scss";

function Checkbox(props) {
  const { checked, onChange, style, label } = props;
  return (
    <div className="checkbox-root" style={style}>
      <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
        <span className="cr">
          <i className="cr-icon fa fa-check" />
        </span>
      </label>
      {label && <span>{label}</span>}
    </div>
  );
}

export default Checkbox;
