// Assist Player Select
export var AssistPlayerSelect = {
  name: "shot-assist",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "assist.select-player",
  showOn: [{ state: "primary", value: "shot-assist", operator: "is" }],
  allowBack: {
    state: "primary",
    value: "shot-type",
    event: "shot",
    save: false,
  },
  allowFlag: true,
  allowCancel: false,
  showDetails: { events: ["shot"] },
  children: [
    {
      name: "assist-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: true,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: true,
        showBench: false,
        showCoach: false,
        excludePlayer: true,
        excludeFrom: "shot",
      },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "assist", type: "eventType" },
            {
              action: "clearData",
              value: ["x", "y", "options", "subType", "scores", "success"],
              type: "main",
            },
            { action: "sendEvent", value: "new", type: "main" },
          ],
          conditions: [],
        },
        // And 1 Scenario
        {
          actions: [
            {
              action: "copyData",
              value: "x",
              type: "shot",
            },
            {
              action: "copyData",
              value: "y",
              type: "shot",
            },
            {
              action: "clearData",
              value: ["personId"],
              type: "main",
            },
            { action: "nextState", value: "and1-foul-player", type: "primary" },
          ],
          conditions: [
            {
              item: "options",
              value: "foulOnShot",
              event: "shot",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "nextState", value: null, type: "primary" },
            { action: "clearEvent", value: "assist", type: "main" },
            { action: "clearEvent", value: "shot", type: "main" },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [
            {
              item: "options",
              value: "foulOnShot",
              event: "shot",
              operator: "not",
            },
          ],
        },
      ],
    },
    {
      name: "no-assist",
      component: "TypeButton",
      layout: "match-button center",
      mode: "enable",
      text: "assist.none",
      showOn: [true],
      actionGroups: [
        // And 1 Scenario
        {
          actions: [
            {
              action: "copyData",
              value: "x",
              type: "shot",
            },
            {
              action: "copyData",
              value: "y",
              type: "shot",
            },
            {
              action: "clearData",
              value: ["personId"],
              type: "main",
            },
            { action: "nextState", value: "and1-foul-player", type: "primary" },
          ],
          conditions: [
            {
              item: "options",
              value: "foulOnShot",
              event: "shot",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "nextState", value: null, type: "primary" },
            { action: "clearEvent", value: "assist", type: "main" },
            { action: "clearEvent", value: "shot", type: "main" },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [
            {
              item: "options",
              value: "foulOnShot",
              event: "shot",
              operator: "not",
            },
          ],
        },
      ],
      startEvent: false,
      startPlay: false,
    },
  ],
};
