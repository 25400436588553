import {
  Clock,
  Rink,
  SwitchTeamsButton,
  FaceOffButton,
  VideoReviewButton,
  TimeoutButton,
  OffsideButton,
  FloatingActionPanel,
} from "./includes/Base";

import {
  FaceOff,
  Shootout,
  PenaltyDrawnPlayerSelect,
  PenaltyPlayerSelect,
  PenaltyTypeSelect,
  PenaltyShotOutcomeSelect,
  ShotBlockedPlayerSelect,
  ShotTypeSelect,
  ShotPlayerSelect,
  ShotPrimaryAssistPlayerSelect,
  ShotSecondaryAssistPlayerSelect,
  ShotOutcomeTypeSelect,
  VideoReviewSelect,
} from "./includes/Panels";

import {
  ModifyFaceOffResult,
  ModifyPlayerSelect,
  ModifyShotType,
  ModifyVideoReview,
} from "./includes/Modify";

const InsertChildPlayerSameTeam = {
  name: "player",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "player.select",
  showOn: [{ state: "editState", value: "child-insert-same", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["parent"] },
  children: [
    {
      name: "player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      options: {
        currentEntityEvent: "parent",
        currentEntity: true,
        showBench: false,
        showCoach: false,
        excludeOtherEvents: false,
        excludePlayer: true,
        excludeFrom: "parent",
        calculateOnCourt: "after",
      },
      actionGroups: [
        {
          actions: [
            { action: "newEventId", value: null, type: "main" },
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            { action: "calculateOnCourt" },
            { action: "nextState", value: null, type: "editState" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};

const Ice3Workflow = {
  displayEventLog: true,
  sport: "ice3",
  panels: [
    {
      name: "main-area",
      component: "StackingPanel",
      showOn: [true],
      layout: "column",
      children: [
        {
          name: "top-panel",
          component: "StackingPanel",
          layout: "fill-content",
          showOn: [true],
          children: [
            {
              name: "team-0-team-select",
              component: "Teams",
              showOn: [true],
              mode: "visible",
              showPossessionIcon: false,
              showArrow: false,
              showStats: false,
            },

            {
              name: "clock-and-buttons",
              component: "StackingPanel",
              showOn: [true],
              layout: "column fill-parent clock-and-buttons",
              children: [
                Clock,
                {
                  name: "match-buttons",
                  component: "ButtonPanel",
                  layout: "match-buttons-stackable",
                  mode: "visible",
                  showOn: [true],
                  children: [
                    VideoReviewButton,
                    SwitchTeamsButton,
                    FaceOffButton,
                  ],
                },
              ],
            },
            {
              name: "team-1-team-select",
              component: "Teams",
              showOn: [true],
              mode: "visible",
              showPossessionIcon: false,
              showArrow: false,
              showStats: false,
            },
          ],
        },
        {
          name: "play-area",
          component: "StackingPanel",
          showOn: [true],
          layout: "fill-parent",
          children: [
            {
              name: "team-0-player-select",
              component: "Players",
              showOn: [true],
              mode: "visible",
            },
            {
              name: "middle-play-area",
              component: "StackingPanel",
              showOn: [true],
              layout: "column fill-content",
              children: [
                Rink,
                {
                  name: "offside-timeout-buttons",
                  component: "TeamGroupedButtons",
                  layout: "horizontal-buttons",
                  mode: "visible",
                  showOn: [true],
                  children: [OffsideButton, TimeoutButton],
                },
              ],
            },
            {
              name: "team-1-player-select",
              component: "Players",
              showOn: [true],
              mode: "visible",
            },
          ],
        },
      ],
    },
    FloatingActionPanel,
    ShotTypeSelect,
    ShotPlayerSelect,
    ShotPrimaryAssistPlayerSelect,
    ShotSecondaryAssistPlayerSelect,
    ShotOutcomeTypeSelect,
    ShotBlockedPlayerSelect,
    FaceOff,
    Shootout,
    PenaltyDrawnPlayerSelect,
    PenaltyPlayerSelect,
    PenaltyTypeSelect,
    PenaltyShotOutcomeSelect,
    VideoReviewSelect,
    {
      name: "edit",
      component: "EditPanel",
      layout: "edit-base",
      mode: "visible",
      showOn: true,
    },
    {
      name: "insert",
      component: "InsertPanel",
      layout: "insert-base",
      mode: "visible",
      showOn: true,
    },
    {
      name: "delete",
      component: "DeletePanel",
      layout: "delete-base",
      mode: "visible",
      showOn: true,
    },
    {
      name: "insert-clock",
      component: "InsertClock",
      layout: "insert-clock",
      mode: "visible",
      options: { disablePreMatch: true },
      showOn: [{ state: "insertMode.enabled", value: true, operator: "is" }],
      actionGroups: [],
    },
  ],
  modify: {
    types: [
      {
        name: "goal_made",
        eventTypes: ["goal"],
        success: true,
        editable: [
          { type: "personId", text: "edit.shot.person", panel: "player" },
          { type: "subType", text: "edit.shot.type", panel: "shot-type" },
        ],
        delete: true,
        delete_all: true,
        child_inserts: [
          {
            type: "assist",
            subType: "assistPrimary",
            text: "insert.child.assistPrimary",
            panel: "child-insert-same",
          },
          {
            type: "assist",
            subType: "assistSecondary",
            text: "insert.child.assistSecondary",
            panel: "child-insert-same",
          },
        ],
      },
      {
        name: "goal_attempt",
        eventTypes: ["goal"],
        success: false,
        editable: [
          { type: "personId", text: "edit.shot.person", panel: "player" },
        ],
        delete: true,
        delete_all: true,
      },
      {
        name: "assist",
        eventTypes: ["assist"],
        success: null,
        editable: [
          { type: "personId", text: "edit.assist.person", panel: "player" },
        ],
        delete: true,
        delete_all: false,
      },
      {
        name: "block",
        eventTypes: ["block"],
        success: null,
        editable: [
          { type: "personId", text: "edit.block.person", panel: "player" },
        ],
        delete: true,
        delete_all: false,
      },
      {
        name: "save",
        eventTypes: ["save"],
        success: null,
        editable: [
          { type: "personId", text: "edit.save.person", panel: "player" },
        ],
        delete: true,
        delete_all: false,
      },
      {
        name: "penalty",
        eventTypes: ["penalty"],
        success: null,
        editable: [
          { type: "personId", text: "edit.penalty.person", panel: "player" },
        ],
        delete: true,
        delete_all: true,
      },
      {
        name: "penaltyShotAwarded",
        eventTypes: ["penaltyShotAwarded"],
        success: null,
        editable: [
          {
            type: "personId",
            text: "edit.penaltyShotAwarded.person",
            panel: "player",
          },
        ],
        delete: true,
        delete_all: true,
      },
      {
        name: "faceOff",
        eventTypes: ["faceOff"],
        success: null,
        editable: [
          { type: "personId", text: "edit.faceOff.person", panel: "player" },
          {
            type: "success",
            text: "edit.faceOff.success",
            panel: "faceoff-result",
          },
        ],
        delete: true,
        delete_all: true,
        delete_conditions: [
          {
            item: "options",
            value: undefined,
            operator: "not",
          },
        ],
      },
      {
        name: "substitution",
        eventTypes: ["substitution"],
        success: null,
        editable: [
          {
            type: "personId",
            text: "edit.substitution.single",
            panel: "player",
          },
        ],
        delete: true,
      },
      {
        name: "videoReview",
        eventTypes: ["videoReview"],
        success: null,
        editable: [
          {
            type: "subType",
            text: "edit.videoReview.outcome",
            panel: "video-review",
          },
        ],
        delete: true,
        delete_all: true,
      },
      {
        name: "offside",
        eventTypes: ["offside"],
        success: null,
        editable: [{ type: "delete_only", text: null, panel: null }],
        delete: true,
        delete_all: true,
      },
      {
        name: "timeOut",
        eventTypes: ["timeOut"],
        success: null,
        editable: [{ type: "delete_only", text: null, panel: null }],
        delete: true,
        delete_all: true,
      },
    ],
    panels: [
      InsertChildPlayerSameTeam,
      ModifyFaceOffResult,
      ModifyPlayerSelect,
      ModifyShotType,
      ModifyVideoReview,
    ],
  },
};

export default Ice3Workflow;
