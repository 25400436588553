import React, { useContext, useState } from "react";
import { injectIntl } from "react-intl";
import FieldSVG from "../../../../assets/hockey/hockey5s.svg?react";

import { useFieldHighlight } from "./useFieldHighlight";
import { lightOrDark } from "../../../Base/Utilities/LightOrDark";
import { useTrackingKeys } from "./useTrackingKeys";
import { createPossessionChangeHandler, trackingPositions, trackingPositionHotkeys } from "./Field.logic";
import ArrowSingle from "./arrow-single.svg?react";
import { hexToRgba } from "../../../Base/Utilities/Color";
import { FixtureDetailsContext } from "../../../../Config/FixtureDetails";

const positionCountByTeam = 4;

const noop = () => {};

const Field = (props) => {
  const { currentState, intl, eventStore } = props;
  const { formatMessage } = intl;
  const { competitors } = useContext(FixtureDetailsContext);
  const [isChallengeDisabled, setChallengeDisabled] = useState(false);

  const onChallengeClicked = () => {
    setChallengeDisabled(true);

    window.setTimeout(() => setChallengeDisabled(false), 3000);
  };

  useFieldHighlight(props);

  useTrackingKeys(props, trackingPositionHotkeys.hockey5s, trackingPositions.hockey5s, onChallengeClicked);

  const renderButton = (position, idx) => {
    // fist 5 position is the first team, second 5 position is the second team
    const entityIdx = Math.floor(idx / positionCountByTeam);
    const entity = currentState.entities[entityIdx];

    const { posTracking } = currentState;

    const onButtonClick = createPossessionChangeHandler({
      entity,
      position,
      props,
      cb: onChallengeClicked,
      eventStore,
    });

    const isAnotherButtonActive =
      posTracking && posTracking.entity.entityId === entity.entityId && posTracking.type !== position;

    const competitor = competitors.find((comp) => comp.entityId === entity.entityId);

    const getLabelByPosition = (position) => {
      if (position === "goal" || position === "challenge") {
        return position;
      }

      return <span>{competitor?.codeLocal}</span>;
    };

    const isDisabled = position === "challenge" && isChallengeDisabled;

    return (
      <div
        className={`hockey-field-button hockey-field-button--${position} hockey-field-button--entity-${entityIdx} ${lightOrDark(
          entity.primaryColor,
        )} ${isDisabled ? "disabled" : ""}`}
        key={`${position}-${idx}`}
        style={{
          gridArea: `button${idx + 1}`,
          backgroundColor: hexToRgba(entity.primaryColor, isAnotherButtonActive ? 0.3 : 1),
        }}
        onClick={!isDisabled ? onButtonClick : noop}
      >
        <span className={`hockey-field-button--name  ${isAnotherButtonActive ? "is-inactive" : ""}`}>
          <span>{getLabelByPosition(position)}</span>
          <br />
          {position === "circle" && (
            <span>
              {formatMessage({
                id: "event.possession.circle",
                defaultMessage: "Circle Entry",
              })}
            </span>
          )}
          {position !== "goal" && position !== "challenge" && (
            <ArrowSingle className={entityIdx === 0 ? "arrow-normal" : "arrow-reversed"} />
          )}
        </span>
        <span
          className="hockey-field-button--hotkey"
          style={{
            backgroundColor: entity.primaryColor,
          }}
        >
          {trackingPositionHotkeys.hockey5s[idx]}
        </span>
      </div>
    );
  };

  return (
    <div className="hockey-area">
      <FieldSVG id="base-play-area" />
      <div className="hockey-area-buttons--hockey5s">{trackingPositions.hockey5s.map(renderButton)}</div>
    </div>
  );
};

export default injectIntl(Field);
