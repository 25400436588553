export const PenaltyShotPlayer = {
  name: "penalty-shot-player",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "shot.select-player",
  showOn: [{ state: "primary", value: "penalty-shot-player", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelPlay: true,
  showDetails: { events: ["main"] },
  children: [
    {
      name: "foul-shot-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: true,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: false,
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [
            {
              action: "saveData",
              value: "sevenMetrePenalty",
              type: "eventType",
            },
            { action: "saveData", value: "shot", type: "subType" },

            { action: "copyEvent", value: "shot", type: "to" },

            {
              action: "nextState",
              value: "penalty-change-goal-keeper",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "confirm-later-foul-shot-player",
      component: "ButtonPanel",
      layout: "button-group centered",
      mode: "visible",
      showOn: [true],
      children: [
        {
          name: "confirm-later",
          component: "TypeButton",
          layout: "match-button",
          mode: "enable",
          text: "confirmLater",
          showOn: [true],
          startEvent: true,
          startPlay: false,
          actionGroups: [
            {
              actions: [
                { action: "modifyEvent", value: "swapEntities", type: "main" },

                { action: "saveData", type: "flagged", value: true },
                { action: "saveData", value: "shot", type: "subType" },
                {
                  action: "saveData",
                  value: "sevenMetrePenalty",
                  type: "eventType",
                },

                { action: "copyEvent", value: "shot", type: "to" },

                {
                  action: "nextState",
                  value: "penalty-change-goal-keeper",
                  type: "primary",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
