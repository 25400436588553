export const ModifyVideoReviewType = {
    name: "modify-video-review-type",
    component: "MainPanel",
    layout: "main-panel large align-center",
    mode: "visible",
    text: "videoReview.type",
    showOn: [{ state: "editState", value: "video-review-type", operator: "is" }],
    allowFlag: true,
    allowCancel: true,
    showDetails: { events: ["edit"] },
    children: [
      {
        name: "change-to-umpire",
        component: "PanelText",
        layout: "panel-text",
        mode: "visible",
        showOn: [
          {
            state: "eventOptions.team",
            value: true,
            operator: "is",
            event: "main",
          },
        ],
        options: {
          layout: "description-text",
          text: "videoReview.change.to.umpire",
        },
        actionGroups: [],
      },
      {
        name: "change-to-team",
        component: "PanelText",
        layout: "panel-text",
        mode: "visible",
        showOn: [
          {
            state: "eventOptions.umpire",
            value: true,
            operator: "is",
            event: "main",
          },
        ],
        options: {
          layout: "description-text",
          text: "videoReview.change.to.team",
        },
        actionGroups: [],
      },
      {
        name: "confirm-panel",
        component: "SubPanel",
        layout: "sub-panel",
        mode: "visible",
        text: null,
        showOn: [true],
        children: [
          {
            name: "ok-button",
            component: "TypeButton",
            layout: "match-button action",
            mode: "enable",
            text: "ok",
            showOn: [true],
            actionGroups: [
              {
                actions: [
                  { action: "clearData", value: ["entityId"], type: "main"},
                ],
                conditions: [
                  {
                    item: "optionValue",
                    event: "main",
                    operator: "is",
                    name: "team",
                    value: true,
                  },
                ],
              },
              {
                actions: [
                  {
                    action: "switchValues",
                    value: {
                      property: "options",
                      values: [{team: true}, {umpire: true}],
                    },
                    type: "main",
                  },
                ],
                conditions: [],
              },
              {
                actions: [
                  {
                    action: "nextState",
                    value: "entity",
                    type: "editState",
                  },
                ],
                conditions: [
                  {
                    item: "optionValue",
                    event: "main",
                    operator: "is",
                    name: "team",
                    value: true,
                  },
                ],
              },
              {
                actions: [
                  {
                    action: "nextState",
                    value: null,
                    type: "editState",
                  },
                ],
                conditions: [],
              }
            ],
          },
        ],
      },
    ]
}
