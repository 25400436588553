import React, { useEffect } from "react";
import handballCourt from "../../../../assets/handball/court.png";
import handballCourtInsert from "../../../../assets/handball/court-insert.png";
import handballRegions from "../../../../assets/handball/court_zones.png";
import LeftArrowPosSVG from "../../../../assets/handball/left-pos-arrow.svg?react";
import RightArrowPosSVG from "../../../../assets/handball/right-pos-arrow.svg?react";
import OpenNetLeftSVG from "../../../../assets/handball/open-net-left.svg?react";
import OpenNetRightSVG from "../../../../assets/handball/open-net-right.svg?react";
import { lightOrDark } from "../../../Base/Utilities/LightOrDark.js";
import { checkGameStarted } from "../../../Base/Utilities/Game.js";

const Court = ({ onClick, props }) => {
  const { currentState } = props;
  const possessionIndex = (currentState?.entities ?? []).findIndex(
    (entity) => entity.hasPossession
  );

  const isOpenNet = (teamIndex) => {
    return currentState.entities[teamIndex].persons.filter(
      (p) => p.position === "G" && p.active
    ).length === 0 && checkGameStarted(props);
  }

  useEffect(() => {
    const root = document.querySelector(":root");

    (currentState?.entities ?? []).forEach((entity, idx) => {
      root.style.setProperty(`--entity-${idx}-color`, entity.primaryColor);
      root.style.setProperty(
        `--entity-${idx}-text-color`,
        lightOrDark(entity.primaryColor) === "light" ? "#000000" : "#ffffff"
      );
    });
  }, [currentState.entities]);

  return (
    <>
      { isOpenNet(0) && (
        <OpenNetLeftSVG
          className="open-net-indicator left"
          alt="open-net-indicator"
        />
      )}

      { isOpenNet(1) && (
        <OpenNetRightSVG
          className="open-net-indicator right"
          alt="open-net-indicator"
        />
      )}

      <img
        id="base-play-area"
        alt="base-play-area"
        src={currentState?.insertMode?.enabled === true ? handballCourtInsert : handballCourt}
        style={{ position: "absolute" }}
        onClick={onClick}
        draggable={false}
      />
      <img
        id="base-play-area-regions"
        alt="base-play-area-regions"
        src={handballRegions}
        style={{ opacity: "0", width: "100%" }}
        draggable={false}
        onClick={onClick}
      />
      {possessionIndex === 0 && (
        <RightArrowPosSVG
          className="handball-possession-indicator right"
          alt="possession-indicator"
        />
      )}

      {possessionIndex === 1 && (
        <LeftArrowPosSVG
          className="handball-possession-indicator left"
          alt="possession-indicator"
        />
      )}
    </>
  );
};

export default Court;
