import React, { useContext, useState } from "react";
import { injectIntl } from "react-intl";
import { FixtureDetailsContext } from "../../../../Config/FixtureDetails";
import MessagePopup from "../../../Base/MessagePopup";
import { isGameConfirmationButtonDisabled } from "../Clock.utils";
import { sendEvent } from "../../../Base/Utilities/SendEvent.js";
import { createBaseEvent } from "../../../Base/Utilities/CreateBaseEvent.js";

const ClockButtons = (props) => {
  const { clockRunning, currentState, intl, setClockRunning, isEnabled, updateState, eventStore } = props;
  const { period, userMode } = currentState;
  const { formatMessage } = intl;
  const { fixtureProfile, sport } = useContext(FixtureDetailsContext);
  const [showConfirmAction, setShowConfirmAction] = useState(null);

  isGameConfirmationButtonDisabled(eventStore);

  const changeToShootoutPeriod = () => {
    updateState("period", {
      ...period,
      periodId: fixtureProfile.shootOutPeriodId,
    });
    setClockRunning(false, "startShootout");
  };

  const isShootoutPendingEventPresent = () => {
    return !!currentState?.mqtt?.plays.find((event) => event.eventType === "shootOut" && event.subType === "pending");
  };

  const sendShootoutPending = () => {
    const action = createBaseEvent(currentState);

    action.event.eventType = "shootOut";
    action.event.subType = "pending";

    sendEvent(action, props);
  };

  const goToNextPeriod = () => {
    setShowConfirmAction(null);
    setClockRunning(false, "periodEnd");
  };

  const goToNextExtraPeriod = () => {
    setShowConfirmAction(null);
    setClockRunning(false, "startExtra");
  };

  const goToShootOut = () => {
    setShowConfirmAction(null);
    changeToShootoutPeriod();
    if (!isShootoutPendingEventPresent()) {
      sendShootoutPending();
    }
    if (userMode === "clockOperator") {
      updateState("primary", "shootOut-clock");
    } else {
      updateState("primary", "shootOut");
    }
  };

  const endFixture = () => {
    setShowConfirmAction(null);
    setClockRunning(false, "fixtureEnd");
  };

  const endOvertime = () => {
    setShowConfirmAction(null);
    setClockRunning(false, "fixtureEnd");
  };

  const isFixtureNeverStarted =
    !clockRunning &&
    period &&
    period.periodId === 1 &&
    (period.periodStatus === "pending" || period.periodStatus === "warm_up" || period.periodStatus === "initial");

  function getNeedThrowOffEvent() {
    if (sport === "handball") return eventStore.every((event) => event.eventType !== "throwOff");
    return false;
  }

  function getNeedAboutToStartEvent() {
    if (sport === "handball") {
      return eventStore.every((event) => event.subType !== "about_to_start");
    }
    return false;
  }

  function getNeedCentrePassEvent() {
    if (sport === "hockey") return eventStore.every((event) => event.eventType !== "centrePass");
    return false;
  }

  function getNeedWarmupEvent() {
    if (sport === "hockey") {
      return period.periodStatus === "initial";
    }
    return false;
  }

  const getNeedOnPitchEvent = () => {
    if (sport === "handball") {
      return eventStore.every((event) => event.subType !== "on_pitch");
    }
    return false;
  };

  const getNeedWarmingUpEvent = () => {
    if (sport === "handball") {
      return eventStore.every((event) => event.subType !== "warm_up");
    }
    return false;
  };

  const getCurrentEvent = () => {
    const events = eventStore.filter((obj) => obj.eventType === "fixture");
    return events[events.length - 1];
  };

  const needWarmUpEvent = getNeedWarmupEvent();
  const needCentrePassEvent = getNeedCentrePassEvent();

  const needThrowOffEvent = getNeedThrowOffEvent();

  const needAboutToStartEvent = getNeedAboutToStartEvent();

  const needOnPitchEvent = getNeedOnPitchEvent();
  const needWarmingUpEvent = getNeedWarmingUpEvent();
  const currentEvent = getCurrentEvent();
  const currentSubType = currentEvent?.subType;

  // show additional end overtime when clock is stopped if match profile allows it and we are in overtime
  const showEndOvertime = () =>
    (sport === "basketball" || sport === "handball") &&
    fixtureProfile.simpleTargetScoreEndingAllowed &&
    period.periodId >= 11;

  const startButtonWrapper = (startButton) => {
    if (showEndOvertime())
      return (
        <div className="end-overtime-buttons">
          <span className="next-button" onClick={() => setShowConfirmAction("overtimeEnd")}>
            {formatMessage({
              id: "clock.end.overtime",
              defaultMessage: "End Overtime",
            })}
          </span>
          {startButton}
        </div>
      );
    return startButton;
  };

  return (
    <React.Fragment>
      <span className={"clock-button enabled-" + isEnabled}>
        {!clockRunning &&
          period &&
          period.periodStatus === "inProgress" &&
          startButtonWrapper(
            <span className="start-button" onClick={() => setClockRunning(true, "clockStart")}>
              {formatMessage({
                id: "clock.start",
                defaultMessage: "Start",
              })}
            </span>,
          )}
        {clockRunning && period && period.periodStatus === "inProgress" && (
          <span className="stop-button" onClick={(e) => setClockRunning(false, "clockStop")}>
            {formatMessage({
              id: "clock.stop",
              defaultMessage: "Stop",
            })}
          </span>
        )}
        {!clockRunning && period && period.periodId > 1 && period.periodStatus === "pending" && (
          <span className="start-button" onClick={(e) => setClockRunning(true, "periodStart")}>
            {formatMessage({
              id: "clock.start.period",
              defaultMessage: "Start Period",
            })}
          </span>
        )}
        {isFixtureNeverStarted && !needThrowOffEvent && !needCentrePassEvent && (
          <span className="start-button" onClick={(e) => setClockRunning(true, "fixtureStart")}>
            {formatMessage({
              id: "clock.start.fixture",
              defaultMessage: "Start Match",
            })}
          </span>
        )}

        {isFixtureNeverStarted &&
          needThrowOffEvent &&
          !needOnPitchEvent &&
          currentSubType !== "on_pitch" &&
          !needWarmingUpEvent &&
          !needAboutToStartEvent && (
            <span className="start-button" onClick={(e) => updateState("primary", "throw-off")}>
              {formatMessage({
                id: "clock.throwOff",
                defaultMessage: "Throw off",
              })}
            </span>
          )}

        {isFixtureNeverStarted && needOnPitchEvent && (
          <span
            className="start-button"
            onClick={() => {
              const action = createBaseEvent(currentState);
              action.event.eventType = "fixture";
              action.event.subType = "on_pitch";

              sendEvent(action, props);
            }}
          >
            {formatMessage({
              id: "clock.playersOnCourt",
              defaultMessage: "Players On Court",
            })}
          </span>
        )}

        {isFixtureNeverStarted && !needOnPitchEvent && currentSubType === "on_pitch" && !currentState?.onPitchEnd && (
          <span
            className="stop-button"
            onClick={() => {
              updateState("onPitchEnd", true);
            }}
          >
            {formatMessage({
              id: "clock.playersOnCourt.end",
              defaultMessage: "End Players On Court",
            })}
          </span>
        )}

        {isFixtureNeverStarted && currentState?.onPitchEnd && needWarmingUpEvent && (
          <span
            className="start-button"
            onClick={() => {
              const action = createBaseEvent(currentState);
              action.event.eventType = "fixture";
              action.event.subType = "warm_up";

              sendEvent(action, props);

              updateState("onPitchEnd", false);
            }}
          >
            {formatMessage({
              id: "clock.playersWarmingUp",
              defaultMessage: "Players Warming Up",
            })}
          </span>
        )}

        {isFixtureNeverStarted && needThrowOffEvent && currentSubType === "warm_up" && needAboutToStartEvent && (
          <span
            className="stop-button"
            onClick={() => {
              const action = createBaseEvent(currentState);
              action.event.eventType = "fixture";
              action.event.subType = "about_to_start";

              sendEvent(action, props);
            }}
          >
            {formatMessage({
              id: "clock.playersWarmingUp.end",
              defaultMessage: "End Players Warming Up",
            })}
          </span>
        )}

        {isFixtureNeverStarted && needWarmUpEvent && (
          <span
            className="start-button"
            onClick={() => {
              updateState("primary", "warmup-clock");
            }}
          >
            {formatMessage({
              id: "clock.warmup",
              defaultMessage: "Warm up",
            })}
          </span>
        )}
        {(period.periodStatus === "pending" || period.periodStatus === "warm_up") && needCentrePassEvent && (
          <span className="start-button" onClick={(e) => updateState("primary", "centre-pass")}>
            {formatMessage({
              id: "clock.centrePass",
              defaultMessage: "Centre Pass",
            })}
          </span>
        )}
        {!clockRunning && period && period.periodStatus === "ended" && (
          <span className="stop-button" onClick={() => setShowConfirmAction("nextPeriod")}>
            {formatMessage({
              id: "clock.end.period",
              defaultMessage: "End Period",
            })}
          </span>
        )}
        {!clockRunning && period && period.periodStatus === "periodEnd" && (
          <span className="stop-button" onClick={(e) => setClockRunning(false, "confirmed")}>
            {formatMessage({
              id: "clock.confirm.period",
              defaultMessage: "Confirm Period",
            })}
          </span>
        )}
        {!clockRunning && period && period.periodStatus === "pendingExtra" && (
          <div className="extra-time-container">
            {(sport === "basketball" || sport === "handball") && (
              <span className="next-button" onClick={() => setShowConfirmAction("nextPeriodExtra")}>
                {formatMessage({
                  id: "clock.continue.period",
                  defaultMessage: "Next Period",
                })}
              </span>
            )}
            {(sport === "ice3" || sport === "hockey" || sport === "handball") && (
              <span className="next-button" onClick={() => setShowConfirmAction("shootOut")}>
                {formatMessage({
                  id: "clock.shootout",
                  defaultMessage: "Shoot-out",
                })}
              </span>
            )}
            <span className="stop-button" onClick={() => setShowConfirmAction("fixtureEnd")}>
              {formatMessage({
                id: "clock.end.fixture",
                defaultMessage: "End Game",
              })}
            </span>
          </div>
        )}
        {!clockRunning && period && period.periodStatus === "fixtureConfirm" && (
          <span
            className={`stop-button ${isGameConfirmationButtonDisabled(eventStore) ? "disabled" : ""}`}
            onClick={(e) => {
              if (!isGameConfirmationButtonDisabled(eventStore)) {
                setClockRunning(false, "fixtureConfirm");
              }
            }}
          >
            {formatMessage({
              id: "clock.confirm.fixture",
              defaultMessage: "Confirm Game",
            })}
          </span>
        )}
        {!clockRunning && period && period.periodStatus === "fixtureComplete" && (
          <span className="done-button">
            {formatMessage({
              id: "clock.complete.fixture",
              defaultMessage: "Complete",
            })}
          </span>
        )}
      </span>
      {showConfirmAction === "nextPeriod" && (
        <MessagePopup
          text={formatMessage({
            id: "clock.continue.period.confirmationMessage",
            defaultMessage: "End Period? Are you sure?",
          })}
          onConfirm={goToNextPeriod}
          onDecline={() => setShowConfirmAction(null)}
        />
      )}
      {showConfirmAction === "nextPeriodExtra" && (
        <MessagePopup
          text={formatMessage({
            id: "clock.continue.period.confirmationMessage",
            defaultMessage: "End Period? Are you sure?",
          })}
          onConfirm={goToNextExtraPeriod}
          onDecline={() => setShowConfirmAction(null)}
        />
      )}
      {showConfirmAction === "shootOut" && (
        <MessagePopup
          text={formatMessage({
            id: "clock.continue.period.confirmationMessage",
            defaultMessage: "End Period? Are you sure?",
          })}
          onConfirm={goToShootOut}
          onDecline={() => setShowConfirmAction(null)}
        />
      )}
      {showConfirmAction === "fixtureEnd" && (
        <MessagePopup
          text={formatMessage({
            id: "clock.end.fixture.confirmationMessage",
            defaultMessage: "End Match? Are you sure?",
          })}
          onConfirm={endFixture}
          onDecline={() => setShowConfirmAction(null)}
        />
      )}
      {showConfirmAction === "overtimeEnd" && (
        <MessagePopup
          text={formatMessage({
            id: "clock.end.overtime.confirmationMessage",
            defaultMessage: "End Overtime? Are you sure?",
          })}
          onConfirm={endOvertime}
          onDecline={() => setShowConfirmAction(null)}
        />
      )}
    </React.Fragment>
  );
};

export default injectIntl(ClockButtons);
