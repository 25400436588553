import { Redirect, useLocation } from "react-router-dom";
import { useFetch } from "../Base/Hooks/basicFetch";
import Logger from "../Base/Utilities/Logger";

const logger = Logger.getInstance();

const ExternalFixture = ({ currentState }) => {
  const query = new URLSearchParams(useLocation().search);

  const externalSourceType = query.get("externalSourceType");
  const externalFixtureId = query.get("externalFixtureId");

  if (!externalSourceType || !externalFixtureId) {
    return <Redirect to="/fixtures" />;
  }

  const { error, loading, data } = useFetch(
    "/v1/client/fixtures_external_source/" +
      externalSourceType +
      "/" +
      externalFixtureId +
      "/detail",
    { method: "GET", values: null, key: currentState.authKey }
  );

  if (error) {
    logger.log(error);
    console.error(error);
    return <Redirect to="/fixtures" />;
  }

  if (loading || loading === undefined) {
    return null;
  }

  if (!data || !data.data) {
    return <Redirect to="/fixtures" />;
  }

  const { fixtureId } = data.data;

  return <Redirect to={`/details/${fixtureId}`} />;
};

export default ExternalFixture;
