import React from "react";
import { v1 as uuidv1 } from "uuid";
import { checkGameStarted } from "../../../Base/Utilities/Game";
import { lightOrDark } from "../../../Base/Utilities/LightOrDark";
import { clone } from "../../../Base/Utilities/Object";
import { getEventTime, sendEvent } from "../../../Base/Utilities/SendEvent";
import { formatPlayerShortenedName } from "../../../Base/Utilities/Player";

const Ice3Player = (props) => {
  const { player, teamColor, entity, updateState, currentState } = props;

  const isGameStarted = checkGameStarted(props);

  const sendPlayerEvent = () => {
    const timestamp = new Date();
    const clockParts = currentState.clock.displayTime.split(":");
    const msg = {
      class: "sport",
      eventType: "substitution",
      subType: player.active ? "out" : "in",
      entityId: entity.entityId,
      personId: player.personId,
      eventId: uuidv1(),
      playId: uuidv1(),
      periodId: currentState.period.periodId,
      options: {
        goalie: player.position === "G",
        position: player.position,
      },
      timestamp: timestamp.toISOString(),
      eventTime: getEventTime(),
      clock:
        "PT" + parseInt(clockParts[0]) + "M" + parseInt(clockParts[1]) + "S",
    };

    const subAction = {
      action: "sendEvent",
      event: msg,
      type: "custom",
    };
    sendEvent(subAction, props);

    if (currentState.insertStore && currentState.insertStore.length > 0) {
      props.updateState("processInserts", true);
    }
  };

  const updatePlayersState = () => {
    const updatedEntities = clone(props.currentState.entities);

    const teamIndex = updatedEntities.findIndex(
      (oneEntity) => oneEntity.entityId === entity.entityId
    );

    if (teamIndex < -1) {
      console.warn("Team not found");
      return;
    }

    const team = updatedEntities[teamIndex];
    const currentPlayer = team.persons.find(
      (el) => el.personId === player.personId
    );

    if (!currentPlayer) {
      console.warn("Player not found");
      return;
    }

    currentPlayer.active = !currentPlayer.active;

    updateState("entities", updatedEntities);
  };

  const onPlayerClick = () => {
    if (!isGameStarted) {
      return;
    }
    sendPlayerEvent();
    updatePlayersState();
  };

  return (
    <div
      className={`player ${player.active ? "active" : ""} ${lightOrDark(
        teamColor
      )}${!isGameStarted ? " player--disabled" : ""}`}
      style={{
        backgroundColor: player.active ? teamColor : "#3B444C",
      }}
      onClick={onPlayerClick}
    >
      {player.position === "G" ? (
        <div className="player--goalie">
          <div className={`player--goalie-icon ${lightOrDark(teamColor)}`} />
          <div>
            {player.bib}
            {player.captain && " (C)"}
            <div className="player--name">
              {formatPlayerShortenedName(player.name)}
            </div>
          </div>
        </div>
      ) : (
        <>
          {player.bib}
          {player.captain && " (C)"}
          <div className="player--name">
            {formatPlayerShortenedName(player.name)}
          </div>
        </>
      )}
    </div>
  );
};

export default Ice3Player;
