export function convertClock(clock) {
  let clockDisplay = clock.replace("PT", "").replace("S", "");
  let clockParts = clockDisplay.split("M");
  clockDisplay =
    ("0" + clockParts[0]).slice(-2) + ":" + ("0" + clockParts[1]).slice(-2);
  return clockDisplay;
}

export function convertDisplayTimeToDataTime(displayTime) {
  let clockParts = displayTime.split(":");
  return "PT" + parseInt(clockParts[0]) + "M" + parseInt(clockParts[1]) + "S";
}

export function convertEventTimeClock({
  clock,
  periodId,
  fixtureProfile,
  sport,
}) {
  const displayTime = convertClock(clock);
  const { periodLength } = fixtureProfile;

  if (sport !== "handball" || (periodId === 1 || periodId >= fixtureProfile.initialExtraPeriodId)) {
    return displayTime;
  }

  const [minutes, seconds] = displayTime?.split(":");
  return `${parseInt(minutes, 10) + periodLength}:${seconds}`;
}

export function convertToFullDisplayTime({
  clock,
  periodId,
  fixtureProfile,
  sport,
}) {
  const { periodLength } = fixtureProfile;

  if (sport !== "handball" || (periodId === 1 || periodId >= fixtureProfile.initialExtraPeriodId)) {
    return clock;
  }

  const [minutes, seconds] = clock?.split(":");
  return `${parseInt(minutes, 10) - periodLength}:${seconds}`;
}
