
import "./ResultBox.scss";
import { ResultEntry } from "./ResultEntry";

export const ResultBox = (props) => {
  const {data} = props
  return (
    <div className="shootout-resultbox-root">
      {data.map((entry, idx) => (
        <ResultEntry
          key={`res-${idx}`}
          idx={idx + 1}
          status={entry.status}
          bib={entry.bib}
          isSuccess={entry.isSuccess}
        />
      ))}
    </div>
  )
}
