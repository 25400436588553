export const Court = {
  name: "position-select",
  component: "NewPositionSelect",
  layout: "court-map",
  showOn: [
    { state: "fixture.inProgress", operator: "is" },
  ],
  actionGroups: [
    {
      actions: [
        // Show Floating Panel
        { action: "nextState", value: "main-buttons", type: "primary" },
      ],
      conditions: [],
    },
  ],
};
