import { JumpballFromReboundOptions } from "../../Options/JumpballOptions";

export const JumpballFromRebound = {
  name: "jumpball-from-rebound",
  component: "MainPanel",
  layout: "main-panel large align-center padded",
  mode: "visible",
  text: "jumpball.details",
  allowFlag: true,
  allowCancel: false,
  allowBack: { state: "primary", value: "shot-rebound", event: "main" },
  showOn: [{ state: "primary", value: "rebound-jumpball", operator: "is" }],
  children: [
    {
      name: "jumpball-from-rebound-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      startEvent: false,
      options: {
        value: "options",
        children: JumpballFromReboundOptions,
        layout: "type-button",
        prefix: "jumpball",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "clearData",
              value: ["personId", "x", "y"],
              type: "main",
            },
            { action: "saveData", value: "jumpBall", type: "eventType" },
            { action: "saveData", value: "won", type: "subType" },
            { action: "saveData", value: "", type: "temp" },
            { action: "copyEvent", value: "jumpball", type: "to" },
          ],
          conditions: [],
        },
        {
          actions: [
            { action: "saveData", value: ["hasPossession"], type: "variables" },
            { action: "saveData", value: "same", type: "temp" },
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "setArrow",
              value: { currentEntity: false },
              type: "main",
              options: { queued: true, event: "clockStart" },
            },
          ],
          conditions: [
            {
              item: "hasPossession",
              value: true,
              event: "main",
              operator: "is",
            },
            {
              item: "hasArrow",
              value: true,
              event: "main",
              operator: "is",
            },
          ],
          operator: "all",
        },
        {
          actions: [
            {
              action: "saveData",
              value: ["notHasPossession"],
              type: "variables",
            },
            { action: "saveData", value: "split", type: "temp" },
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "setPossession",
              value: { currentEntity: true },
              type: "main",
            },
            {
              action: "setArrow",
              value: { currentEntity: false },
              type: "main",
              options: { queued: true, event: "clockStart" },
            },
          ],
          conditions: [
            {
              item: "temp",
              value: "same",
              event: "main",
              operator: "not",
            },
          ],
          operator: "all",
        },
        {
          actions: [
            { action: "saveData", value: "rebound", type: "eventType" },
            { action: "saveData", value: "defensive", type: "subType" },
            {
              action: "clearData",
              value: ["x", "y", "options", "scores", "success", "blocked"],
              type: "main",
            },
          ],
          conditions: [],
        },
        {
          actions: [
            { action: "saveData", value: "offensive", type: "subType" },
          ],
          conditions: [
            {
              item: "entityId",
              value: { type: "shot" },
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "newEventId", value: null, type: "main" },
            {
              action: "saveData",
              value: [{ option: "team", value: true }],
              type: "options",
            },
            { action: "sendEvent", value: "new", type: "main" },
          ],
          conditions: [],
        },
        {
          actions: [
            { action: "saveData", value: "noTeamRebound", type: "temp" },
          ],
          conditions: [
            {
              item: "options",
              value: "contestedRebound",
              event: "jumpball",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "nextState",
              value: "rebound-player-from-jumpball",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "options",
              value: "contestedRebound",
              event: "jumpball",
              operator: "is",
            },
            {
              item: "options",
              value: "heldBall",
              event: "jumpball",
              operator: "is",
            },
          ],
          operator: "any",
        },
        {
          actions: [{ action: "nextState", value: null, type: "primary" }],
          conditions: [
            {
              item: "options",
              value: "contestedRebound",
              event: "jumpball",
              operator: "not",
            },
            {
              item: "options",
              value: "heldBall",
              event: "jumpball",
              operator: "not",
            },
          ],
          operator: "all",
        },
      ],
    },
  ],
};

export const ReboundPlayerFromJumpball = {
  name: "rebound-follow-on",
  component: "MainPanel",
  layout: "main-panel large align-center padded",
  mode: "visible",
  text: "rebound.select-player",
  allowFlag: true,
  allowCancel: false,
  showOn: [
    { state: "primary", value: "rebound-player-from-jumpball", operator: "is" },
  ],
  children: [
    {
      name: "rebound-from-jumpball-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      startEvent: false,
      startPlay: false,
      options: {
        currentEntity: true,
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [
            { action: "clearData", value: ["options"], type: "main" },
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "team-rebound-button",
      component: "TypeButton",
      layout: "match-button wide",
      mode: "visible",
      text: "rebound.team",
      showOn: [
        {
          state: "event.temp",
          value: "noTeamRebound",
          operator: "not",
          event: "main",
        },
      ],
      startEvent: true,
      actionGroups: [
        {
          actions: [{ action: "nextState", value: null, type: "primary" }],
          conditions: [],
        },
      ],
    },
  ],
};
