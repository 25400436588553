export const ShotPrimaryAssistPlayerSelect = {
  name: "shot-primary-assist-player",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "assist.select-primary-player",
  showOn: [{ state: "primary", value: "shot-primary-assist", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "assist-primary-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: true,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: true,
        showBench: false,
        showCoach: false,
        excludeOtherEvents: true,
        excludePlay: true,
        excludeFrom: "main",
      },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "assist", type: "eventType" },
            { action: "saveData", value: "assistPrimary", type: "subType" },
            { action: "saveData", value: true, type: "success" },
            {
              action: "clearData",
              value: ["x", "y", "options", "scores"],
              type: "main",
            },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "newEventId", value: null, type: "main" },
            {
              action: "nextState",
              value: "shot-secondary-assist",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "no-primary-assist",
      component: "TypeButton",
      layout: "match-button center",
      mode: "enable",
      text: "assist.none",
      showOn: [true],
      actionGroups: [
        {
          actions: [{ action: "nextState", value: null, type: "primary" }],
          conditions: [],
        },
      ],
    },
  ],
};

export const ShotSecondaryAssistPlayerSelect = {
  name: "shot-secondary-assist-player",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "assist.select-secondary-player",
  showOn: [
    { state: "primary", value: "shot-secondary-assist", operator: "is" },
  ],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "assist-secondary-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: true,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: true,
        showBench: false,
        showCoach: false,
        excludeOtherEvents: true,
        excludePlay: true,
        excludeFrom: "main",
      },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "assist", type: "eventType" },
            { action: "saveData", value: "assistSecondary", type: "subType" },
            { action: "saveData", value: true, type: "success" },
            {
              action: "clearData",
              value: ["x", "y", "options", "scores"],
              type: "main",
            },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "no-secondary-assist",
      component: "TypeButton",
      layout: "match-button center",
      mode: "enable",
      text: "assist.noneSecondary",
      showOn: [true],
      actionGroups: [
        {
          actions: [{ action: "nextState", value: null, type: "primary" }],
          conditions: [],
        },
      ],
    },
  ],
};
