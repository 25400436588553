import React, { useState, useEffect, useContext } from "react";
import { injectIntl } from "react-intl";
import { FixtureDetailsContext } from "../../../../../Config/FixtureDetails";
import { lightOrDark } from "../../../../Base/Utilities/LightOrDark";

const PlayerStats = (props) => {
  const { currentState, team, teamIndex, setIsStatsVisible, intl } = props;
  const { formatMessage } = intl;
  const [activePlayers, setActivePlayers] = useState();
  const [currentEntity, setCurrentEntity] = useState(null);
  const [persons, setPersons] = useState();
  const {
    fixtureProfile: { maximumPlayersOnCourt, maxFoulsPersonal },
  } = useContext(FixtureDetailsContext);

  useEffect(() => {
    setCurrentEntity(
      currentState.entities[
        currentState.entities.findIndex((el) => el.entityId === team)
      ]
    );
    if (currentState.statistics[team]) {
      setPersons(currentState.statistics[team].persons);
    }
    // eslint-disable-next-line
  }, [team]);

  useEffect(() => {
    if (currentEntity) {
      setActivePlayers(
        <React.Fragment>
          {currentEntity.persons
            .sort((a, b) => (parseInt(a.bib) < parseInt(b.bib) ? -1 : 1))
            .map((player) => {
              let foulCountClass = "fouls";
              if (
                persons[player.personId].foulsTotal ===
                maxFoulsPersonal - 1
              ) {
                foulCountClass = `${foulCountClass} fouls-close-to-max`;
              }
              if (persons[player.personId].foulsTotal >= maxFoulsPersonal) {
                foulCountClass = `${foulCountClass} fouls-at-max`;
              }
              return (
                <div
                  className={"player-stats-row active-" + player.active}
                  key={player.personId}
                >
                  <span
                    className={"bib " + lightOrDark(currentEntity.primaryColor)}
                    style={
                      player.active
                        ? {
                            borderColor: currentEntity.primaryColor,
                            backgroundColor: currentEntity.primaryColor,
                          }
                        : {
                            borderColor: "#aaa",
                            backgroundColor: " rgb(223 223 223)",
                          }
                    }
                  >
                    {player.bib}
                  </span>
                  <span className="name">{player.name}</span>
                  <span className="points">
                    {persons[player.personId] &&
                      persons[player.personId].points}
                  </span>
                  <span className={foulCountClass}>
                    {persons[player.personId].foulsTotal}
                  </span>
                </div>
              );
            })}
          {[
            ...Array(
              maximumPlayersOnCourt -
                currentEntity.persons.filter((person) => person.active === true)
                  .length >
                -1
                ? maximumPlayersOnCourt -
                    currentEntity.persons.filter(
                      (person) => person.active === true
                    ).length
                : 0
            ),
          ].map((_, i) => (
            <div
              key={i}
              className="player-stat-row active-false"
              style={{
                borderColor: "transparent",
              }}
            >
              &nbsp;
            </div>
          ))}
        </React.Fragment>
      );
    }
    // eslint-disable-next-line
  }, [currentEntity]);

  return (
    <div className={"team-" + teamIndex + " player-stats"}>
      <div className={"player-stats-row active-false header"}>
        <span className="cancel" onClick={(e) => setIsStatsVisible(null)}>
          <i className="fas fa-times"></i>
        </span>
        <span className="name">
          {formatMessage({
            id: "person.playerName",
            defaultMessage: "Name",
          })}
        </span>
        <span className="points">
          {formatMessage({
            id: "points.abbreviation",
            defaultMessage: "Pts",
          })}
        </span>
        <span className="fouls">
          {formatMessage({
            id: "fouls.abbreviation",
            defaultMessage: "Fouls",
          })}
        </span>
      </div>
      {activePlayers}
    </div>
  );
};

export default injectIntl(PlayerStats);
