export var And1FoulPlayer = {
  name: "and1-foul-player",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "foul.select-player",
  showOn: [{ state: "primary", value: "and1-foul-player", operator: "is" }],
  allowFlag: true,
  allowCancel: false,
  showDetails: { events: ["shot"] },
  children: [
    // Select Player
    {
      name: "block-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      startEvent: false,
      startPlay: false,
      options: {
        currentEntity: true,
        showBench: false,
        showCoach: false,
        currentEntityEvent: "foul",
      },
      actionGroups: [
        {
          actions: [
            { action: "copyEvent", value: "temp", type: "to" },
            { action: "copyEvent", value: "foul", type: "from" },
            { action: "copyData", type: "temp", value: "personId" },
            { action: "saveData", value: "personal", type: "subType" },
            {
              action: "saveData",
              value: [
                { option: "freeThrows", value: 1 },
                { option: "shooting", value: true },
              ],
              type: "options",
            },
            { action: "copyEvent", value: "foul", type: "to" },
            { action: "sendEvent", value: "edit", type: "main" },
            { action: "clearEvent", value: "fouldrawn", type: "main" },
            { action: "clearEvent", value: "freethrow", type: "main" },
            {
              action: "adjustTeamValue",
              value: 1,
              options: { currentEntity: true, item: "fouls" },
            },
            { action: "newEventId", value: null, type: "main" },
            { action: "copyData", type: "shot", value: "entityId" },
            { action: "copyData", type: "shot", value: "personId" },
            {
              action: "clearData",
              value: ["options", "subType", "scores", "success"],
              type: "main",
            },
            { action: "saveData", value: "foul", type: "eventType" },
            { action: "saveData", value: "drawn", type: "subType" },
            {
              action: "saveData",
              value: [{ option: "freeThrows", value: 1 }],
              type: "options",
            },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "copyEvent", value: "fouldrawn", type: "to" },
          ],
          conditions: [],
        },
        {
          actions: [
            { action: "newEvent", value: null, type: "main" },
            { action: "saveData", value: "freeThrow", type: "eventType" },
            { action: "saveData", value: "awarded", type: "subType" },
            { action: "copyData", value: "entityId", type: "shot" },
            { action: "copyData", value: "personId", type: "shot" },
            { action: "saveData", value: true, type: "success" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [
            {
              item: "options",
              value: "and1InPP",
              event: "shot",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "nextState",
              value: "free-throws",
              type: "secondary",
            },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [
            {
              item: "options",
              value: "and1InPP",
              event: "shot",
              operator: "not",
            },
          ],
        },
      ],
    },
  ],
};
//   // Foul Player Select
//   {
//     name: "and1-foul-player",
//     component: "MainPanel",
//     layout: "main-panel large",
//     mode: "visible",
//     text: "foul.select-player",
//     showOn: [{ state: "primary", value: "and1-foul-player", operator: "is" }],
//     allowFlag: true,
//     allowCancel: true,
//     showDetails: { events: ["main"] },
//     children: [
//       {
//         name: "and1-foul-player-select",
//         component: "PlayerSelect",
//         layout: "player-select",
//         mode: "visible",
//         startEvent: true,
//         startPlay: true,
//         showOn: [true],
//         options: {
//           currentEntity: true,
//           showBench: false,
//           showCoach: false,
//         },
//         actionGroups: [
//           {
//             actions: [
//               { action: "debug", value: "main" },
//               //   {
//               //     action: "clearData",
//               //     value: ["options", "subType", "scores", "success"],
//               //     type: "main",
//               //   },
//               { action: "saveData", value: "personal", type: "subType" },
//               {
//                 action: "saveData",
//                 value: [
//                   { option: "freeThrows", value: 1 },
//                   { option: "shooting", value: true },
//                 ],
//                 type: "options",
//               },
//               { action: "sendEvent", value: "new", type: "main" },
//               { action: "copyEvent", value: "foul", type: "to" },
//               //   { action: "clearEvent", value: "fouldrawn", type: "main" },
//               {
//                 action: "adjustTeamValue",
//                 value: 1,
//                 options: { currentEntity: true, item: "fouls" },
//               },
//               { action: "copyEvent", value: "shot", type: "from" },
//               {
//                 action: "clearData",
//                 value: ["options", "subType", "scores", "success"],
//                 type: "main",
//               },
//               { action: "saveData", value: "foul", type: "eventType" },
//               { action: "saveData", value: "drawn", type: "subType" },
//               {
//                 action: "saveData",
//                 value: [{ option: "freeThrows", value: 1 }],
//                 type: "options",
//               },
//               { action: "sendEvent", value: "new", type: "main" },
//               { action: "copyEvent", value: "fouldrawn", type: "to" },
//               {
//                 action: "nextState",
//                 value: "free-throws",
//                 type: "secondary",
//               },
//               { action: "nextState", value: null, type: "primary" },
//             ],
//             conditions: [],
//           },
//         ],
//       },
//     ],
//   };
