import { useContext, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import { sendInternalEvent } from "../Utilities/SendEvent";
import "./OpenBetting.scss";

const OpenBetting = ({ currentState, intl: { formatMessage }, location }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showConfirmOpen, setShowConfirmOpen] = useState(false);
  const { fixtureProfile } = useContext(FixtureDetailsContext);

  const handleClick = () => {
    const nextOpenState = !isOpen;
    if (nextOpenState) {
      setShowConfirmOpen(nextOpenState);
    } else {
      sendInternalEvent("betStop", currentState);
      setIsOpen(false);
    }
  };

  const handleConfirmOpen = () => {
    sendInternalEvent("betStart", currentState);
    setIsOpen(true);
    setShowConfirmOpen(false);
  };

  const handleConfirmCancel = () => {
    setShowConfirmOpen(false);
  };

  useEffect(() => {
    if (currentState?.mqtt?.internalEvents) {
      const starts = currentState.mqtt.internalEvents.filter((e) => e.eventType === "betStart");
      const stops = currentState.mqtt.internalEvents.filter((e) => e.eventType === "betStop");
      const newIsOpen = starts > stops;
      setIsOpen(newIsOpen);
    }
  }, [currentState?.mqtt?.internalEvents]);

  // don't show the button on fixtures page
  if (location.pathname.includes("/fixtures/")) return null;

  // don't show the button if it's disabled through fixture profile
  if (!fixtureProfile?.enableBetting) return null;

  return (
    <>
      <div className={`open-betting open-betting--${isOpen ? "open" : "closed"}`} onClick={handleClick}>
        <span>
          {isOpen
            ? formatMessage({
                id: "betting.open",
                defaultMessage: "Open",
              })
            : formatMessage({
                id: "betting.closed",
                defaultMessage: "Closed",
              })}
        </span>
      </div>
      {showConfirmOpen && (
        <Modal
          className="confirm-open-modal"
          onClose={handleConfirmCancel}
          title={formatMessage({
            id: "betting.open.title",
            defaultMessage: "Confirm OPEN",
          })}
        >
          <span>
            {formatMessage({
              id: "betting.open.description",
              defaultMessage: "Set match to open?",
            })}
          </span>
          <div className="confirm-open-modal-button-row">
            <Button onClick={handleConfirmOpen} variant="success">
              {formatMessage({
                id: "yes",
                defaultMessage: "Yes",
              })}
            </Button>
            <Button onClick={handleConfirmCancel} variant="danger">
              {formatMessage({
                id: "no",
                defaultMessage: "No",
              })}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default withRouter(injectIntl(OpenBetting));
