import React from "react";
import { injectIntl } from "react-intl";
import Logger from "../../Base/Utilities/Logger";
import DownloadLogIcon from "../../../assets/download-log.svg?react";
import "./ReportIssue.scss";

const logger = Logger.getInstance();

function downloadTextFile(name) {
  const aElement = document.createElement("a");

  aElement.href = URL.createObjectURL(
    new Blob([logger.getAllLogs()], {
      type: "text/json",
    })
  );
  aElement.download = name;
  aElement.click();
}

function ReportIssue(props) {
  const { currentState, intl, verboseVariant } = props;
  const { formatMessage } = intl;
  const entities = currentState?.entities;

  if (!entities) {
    return null;
  }

  const onReportIssue = () => {
    const teamNames = entities
      .map((entity) => entity.fullNameLatin ?? entity.nameFullLocal)
      .join(" - ");

    const now = new Date();
    const fileName = `${teamNames} ${now.toLocaleString()}.json`;

    downloadTextFile(fileName);
  };

  return (
    <div
      className="report-issue"
      onClick={onReportIssue}
      title={formatMessage({
        id: "downloadLogs",
        defaultMessage: "Download logs",
      })}
    >
      <DownloadLogIcon width="2.2vh" height="2.2vh" />
      {verboseVariant && (
        <span className="report-issue-text">
          {formatMessage({
            id: "downloadLogs",
            defaultMessage: "Download logs",
          })}
        </span>
      )}
    </div>
  );
}

export default injectIntl(ReportIssue);
