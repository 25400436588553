import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";

import {
  ignoreSubkeyEventTypes,
  includeSuccessEventTypes,
} from "../../../Base/Utilities/EventHelpers";

const EventDetails = (props) => {
  const { currentState, intl, event } = props;
  const { formatMessage } = intl;
  const [detailsText, setDetailsText] = useState();
  const [team, setTeam] = useState();
  const [player, setPlayer] = useState();
  const [staff, setStaff] = useState();

  const [eventDetails, setEventDetails] = useState();

  useEffect(() => {
    if (currentState && currentState.events && currentState.events[event]) {
      setEventDetails(currentState.events[event]);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (eventDetails) {
      setTeam(
        currentState.entities.find(
          (el) => el.entityId === eventDetails.entityId
        )
      );
      let translationkey = "event." + eventDetails.eventType;
      if (eventDetails.subType) {
        translationkey = !ignoreSubkeyEventTypes.includes(
          eventDetails.eventType
        )
          ? translationkey + "." + eventDetails.subType
          : translationkey;
      }
      if (typeof eventDetails.success === "boolean") {
        translationkey = includeSuccessEventTypes.includes(
          eventDetails.eventType
        )
          ? translationkey + "." + eventDetails.success
          : translationkey;
      }

      setDetailsText(translationkey);
    }
    // eslint-disable-next-line
  }, [eventDetails]);

  useEffect(() => {
    if (team) {
      setPlayer(
        team.persons.find((el) => el.personId === eventDetails.personId)
      );
      setStaff(
        team.staff.find((el) => el.personId === eventDetails.personId)
      );
    }
    // eslint-disable-next-line
  }, [team]);

  return (
    <div className="event">
      {detailsText && (
        <span className="event-type">
          <i
            className="fas fa-square"
            style={team && { color: team.primaryColor }}
          ></i>
          {formatMessage({
            id: detailsText,
            defaultMessage: detailsText,
          })}
        </span>
      )}
      {team && <span className="event-team">{team.fullNameLatin}</span>}
      {player && (
        <span className="event-player">
          <span>{player.bib}</span>
          <span>{player.name}</span>
        </span>
      )}
      {staff && (
        <span className="event-player">
          <span>{staff.name}</span>
        </span>
      )}
    </div>
  );
};

export default injectIntl(EventDetails);
