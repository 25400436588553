export const getOfficialRoles = (sport) => {
  if (sport === "basketball") {
    return {
      commissioner: { role: "COMMISSIONER" },
      crewChief: { role: "CREW_CHIEF" },
      doctor: { role: "DOCTOR" },
      groundskeeper: { role: "GROUNDSKEEPER" },
      matchOfficial: { role: "MATCH_OFFICIAL" },
      referee: { role: "REFEREE" },
      refereeAssistant: { role: "REFEREE_ASSISTANT" },
      refereeReserve: { role: "REFEREE_RESERVE" },
      refereeReserveAssistant: { role: "REFEREE_ASSISTANT_RESERVE" },
      scorekeeper: { role: "SCORER" },
      scorekeeperAssistant: { role: "SCORER_ASSISTANT" },
      statistician: { role: "STATISTICIAN" },
      timekeeper: { role: "TIMER" },
      umpire: { role: "UMPIRE" },
      umpire1: { role: "UMPIRE_1" },
      umpire2: { role: "UMPIRE_2" },
      shotClockOperator: { role: "SHOT_CLOCK_OPERATOR" },
    };
  }
  if (sport === "ice3") {
    return {
      referee: { role: "REFEREE" },
    };
  }
  if (sport === "hockey") {
    return {
      mediaOfficer: { role: "MEDIA_OFFICER" },
      umpireReserve: { role: "UMPIRE_RESERVE" },
      technicalOfficial: { role: "TECHNICAL_OFFICIAL" },
      technicalDelegate: { role: "TECHNICAL_DELEGATE" },
      judgeTiming: { role: "JUDGE_TIMING" },
      judgeScoring: { role: "JUDGE_SCORING" },
      umpire1: { role: "UMPIRE_1" },
      umpire2: { role: "UMPIRE_2" },
      umpireVideo: { role: "UMPIRE_VIDEO" },
    };
  }
  if (sport === "handball") {
    return {
      refereeAssistant: { role: "ASSISTANT_REFEREE_1" },
      refereeAssistant2: { role: "ASSISTANT_REFEREE_2" },
      referee: { role: "REFEREE" },
      secretary: { role: "SECRETARY" },
      timekeeper: { role: "TIMEKEEPER" },
      delegate: { role: "DELEGATE" },
      referee_observer: { role: "REFEREE_OBSERVER" },
    };
  }
};
