import { useState, useEffect, useContext } from "react";
import { Tracing } from "../../../App.tracing";
import { APP_API_URL } from "../../../Config/Environment";
import Logger from "../Utilities/Logger";
import { AuthContext } from "../../../Config/Auth";

const logger = Logger.getInstance();

const defaultOpts = { method: "GET", values: null, key: null };

export const useFetch = (input, opts = defaultOpts, refreshFlag = true) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState();
  const [data, setData] = useState(null);
  const { readBody = (body) => body.json() } = opts;
  const { accessToken, refreshToken, updateTokens, refreshAccessToken } =
    useContext(AuthContext);
  const abortController = new AbortController();
  const signal = abortController.signal;

  useEffect(() => {
    if (refreshFlag === true) {
      setError(null);
      const url = `${APP_API_URL}${input}`;
      getData(url, accessToken);
    }
    // eslint-disable-next-line
  }, [refreshFlag]);

  const getData = async function (url, accessKey, firstTry = true) {
    if (input && refreshFlag && accessKey !== null) {
      setLoading(true);
      try {
        const response = await fetch(url, {
          method: opts.method,
          headers: {
            Authorization: "Bearer " + accessKey,
          },
          signal: signal,
          // body: JSON.stringify(opts.values),
        });
        if (response.ok) {
          const body = await readBody(response);
          setData(body);
          setLoading(false);
        } else {
          const body = await readBody(response);
          if (body.error?.code === 401 && firstTry) {
            const refreshData = await refreshAccessToken();
            if (refreshData.ok) {
              getData(url, refreshData.accessToken, false);
            } else {
              setError(body.error);
              setLoading(false);
            }
          } else {
            logger.log("error", body);
            setError(body.error);
            setLoading(false);
          }
        }
      } catch (e) {
        Tracing.capture(e);
        setData(null);
        setError(e);
        console.error("Fetch error", e);
      }
    }
  };

  useEffect(() => {
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  return { error, loading, data };
};
