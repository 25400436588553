import { useEffect } from "react";

const QueudActions = (props) => {
  const { currentState, updateState } = props;

  useEffect(() => {
    if (currentState.queue && currentState.queue.length) {
      switch (currentState.clock.clockRunning) {
        case true:
          currentState.queue
            .filter((el) => el.event === "clockStart")
            .map((event) => {
              _processAction(event.uid, event.entities);
              return true;
            });
          break;
        case false:
          currentState.queue
            .filter((el) => el.event === "clockStop")
            .map((event) => {
              _processAction(event.uid, event.entities);
              return true;
            });
          break;

        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [currentState, props]);

  function _processAction(uid, entities) {
    let tempEntities = JSON.parse(JSON.stringify(props.currentState.entities));
    entities.map((entity) => {
      tempEntities[
        tempEntities.findIndex((el) => el.entityId === entity.entityId)
      ][entity.item] = entity.value;
      return true;
    });
    updateState("entities", tempEntities);

    let queue = currentState.queue;
    queue.splice(
      queue.findIndex((el) => el.uid === uid),
      1
    );
    updateState("queue", queue);
  }

  return null;
};

export default QueudActions;
