import React, { useContext, useEffect, useMemo, useState } from "react";
import { injectIntl } from "react-intl";
import { FixtureDetailsContext } from "../../../../Config/FixtureDetails.jsx";
import {
  getLatestSuspensionEventForPersonId,
  getRelatedClockEventsByPlayId,
  getRemainingTimeFromSuspension,
} from "../../../Base/Utilities/Suspension.js";
import { createBaseEvent } from "../../../Base/Utilities/CreateBaseEvent.js";
import { sendEvent } from "../../../Base/Utilities/SendEvent.js";

const PlayerTile = (props) => {
  const { player, entity, currentState, intl } = props;
  const { formatMessage } = intl;
  const { fixtureProfile, sport } = useContext(FixtureDetailsContext);
  const { mqtt } = currentState;
  const [stopSentStatus, setStopSentStatus] = useState({});

  const suspensionEvent = getLatestSuspensionEventForPersonId({
    entity,
    personId: player?.personId,
    events: mqtt.plays ?? [],
    sport,
  });

  const clockEvents = getRelatedClockEventsByPlayId({
    playId: suspensionEvent?.playId,
    events: mqtt.plays ?? [],
  });

  const remainingTime = getRemainingTimeFromSuspension(
    suspensionEvent && clockEvents.start && !clockEvents.stop
      ? {
          ...suspensionEvent,
          clock: clockEvents.start.clock,
          options: clockEvents.adjust?.options ?? {},
        }
      : null,
    currentState.clock?.displayTime,
    currentState.period.periodId,
    fixtureProfile,
  );

  const sendClockEvent = (subType, playId) => {
    const action = createBaseEvent(currentState);
    const clockParts = currentState.clock.displayTime.split(":");
    action.event.class = "clock";
    action.event.eventType = "suspension";
    action.event.subType = subType;
    action.event.entityId = entity.entityId;
    action.event.personId = player.personId;
    action.event.clock = `PT${parseInt(clockParts[0])}M${parseInt(clockParts[1])}S`;

    if (playId) {
      action.event.playId = playId;
    }

    sendEvent(action, props);
  };

  useEffect(() => {
    const isAlreadySent = stopSentStatus[clockEvents?.start?.eventId];

    if (clockEvents?.start && !clockEvents?.stop && !isAlreadySent && remainingTime <= 1) {
      sendClockEvent("stop", suspensionEvent.playId);
      setStopSentStatus({
        ...stopSentStatus,
        [clockEvents.start.eventId]: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingTime]);

  const staffRole = useMemo(() => {
    if (sport !== "handball") {
      return "";
    }

    if (player.role === undefined) {
      return "";
    }

    return Object.keys(entity.staffState).filter((role) => entity.staffState[role].personId === player.personId)[0];
  }, [player]);

  const renderTile = () => {
    if (remainingTime === -1) {
      return (
        <div className="player-tile__card" onClick={() => sendClockEvent("start", suspensionEvent.playId)}>
          <span className="player-tile__card-text">Start clock</span>
        </div>
      );
    }

    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime - minutes * 60;
    const timeStr = `${minutes}:${String(seconds).padStart(2, "0")}`;
    return (
      <div className="player-tile__card" onClick={() => sendClockEvent("stop", suspensionEvent.playId)}>
        <div className="fas fa-ban" />
        <span className="player-tile__card-text">{timeStr}</span>
      </div>
    );
  };

  return suspensionEvent && !clockEvents?.stop ? (
    <div className="player-tile">
      <div>
        {player.bib}
        {player.captain && " (C)"}
        {staffRole &&
          formatMessage({
            id: `role.abbrev.${staffRole}`,
            defaultMessage: `role.abbrev.${staffRole}`,
          })}
      </div>
      {renderTile()}
    </div>
  ) : null;
};

export default injectIntl(PlayerTile);
