import React, {
  forwardRef,
  useImperativeHandle,
  useReducer,
  useState,
  useEffect,
} from "react";
import { Helmet } from "react-helmet-async";
import { FormattedMessage } from "react-intl";

import FlashMessage from "./FlashMessage";
import PageBlocker from "./PageBlocker";
import { create_UUID } from "../Utilities/UID";
import { scrollToTop } from "../Utilities/ScrollToTop";

import "./PageDisplay.scss";

const PageDisplay = forwardRef((props, ref) => {
  const {
    children,
    title,
    pageTitle,
    error,
    history,
    pageBlocked,
    search,
    reload,
    loading,
  } = props;
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    scrollToTop();
    window.addEventListener("scroll", setScrollPos);
    return () => window.removeEventListener("scroll", setScrollPos);
    // eslint-disable-next-line
  }, []);

  const [flashMessages, dispatch] = useReducer(
    (flashMessages, { type, value }) => {
      switch (type) {
        case "add":
          return [...flashMessages, value];
        case "remove":
          return flashMessages.filter((item) => item.uid !== value);
        default:
          return flashMessages;
      }
    },
    []
  );

  useImperativeHandle(ref, () => ({
    addFlash: (message, type, timeout) => {
      dispatch({
        type: "add",
        value: {
          message: message,
          type: type,
          timeout: timeout,
          isOpen: true,
          uid: create_UUID(),
        },
      });
    },
  }));

  function changeDarkMode(darkMode) {
    localStorage.setItem("darkmode", darkMode);
    props.setDarkMode(darkMode);
  }

  function removeMessage(index) {
    dispatch({ type: "remove", value: index });
  }

  function setScrollPos() {
    setScrollTop(window.scrollY);
  }

  function goBack() {
    history.goBack();
  }

  return (
    <React.Fragment>
      {flashMessages &&
        flashMessages.map((item, index) => (
          <FlashMessage
            key={index}
            item={item}
            index={item.uid}
            color={item.type}
            isOpen={item.isOpen}
            message={item.message}
            removeFlash={removeMessage}
          />
        ))}
      <h2 className={"page-title search-" + search}>
        {title}
        {!title && "-"}
        {typeof reload !== "undefined" && (
          <span
            onClick={() => reload(true)}
            className={"reload-data loading-" + loading}
          >
            <i className="fas fa-sync-alt"></i>
          </span>
        )}
        <span>
          {typeof props.darkmode !== "undefined" && (
            <button
              onClick={() => changeDarkMode(!props.darkmode)}
              className="btn btn-outline-secondary btn-sm float-right"
            >
              {props.darkmode && <i className="fas fa-sun" />}
              {!props.darkmode && <i className="fas fa-moon" />}
            </button>
          )}
          {props.darkmode}
          {typeof props.layout !== "undefined" && (
            <button
              onClick={() => props.setLayout(!props.layout)}
              className="btn btn-outline-secondary btn-sm float-right"
            >
              {!props.layout && <i className="fas fa-th" />}
              {props.layout && <i className="fas fa-grip-vertical" />}
            </button>
          )}
          {history && (
            <button
              onClick={goBack}
              className="btn btn-outline-secondary btn-sm float-right back-button"
            >
              <i className="fas fa-angle-left" />{" "}
              <FormattedMessage
                id="goback"
                defaultMessage="Go Back"
                description="Go Back Button"
              />
            </button>
          )}
        </span>
      </h2>
      <div className="row">
        <div className="col-12 page-content">
          {children}
          <h3 className="page-error">{error?.message}</h3>
          {pageTitle !== null && (
            <Helmet>
              <title>{pageTitle}</title>
            </Helmet>
          )}
        </div>
      </div>
      {scrollTop > 50 && (
        <button onClick={scrollToTop} className="btn-up btn btn-secondary">
          <i className="fas fa-arrow-up" />
        </button>
      )}

      <PageBlocker blocked={pageBlocked} />
    </React.Fragment>
  );
});

export default PageDisplay;
