import { VideoReviewOptions } from "./../subTypes";

export const ModifyVideoReview = {
  name: "video-review",
  component: "MainPanel",
  layout: "main-panel x-large",
  mode: "visible",
  text: "shot.type",
  showOn: [{ state: "editState", value: "video-review", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    {
      name: "video-review-options",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.eventType",
          value: "videoReview",
          operator: "is",
          event: "edit",
        },
      ],
      options: {
        value: "subType",
        children: VideoReviewOptions,
        layout: "type-button",
        type: "edit",
      },
      actionGroups: [],
    },
    {
      name: "confirm-panel",
      component: "SubPanel",
      layout: "sub-panel",
      mode: "visible",
      text: null,
      showOn: [true],
      children: [
        {
          name: "ok-button",
          component: "TypeButton",
          layout: "match-button center action",
          mode: "enable",
          text: "ok",
          showOn: [
            {
              state: "event.subType",
              value: null,
              operator: "not",
              event: "edit",
            },
          ],
          actionGroups: [
            {
              actions: [
                { action: "sendEvent", value: "edit", type: "edit" },
                { action: "nextState", value: null, type: "editState" },
              ],
              conditions: [],
            },
          ],
        },
      ],
    },
  ],
};
