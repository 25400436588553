const createSuspensionClockEvent = () => [
  { action: "copyEvent", value: "parent", type: "to" },
  { action: "newEventId", value: null, type: "main" },
  {
    action: "saveData",
    value: "clock",
    type: "class",
  },
  {
    action: "saveData",
    value: "suspension",
    type: "eventType",
  },
  {
    action: "saveData",
    value: "adjust",
    type: "subType",
  },
  {
    action: "saveData",
    value: [{ option: "value", value: "2" }],
    type: "options",
  },
  { action: "sendEvent", value: "new", type: "main" },
  { action: "copyEvent", value: "card-clock", type: "to" },
  { action: "copyEvent", value: "parent", type: "from" },
];

const startSuspensionClockEvent = () => [
  { action: "copyEvent", value: "parent", type: "to" },
  { action: "copyEvent", value: "card-clock", type: "from" },
  { action: "newEventId", value: null, type: "main" },
  {
    action: "saveData",
    value: "start",
    type: "subType",
  },
  {
    action: "clearData",
    value: ["options"],
    type: "main",
  },
  { action: "sendEvent", value: "new", type: "main" },
  { action: "copyEvent", value: "parent", type: "from" },
];

export const InsertSanctionsType = {
  name: "sanctions-type",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "sanction.type.select",
  allowFlag: true,
  allowCancel: true,
  showOn: [{ state: "editState", value: "sanctions-type-insert", operator: "is" }],
  children: [
    {
      name: "suspension-buttons",
      component: "ButtonPanel",
      layout: "button-group centered",
      mode: "visible",
      showOn: [true],
      children: [
        {
          name: "2min-suspension",
          component: "TypeButton",
          layout: "type-button",
          mode: "enable",
          text: "suspension.2min",
          showOn: [true],
          actionGroups: [
            {
              actions: [
                { action: "copyEvent", value: "parent", type: "from" },
                { action: "newEventId", value: null, type: "main" },
                { action: "clearData", value: ["subType"], type: "main" },
                { action: "saveData", value: "suspension", type: "eventType" },
                { action: "saveData", value: "twoMinutes", type: "subType" },
                { action: "sendEvent", value: "new", type: "main" },

                ...createSuspensionClockEvent(),
                ...startSuspensionClockEvent(),

                { action: "nextState", value: null, type: "editState" },
              ],
            },
          ],
        },
        {
          name: "4min-suspension",
          component: "TypeButton",
          layout: "type-button",
          mode: "enable",
          text: "suspension.4min",
          showOn: [true],
          actionGroups: [
            {
              actions: [
                { action: "copyEvent", value: "parent", type: "from" },
                { action: "newEventId", value: null, type: "main" },
                { action: "clearData", value: ["subType"], type: "main" },
                { action: "saveData", value: "suspension", type: "eventType" },
                { action: "saveData", value: "fourMinutes", type: "subType" },
                { action: "sendEvent", value: "new", type: "main" },

                ...createSuspensionClockEvent(),
                ...startSuspensionClockEvent(),

                { action: "nextState", value: null, type: "editState" },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "card-buttons",
      component: "ButtonPanel",
      layout: "button-group centered",
      mode: "visible",
      showOn: [true],
      children: [
        {
          name: "yellow-card",
          component: "TypeButton",
          layout: "type-button yellowCard",
          mode: "enable",
          text: "yellowCard",
          showOn: [true],
          actionGroups: [
            {
              actions: [
                { action: "copyEvent", value: "parent", type: "from" },
                { action: "newEventId", value: null, type: "main" },
                { action: "clearData", value: ["subType"], type: "main" },
                { action: "saveData", value: "yellowCard", type: "eventType" },
                { action: "sendEvent", value: "new", type: "main" },
                { action: "nextState", value: null, type: "editState" },
              ],
            },
          ],
        },
        {
          name: "red-card",
          component: "TypeButton",
          layout: "type-button redCard",
          mode: "enable",
          text: "redCard",
          showOn: [true],
          actionGroups: [
            {
              actions: [
                { action: "copyEvent", value: "parent", type: "from" },
                { action: "newEventId", value: null, type: "main" },
                { action: "clearData", value: ["subType"], type: "main" },
                { action: "saveData", value: "redCard", type: "eventType" },
                { action: "sendEvent", value: "new", type: "main" },

                { action: "newEvent", value: null, type: "main" },
                { action: "saveData", value: "suspension", type: "eventType" },
                { action: "saveData", value: "twoMinutes", type: "subType" },
                { action: "sendEvent", value: "new", type: "main" },

                ...createSuspensionClockEvent(),
                ...startSuspensionClockEvent(),

                { action: "nextState", value: null, type: "editState" },
              ],
            },
          ],
        },
        {
          name: "blue-card",
          component: "TypeButton",
          layout: "type-button blueCard",
          mode: "enable",
          text: "blueCard",
          showOn: [true],
          actionGroups: [
            {
              actions: [
                { action: "copyEvent", value: "parent", type: "from" },
                { action: "newEventId", value: null, type: "main" },
                { action: "clearData", value: ["subType"], type: "main" },
                { action: "saveData", value: "blueCard", type: "eventType" },
                { action: "sendEvent", value: "new", type: "main" },
                { action: "nextState", value: null, type: "editState" },
              ],
            },
          ],
        },
      ],
    },
  ],
}