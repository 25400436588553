import { DoubleFoulTypes } from "../../subTypes/EliteFoulTypes";

export const DoubleFoulType = {
  name: "double-foul-type",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "foul.type",
  showOn: [{ state: "primary", value: "double-foul-type", operator: "is" }],
  startEvent: false,
  startPlay: false,
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["main"] },
  allowBack: { state: "primary", value: "foul-type", event: "main" },
  children: [
    {
      name: "double-foul-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "subType",
        children: DoubleFoulTypes,
        layout: "type-button",
        prefix: "foul",
      },
      actionGroups: [
        {
          actions: [
            { action: "sendEvent", value: "new", type: "main" },
            { action: "copyEvent", value: "foul", type: "to" },
            {
              action: "nextState",
              value: "double-foul-drawn-player",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
  ],
};

export const DoubleFoulDrawnPlayer = {
  name: "double-foul-drawn-player",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "fouldrawn.select-player",
  allowFlag: true,
  allowCancel: false,
  showDetails: { events: ["foul", "fouldrawn"] },
  showOn: [
    {
      state: "primary",
      value: "double-foul-drawn-player",
      operator: "is",
    },
  ],
  allowBack: {
    state: "primary",
    value: "double-foul-type",
    event: "foul",
  },
  children: [
    {
      name: "double-foul-drawn-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      options: {
        currentEntity: false,
        currentEntityEvent: "foul",
        showBench: false,
        showCoach: false,
      },
      startEvent: false,
      startPlay: false,
      actionGroups: [
        {
          actions: [
            { action: "newEventId", value: null, type: "main" },
            { action: "saveData", value: "foul", type: "eventType" },
            { action: "saveData", value: "drawn", type: "subType" },
            { action: "sendEvent", value: "new", type: "main" },
          ],
          conditions: [],
        },
        // if it's a personal double foul, then don't ask for the second type, it only can be PF + PF
        {
          actions: [
            { action: "newEventId", value: null, type: "main" },
            { action: "saveData", value: "foul", type: "eventType" },
            { action: "saveData", value: "personal", type: "subType" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "copyEvent", value: "foul", type: "from" },
            { action: "newEventId", value: null, type: "main" },
            { action: "saveData", value: "drawn", type: "subType" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "nextState", value: null, type: "primary" },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [
            {
              item: "subType",
              value: "personal",
              event: "foul",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "nextState",
              value: "double-drawn-foul-type",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "subType",
              value: "personal",
              event: "foul",
              operator: "not",
            },
          ],
        },
      ],
    },
  ],
};

export const DoubleFoulDrawnType = {
  name: "double-foul-type",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "foul.type",
  showOn: [
    { state: "primary", value: "double-drawn-foul-type", operator: "is" },
  ],
  startEvent: false,
  startPlay: false,
  allowFlag: true,
  allowCancel: false,
  showDetails: { events: ["main"] },
  allowBack: {
    state: "primary",
    value: "double-foul-drawn-player",
    event: "main",
  },
  children: [
    {
      name: "double-foul-drawn-foul-type-personal",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      startEvent: false,
      startPlay: false,
      options: {
        value: "subType",
        children: ["unsportsmanlike", "disqualifying"],
        layout: "type-button",
        prefix: "foul",
      },
      actionGroups: [
        {
          actions: [
            { action: "newEventId", value: null, type: "main" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "copyEvent", value: "foul", type: "from" },
            { action: "newEventId", value: null, type: "main" },
            { action: "saveData", value: "drawn", type: "subType" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "nextState", value: null, type: "primary" },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
