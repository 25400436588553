export const VideoReviewTeamSelect = {
  name: "video-review-team-select",
  component: "MainPanel",
  layout: "main-panel padded align-center",
  mode: "visible",
  text: "videoReview.teamSelect",
  showOn: [
    { state: "primary", value: "video-review-team-select", operator: "is" },
  ],
  allowFlag: true,
  allowCancel: true,
  children: [
    {
      name: "team-0",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: { team: "name" },
      showOn: [true],
      options: { team: 0 },
      startPlay: true,
      startEvent: false,
      actionGroups: [
        {
          actions: [
            {
              action: "nextState",
              value: "video-review-outcome",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "team-1",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: { team: "name" },
      showOn: [true],
      options: { team: 1 },
      startPlay: true,
      startEvent: false,
      actionGroups: [
        {
          actions: [
            {
              action: "nextState",
              value: "video-review-outcome",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
