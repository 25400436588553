// Free Throws Panel
export var FreeThrows = {
  name: "free-throws",
  component: "MainPanel",
  layout: "main-panel full align-center",
  mode: "visible",
  text: "freethrows",
  allowFlag: true,
  allowCancel: false,
  showDetails: { events: ["foul", "fouldrawn"] },
  showOn: [{ state: "secondary", value: "free-throws", operator: "is" }],
  children: [
    {
      name: "free-throw-panel",
      component: "Freethrows",
      layout: "free-throws full",
      mode: "enable",
      text: null,
      showOn: [true],
      options: {
        showAssists: true,
        currentEntity: false,
        currentEntityEvent: "foul",
        timeoutPanel: "timeout",
        timeoutPanelType: "primary",
      },
      actionGroups: [
        // Personal Foul - last freethrow successfull
        {
          actions: [
            // Switch Poss
            {
              action: "setPossession",
              value: { currentEntity: false },
              type: "main",
            },
            {
              action: "nextState",
              value: null,
              type: "secondary",
            },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [
            {
              item: "subType",
              value: "personal",
              event: "foul",
              operator: "is",
            },
            {
              item: "success",
              value: true,
              event: "main",
              operator: "is",
            },
          ],
          operator: "all",
        },
        {
          // Offensive Foul - last freethrow successfull
          actions: [
            {
              action: "setPossession",
              value: { currentEntity: false },
              type: "main",
            },
            {
              action: "nextState",
              value: null,
              type: "secondary",
            },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [
            {
              item: "subType",
              value: "offensive",
              event: "foul",
              operator: "is",
            },
            {
              item: "success",
              value: true,
              event: "main",
              operator: "is",
            },
          ],
          operator: "all",
        },
        {
          // Personal Foul - last freethrow un-successfull
          actions: [
            {
              action: "clearData",
              value: ["personId"],
            },
            { action: "startClock", value: "start", type: "main" },
            {
              action: "nextState",
              value: "shot-rebound",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "success",
              value: false,
              event: "main",
              operator: "is",
            },
            {
              item: "subType",
              value: "personal",
              event: "foul",
              operator: "is",
            },
            {
              item: "subType",
              value: [
                "benchTechnical",
                "coachDisqualifying",
                "coachTechnical",
                "disqualifying",
                "technical",
                "unsportsmanlike",
              ],
              event: "foul",
              operator: "not",
            },
          ],
          operator: "all",
        },
        {
          actions: [
            {
              action: "clearData",
              value: ["personId"],
            },
            { action: "startClock", value: "start", type: "main" },
            {
              action: "nextState",
              value: "shot-rebound",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "success",
              value: false,
              event: "main",
              operator: "is",
            },
            {
              item: "subType",
              value: "offensive",
              event: "foul",
              operator: "is",
            },
          ],
          operator: "all",
        },
        {
          actions: [
            {
              action: "nextState",
              value: null,
              type: "secondary",
            },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [],
        },
        // Tech / unspo / disq fouls - retain possession
        {
          actions: [
            // Switch Poss
            {
              action: "setPossession",
              value: { currentEntity: true },
              type: "main",
            },
            {
              action: "nextState",
              value: null,
              type: "secondary",
            },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [
            {
              item: "subType",
              value: ["coachDisqualifying", "disqualifying", "unsportsmanlike"],
              event: "foul",
              operator: "is",
            },
            {
              item: "options",
              value: "isCalledFromFT",
              event: "main",
              operator: "not",
            },
          ],
          operator: "all",
        },
        {
          actions: [
            { action: "popStash", type: "main" },
            { action: "clearEvent", value: "freethrow", type: "main" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
