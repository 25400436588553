import { convertClock } from "../ConvertClock";

let lastProcessedEventId = "";

export async function clockCatchup(
  message,
  currentState,
  updateState,
  fixtureProfile
) {
  let tempPeriod = currentState.period ? currentState.period : { periodId: 0 };
  let tempClock = currentState.clock
    ? JSON.parse(JSON.stringify(currentState.clock))
    : {};

  message = message.sort(function (a, b) {
    var aMixed = new Date(a.eventTime.replace("Z", ""));
    var bMixed = new Date(b.eventTime.replace("Z", ""));
    return aMixed < bMixed || a.periodId < b.periodId ? -1 : 1;
  });

  const lastProcessedMessageIndex = lastProcessedEventId
    ? message.findIndex((event) => event.eventId === lastProcessedEventId)
    : 0;

  const messagesToProcess = message.slice(lastProcessedMessageIndex + 1);

  messagesToProcess.forEach((event) => {
    if (event.periodId >= tempPeriod.periodId) {
      tempPeriod.periodId = event.periodId;
    }

    switch (event.eventType) {
      case "period":
        tempPeriod.periodId = event.periodId;
        let periodStatus =
          event.subType === "start" ? "inProgress" : event.subType;
        if (periodStatus !== "pending") {
          // tempPeriod.periodStatus = periodStatus;
          switch (event.subType) {
            case "start":
              tempPeriod.periodStatus = "inProgress";
              break;
            case "end":
              tempPeriod.periodStatus = "periodEnd";
              break;
            case "confirmed":
              if (tempPeriod.periodId >= fixtureProfile.numberOfPeriods) {
                tempPeriod.periodStatus = "pendingExtra";
              } else {
                tempPeriod.periodStatus = "pending";
                tempPeriod.periodId = tempPeriod.periodId + 1;
              }
              break;

            default:
              break;
          }
        }
        // tempPeriod.periodId = event.periodId;
        if (
          event.subType === "pending" &&
          event.periodId > 0
          // &&           event.periodId > tempPeriod.periodId
        ) {
          tempPeriod.periodStatus = "pending";
          if (!event.clock) {
            if (fixtureProfile.clockDirection === "COUNT_DOWN") {
              event.clock =
                event.periodId >= fixtureProfile.initialExtraPeriodId
                  ? "PT" + fixtureProfile.overtimeLength + "M0S"
                  : "PT" + fixtureProfile.periodLength + "M0S";
            } else {
              event.clock = "PT0M0S";
            }
            tempClock.displayTime = convertClock(event.clock);
          }
        }
        break;
      case "fixture":
        switch (event.subType) {
          case "end":
            tempPeriod.periodStatus = "fixtureConfirm";
            break;
          case "confirmed":
            tempPeriod.periodStatus = "fixtureComplete";
            break;
          default:
            break;
        }
        break;
      case "main":
        tempClock.displayTime = convertClock(event.clock);
        if (event.class === "clock") {
          tempClock.displayTime = convertClock(event.clock);
          tempClock.clockRunning = event.subType !== "stop";
        }
        if (fixtureProfile.clockDirection === "COUNT_DOWN") {
          if (tempClock.displayTime === "00:00" && event.subType === "stop") {
            tempPeriod.periodStatus = "ended";
          }
        } else if (fixtureProfile.clockDirection === "COUNT_UP") {
          if (
            tempClock.displayTime === `${fixtureProfile.periodLength}:00` &&
            event.subType === "stop"
          ) {
            tempPeriod.periodStatus = "ended";
          }
        }
        break;
      default:
        break;
    }

    if (event.clock && !currentState.clock?.clockRunning) {
      tempClock = {
        ...tempClock,
        displayTime: convertClock(event.clock),
      };
    }
  });

  if (messagesToProcess.length > 0) {
    updateState("period", tempPeriod);
    updateState("clock", tempClock);
  }

  lastProcessedEventId = message[message.length - 1].eventId;
}
