import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";

import { useIsVisible } from "../../../Base/Hooks/IsVisible";

const PanelText = (props) => {
  const { panel, intl, style } = props;
  const { formatMessage } = intl;
  const [layout, setLayout] = useState();
  const [isVisible, isEnabled] = useIsVisible(panel, props);

  useEffect(() => {
    if (panel && panel.options && panel.options.text) {
      setLayout(
        <div className={panel.options.layout} style={style}>
          {formatMessage({
            id: panel.options.text,
            defaultMessage: panel.options.text,
          })}
        </div>
      );
    }
    // eslint-disable-next-line
  }, [panel, props]);

  return (
    <React.Fragment>
      {isVisible && (
        <div className={panel.layout + " enabled-" + isEnabled}>{layout}</div>
      )}
    </React.Fragment>
  );
};

export default injectIntl(PanelText);
