import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import Cancel from "./Cancel";
import SpeedTest from "./SpeedTest";

import "./DebugPanel.scss";

const DebugPanel = (props) => {
  const { intl, currentState } = props;
  const { formatMessage } = intl;
  const [connectionInfo, setConnectionInfo] = useState(null);
  const [hasInternet, setHasInternet] = useState(false);

  useEffect(() => {
    if (navigator.connection) {
      // console.log(navigator.connection);
      setConnectionInfo(navigator.connection);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!currentState.connected) {
      setHasInternet(false);
    }
    // eslint-disable-next-line
  }, [currentState.connected]);

  function forceReconnect() {
    props.updateState("forceReconnect", true);
    console.log("button force");
  }

  return (
    <React.Fragment>
      {currentState.showInfo && (
        <div className="debug-panel">
          <div>
            <h3>
              {formatMessage({
                id: "debug.heading",
                defaultMessage: "Debug",
              })}
              :
              <div className="panel-extras">
                <Cancel {...props} />
              </div>
            </h3>
            {connectionInfo && (
              <div className="div-50">
                <div className="info-container">
                  <div className="info-title">Network Connection:</div>
                  <div className="info-value">
                    {!currentState.connected && (
                      <i className="fas fa-ban" style={{ color: "red" }}></i>
                    )}
                    {currentState.connected && (
                      <i
                        className="fas fa-check"
                        style={{ color: "green" }}
                      ></i>
                    )}
                  </div>
                </div>
                <div className="info-container">
                  <div className="info-title">Internet Connection:</div>
                  <div className="info-value">
                    {currentState.connected && (
                      <SpeedTest setHasInternet={setHasInternet} />
                    )}
                    {!currentState.connected && (
                      <i className="fas fa-ban" style={{ color: "red" }}></i>
                    )}
                  </div>
                </div>
                <div className="info-container">
                  <div className="info-title">Stream Connected:</div>
                  <div className="info-value">
                    {!currentState.mqttConnected && (
                      <i className="fas fa-ban" style={{ color: "red" }}></i>
                    )}
                    {currentState.mqttConnected && (
                      <i
                        className="fas fa-check"
                        style={{ color: "green" }}
                      ></i>
                    )}
                  </div>
                </div>
                {!currentState.mqttConnected && (
                  <div className="info-container">
                    <div className="info-title">Reconnect:</div>
                    <div className="info-value">
                      <span
                        onClick={(e) => forceReconnect()}
                        className={"reconnect-button enabled-" + hasInternet}
                      >
                        Reconnect
                      </span>
                      {currentState.forceReconnect && (
                        <i className="fas fa-cog fa-spin"></i>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default injectIntl(DebugPanel);
