// Timeout Select

export var TimeoutSelect = {
  name: "timeout",
  component: "MainPanel",
  layout: "main-panel large padded align-center",
  mode: "visible",
  text: "timeout.select",
  allowFlag: true,
  allowCancel: true,
  showOn: [{ state: "primary", value: "timeout", operator: "is" }],
  children: [
    {
      name: "team-0-timeout",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: { team: "name" },
      showOn: [true],
      options: { team: 0 },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "timeOut", type: "eventType" },
            {
              action: "clearData",
              value: ["options", "personId", "subType"],
              type: "main",
            },
            {
              action: "nextState",
              value: "timeout-details",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "official-timeout",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: "official",
      showOn: [true],
      // options: { freeText: "This is free text" },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "official", type: "entityId" },
            { action: "saveData", value: "timeOut", type: "eventType" },
            {
              action: "clearData",
              value: ["options", "personId", "subType"],
              type: "main",
            },
            {
              action: "nextState",
              value: "timeout-details",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "team-1-timeout",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: { team: "name" },
      showOn: [true],
      options: { team: 1 },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "timeOut", type: "eventType" },
            {
              action: "clearData",
              value: ["options", "personId", "subType"],
              type: "main",
            },
            {
              action: "nextState",
              value: "timeout-details",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
