import { TechnicalFaultTypes } from "../Constants";

export const ModifyTechnicalFaultType = {
  name: "technical-fault-type",
  component: "MainPanel",
  layout: "main-panel x-large",
  mode: "visible",
  text: "technicalFault.type",
  showOn: [
    { state: "editState", value: "technical-fault-type", operator: "is" },
  ],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    {
      name: "technical-fault-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "subType",
        children: TechnicalFaultTypes,
        layout: "type-button",
        prefix: "technicalFault",
        type: "edit",
      },
      actionGroups: [
        {
          actions: [
            { action: "sendEvent", value: "edit", type: "edit" },
            { action: "nextState", value: null, type: "editState" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
