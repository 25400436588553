import React, { useContext } from "react";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";
import { getEventTypeEventsForEntity } from "../../Base/Utilities/EventHelpers";
import { getPlayerFromSinBin, getPlayersWithActiveSuspensions } from "../../Base/Utilities/Suspension";

const SinBin = ({ currentState, entity, eventStore, index, intl }) => {
  const { formatMessage } = intl;
  const { fixtureProfile, sport } = useContext(FixtureDetailsContext);

  const getCardCount = (eventName) => {
    return getEventTypeEventsForEntity(eventName, entity.entityId, eventStore, fixtureProfile).filter(
      (ev) => !ev.options?.possible,
    ).length;
  };

  const getPlayersInBinCount = () => {
    if (sport === "hockey") return getPlayerFromSinBin(currentState, eventStore, fixtureProfile).at(index).length;
    if (sport === "handball")
      return getPlayersWithActiveSuspensions(currentState, eventStore, fixtureProfile).at(index).length;
  };

  return (
    <div
      className="teams__sin-bin"
      style={{
        borderColor: entity.primaryColor,
      }}
    >
      <div className="teams__card-box-container">
        {sport === "hockey" && (
          <>
            <div className="teams__card-box teams__card-box--green">{getCardCount("greenCard")}</div>
            <div className="teams__card-box teams__card-box--yellow">{getCardCount("yellowCard")}</div>
            <div className="teams__card-box teams__card-box--red">{getCardCount("redCard")}</div>
          </>
        )}
        {sport === "handball" && (
          <>
            <div className="teams__card-box teams__card-box--yellow">{getCardCount("yellowCard")}</div>
            <div className="teams__card-box teams__card-box--red">{getCardCount("redCard")}</div>
            <div className="teams__card-box teams__card-box--blue">{getCardCount("blueCard")}</div>
          </>
        )}
      </div>
      <span className="teams__sin-bin__text">
        <span>
          {formatMessage({
            id: "suspensions",
            defaultMessage: "Suspensions",
          })}
        </span>
        <span>
          {`${getPlayersInBinCount()} ${formatMessage({
            id: "players",
            defaultMessage: "players",
          })}`}
        </span>
      </span>
    </div>
  );
};

export default SinBin;
