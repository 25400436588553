import * as FoulTypes from "../../subTypes/EliteFoulTypes";

export const CancellationFoulDrawnType = {
  name: "cancellation-foul-drawn-type",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "foul.type",
  showOn: [
    { state: "primary", value: "cancellation-drawn-foul-type", operator: "is" },
  ],
  startEvent: false,
  startPlay: false,
  allowFlag: true,
  allowCancel: false,
  showDetails: { events: ["main"] },
  allowBack: {
    state: "primary",
    value: "cancellation-foul-drawn-player",
    event: "main",
  },
  children: [
    {
      name: "cancellation-foul-drawn-type-technical-player",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.subType",
          value: FoulTypes.TechnicalFoulTypes,
          operator: "is",
          event: "foul",
        },
        {
          state: "event.bench",
          value: false,
          operator: "is",
          event: "main",
        },
        {
          state: "event.coach",
          value: false,
          operator: "is",
          event: "main",
        },
      ],
      options: {
        value: "subType",
        children: ["technical", "throwIn"],
        layout: "type-button",
        prefix: "foul",
      },
      actionGroups: [
        {
          actions: [{ action: "saveData", value: "personal", type: "subType" }],
          conditions: [
            {
              item: "subType",
              value: "throwIn",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "sendEvent", value: "new", type: "main" },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
    // responses to throwIn foul
    {
      name: "cancellation-foul-drawn-type-throwIn-player",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.subType",
          value: "personal",
          operator: "is",
          event: "foul",
        },
        {
          state: "event.bench",
          value: false,
          operator: "is",
          event: "main",
        },
        {
          state: "event.coach",
          value: false,
          operator: "is",
          event: "main",
        },
      ],
      options: {
        value: "subType",
        children: ["technical"],
        layout: "type-button",
        prefix: "foul",
      },
      actionGroups: [
        {
          actions: [
            { action: "sendEvent", value: "new", type: "main" },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
    // DQ and UF
    {
      name: "cancellation-foul-drawn-type-dq-coach",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.subType",
          value: FoulTypes.CancellationDisqualifyingFoulTypes,
          operator: "is",
          event: "foul",
        },
        {
          state: "event.coach",
          value: true,
          operator: "is",
          event: "main",
        },
      ],
      options: {
        value: "subType",
        children: ["coachDisqualifying"],
        layout: "type-button",
        prefix: "foul",
      },
      actionGroups: [
        {
          actions: [
            { action: "clearData", value: ["personId"] },
            { action: "sendEvent", value: "new", type: "main" },
          ],
          conditions: [],
        },
        {
          actions: [
            { action: "copyEvent", value: "foul", type: "from" },
            { action: "newEventId", value: null, type: "main" },
            { action: "saveData", value: "drawn", type: "subType" },
            { action: "sendEvent", value: "new", type: "main" },
          ],
          conditions: [
            {
              item: "coach",
              value: false,
              event: "foul",
              operator: "is",
            },
          ],
        },
        {
          actions: [{ action: "nextState", value: null, type: "primary" }],
          conditions: [],
        },
      ],
    },
    {
      name: "cancellation-foul-drawn-type-dq-player",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.subType",
          value: FoulTypes.CancellationDisqualifyingFoulTypes,
          operator: "is",
          event: "foul",
        },
        {
          state: "event.coach",
          value: false,
          operator: "is",
          event: "main",
        },
      ],
      options: {
        value: "subType",
        children: ["unsportsmanlike", "disqualifying"],
        layout: "type-button",
        prefix: "foul",
      },
      actionGroups: [
        {
          actions: [{ action: "sendEvent", value: "new", type: "main" }],
          conditions: [],
        },
        {
          actions: [
            { action: "copyEvent", value: "foul", type: "from" },
            { action: "newEventId", value: null, type: "main" },
            { action: "saveData", value: "drawn", type: "subType" },
            { action: "sendEvent", value: "new", type: "main" },
          ],
          conditions: [
            {
              item: "coach",
              value: false,
              event: "foul",
              operator: "is",
            },
          ],
        },
        {
          actions: [{ action: "nextState", value: null, type: "primary" }],
          conditions: [],
        },
      ],
    },
  ],
};
