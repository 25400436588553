export const PenaltyFoulPlayer = {
  name: "penalty-foul-player",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "foul.select-player",
  showOn: [{ state: "primary", value: "penalty-foul-player", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "foul-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: true,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: false,
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [
            {
              action: "clearData",
              value: ["options", "scores", "success"],
              type: "main",
            },
            {
              action: "saveData",
              value: "sevenMetrePenalty",
              type: "eventType",
            },
            { action: "saveData", value: "caused", type: "subType" },
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "nextState",
              value: "penalty-suspension",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "card-buttons",
      component: "ButtonPanel",
      layout: "button-group centered",
      mode: "visible",
      showOn: [true],
      children: [
        {
          name: "confirm-later",
          component: "TypeButton",
          layout: "match-button",
          mode: "enable",
          text: "confirmLater",
          showOn: [true],
          startEvent: true,
          startPlay: false,
          actionGroups: [
            {
              actions: [
                { action: "modifyEvent", value: "swapEntities", type: "main" },

                { action: "saveData", value: "caused", type: "subType" },
                { action: "saveData", type: "flagged", value: true },
                {
                  action: "saveData",
                  value: "sevenMetrePenalty",
                  type: "eventType",
                },

                { action: "sendEvent", value: "new", type: "main" },

                {
                  action: "nextState",
                  value: "penalty-suspension",
                  type: "primary",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
