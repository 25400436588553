import { RedCardReasons, YellowCardReasons } from "../subTypes";

export var ModifyCardReason = {
  name: "card-reason",
  component: "MainPanel",
  layout: "main-panel x-large",
  mode: "visible",
  text: "card.reason",
  showOn: [{ state: "editState", value: "card-reason", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    // Yellow card
    {
      name: "card-reasons-yellow",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.eventType",
          value: "yellowCard",
          operator: "is",
          event: "main",
        },
      ],
      options: {
        value: "singleOption",
        optionName: "cardReason",
        children: YellowCardReasons,
        layout: "type-button",
        prefix: "cardReason",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            { action: "nextState", value: null, type: "editState" },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "card-reasons-red",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.eventType",
          value: "redCard",
          operator: "is",
          event: "main",
        },
      ],
      options: {
        value: "singleOption",
        optionName: "cardReason",
        children: RedCardReasons,
        layout: "type-button",
        prefix: "cardReason",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            { action: "nextState", value: null, type: "editState" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
