import React, { useContext, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { FixtureDetailsContext } from "../../../../Config/FixtureDetails";
import { useIsVisible } from "../../../Base/Hooks/IsVisible";
import { useDoActions } from "../../../Base/Hooks/doActions";
import {
  isPowerPlayActive,
  isPowerPlayEnabled,
} from "../../../Base/Utilities/MatchProfile";
import { sendEvent } from "../../../Base/Utilities/SendEvent";

const MadeMissed = (props) => {
  const { team, success, style, panel, updateState, intl, currentState } =
    props;
  const { formatMessage } = intl;
  const [buttonText, setButtonText] = useState(null);
  const [shotType, setShotType] = useState(null);
  const [shotOptions, setShotOptions] = useState({});
  const [isVisible, isEnabled] = useIsVisible(panel, props);
  const [doAction, setDoAction] = useState(false);
  const [actionsDone] = useDoActions(panel, props, doAction);
  const { fixtureProfile } = useContext(FixtureDetailsContext);

  useEffect(() => {
    if (actionsDone) {
      setDoAction(false);

      let currentEvents = currentState.events;

      let lastRebound =
        currentEvents && currentEvents["rebound"]
          ? currentEvents["rebound"]
          : null;

      let lastTurnover =
        currentEvents && currentEvents["turnover"]
          ? currentEvents["turnover"]
          : null;

      let newOptions = shotOptions;

      if (
        lastRebound &&
        lastRebound.entityId === currentState.entities[team].entityId
      ) {
        newOptions["secondChance"] = true;
      }

      if (
        lastTurnover &&
        lastTurnover.entityId !== currentState.entities[team].entityId
      ) {
        newOptions["fromTurnover"] = true;
      }

      if (isPowerPlayEnabled(currentState, fixtureProfile)) {
        newOptions["powerPlay"] = isPowerPlayActive(
          currentState,
          fixtureProfile,
          props.eventStore
        );
      }

      // Remove temp Rebounds and temp turnovers
      if (currentEvents) {
        delete currentEvents.rebound;
        delete currentEvents.turnover;
        updateState("events", currentEvents);
      }

      delete currentEvents["main"].subType;
      delete currentEvents["main"].personId;
      delete currentEvents["main"].flagged;

      currentEvents["main"]["eventType"] = shotType + "pt";
      currentEvents["main"]["entityId"] = currentState.entities[team].entityId;
      currentEvents["main"]["success"] = success === 1 ? true : false;
      currentEvents["main"]["options"] = {};
      if (shotType === "3") {
        delete newOptions["pointsInThePaint"];
      }
      currentEvents["main"]["options"] = newOptions;
      setShotOptions(newOptions);
      updateState("events", currentEvents);

      if (panel.next) {
        updateState("main", panel.next);
      }
      if (panel.options && panel.options.send) {
        let action = {
          action: "sendEvent",
          value: "new",
          type: "main",
        };
        sendEvent(action, props);
      }
    }
    // eslint-disable-next-line
  }, [actionsDone]);

  useEffect(() => {
    let text = "";
    let shot = "";
    if (success === 1) {
      text = "made";
    } else {
      text = "missed";
    }
    if (currentState.region) {
      switch (team) {
        case 0:
          switch (currentState.region) {
            case "P1":
              shot = "2";
              setShotOptions({ pointsInThePaint: true });
              break;
            case "C1":
              shot = "2";
              // setShotOptions({ pointsInThePaint: false });
              break;
            default:
              shot = "3";
              setShotOptions({ pointsInThePaint: false });
              break;
          }
          break;
        case 1:
          switch (currentState.region) {
            case "P2":
              shot = "2";
              setShotOptions({ pointsInThePaint: true });
              break;
            case "C2":
              shot = "2";
              // setShotOptions({ pointsInThePaint: false });
              break;
            default:
              shot = "3";
              setShotOptions({ pointsInThePaint: false });
              break;
          }
          break;
        default:
          break;
      }
      setShotType(shot);
    }
    setButtonText(text);
    // eslint-disable-next-line
  }, [team, props]);

  return (
    <React.Fragment>
      {isVisible && (
        <div
          className={
            panel.layout + " enabled-" + isEnabled + " success-" + success
          }
          style={style}
          onClick={(e) => setDoAction(true)}
        >
          <span>
            {success === 1 ? (
              <i className="fas fa-check"></i>
            ) : (
              <i className="fas fa-times"></i>
            )}
            <span>
              {formatMessage({
                id: buttonText,
                defaultMessage: buttonText,
              })}
              <span className="shot">{shotType}</span>
            </span>
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

export default injectIntl(MadeMissed);
