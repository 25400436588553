import React from "react";
import ReactDOM from "react-dom";
import { Button } from "reactstrap";
import { injectIntl } from "react-intl";

import "./MessagePopup.scss";

const MessagePopup = ({ intl, onConfirm, onDecline, text }) => {
  const node = document.getElementById("modals-container");
  const { formatMessage } = intl;
  return ReactDOM.createPortal(
    <div className="messagePopup--container">
      <div className="messagePopup--content">
        <div>{text}</div>
        <div className="messagePopup--buttons">
          <Button type="submit" color="success" onClick={onConfirm}>
            {formatMessage({
              id: "yes",
              defaultMessage: "Yes",
            })}
          </Button>
          <Button type="submit" color="danger" onClick={onDecline}>
            {formatMessage({
              id: "no",
              defaultMessage: "No",
            })}
          </Button>
        </div>
      </div>
    </div>,
    node
  );
};

export default injectIntl(MessagePopup);
