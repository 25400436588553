import React, { useState } from "react";
import { injectIntl } from "react-intl";
import Button from "../../Base/Button/Button";
import CounterInput from "../../Base/CounterInput/CounterInput";
import Modal from "../../Base/Modal/Modal";
import "./ScoreQuery.scss";

/*
 * entities: Array<IEntity>:
 * onSubmit: (entity1Score: number, entity2Score: number) => void;
 * onClose: () => void;
 *
 */
function ScoreQuery(props) {
  const { onClose, onSubmit, entities, intl } = props;
  const { formatMessage } = intl;
  const [entity1Score, setEntity1Score] = useState(entities[0].score ?? 0);
  const [entity2Score, setEntity2Score] = useState(entities[1].score ?? 0);

  return (
    <Modal shouldTrapFocus title="Score query" onClose={onClose}>
      <h2 className="score-query-title">
        {formatMessage({
          id: "scoreQuery.scoreCorrect",
          defaultMessage: "Is the score below correct?",
        })}
      </h2>
      <div className="score-query-inputs">
        <CounterInput
          value={entity1Score}
          onChange={(value) => setEntity1Score(value)}
          teamName={entities[0].fullNameLatin}
          autoFocus
        />
        <CounterInput
          value={entity2Score}
          onChange={(value) => setEntity2Score(value)}
          teamName={entities[1].fullNameLatin}
          arrowSide="right"
        />
      </div>
      <div className="score-query-button">
        <Button
          onClick={() => onSubmit(entity1Score, entity2Score)}
          variant="danger"
        >
          {formatMessage({
            id: "confirm",
            defaultMessage: "Confirm",
          })}
        </Button>
      </div>
    </Modal>
  );
}

export default injectIntl(ScoreQuery);
