import { useCallback, useEffect, useState } from "react";

export const useIsTabActive = (panel, props) => {
  const [isActive, setIsActive] = useState(true);
  const [lastActive, setLastActive] = useState(Date.now());

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === "hidden") {
      setLastActive(Date.now());
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return [isActive, lastActive];
};
