export var FloatingActionPanel = {
  name: "main-buttons",
  component: "TeamButtons",
  layout: "team-buttons-base",
  mode: "visible",
  // showForInsert: true,
  showOn: [
    { state: "region", value: null, operator: "not" },
    { state: "primary", value: "main-buttons", operator: "is" },
    // {
    //   state: "period.periodStatus",
    //   value: "fixtureComplete",
    //   operator: "not",
    // },
  ],
  startEvent: false,
  startPlay: false,
  children: [
    // Made Shot Button
    {
      name: "made-button",
      component: "MadeMissed",
      layout: "team-button",
      text: "made",
      mode: "enable",
      showForInsert: true,
      showOn: [
        {
          state: "period.periodStatus",
          value: "inProgress",
          operator: "is",
        },
      ],
      options: { send: "new", success: 1 },
      actionGroups: [
        {
          actions: [
            { action: "clearEvent", value: "foul", type: "main" },
            { action: "clearEvent", value: "shot", type: "main" },
            // Set initial Event Data
            {
              action: "saveData",
              value: ["x", "y", "periodId", "clock", "eventTime", "scores"],
              type: "variables",
            },
            // Set new panel
            { action: "nextState", value: "shot-player", type: "primary" },
          ],
          conditions: [],
        },
      ],
      startEvent: false,
      softStartEvent: true,
      startPlay: true,
    },
    // Missed Button
    {
      name: "missed-button",
      component: "MadeMissed",
      layout: "team-button",
      mode: "enable",
      text: "missed-shot",
      showForInsert: true,
      showOn: [
        {
          state: "period.periodStatus",
          value: "inProgress",
          operator: "is",
        },
      ],
      options: { success: 0 },
      actionGroups: [
        {
          actions: [
            { action: "clearEvent", value: "shot", type: "main" },
            // Set initial Event Data
            {
              action: "saveData",
              value: ["x", "y", "periodId", "clock", "eventTime", "scores"],
              type: "variables",
            },
            { action: "nextState", value: "shot-player", type: "primary" },
          ],
          conditions: [],
        },
      ],
      startEvent: false,
      softStartEvent: true,
      startPlay: true,
    },
    // Foul Button
    {
      name: "foul-button",
      component: "TypeButton",
      layout: "team-button",
      mode: "enable",
      text: "foul",
      showForInsert: true,
      showOn: [
        {
          state: "period.periodStatus",
          value: "fixtureComplete",
          operator: "not",
        },
      ],
      actionGroups: [
        {
          actions: [
            { action: "stopClock", value: "stop", type: "main" },
            { action: "clearEvent", value: "shot", type: "main" },
            { action: "saveData", value: "foul", type: "eventType" },
            {
              action: "saveData",
              value: ["x", "y", "periodId", "clock", "eventTime", "scores"],
              type: "variables",
            },
            { action: "clearData", value: ["subType"], type: "main" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "nextState", value: "foul-player", type: "primary" },
          ],
          conditions: [],
        },
      ],
      startEvent: false,
      softStartEvent: true,
      startPlay: true,
    },
    // Turnover Button
    {
      name: "turnover-button",
      component: "TypeButton",
      layout: "team-button",
      mode: "enable",
      text: "turnover",
      showForInsert: true,
      showOn: [
        {
          state: "period.periodStatus",
          value: "inProgress",
          operator: "is",
        },
      ],
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "turnover", type: "eventType" },
            {
              action: "saveData",
              value: ["x", "y", "periodId", "clock", "eventTime"],
              type: "variables",
            },
            {
              action: "saveData",
              value: [{ option: "isDefensive", value: false }],
              type: "options",
            },
          ],
          conditions: [],
        },
        {
          actions: [
            {
              action: "saveData",
              value: [{ option: "isDefensive", value: true }],
              type: "options",
            },
            {
              action: "setPossession",
              value: { currentEntity: false },
              type: "main",
            },
          ],
          conditions: [
            {
              item: "hasPossession",
              value: true,
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "nextState",
              value: "turnover-player",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
      startEvent: false,
      softStartEvent: true,
      startPlay: true,
    },
  ],
  actionGroups: [
    {
      actions: [{ action: "nextState", value: null, type: "primary" }],
    },
  ],
};
