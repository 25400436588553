export var FreeThrowSuccess = {
  name: "free_throw_success",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "edit.shot.success",
  showOn: [{ state: "editState", value: "free-throw-success", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    {
      name: "free-throw-change-to-missed",
      component: "PanelText",
      layout: "panel-text",
      mode: "visible",
      showOn: [
        {
          state: "event.success",
          value: true,
          operator: "is",
          event: "main",
        },
      ],
      options: {
        layout: "description-text",
        text: "freethrow.change.to.missed",
      },
      actionGroups: [],
    },
    {
      name: "freethrow-missed",
      component: "ButtonGroup",
      layout: "button-group true-false",
      mode: "visible",
      showOn: [
        {
          state: "event.success",
          value: true,
          operator: "is",
          event: "main",
        },
      ],
      startPlay: false,
      options: {
        value: "success",
        children: [false],
        layout: "type-button",
        prefix: "edit.shot.made.success",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            {
              action: "copyEvent",
              value: "shot",
              type: "to",
            },
            {
              action: "adjustTeamValue",
              value: -1,
              options: { currentEntity: true, item: "score" },
            },
          ],
          conditions: [],
        },
        {
          actions: [
            {
              action: "nextState",
              value: "shot-rebound-insert",
              type: "editState",
            },
          ],
          conditions: [
            {
              item: "subType",
              value: ["1Of1", "2Of2", "3Of3"],
              event: "shot",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "deleteFromPlay",
              value: "assist",
              type: "shot",
            },
          ],
          conditions: [
            {
              item: "anySuccess",
              value: "freeThrow",
              event: "shot",
              operator: "not",
            },
          ],
        },
        {
          actions: [
            {
              action: "nextState",
              value: null,
              type: "editState",
            },
          ],
          conditions: [
            {
              item: "subType",
              value: ["1Of1", "2Of2", "3Of3"],
              event: "shot",
              operator: "not",
            },
          ],
        },
      ],
    },
    {
      name: "free-throw-change-to-missed",
      component: "PanelText",
      layout: "panel-text",
      mode: "visible",
      showOn: [
        {
          state: "event.success",
          value: false,
          operator: "is",
          event: "main",
        },
      ],
      options: {
        layout: "description-text",
        text: "freethrow.change.to.made",
      },
      actionGroups: [],
    },
    {
      name: "freethrow-missed",
      component: "ButtonGroup",
      layout: "button-group true-false",
      mode: "visible",
      showOn: [
        {
          state: "event.success",
          value: false,
          operator: "is",
          event: "main",
        },
      ],
      startPlay: false,
      options: {
        value: "success",
        children: [true],
        layout: "type-button",
        prefix: "edit.shot.made.success",
      },
      actionGroups: [
        {
          actions: [
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            {
              action: "copyEvent",
              value: "shot",
              type: "to",
            },
            {
              action: "nextState",
              value: null,
              type: "editState",
            },
            {
              action: "adjustTeamValue",
              value: 1,
              options: { currentEntity: true, item: "score" },
            },
          ],
          conditions: [],
        },
        {
          actions: [
            {
              action: "deleteFromPlay",
              value: "rebound",
              type: "shot",
            },
            // { action: "alert", value: "missed to made - delete rebound" },
          ],
          conditions: [
            {
              item: "subType",
              value: ["1Of1", "2Of2", "3Of3"],
              event: "main",
              operator: "is",
            },
          ],
        },
        // {
        //   actions: [
        //     {
        //       action: "nextState",
        //       value: null,
        //       type: "editState",
        //     },
        //   ],
        //   conditions: [
        //     {
        //       item: "event.subType",
        //       value: ["1Of2", "1Of3", "2Of3"],
        //       event: "main",
        //       operator: "is",
        //     },
        //   ],
        // },
      ],
    },
  ],
};
