import { useRef } from "react";
import { useOnClickOutside } from "../../../Base/Hooks/useOnClickOutside";
import "./EventLogDropdown.scss";

const EventLogDropdown = (props) => {
  const {
    show,
    children,
    onOutsideClick = () => {},
    bridgeStyle,
    containerStyle,
    contentStyle,
  } = props;

  const container = useRef(null);

  useOnClickOutside(container, onOutsideClick);

  return (
    <div
      ref={container}
      className="event-log-dropdown-root"
      style={containerStyle}
    >
      <div className="event-log-dropdown-bridge" style={bridgeStyle} />
      <div className="event-log-dropdown-content" style={contentStyle} >{children}</div>
    </div>
  );
};

export default EventLogDropdown;
