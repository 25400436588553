// Rebound Player /  shot clock
export var ReboundPlayer = {
  name: "shot-rebound",
  component: "MainPanel",
  layout: "main-panel x-large align-center",
  mode: "visible",
  text: "rebound.select-player",
  showOn: [{ state: "primary", value: "shot-rebound", operator: "is" }],
  // allowBack: { state: "primary", value: "shot-type", event: "shot" },
  allowFlag: true,
  allowCancel: false,
  showDetails: { events: ["shot", "block"] },
  children: [
    // Left Team
    {
      name: "rebound-player-select-team-0",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      startEvent: true,
      startPlay: false,
      options: {
        team: 0,
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "rebound", type: "eventType" },
            { action: "saveData", value: "defensive", type: "subType" },
            {
              action: "clearData",
              value: ["x", "y", "options", "scores", "success", "blocked"],
              type: "main",
            },
          ],
          conditions: [],
        },
        {
          actions: [
            {
              action: "saveData",
              value: "offensive",
              type: "subType",
            },
          ],
          conditions: [
            {
              item: "entityId",
              value: { type: "shot" },
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [{ action: "sendEvent", value: "new", type: "main" }],
          conditions: [],
        },
        {
          actions: [
            {
              action: "clearData",
              value: ["flagged"],
              type: "main",
            },
            {
              action: "copyEvent",
              value: "rebound",
              type: "to",
            },
            { action: "clearEvent", value: "turnover", type: "main" },
            {
              action: "nextState",
              value: "rebound-follow-on",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "subType",
              value: "offensive",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [{ action: "sendEvent", value: "new", type: "main" }],
          conditions: [],
        },
        {
          actions: [
            {
              action: "setPossession",
              value: { currentEntity: true },
              type: "main",
            },
          ],
          conditions: [
            {
              item: "entityId",
              value: { type: "shot" },
              event: "main",
              operator: "not",
            },
          ],
        },
        {
          actions: [
            { action: "clearEvent", value: "turnover", type: "main" },
            {
              action: "nextState",
              value: null,
              type: "primary",
            },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [
            {
              item: "subType",
              value: "defensive",
              event: "main",
              operator: "is",
            },
          ],
        },
      ],
    },
    // Right Team
    {
      name: "rebound-player-select-team-1",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      startEvent: true,
      startPlay: false,
      options: {
        team: 1,
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "rebound", type: "eventType" },
            { action: "saveData", value: "defensive", type: "subType" },
            {
              action: "clearData",
              value: ["x", "y", "options", "scores", "success", "blocked"],
              type: "main",
            },
          ],
          conditions: [],
        },
        {
          actions: [
            {
              action: "saveData",
              value: "offensive",
              type: "subType",
            },
          ],
          conditions: [
            {
              item: "entityId",
              value: { type: "shot" },
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [{ action: "sendEvent", value: "new", type: "main" }],
          conditions: [],
        },
        {
          actions: [
            {
              action: "setPossession",
              value: { currentEntity: true },
              type: "main",
            },
          ],
          conditions: [
            {
              item: "entityId",
              value: { type: "shot" },
              event: "main",
              operator: "not",
            },
          ],
        },
        {
          actions: [
            {
              action: "clearData",
              value: ["flagged"],
              type: "main",
            },
            {
              action: "copyEvent",
              value: "rebound",
              type: "to",
            },
            { action: "clearEvent", value: "turnover", type: "main" },
            {
              action: "nextState",
              value: "rebound-follow-on",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "subType",
              value: "offensive",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "clearEvent", value: "turnover", type: "main" },
            {
              action: "nextState",
              value: null,
              type: "primary",
            },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [
            {
              item: "subType",
              value: "defensive",
              event: "main",
              operator: "is",
            },
          ],
        },
      ],
    },
    // Team Rebounds
    {
      name: "team-rebounds-panel",
      component: "SubPanel",
      layout: "sub-panel align-center",
      mode: "visible",
      text: null,
      showOn: [true],
      children: [
        // Team 0 Button
        {
          name: "team-0-button",
          component: "TypeButton",
          layout: "match-button wide team  team-0",
          mode: "enable",
          text: "rebound.team",
          options: { team: 0 },
          showOn: [true],
          startEvent: true,
          actionGroups: [
            {
              actions: [
                { action: "saveData", value: "rebound", type: "eventType" },
                {
                  action: "clearData",
                  value: ["personId", "x", "y", "success", "options", "scores", "blocked"],
                  type: "main",
                },
              ],
              conditions: [],
            },
            {
              actions: [
                {
                  action: "saveData",
                  value: "offensive",
                  type: "subType",
                },
              ],
              conditions: [
                {
                  item: "entityId",
                  value: { type: "shot" },
                  event: "main",
                  operator: "is",
                },
              ],
            },
            {
              actions: [
                {
                  action: "saveData",
                  value: "defensive",
                  type: "subType",
                },
              ],
              conditions: [
                {
                  item: "entityId",
                  value: { type: "shot" },
                  event: "main",
                  operator: "not",
                },
              ],
            },
            {
              actions: [
                {
                  action: "saveData",
                  value: [{ option: "team", value: true }],
                  type: "options",
                },
                { action: "sendEvent", value: "new", type: "main" },
              ],
              conditions: [],
            },
            {
              actions: [
                {
                  action: "setPossession",
                  value: { currentEntity: true },
                  type: "main",
                },
              ],
              conditions: [
                {
                  item: "entityId",
                  value: { type: "shot" },
                  event: "main",
                  operator: "not",
                },
              ],
            },
            {
              actions: [
                { action: "clearEvent", value: "turnover", type: "main" },
                { action: "clearData", value: ["options"], type: "main" },
                { action: "nextState", value: null, type: "primary" },
                { action: "processInserts", value: "all", type: "inserts" },
              ],
              conditions: [],
            },
          ],
          actions: [],
        },
        // Team 1 Button
        {
          name: "team-1-button",
          component: "TypeButton",
          layout: "match-button wide team team-1",
          mode: "enable",
          text: "rebound.team",
          options: { team: 1 },
          showOn: [true],
          startEvent: true,
          actionGroups: [
            {
              actions: [
                { action: "saveData", value: "rebound", type: "eventType" },
                {
                  action: "clearData",
                  value: ["personId", "x", "y", "success", "options", "scores", "blocked"],
                  type: "main",
                },
              ],
              conditions: [],
            },
            {
              actions: [
                {
                  action: "saveData",
                  value: "offensive",
                  type: "subType",
                },
              ],
              conditions: [
                {
                  item: "entityId",
                  value: { type: "shot" },
                  event: "main",
                  operator: "is",
                },
              ],
            },
            {
              actions: [
                {
                  action: "saveData",
                  value: "defensive",
                  type: "subType",
                },
              ],
              conditions: [
                {
                  item: "entityId",
                  value: { type: "shot" },
                  event: "main",
                  operator: "not",
                },
              ],
            },
            {
              actions: [
                {
                  action: "saveData",
                  value: [{ option: "team", value: true }],
                  type: "options",
                },
                { action: "sendEvent", value: "new", type: "main" },
              ],
              conditions: [],
            },
            {
              actions: [
                {
                  action: "setPossession",
                  value: { currentEntity: true },
                  type: "main",
                },
              ],
              conditions: [
                {
                  item: "entityId",
                  value: { type: "shot" },
                  event: "main",
                  operator: "not",
                },
              ],
            },
            {
              actions: [
                { action: "clearEvent", value: "turnover", type: "main" },
                { action: "clearData", value: ["options"], type: "main" },
                { action: "nextState", value: null, type: "primary" },
                { action: "processInserts", value: "all", type: "inserts" },
              ],
              conditions: [],
            },
          ],
        },
      ],
    },
    // Block Button
    {
      name: "block-button",
      component: "TypeButton",
      layout: "match-button narrow",
      mode: "enable",
      text: "block",
      showOn: [
        {
          state: "eventOptions.blocked",
          value: true,
          operator: "not",
          event: "shot",
        },
        {
          state: "event.eventType",
          value: "freeThrow",
          operator: "not",
          event: "main",
        },
      ],
      actionGroups: [
        {
          actions: [
            {
              action: "saveData",
              value: [{ option: "blocked", value: true }],
              type: "options",
            },
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "clearData",
              value: ["personId"],
              type: "main",
            },
            {
              action: "clearEvent",
              value: "block",
              type: "main",
            },
            {
              action: "nextState",
              value: "shot-block",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },

    // Shot clock violation
    {
      name: "shotclock-violation",
      component: "TypeButton",
      layout: "match-button narrow",
      mode: "enable",
      text: "shotclock.violation",
      showOn: [true],
      startEvent: true,
      startPlay: false,
      actionGroups: [
        {
          actions: [
            { action: "stopClock", value: "stop", type: "main" },
            {
              action: "copyData",
              value: "entityId",
              type: "shot",
            },
            {
              action: "clearData",
              value: ["personId", "scores", "x", "y", "options", "success", "blocked"],
              type: "main",
            },
            {
              action: "saveData",
              value: ["clock", "eventTime"],
              type: "variables",
            },
            { action: "saveData", value: "turnover", type: "eventType" },
            { action: "saveData", value: "shotClock", type: "subType" },
            {
              action: "saveData",
              value: [{ option: "team", value: true }],
              type: "options",
            },
            {
              action: "setPossession",
              value: { currentEntity: false },
              type: "main",
            },
            { action: "copyEvent", value: "turnover", type: "to" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "clearEvent", value: "turnover", type: "main" },
            { action: "nextState", value: null, type: "primary" },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [],
        },
      ],
    },
    // jumpball
    {
      name: "jumpball-from-rebound",
      component: "TypeButton",
      layout: "match-button narrow",
      mode: "enable",
      text: "jumpball",
      showOn: [true],
      startEvent: true,
      startPlay: false,
      actionGroups: [
        {
          actions: [
            { action: "stopClock", value: "stop", type: "main" },
            {
              action: "nextState",
              value: "full-jumpball-from-rebound-details",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "fixtureProfile",
              value: true,
              event: "midGameJumpBallAllowed",
              operator: "is",
            },
          ],
        },
        {
          actions: [{ action: "nextState", value: "rebound-jumpball", type: "primary" }],
          conditions: [
            {
              item: "fixtureProfile",
              value: true,
              event: "midGameJumpBallAllowed",
              operator: "not",
            },
          ],
        },
      ],
    },
    //No rebound / end of period
    {
      name: "no-rebound",
      component: "TypeButton",
      layout: "match-button narrow",
      mode: "enable",
      text: "rebound.none",
      showOn: [{ state: "clock.displayTime", value: "00:00", operator: "is" }],
      startEvent: true,
      startPlay: false,
      showForInsert: true,
      actionGroups: [
        {
          actions: [
            { action: "clearEvent", value: "turnover", type: "main" },
            { action: "nextState", value: null, type: "primary" },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
