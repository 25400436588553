import React, { useEffect, useState, useContext } from "react";
import { withRouter, Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { FixtureDetailsContext } from "../../Config/FixtureDetails";
import { ConnectionContext } from "../../Config/Connection";

import "./Menu.scss";
import { REPORTS_APP_URL } from "../../Config/Environment";

const Menu = (props) => {
  const {
    location,
    orgnizationId,
    clock,
    locale,
    currentState,
    formatMessage,
  } = props;
  const [fixtureId, setFixtureId] = useState();
  const [pageType, setPageType] = useState("fixtures");
  const [isEnabled, setIsEnabled] = useState(false);
  const { reports, fixtureProfile } = useContext(FixtureDetailsContext);
  const { sigToken } = useContext(ConnectionContext);
  const { period } = currentState;

  useEffect(() => {
    if (location.pathname.includes("/game/")) {
      setPageType("game");
      setFixtureId(location.pathname.replace("/game/", ""));
    } else if (location.pathname.includes("/details/")) {
      setPageType("details");
      setFixtureId(location.pathname.replace("/details/", ""));
    } else if (location.pathname.includes("/setup/")) {
      setPageType("setup");
      setFixtureId(location.pathname.replace("/setup/", ""));
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (clock) {
      setIsEnabled(clock.clockRunning === false ? true : false);
    }
    // eslint-disable-next-line
  }, [clock]);

  const isFixtureNotStarted =
    !clock?.clockRunning &&
    period &&
    period.periodId === 1 &&
    (period.periodStatus === "pending" ||
      period.periodStatus === "warm_up" ||
      period.periodStatus === "initial");

  const filterReports = (report) =>
    ["playbyplay", "playbyplayfull", "quarterscore"].includes(report)
      ? !isFixtureNotStarted
      : true;

  const getReportURL = (report) => {
    let base = `${REPORTS_APP_URL}/${orgnizationId}/${sigToken}`;
    // per period boxscore reports
    if (report.includes("boxscore.")) {
      const periodId = report.split(".")[1];
      return `${base}/boxscore/${periodId}`;
    }
    if (report === "advancedBoxscore") {
      return `${base}/${report}?locale=${locale}`;
    }
    return `${base}/${report}`;
  };

  const getBoxscoreWithPeriodsItems = () => {
    const currentPeriod = currentState?.period?.periodId;
    const boxscoreReports = ["boxscore.full"];
    if (currentPeriod >= fixtureProfile.initialExtraPeriodId) {
      const periodReports = Array.from({
        length: fixtureProfile.numberOfPeriods,
      }).map((_, index) => `boxscore.P${index + 1}`);
      boxscoreReports.push(...periodReports);
      const otPeriodsCount = currentPeriod - (fixtureProfile.initialExtraPeriodId - 1);
      const otReports = Array.from({
        length: otPeriodsCount,
      }).map((_, index) => `boxscore.OT${index + 1}`);
      boxscoreReports.push(...otReports);
    } else {
      const periodReports = Array.from({
        length: currentPeriod,
      }).map((_, index) => `boxscore.P${index + 1}`);
      boxscoreReports.push(...periodReports);
    }
    return boxscoreReports;
  };

  const getLabelForPeriod = (periodId) => {
    if (periodId === "full")
      return formatMessage({
        id: "reports.boxscore.full",
        defaultMessage: "reports.boxscore.full",
      });
    if (periodId.startsWith("P")) {
      const period = periodId.slice(1);
      return formatMessage(
        {
          id: "period.full",
          defaultMessage: "period.full",
        },
        { period }
      );
    }
    if (periodId.startsWith("OT")) {
      const period = periodId.slice(2);
      return formatMessage(
        {
          id: "period.overtime.full",
          defaultMessage: "period.overtime.full",
        },
        { period }
      );
    }
  };

  const showFixtures = pageType !== "fixtures";
  const showGameDetails = ["setup", "game"].includes(pageType);
  const showTeamSetup = ["game"].includes(pageType);
  const showReports = ["setup", "game"].includes(pageType);

  return (
    <nav role="navigation" className="top-nav">
      {showFixtures && (
        <Link
          to={{
            pathname: "/fixtures/",
            search: location.search,
          }}
          className="menu-item"
        >
          <i className="fas fa-square"></i>
          <FormattedMessage
            id="fixtures"
            defaultMessage="Fixtures"
            description="fixture link"
          />
        </Link>
      )}
      {showGameDetails && (
        <Link
          to={{
            pathname: `/details/${fixtureId}`,
            search: location.search,
          }}
          className={`menu-item enabled-${isEnabled || pageType === "setup"}`}
        >
          <i className="fas fa-square"></i>
          <FormattedMessage id="menu.details" defaultMessage="Details" />
        </Link>
      )}
      {showTeamSetup && (
        <Link
          to={{
            pathname: `/setup/${fixtureId}`,
            search: location.search,
          }}
          className={`menu-item enabled-${isEnabled}`}
        >
          <i className="fas fa-square"></i>
          <FormattedMessage id="menu.setup" defaultMessage="Setup" />
        </Link>
      )}
      {showReports && (
        <div className="dropdown">
          <div className="menu-item dropdown-trigger">
            <i className="fas fa-square"></i>
            <FormattedMessage
              id="menu.reports"
              defaultMessage="reports"
              description="reports link"
            />
          </div>
          <ul className="dropdown-list">
            {reports.filter(filterReports).map((report) => {
              if (report === "boxscoreWithPeriods") {
                return (
                  <li key={report} className="dropdown-list-item submenu">
                    <span className="submenu-trigger">
                      <FormattedMessage id="reports.boxscore" />
                    </span>
                    <ul className="submenu-list">
                      {getBoxscoreWithPeriodsItems().map((subReport) => {
                        const periodId = subReport.split(".")[1];
                        const label = getLabelForPeriod(periodId);
                        return (
                          <li key={subReport} className="dropdown-list-item">
                            <a
                              href={getReportURL(subReport)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {label}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              }
              return (
                <li key={report} className="dropdown-list-item">
                  <a
                    href={getReportURL(report)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id={"reports." + report} />
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </nav>
  );
};

export default withRouter(Menu);
