const VideoReviewOptions = ["upheld", "rejected"];

const sharedActions = [
  { action: "newEventId", value: null, type: "main" },
  { action: "sendEvent", value: "new", type: "main" },
  {
    action: "clearData",
    value: ["eventType", "subType", "options"],
    type: "main",
  },
];

const clearTypeOptionActions = [
  {
    action: "clearData",
    value: [{"option": "team"}, {"option": "umpire"}],
    type: "main",
  },
]

export const VideoReviewOutcomeSelect = {
  name: "video-review-outcome",
  component: "MainPanel",
  layout: "main-panel",
  mode: "visible",
  text: "videoReview.outcome",
  showOn: [
    { state: "secondary", value: "video-review-outcome", operator: "is" },
  ],
  startEvent: false,
  startPlay: false,
  allowFlag: true,
  allowCancel: true,
  children: [
    {
      name: "video-review-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "subType",
        children: VideoReviewOptions,
        layout: "type-button",
      },
      actionGroups: [
        {
          actions: [
            ...sharedActions,
            { action: "copyEvent", value: "circleEntry", type: "from" },
            ...clearTypeOptionActions,
            {
              action: "nextState",
              value: "circle-entry-select",
              type: "secondary",
            },
          ],
          conditions: [
            {
              item: "options",
              value: "fromCircleEntry",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            ...sharedActions,
            { action: "processInserts", value: "all", type: "inserts" },
            { action: "nextState", value: null, type: "secondary" },
          ],
          conditions: [
            {
              item: "options",
              value: "fromCircleEntry",
              event: "main",
              operator: "not",
            },
          ],
        },
      ],
    },
  ],
};
