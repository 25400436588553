import Logger from "./Logger";

const logger = Logger.getInstance();

const TWENTY_SECONDS = 20 * 1000;

class AckWatcher {
  watchedEvents = [];
  threshold = TWENTY_SECONDS;

  constructor() {
    this.checkEventIds = this.checkEventIds.bind(this);
    window.setInterval(this.checkEventIds, 1000);
  }

  watchEvent(event) {
    this.watchedEvents.push({
      event,
      sent: new Date(),
    });
  }

  ackReceivedForEventId(eventId) {
    const idx = this.watchedEvents.findIndex(
      (watchedEvent) => watchedEvent.event.eventId === eventId
    );

    if (idx === -1) {
      return;
    }

    this.watchedEvents.splice(idx, 1);
  }

  isAboveThreshold(watchedEvent) {
    return new Date() - watchedEvent.sent >= this.threshold;
  }

  checkEventIds() {
    this.watchedEvents.forEach((watchedEvent) => {
      if (this.isAboveThreshold(watchedEvent)) {
        logger.log(
          "Ack has not received within 20 seconds",
          `sent: ${watchedEvent.sent}`,
          watchedEvent.event
        );

        // log only once, then remove the event
        this.watchedEvents = this.watchedEvents.filter(
          ({ event }) => event.eventId !== watchedEvent.event.eventId
        );
      }
    });
  }
}

export default AckWatcher;
