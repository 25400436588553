export const ModifyEntitySelect = {
  name: "entity",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "entity.select",
  showOn: [{ state: "editState", value: "entity", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },

  children: [
    {
      name: "team-0",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: { team: "name" },
      showOn: [true],
      options: { team: 0 },
      actionGroups: [
        {
          actions: [
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            { action: "nextState", value: null, type: "editState" },
          ],
          conditions: [
            {
              item: "eventType",
              value: "possession",
              event: "edit",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            { action: "nextState", value: null, type: "editState" },
          ],
          conditions: [
            {
              item: "eventType",
              value: "centrePass",
              event: "edit",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            { action: "nextState", value: null, type: "editState" },
          ],
          conditions: [
            {
              item: "eventType",
              value: "videoReview",
              event: "edit",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "nextState", value: "player", type: "editState" },
          ],
          conditions: [
            {
              item: "eventType",
              value: "greenCard",
              event: "edit",
              operator: "is",
            },
            {
              item: "eventType",
              value: "yellowCard",
              event: "edit",
              operator: "is",
            },
            {
              item: "eventType",
              value: "redCard",
              event: "edit",
              operator: "is",
            },
          ],
          operator: "any",
        },
      ],
    },
    {
      name: "team-1",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: { team: "name" },
      showOn: [true],
      options: { team: 1 },
      actionGroups: [
        {
          actions: [
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            { action: "nextState", value: null, type: "editState" },
          ],
          conditions: [
            {
              item: "eventType",
              value: "possession",
              event: "edit",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            { action: "nextState", value: null, type: "editState" },
          ],
          conditions: [
            {
              item: "eventType",
              value: "centrePass",
              event: "edit",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            { action: "nextState", value: null, type: "editState" },
          ],
          conditions: [
            {
              item: "eventType",
              value: "videoReview",
              event: "edit",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "nextState", value: "player", type: "editState" },
          ],
          conditions: [
            {
              item: "eventType",
              value: "greenCard",
              event: "edit",
              operator: "is",
            },
            {
              item: "eventType",
              value: "yellowCard",
              event: "edit",
              operator: "is",
            },
            {
              item: "eventType",
              value: "redCard",
              event: "edit",
              operator: "is",
            },
          ],
          operator: "any",
        },
      ],
    },
  ],
};
