import * as FoulTypes from "../../subTypes/EliteFoulTypes";

export const CancellationFoulDrawn = {
  name: "cancellation-foul-drawn-player",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "fouldrawn.select-player",
  allowFlag: true,
  allowCancel: false,
  showDetails: { events: ["foul", "fouldrawn"] },
  showOn: [
    {
      state: "primary",
      value: "cancellation-foul-drawn-player",
      operator: "is",
    },
  ],
  allowBack: {
    state: "primary",
    value: "cancellation-foul-type",
    event: "foul",
  },
  children: [
    // technical foul type selected in previous step
    {
      name: "foul-drawn-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [
        {
          state: "event.subType",
          value: FoulTypes.CancellationDisqualifyingFoulTypes,
          operator: "not",
          event: "foul",
        },
      ],
      startEvent: false,
      startPlay: false,
      options: {
        currentEntity: false,
        currentEntityEvent: "foul",
        showBench: true,
        showCoach: true,
      },
      actionGroups: [
        {
          actions: [{ action: "clearData", value: ["subType"], type: "main" }],
          conditions: [],
        },
        {
          actions: [
            {
              action: "nextState",
              value: "cancellation-drawn-foul-type",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "bench",
              value: false,
              event: "main",
              operator: "is",
            },
            {
              item: "coach",
              value: false,
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "newEventId", value: null, type: "main" },
            { action: "saveData", value: "foul", type: "eventType" },
            { action: "clearData", value: ["personId"] },
            { action: "saveData", value: "benchTechnical", type: "subType" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [
            {
              item: "bench",
              value: true,
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "newEventId", value: null, type: "main" },
            { action: "saveData", value: "foul", type: "eventType" },
            { action: "saveData", value: "coachTechnical", type: "subType" },
            { action: "clearData", value: ["personId"] },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [
            {
              item: "coach",
              value: true,
              event: "main",
              operator: "is",
            },
          ],
        },
      ],
    },
    // DQ or unsportsmanlike foul selected in previous step
    {
      name: "foul-drawn-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [
        {
          state: "event.subType",
          value: FoulTypes.CancellationDisqualifyingFoulTypes,
          operator: "is",
          event: "foul",
        },
      ],
      startEvent: false,
      startPlay: false,
      options: {
        currentEntity: false,
        currentEntityEvent: "foul",
        showBench: false,
        showCoach: true,
      },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "foul", type: "eventType" },
            { action: "saveData", value: "drawn", type: "subType" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "newEventId", value: null, type: "main" },
          ],
          conditions: [
            {
              item: "subType",
              value: FoulTypes.TechnicalFoulTypes,
              event: "foul",
              operator: "not",
            },
            {
              item: "coach",
              value: false,
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "clearData", value: ["subType"], type: "main" },
            {
              action: "nextState",
              value: "cancellation-drawn-foul-type",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
