export const calculateOnCourt = (status, props, exactTime = "") => {
  let entities = JSON.parse(JSON.stringify(props.currentState.entities));

  // Reset person.active
  entities.forEach((entity) => {
    entity.persons.map((person) => {
      person.active = person.starter;
    });
  });

  const eventTime =
    exactTime !== "" ? exactTime : props.currentState.events["main"].eventTime;

  props.eventStore
    .filter(
      (event) =>
        event.eventType === "substitution" && event.status !== "deleted"
    )
    .sort((a, b) => (a.eventTime < b.eventTime ? -1 : 1))
    .map((substitution) => {
      if (
        exactTime &&
        status !== "all" &&
        substitution.eventTime >= exactTime
      ) {
        return true;
      } else if (status !== "all" && substitution.eventTime <= eventTime) {
        let player = entities
          .find((entity) => entity.entityId === substitution.entityId)
          .persons.find((person) => person.personId === substitution.personId);
        if (player) {
          player.active = substitution.subType === "in" ? true : false;
        }
      } else if (status === "all") {
        let player = entities
          .find((entity) => entity.entityId === substitution.entityId)
          .persons.find((person) => person.personId === substitution.personId);
        if (player) {
          player.active = substitution.subType === "in" ? true : false;
        }
      }
      return true;
    });
  return entities;
};
