export const InsertSuspensionType = {
  name: "suspension-type",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "suspension.type.select",
  allowFlag: true,
  allowCancel: true,
  showOn: [
    { state: "editState", value: "suspension-type-insert", operator: "is" },
  ],
  children: [
    {
      name: "suspension-buttons",
      component: "ButtonPanel",
      layout: "button-group centered",
      mode: "visible",
      showOn: [true],
      children: [
        {
          name: "2min-suspension",
          component: "TypeButton",
          layout: "type-button",
          mode: "enable",
          text: "suspension.2min",
          showOn: [true],
          actionGroups: [
            {
              actions: [
                { action: "copyEvent", value: "parent", type: "from" },
                { action: "newEventId", value: null, type: "main" },
                { action: "clearData", value: ["subType"], type: "main" },
                { action: "saveData", value: "suspension", type: "eventType" },
                { action: "saveData", value: "twoMinutes", type: "subType" },
                { action: "sendEvent", value: "new", type: "main" },
                { action: "nextState", value: null, type: "editState" },
              ],
            },
          ],
        },
        {
          name: "4min-suspension",
          component: "TypeButton",
          layout: "type-button",
          mode: "enable",
          text: "suspension.4min",
          showOn: [true],
          actionGroups: [
            {
              actions: [
                { action: "copyEvent", value: "parent", type: "from" },
                { action: "newEventId", value: null, type: "main" },
                { action: "clearData", value: ["subType"], type: "main" },
                { action: "saveData", value: "suspension", type: "eventType" },
                { action: "saveData", value: "fourMinutes", type: "subType" },
                { action: "sendEvent", value: "new", type: "main" },
                { action: "nextState", value: null, type: "editState" },
              ],
            },
          ],
        },
      ],
    },
  ],
};
