export const ModifyShootoutDefenceOutcome = {
  name: "modify-shootout-defence-outcome",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "edit.shootout.success",
  showOn: [{ state: "editState", value: "shootout-defence-outcome", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    {
      name: "shootout-change-to-made",
      component: "PanelText",
      layout: "panel-text",
      mode: "visible",
      showOn: [
        {
          state: "event.subType",
          value: "conceded",
          operator: "is",
          event: "main",
        },
      ],
      options: {
        layout: "description-text",
        text: "shootoutAttempt.change.to.saved",
      },
      actionGroups: [],
    },
    {
      name: "shootout-change-to-made",
      component: "PanelText",
      layout: "panel-text",
      mode: "visible",
      showOn: [
        {
          state: "event.subType",
          value: "saved",
          operator: "is",
          event: "main",
        },
      ],
      options: {
        layout: "description-text",
        text: "shootoutAttempt.change.to.conceded",
      },
      actionGroups: [],
    },
    {
      name: "confirm-panel",
      component: "SubPanel",
      layout: "sub-panel",
      mode: "visible",
      text: null,
      showOn: [true],
      children: [
        {
          name: "ok-button",
          component: "TypeButton",
          layout: "match-button action",
          mode: "enable",
          text: "ok",
          showOn: [true],
          actionGroups: [
            {
              actions: [
                {
                  action: "switchValues",
                  value: {
                    eventType: "shootOutDefence",
                    property: "subType",
                    values: ["conceded", "saved"],
                  },
                  type: "main",
                },
                {
                  action: "nextState",
                  value: null,
                  type: "editState",
                },
              ],
              conditions: [],
            },
          ],
        },
      ],
    },
  ],
};
