// Stand alone freethrows
export var InsertFreeThrowSetup = {
  name: "insert-freethrows",
  component: "MainPanel",
  layout: "main-panel large center no-group",
  mode: "visible",
  text: "freethrows.add",
  showOn: [
    { state: "editState", value: "child-insert-freethrows", operator: "is" },
  ],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit-0", "edit-1"] },
  children: [
    //Pre - action - runs before select
    {
      name: "pre-action",
      component: "PreAction",
      layout: "pre-action",
      mode: "enable",
      text: "freethrow.none",
      showOn: [true],
      startEvent: false,
      startPlay: false,
      inheritPlay: "foul",
      actionGroups: [
        {
          actions: [
            {
              action: "copyFromPlay",
              value: {
                eventTypes: ["foul"],
                ignoreSubTypes: ["drawn"],
                copyTo: "foul",
              },
              type: "edit",
            },
            {
              action: "copyFromPlay",
              value: {
                eventTypes: ["foul"],
                ignoreSubTypes: [
                  "benchTechnical",
                  "coachDisqualifying",
                  "coachTechnical",
                  "disqualifying",
                  "offensive",
                  "personal",
                  "technical",
                  "unsportsmanlike",
                ],
                copyTo: "fouldrawn",
              },
              type: "edit",
            },
            {
              action: "nextState",
              value: "child-award-freethrows",
              type: "editState",
            },
          ],
          conditions: [],
        },
      ],
    },
  ],
};

// Stand alone freethrows
export var InsertFreeThrowsAwarded = {
  name: "insert-freethrows",
  component: "MainPanel",
  layout: "main-panel large center no-group",
  mode: "visible",
  text: "freethrows.add",
  showOn: [
    { state: "editState", value: "child-award-freethrows", operator: "is" },
  ],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["foul", "fouldrawn"] },
  children: [
    {
      name: "1-freethrows",
      component: "TypeButton",
      layout: "match-button small",
      mode: "enable",
      text: "freethrows.1",
      showOn: [true],
      startEvent: false,
      startPlay: false,
      inheritPlay: "foul",
      actionGroups: [
        {
          actions: [
            { action: "copyEvent", value: "foul", type: "from" },
            {
              action: "saveData",
              value: [{ option: "freeThrows", value: 1 }],
              type: "options",
            },
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            {
              action: "nextState",
              value: "free-throws",
              type: "editState",
            },
            { action: "saveData", value: "insert", type: "status" },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "2-freethrows",
      component: "TypeButton",
      layout: "match-button small",
      mode: "enable",
      text: "freethrows.2",
      showOn: [true],
      startEvent: false,
      startPlay: false,
      inheritPlay: "foul",
      actionGroups: [
        {
          actions: [
            { action: "copyEvent", value: "foul", type: "from" },
            {
              action: "saveData",
              value: [{ option: "freeThrows", value: 2 }],
              type: "options",
            },
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            { action: "saveData", value: "insert", type: "status" },
            {
              action: "nextState",
              value: "free-throws",
              type: "editState",
            },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "3-freethrows",
      component: "TypeButton",
      layout: "match-button small",
      mode: "enable",
      text: "freethrows.3",
      showOn: [true],
      startEvent: false,
      startPlay: false,
      inheritPlay: "foul",
      actionGroups: [
        {
          actions: [
            { action: "copyEvent", value: "foul", type: "from" },
            {
              action: "saveData",
              value: [{ option: "freeThrows", value: 3 }],
              type: "options",
            },
            {
              action: "sendEvent",
              value: "edit",
              type: "main",
            },
            { action: "saveData", value: "insert", type: "status" },
            {
              action: "nextState",
              value: "free-throws",
              type: "editState",
            },
          ],
          conditions: [],
        },
      ],
    },
  ],
};

// Free Throws Panel
export var InsertFreeThrows = {
  name: "free-throws",
  component: "MainPanel",
  layout: "main-panel full align-center",
  mode: "visible",
  text: "freethrows",
  allowFlag: true,
  allowCancel: false,
  showDetails: { events: ["foul", "fouldrawn"] },
  showOn: [{ state: "editState", value: "free-throws", operator: "is" }],
  children: [
    {
      name: "free-throw-panel",
      component: "Freethrows",
      layout: "free-throws full",
      mode: "enable",
      text: null,
      showOn: [true],
      options: {
        showAssists: true,
        currentEntity: false,
        currentEntityEvent: "foul",
      },
      actionGroups: [
        {
          // Personal Foul - last freethrow un-successfull
          actions: [
            {
              action: "clearData",
              value: ["personId"],
            },
            {
              action: "nextState",
              value: "shot-rebound-insert",
              type: "editState",
            },
          ],
          conditions: [
            {
              item: "success",
              value: false,
              event: "main",
              operator: "is",
            },
            {
              item: "subType",
              value: "personal",
              event: "parent",
              operator: "is",
            },
            {
              item: "subType",
              value: [
                "benchTechnical",
                "coachDisqualifying",
                "coachTechnical",
                "disqualifying",
                "technical",
                "unsportsmanlike",
              ],
              event: "parent",
              operator: "not",
            },
          ],
          operator: "all",
        },
        {
          // Personal Foul - last freethrow un-successfull
          actions: [
            {
              action: "clearData",
              value: ["personId"],
            },
            {
              action: "nextState",
              value: null,
              type: "editState",
            },
          ],
          conditions: [
            {
              item: "success",
              value: false,
              event: "main",
              operator: "is",
            },
            {
              item: "subType",
              value: "personal",
              event: "parent",
              operator: "not",
            },
            {
              item: "subType",
              value: [
                "benchTechnical",
                "coachDisqualifying",
                "coachTechnical",
                "disqualifying",
                "technical",
                "unsportsmanlike",
              ],
              event: "parent",
              operator: "is",
            },
          ],
          operator: "all",
        },
        {
          actions: [
            {
              action: "nextState",
              value: null,
              type: "editState",
            },
          ],
          conditions: [
            {
              item: "success",
              value: true,
              event: "main",
              operator: "is",
            },
          ],
          operator: "all",
        },
        {
          actions: [
            {
              action: "clearData",
              value: ["status"],
              type: "main",
            },
          ],
          conditions: [],
          operator: "all",
        },
        {
          actions: [{ action: "clearEvent", value: "freethrow", type: "main" }],
          conditions: [],
        },
      ],
    },
  ],
};
