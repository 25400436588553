import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";

import EditControls from "../EditControls/EditControls";

import { lightOrDark } from "../../../Base/Utilities/LightOrDark";
import {
  ignoreSubkeyEventTypes,
  includeSuccessEventTypes,
} from "../../../Base/Utilities/EventHelpers";

const EditListItem = (props) => {
  const { currentState, event, intl } = props;
  const { formatMessage } = intl;
  const [detailsText, setDetailsText] = useState();
  const [team, setTeam] = useState();
  const [player, setPlayer] = useState();

  useEffect(() => {
    if (event) {
      setTeam(
        currentState.entities.find((el) => el.entityId === event.entityId)
      );
      let translationkey = "event." + event.eventType;
      if (event.subType) {
        translationkey = !ignoreSubkeyEventTypes.includes(event.eventType)
          ? translationkey + "." + event.subType
          : translationkey;
      }
      if (typeof event.success === "boolean") {
        translationkey = includeSuccessEventTypes.includes(event.eventType)
          ? translationkey + "." + event.success
          : translationkey;
      }

      setDetailsText(translationkey);
    }
    // eslint-disable-next-line
  }, [event]);

  useEffect(() => {
    if (team) {
      setPlayer(team.persons.find((el) => el.personId === event.personId));
    }
    // eslint-disable-next-line
  }, [team]);

  return (
    <div className="event">
      {detailsText && (
        <React.Fragment>
          <span className="event-type">
            <span
              className={
                "player-bib " + (team ? lightOrDark(team.primaryColor) : "dark")
              }
              style={{
                backgroundColor: team ? team.primaryColor : "#eee",
              }}
            >
              {player && player.bib ? player.bib : "-"}
            </span>
            <span className="event-details">
              {formatMessage({
                id: detailsText,
                defaultMessage: detailsText,
              })}
            </span>
            {player && (
              <span className="event-player">
                <span>({player.name})</span>
              </span>
            )}
          </span>
          <span className="event-controls">
            <EditControls {...props} event={event} />
          </span>
        </React.Fragment>
      )}
    </div>
  );
};

export default injectIntl(EditListItem);
