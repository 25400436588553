import React from "react";
import { injectIntl } from "react-intl";
import { lightOrDark } from "../../../Base/Utilities/LightOrDark";

import "./PlayerSelect.scss";

const PlayerSelect = (props) => {
  const { entity, onPlayerClick, selectedPlayerId } = props;
  const { persons, primaryColor } = entity;

  return (
    <div className="shootout-player-selector">
      {persons.map((person) => (
        <div
          key={`shootout-${person.personId}`}
          className={`shootout-player-tile  ${lightOrDark(primaryColor)} ${
            selectedPlayerId === person.personId ? "active" : ""
          }`}
          style={{
            backgroundColor: entity.primaryColor,
            borderColor: entity.primaryColor,
          }}
          onClick={() => onPlayerClick(person)}
        >
          {person.bib}
        </div>
      ))}
    </div>
  );
};

export default injectIntl(PlayerSelect);
