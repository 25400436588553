import { VideoReviewOptions } from "./../subTypes";

export const VideoReviewSelect = {
  name: "video-review",
  component: "MainPanel",
  layout: "main-panel",
  mode: "visible",
  text: "videoReview.outcome",
  showOn: [{ state: "primary", value: "video-review", operator: "is" }],
  startEvent: true,
  startPlay: true,
  allowFlag: true,
  allowCancel: true,
  children: [
    {
      name: "video-review-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [true],
      options: {
        value: "subType",
        children: VideoReviewOptions,
        layout: "type-button",
      },
      actionGroups: [
        {
          actions: [
            { action: "stopClock", value: "stop", type: "main" },
            { action: "saveData", value: "videoReview", type: "eventType" },
            {
              action: "clearData",
              value: ["entityId", "options"],
              type: "main",
            },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
