import React, { useState, useEffect, useContext } from "react";
import { injectIntl } from "react-intl";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";
import Button from "../../Base/Button/Button";
import { sendClockEvent } from "../../Base/Utilities/ClockEvents";

function WarmupClock(props) {
  const { intl, currentState, updateState } = props;
  const { period } = currentState;
  const { formatMessage } = intl;
  const [seconds, setSeconds] = useState(8);
  const [isActive, setIsActive] = useState(false);
  const { fixtureProfile } = useContext(FixtureDetailsContext);
  const { warmupSeconds } = fixtureProfile;

  useEffect(() => {
    if (warmupSeconds) {
      sendClockEvent("sport", "fixture", "warm_up", props);
      updateState("period", {
        periodId: currentState.period.periodId,
        periodStatus: "warm_up",
      });
      setSeconds(warmupSeconds);
      setIsActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warmupSeconds]);

  useEffect(() => {
    let interval;

    if (isActive && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    if (seconds === 0) {
      setIsActive(false);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, seconds]);

  const renderClock = () => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds - minutes * 60;

    return (
      <>
        {String(minutes).padStart(2, "0")}:{String(secs).padStart(2, "0")}
      </>
    );
  };

  useEffect(() => {
    const listener = () => {
      if (period.periodStatus === "warm_up") {
        onWarmupEnd();
      }
    };

    window.addEventListener("beforeunload", listener);
    return () => window.removeEventListener("beforeunload", listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  const onWarmupEnd = () => {
    sendClockEvent("sport", "fixture", "pending", props);
    sendClockEvent("sport", "period", "pending", props);
    updateState("period", {
      periodId: currentState.period.periodId,
      periodStatus: "pending",
    });
    updateState("primary", null);
  };

  return (
    <div className="shootout-clock">
      <p>{renderClock()}</p>
      <div className="shootout-clock-controls">
        <Button onClick={onWarmupEnd}>
          {formatMessage({
            id: "warmup.end",
            defaultMessage: "End warm up",
          })}
        </Button>
      </div>
    </div>
  );
}

export default injectIntl(WarmupClock);
