import React, { useState, useEffect, useContext } from "react";
import { injectIntl, FormattedMessage } from "react-intl";

import InsertClockAdjust from "./InsertClockAdjust/InsertClockAdjust";

import { useIsVisible } from "../../Base/Hooks/IsVisible";
import { useDoActions } from "../../Base/Hooks/doActions";
import "./InsertClock.scss";
import {
  getPeriodEndEvent,
  getLastValidPlayFromPeriod,
} from "../../Base/Utilities/Period";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails.jsx";

const InsertClock = (props) => {
  const { config, currentState, updateState, panel } = props;
  const [insertObject, setInsertObject] = useState();
  const [displayTime, setDisplayTime] = useState();
  const [timeSeconds, setTimeSeconds] = useState();
  const [isVisible, isEnabled] = useIsVisible(panel, props);
  const [doAction, setDoAction] = useState(false);
  const [actionsDone] = useDoActions(panel, props, doAction);
  const { fixtureProfile, sport } = useContext(FixtureDetailsContext);

  useEffect(() => {
    if (actionsDone) {
      setDoAction(false);
    }
  }, [actionsDone, props]);

  useEffect(() => {
    if (
      currentState.insertMode &&
      currentState.insertMode.enabled &&
      !currentState.insertMode.range
    ) {
      let currentPlay = currentState.insertMode.play;
      let currentPlays = props.playStore
        .filter((play) => play.periodId === currentPlay.periodId)
        .sort((a, b) =>
          parseInt(a.eventTime) < parseInt(b.eventTime) ? -1 : 1
        );
      let currentPlayIndex = currentPlays.findIndex(
        (play) => play.playId === currentPlay.playId
      );
      const currentPlayDate = new Date(
        currentPlays[currentPlayIndex].eventTime +
          (currentPlays[currentPlayIndex].eventTime.slice(-1) === "Z"
            ? ""
            : "Z")
      );
      let previousPlayDate = null;
      const clockRange = [currentPlays[currentPlayIndex].clock];
      if (currentPlayIndex + 1 === currentPlays.length) {
        previousPlayDate = new Date(currentPlayDate);
        previousPlayDate.setSeconds(previousPlayDate.getSeconds() - 4);
        clockRange[1] = clockRange[0];
      } else {
        previousPlayDate = new Date(
          currentPlays[parseInt(currentPlayIndex) + 1].eventTime +
            (currentPlays[parseInt(currentPlayIndex) + 1].eventTime.slice(
              -1
            ) === "Z"
              ? ""
              : "Z")
        );
        clockRange[1] = currentPlays[currentPlayIndex + 1].clock;
      }

      const midpoint = getMidPoint(currentPlayDate, previousPlayDate);

      const lastValidPlay = getLastValidPlayFromPeriod(
        currentPlays,
        props.eventStore
      );

      if (midpoint && isValidDate(midpoint) && clockRange[0] && clockRange[1]) {
        setInsertObject({
          enabled: true,
          play: currentPlay,
          range: clockRange,
          insertDate: midpoint.toISOString(),
          isFirstPlayInPeriod: currentPlayIndex === currentPlays.length - 1,
          isLastPlayInPeriod: lastValidPlay.playId === currentPlay.playId,
        });
      } else {
        updateState("insertMode", {
          enabled: false,
        });
      }
      if (!currentPlay) {
        updateState("insertMode", {
          enabled: false,
        });
      }
      if (!currentState.insertMode) {
        updateState("insertMode", {
          enabled: false,
        });
      }
    }
    // eslint-disable-next-line
  }, [currentState.insertMode]);

  const getMidPoint = (currentPlayDate, previousPlayDate) => {
    const { currentState } = props;
    const { insertMode, mqtt } = currentState;
    const { play, isAfter } = insertMode;

    if (!isAfter) {
      return new Date(
        (currentPlayDate.getTime() + previousPlayDate.getTime()) / 2
      );
    }

    const periodEndEvent = getPeriodEndEvent(mqtt.plays, play.periodId);

    if (!periodEndEvent) {
      console.error(
        "Period is not ended --- Event can not be inserted after the last event --- Returning currentPlay date"
      );
      return currentPlayDate;
    }
    const periodEndEventDate = new Date(`${periodEndEvent.eventTime}Z`);

    return new Date(
      (currentPlayDate.getTime() + periodEndEventDate.getTime()) / 2
    );
  };

  useEffect(() => {
    if (insertObject) {
      updateState("insertMode", {
        enabled: insertObject.enabled,
        play: insertObject.play,
        clock: insertObject.play.clock,
        range: insertObject.range,
        secondsRange: [
          convertClockToSeconds(insertObject.range[0]),
          convertClockToSeconds(insertObject.range[1]),
        ],
        insertDate: insertObject.insertDate,
      });
      setDisplayTime(_convertClockToDisplay(insertObject.range[0]));
      setTimeSeconds(convertClockToSeconds(insertObject.range[0]));
    }
    // eslint-disable-next-line
  }, [insertObject]);

  useEffect(() => {
    setDisplayTime(_convertSecondsToDisplay(timeSeconds));
    let current = currentState.insertMode;
    if (current) {
      current.clock = _convertSecondsToDisplay(timeSeconds);
      let clockParts = current.clock.split(":");
      current.clock =
        "PT" + parseInt(clockParts[0]) + "M" + parseInt(clockParts[1]) + "S";
      updateState("insertMode", current);
    }
    // eslint-disable-next-line
  }, [timeSeconds]);

  function _convertClockToDisplay(clock) {
    let clockParts = clock.replace("PT", "").replace("S", "").split("M");
    return (
      ("0" + clockParts[0]).slice(-2) + ":" + ("0" + clockParts[1]).slice(-2)
    );
  }

  function _convertSecondsToDisplay(seconds) {
    let displayMinutes = Math.floor(seconds / 60);
    let displaySeconds = seconds % 60;
    return (
      ("0" + displayMinutes).slice(-2) + ":" + ("0" + displaySeconds).slice(-2)
    );
  }

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  function closeInsertMode() {
    updateState("insertMode", { enabled: false });
  }

  const _convertDisplayTime = (displayTime) => {
    if (sport !== "handball" || insertObject.play.periodId === 1) {
      return displayTime;
    }
    const [minutes, seconds] = displayTime?.split(":");
    return `${parseInt(minutes, 10) + fixtureProfile.periodLength}:${seconds}`;
  }

  return (
    <React.Fragment>
      {isVisible && insertObject && insertObject.play && (
        <div className={panel.layout + " insert-clock enabled-" + isEnabled}>
          <div className="title-display">
            <FormattedMessage id="mode.insert" defaultMessage="Insert" />
            <i className="fas fa-times" onClick={() => closeInsertMode()}></i>
          </div>
          <InsertClockAdjust
            timeSeconds={timeSeconds}
            setTimeSeconds={setTimeSeconds}
            insertObject={insertObject}
            config={config}
            period={currentState.period}
          />
          <div className="time-display">{_convertDisplayTime(displayTime)}</div>
          <div className="period-display">
            <FormattedMessage
              id={
                insertObject.play.periodId >= fixtureProfile.initialExtraPeriodId
                  ? "period.overtime.full"
                  : "period.full"
              }
              defaultMessage={
                insertObject.play.periodId >= fixtureProfile.initialExtraPeriodId
                  ? "Overtime {period}"
                  : "Period {period}"
              }
              values={{
                period:
                  insertObject.play.periodId >= fixtureProfile.initialExtraPeriodId
                    ? insertObject.play.periodId - (fixtureProfile.initialExtraPeriodId - 1)
                    : insertObject.play.periodId,
              }}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default injectIntl(InsertClock);

export function convertClockToSeconds(clock) {
  if (!clock) {
    return null;
  }

  let clockParts = clock.replace("PT", "").replace("S", "").split("M");
  return parseInt(clockParts[0]) * 60 + parseInt(clockParts[1]);
}
