import React from "react";
import PlayerTile from "./PlayerTile.jsx";
import "./Players.scss";

const SuspendedPlayers = (props) => {
  const { currentState, eventStore } = props;
  return (
    <div className="suspended-players">
      {[0, 1].map((entityIndex) => {
        return <div className="suspended-players--team" key={entityIndex}>
          {currentState?.entities[entityIndex]?.persons.map((player) => {
            return (
              <PlayerTile
                player={player}
                entity={currentState.entities[entityIndex]}
                eventStore={eventStore}
                currentState={currentState}
                key={player.personId}
                {...props}
              ></PlayerTile>);
          })}
          {currentState?.entities[entityIndex]?.staff.map((player) => {
            return (
              <PlayerTile
                player={player}
                entity={currentState.entities[entityIndex]}
                eventStore={eventStore}
                currentState={currentState}
                key={player.personId}
                {...props}
              ></PlayerTile>);
          })}
        </div>;
      })}
    </div>
  );
};

export default SuspendedPlayers;
