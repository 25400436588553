import * as React from "react";
const SvgRightPosArrow = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Group_18903", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 394.5 511", style: {
  enableBackground: "new 0 0 394.5 511"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("style", { type: "text/css" }, "\n	.st0{opacity:0.4;fill:url(#SVGID_1_);enable-background:new    ;}\n	.st1{opacity:2.000000e-02;fill:#FFFFFF;enable-background:new    ;}\n	.st2{fill:#FFF;}\n"), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_1_", gradientUnits: "userSpaceOnUse", x1: -632.3559, y1: 795.47772, x2: -632.87, y2: 795.47913, gradientTransform: "matrix(-395 0 0 -511 -249783 406744)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "var(--entity-0-color)",
  stopOpacity: 0
}, id: "stop1" }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "var(--entity-0-color)"
}, id: "stop2" })), /* @__PURE__ */ React.createElement("path", { className: "st0", d: "m 0,255.4 -0.5,255.1 h 204 l 140,-255.1 -140,-255.9 h -204 z m 157.5,-5.1 c 0,-2.6 2.1,-4.7 4.7,-4.7 h 64.1 v -10.9 c 0,-1.8 1.1,-3.5 2.7,-4.3 1.7,-0.7 3.7,-0.4 5.1,0.8 l 21.9,20.3 c 1.9,1.7 2.2,4.6 0.5,6.5 -0.1,0.2 -0.3,0.3 -0.5,0.5 l -21.9,20.3 c -1.4,1.2 -3.4,1.5 -5.1,0.8 -1.7,-0.8 -2.7,-2.5 -2.7,-4.3 v -10.9 h -64.1 c -2.6,0 -4.7,-2.1 -4.7,-4.7 z", id: "path2", style: {
  display: "inline",
  fill: "url(#SVGID_1_)"
} }), /* @__PURE__ */ React.createElement("polygon", { className: "st2", points: "254.5,-0.5 394.5,254.8 254.5,510.5 253.5,510.5 393.5,255.4 253.5,-0.5 ", id: "polygon3", transform: "translate(-50)" }, /* @__PURE__ */ React.createElement("animate", { attributeName: "points", dur: "1s", repeatCount: "indefinite", from: "254.5,-0.5 394.5,254.8 254.5,510.5 253.5,510.5 393.5,255.4 253.5,-0.5", to: "304.5,-0.5 444.5,254.8 304.5,510.5 303.5,510.5 443.5,255.4 303.5,-0.5" }))));
export default SvgRightPosArrow;
