import React from "react";
import { Input } from "reactstrap";

const TeamSetupCheckbox = ({ onChange, checked, enabled = true }) => (
  <div className={`checkbox inline enabled-${enabled}`}>
    <label>
      <Input type="checkbox" checked={checked} onChange={onChange} />
      <span className="cr">
        <i className="cr-icon fa fa-check" />
      </span>
    </label>
  </div>
);
export default TeamSetupCheckbox;
