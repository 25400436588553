export function checkGameStarted(props) {
  let status = false;
  if (props.currentState && props.currentState.period) {
    switch (props.currentState.period.periodId) {
      case 1:
        status = !["pending", "initial", "warm_up"].includes(props.currentState.period.periodStatus);
        break;
      default:
        status = true;
        break;
    }
  }
  return status;
}

export function generatePeriodTitle(sport, periodId, fixtureProfile, formatMessage) {
  let title = ""

  if (periodId <= fixtureProfile.numberOfPeriods) {
    title =  `${formatMessage({
      id: "period.abbreviation",
      defaultMessage: "P",
    })}${periodId}`;
  }

  if (periodId >= fixtureProfile.initialExtraPeriodId) {
    title =  `${formatMessage({
      id:  "period.overtime.abbreviation",
      defaultMessage: "OT",
    })}${periodId - fixtureProfile.initialExtraPeriodId + 1}`;
  }

  if (["handball", "hockey"].includes(sport)
    && periodId >= fixtureProfile.shootOutPeriodId)
  {
    title = formatMessage({
      id: "period.overtime.shootout.abbreviation",
      defaultMessage: "SO",
    });
  }

  return title;
}
