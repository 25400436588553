// Jumpball Steal Player Select
export var JumpballStealPlayer = {
  name: "jumpball-turnover-player",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "steal.select-player",
  allowFlag: true,
  allowCancel: false,
  showDetails: { events: ["jumpball", "turnover"] },

  showOn: [
    { state: "primary", value: "jumpball-steal-player", operator: "is" },
  ],
  children: [
    // Select Player
    {
      name: "jb-steal-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      startEvent: true,
      startPlay: false,
      options: {
        currentEntity: false,
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "steal", type: "eventType" },
            {
              action: "clearData",
              value: ["subType", "options", "scores", "success"],
              type: "main",
            },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "copyEvent", value: "steal", type: "to" },
            {
              action: "nextState",
              value: null,
              type: "primary",
            },
            { action: "clearData", value: ["options"], type: "main" },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
        },
      ],
      conditions: [],
    },
  ],
};
