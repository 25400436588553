import React from "react";
import { injectIntl } from "react-intl";

import useEntityDataByPanelName from "../../Base/Hooks/useEntityDataByPanelName";
import { useIsVisible } from "../../Base/Hooks/IsVisible";
import Team from "./Team";

import "./Teams.scss";

const Teams = (props) => {
  const { panel, currentState } = props;
  const [isVisible, isEnabled] = useIsVisible(panel, props);

  const { entity, entityIndex } = useEntityDataByPanelName(panel.name, currentState, true);

  return (
    <React.Fragment>
      {isVisible && (
        <div className={"teams-base enabled-" + isEnabled}>
          <Team entity={entity} index={entityIndex} {...props} />{" "}
        </div>
      )}
    </React.Fragment>
  );
};

export default injectIntl(Teams);
