export const ShotBlockedPlayerSelect = {
  name: "shot-blocked-player",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "shot.select-blocking-player",
  showOn: [{ state: "primary", value: "shot-blocked-player", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  startEvent: true,
  startPlay: false,
  children: [
    {
      name: "shot-blocked-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      options: { currentEntity: false, showBench: false, showCoach: false },
      actionGroups: [
        {
          actions: [
            { action: "newEventId", value: null, type: "main" },
            { action: "saveData", value: "block", type: "eventType" },
            { action: "saveData", value: true, type: "success" },
            {
              action: "clearData",
              value: ["subType", "options"],
              type: "main",
            },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
