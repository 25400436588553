import React, { useState, useEffect, useMemo } from "react";
import { injectIntl } from "react-intl";

const EditControls = (props) => {
  const { currentState, event, intl, workFlow, updateState } = props;
  const { formatMessage } = intl;
  const [editOptions, setEditOptions] = useState();
  const [currentType, setCurrentType] = useState();
  const [currentOption, setCurrentOption] = useState();
  const [shouldShow, setShouldShow] = useState(true);

  useEffect(() => {
    if (workFlow && workFlow.modify && workFlow.modify.types) {
      workFlow.modify.types.map((type) => {
        if (type.eventTypes.includes(event.eventType)) {
          if (
            (type.subTypeIgnore &&
              !type.subTypeIgnore.includes(event.subType)) ||
            !type.subTypeIgnore
          ) {
            let editableTypes = type.editable.filter(
              (type) => _checkConditions(type) === true
            );

            if (event.success === type.success || type.success === null) {
              if (!type.subType) {
                setEditOptions(editableTypes);
                setCurrentType(type);
                setCurrentOption(
                  !currentOption ? (editableTypes[0]?.type || '') : currentOption
                );
              } else {
                switch (type.subType.operator) {
                  case "is":
                    if (type.subType.subType === event.subType) {
                      setEditOptions(editableTypes);
                      setCurrentType(type);
                      setCurrentOption(
                        !currentOption ? (editableTypes[0]?.type || '') : currentOption
                      );
                    }
                    break;
                  case "not":
                    if (type.subType.subType !== event.subType) {
                      setEditOptions(editableTypes);
                      setCurrentType(type);
                      setCurrentOption(
                        !currentOption ? (editableTypes[0]?.type || '') : currentOption
                      );
                    }
                    break;
                  default:
                    break;
                }
              }
            }
          }
        }
        return true;
      });
    }

    // eslint-disable-next-line
  }, [event]);

  function _doAction() {
    currentState.events["main"] = JSON.parse(JSON.stringify(event));
    currentState.events["edit"] = JSON.parse(JSON.stringify(event));
    let tempType = currentType.editable.find((el) => el.type === currentOption);
    if (typeof tempType.linkedEventType !== "undefined") {
      updateState("linkedEventType", tempType.linkedEventType);
    }
    updateState("editState", tempType.panel);
  }

  function handleSelect(e) {
    setCurrentOption(e.target.value);
  }

  function _checkConditions(option) {
    if (option.conditions) {
      let showArray = [];
      option.conditions.map((condition) => {
        switch (condition.operator) {
          case "is":
            if (
              typeof event[condition.item] === "undefined" &&
              (condition.value === null || condition.value === undefined)
            ) {
              showArray.push(true);
              setShouldShow(true);
            } else {
              switch(condition.item) {
                case "options":
                  if(event.options && event.options[condition.value] === true) {
                    showArray.push(true)
                  } else {
                    showArray.push(false)
                  }
                  break;
                default:
                  showArray.push(
                    event[condition.item] === condition.value ? true : false
                  );
                  break;
              }
            }
            break;
          case "not":
            if (
              typeof event[condition.item] !== "undefined" &&
              (condition.value === null || condition.value === undefined)
            ) {
              showArray.push(true);
            } else {
              switch(condition.item) {
                case "options":
                  if(!event.options || (event.options && event.options[condition.value] !== true))  {
                    showArray.push(true)
                  } else {
                    showArray.push(false)
                  }
                  break;
                default:
                  showArray.push(
                    event[condition.item] &&
                      event[condition.item] !== condition.value
                      ? true
                      : false
                  );
                  if (
                    typeof event[condition.item] === "undefined" &&
                    condition.value === null
                  ) {
                    showArray.push(false);
                    // setShouldShow(false);
                  }
                  break;
              }
            }
            break;
          default:
            break;
        }
        return false;
      });
      return showArray.includes(false) ? false : true;
    } else {
      return true;
    }
  }

  const selectOptions = useMemo(() => {
    if (editOptions && !!editOptions.length) {
      if (editOptions[0].type !== "delete_only") {
        return (
          <select
            className="edit-options"
            onChange={handleSelect}
            defaultValue={editOptions[0].type}
          >
            {editOptions.map((option, index) => {
              return (
                <React.Fragment key={index}>
                  <option key={option.type} value={option.type}>
                    {formatMessage({
                      id: option.text,
                      defaultMessage: option.text,
                    })}
                  </option>
                </React.Fragment>
              );
            })}
          </select>
        );
      } else {
        return null;
      }
    }
    // eslint-disable-next-line
  }, [editOptions]);

  const isDeleteEnabled = useMemo(() => {
    const areDeleteConditionsMet =
      currentType &&
      _checkConditions({
        conditions: currentType.delete_conditions || [],
      });
    return currentType && currentType.delete && areDeleteConditionsMet;
    // eslint-disable-next-line
  }, [currentType]);

  function _doDelete() {
    updateState("deleteMode", {
      enabled: true,
      event: event,
      currentType: currentType,
    });
  }

  return (
    <React.Fragment>
      {editOptions && (
        <React.Fragment>
          {selectOptions && (
            <>
              {shouldShow && (
                <>
                  {selectOptions}
                  <div className="button action" onClick={() => _doAction()}>
                    <i className="fas fa-arrow-right"></i>
                  </div>
                </>
              )}
            </>
          )}
          {isDeleteEnabled ? (
            <div className="button delete" onClick={() => _doDelete()}>
              <i className="far fa-trash-alt"></i>
            </div>
          ) : (
            <div className="button blank"></div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default injectIntl(EditControls);
