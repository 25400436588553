import React, { useEffect, useState } from "react";
import { v1 as uuidv1 } from "uuid";
import { injectIntl } from "react-intl";

import FreeThrowResult from "./FreeThrowResult";
import PlayerSelect from "../../../PlayerSelect/PlayerSelect";
import Cancel from "./Cancel/Cancel";

import { useDoActions } from "../../../../Base/Hooks/doActions";
import { getEventTime, sendEvent } from "../../../../Base/Utilities/SendEvent";

import "./Freethrow.scss";

const Freethrows = (props) => {
  const { currentState, panel, intl, updateState } = props;
  const { formatMessage } = intl;
  const [freeThrows, setFreeThrows] = useState();
  const [suggestedPlayer, setSuggestedPlayer] = useState();
  const [freeThrowResults, setFreeThrowResults] = useState([]);
  const [freeThrowSuccesses, setFreeThrowSuccesses] = useState([]);
  const [newResult, setNewResult] = useState(null);
  const [freeThrowsDone, setFreeThrowsDone] = useState(false);
  const [freeThrowsStarted, setFreeThrowsStarted] = useState(false);
  const [playId, setPlayId] = useState();
  const [entityId, setEntityId] = useState();
  const [shooter, setShooter] = useState(null);
  const [assister, setAssister] = useState(null);
  const [shotOptions, setShotOptions] = useState(null);
  const [fastBreak, setFastBreak] = useState(
    currentState?.events?.shot?.options?.fastBreak ?? false
  );
  const [inInsertMode, setInInsertMode] = useState(false);
  const [done, setDone] = useState(false);
  const [doAction, setDoAction] = useState(false);
  const [actionsDone] = useDoActions(panel, props, doAction);

  useEffect(() => {
    if (actionsDone) {
      setDoAction(false);
    }
  }, [actionsDone]);

  useEffect(() => {
    if (done) {
      const anySuccess = freeThrowResults.find((el) => el.success === true);
      let currentEvents = currentState.events;
      currentEvents["shot"] = JSON.parse(
        JSON.stringify(currentState.events["main"])
      );

      if (anySuccess && assister) {
        currentEvents.main["eventId"] = uuidv1();
        currentEvents.main["eventType"] = "assist";
        currentEvents.main["personId"] = assister;
        currentEvents.main["playId"] = playId;
        delete currentEvents.main["subType"];
        delete currentEvents.main["y"];
        delete currentEvents.main["x"];
        delete currentEvents.main["success"];
        delete currentEvents.main["options"];
        updateState("events", currentEvents);

        const action = {
          action: "sendEvent",
          value: "new",
          type: "main",
        };
        sendEvent(action, props);
      }

      currentEvents.main["success"] =
        freeThrowResults[freeThrowResults.length - 1].success;
      updateState("events", currentEvents);

      setDoAction(true);
    }

    // eslint-disable-next-line
  }, [done]);

  useEffect(() => {
    if (currentState.events.main?.options?.wasStashed) {
      const stashedPlayId = currentState.events["main"].playId;
      updateState("playId", stashedPlayId);
      setPlayId(stashedPlayId);
      const newOptions = setOptions(currentState.events);
      setShotOptions(newOptions);
    } else {
      const newPlayId = uuidv1();
      updateState("playId", newPlayId);
      setPlayId(newPlayId);
      const newMainEvent = buildNewMainEvent(currentState.events, newPlayId);
      newMainEvent.options.successes =
        currentState.events.main?.options?.successes &&
        currentState.events.main.options.successes.length > 0
          ? currentState.events.main.options.successes
          : freeThrowSuccesses;
      updateState("events", { ...currentState.events, main: newMainEvent });
    }

    const freeThrowsCount =
      currentState.events.main?.options?.freeThrows > 0
        ? currentState.events.main.options.freeThrows
        : 0;

    setFreeThrowSuccesses(
      currentState.events.main.options.successes.length > 0
        ? currentState.events.main.options.successes
        : new Array(freeThrowsCount).fill(null)
    );

    setFreeThrows(freeThrowsCount);
    setSuggestedPlayer(
      currentState.events.fouldrawn && currentState.events.fouldrawn.personId
        ? currentState.events.fouldrawn.personId
        : null
    );

    if (currentState.insertMode && currentState.insertMode.enabled) {
      setInInsertMode(true);
    }
    currentState.events["freethrow"] = { ...currentState.events["main"] };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const newResults = [...Array(freeThrows).keys()].map((itemIndex) => {
      const wasItemSuccess = freeThrowSuccesses[itemIndex] ?? null;
      return { success: wasItemSuccess };
    });
    setFreeThrowResults(newResults, freeThrowSuccesses);
    // eslint-disable-next-line
  }, [freeThrows, freeThrowSuccesses]);

  useEffect(() => {
    let tempEntity = {};
    let currentEntityEvent = panel.options.currentEntityEvent
      ? panel.options.currentEntityEvent
      : "main";
    if (panel.options.currentEntity === true) {
      tempEntity = currentState.entities.filter(
        (team) =>
          team.entityId === currentState.events[currentEntityEvent].entityId
      )[0];
    } else if (panel.options.currentEntity === false) {
      tempEntity = currentState.entities.filter(
        (team) =>
          team.entityId !== currentState.events[currentEntityEvent].entityId
      )[0];
    } else {
      tempEntity = currentState.entities.filter(
        (team) => team.entityId !== null
      )[panel.options.team];
    }
    setEntityId(tempEntity.entityId);
    // eslint-disable-next-line
  }, [panel]);

  useEffect(() => {
    setShooter(suggestedPlayer);
    // eslint-disable-next-line
  }, [suggestedPlayer]);

  function updateShooter(person) {
    setShooter(person.personId);
  }

  function updateAssist(person) {
    setAssister(person.personId);
  }

  useEffect(() => {
    if (newResult) {
      const newFreeThrowResults = [...freeThrowResults];
      newFreeThrowResults[newResult.index].success = newResult.result;
      setFreeThrowResults(newFreeThrowResults);
      setFreeThrowsStarted(true);
      setNewResult(null);
    }
    // eslint-disable-next-line
  }, [newResult]);

  useEffect(() => {
    if (
      freeThrowResults.filter((res) => res.success !== null).length <
      freeThrowResults.length
    ) {
      setFreeThrowsDone(false);
    } else {
      setFreeThrowsDone(true);
    }
  }, [freeThrowResults]);

  const updateResults = (index, result) => {
    setNewResult({ index: index, result: result });
  };

  function showTimeout() {
    if (panel.options.timeoutPanel && panel.options.timeoutPanelType) {
      const currentStash = props.currentState.stash || [];
      const currentEvents = props.currentState.events;
      currentEvents.main["options"] = setOptions(currentEvents);
      currentEvents.main["options"].wasStashed = true;

      currentStash.push({
        events: JSON.parse(JSON.stringify(currentEvents)),
        state: { secondary: "free-throws" },
      });
      updateState("stash", currentStash);

      updateState(panel.options.timeoutPanelType, panel.options.timeoutPanel);
    }
  }

  function showTechnicalFoul() {
    const currentStash = props.currentState.stash || [];
    const currentEvents = props.currentState.events;
    currentEvents.main["options"] = setOptions(currentEvents);
    currentEvents.main["options"].wasStashed = true;

    currentStash.push({
      events: JSON.parse(JSON.stringify(currentEvents)),
      state: { secondary: "free-throws" },
    });
    updateState("stash", currentStash);
    updateState("secondary", null);
    updateState("primary", "technical-foul-from-ft");
  }

  function _formatClock(displayClock) {
    let clockParts = displayClock.split(":");
    return "PT" + parseInt(clockParts[0]) + "M" + parseInt(clockParts[1]) + "S";
  }

  function setOptions(currentEvents) {
    let newOptions = currentEvents["freethrow"]
      ? currentEvents["freethrow"].options
      : currentEvents["main"].options;
    if (!newOptions) {
      newOptions = {};
    }

    let lastRebound =
      currentEvents && currentEvents["rebound"]
        ? currentEvents["rebound"]
        : null;

    let lastTurnover =
      currentEvents && currentEvents["turnover"]
        ? currentEvents["turnover"]
        : null;

    if (lastRebound && lastRebound.entityId === currentEvents.main.entityId) {
      newOptions["secondChance"] = true;
    }

    if (lastTurnover && lastTurnover.entityId !== currentEvents.main.entityId) {
      newOptions["fromTurnover"] = true;
    }

    if (fastBreak) {
      newOptions["fastBreak"] = true;
    }

    // Remove temp Rebounds and teap turnovers
    if (currentEvents) {
      delete currentEvents.rebound;
      delete currentEvents.turnover;
      updateState("events", currentEvents);

      return newOptions;
    }
  }

  function buildNewMainEvent(currentEvents, playId) {
    const newOptions = setOptions(currentEvents);
    const newMainEvent = {
      ...currentEvents["main"],
      playId,
      eventTime: getEventTime(),
      clock: _formatClock(currentState.clock.displayTime),
      periodId: currentState.period.periodId,
      eventId: uuidv1(),
      eventType: "freeThrow",
      entityId: currentState.entities.find(
        (el) => el.entityId !== currentState.events.foul.entityId
      ).entityId,
      options: newOptions,
    };
    delete newMainEvent["subType"];
    setShotOptions(newOptions);
    return newMainEvent;
  }

  return (
    <React.Fragment>
      <div className="freethrow">
        <Cancel {...props} enabled={!freeThrowsStarted} />
        <div className="left-panel">
          <h4>
            {formatMessage({
              id: "freethrows",
              defaultMessage: "freethrows",
            })}
          </h4>
          {freeThrowSuccesses.length > 0 &&
            [...Array(freeThrows).keys()].map((itemIndex) => (
              <FreeThrowResult
                {...props}
                key={itemIndex + 1}
                item={itemIndex + 1}
                total={freeThrows}
                shooter={shooter}
                updateResults={updateResults}
                entityId={entityId}
                playId={playId}
                shotOptions={shotOptions}
                fastBreak={fastBreak}
                wasSuccess={freeThrowSuccesses[itemIndex]}
              />
            ))}
          {!inInsertMode && panel.options.timeoutPanel && (
            <>
              <div
                className={"match-button enabled-" + !freeThrowsDone}
                onClick={() => showTimeout()}
              >
                {formatMessage({
                  id: "timeout",
                  defaultMessage: "Timeout",
                })}
              </div>
              <div
                className={"match-button enabled-" + !freeThrowsDone}
                onClick={() => showTechnicalFoul()}
              >
                {formatMessage({
                  id: "foul.technical",
                  defaultMessage: "Technical Foul",
                })}
              </div>
            </>
          )}
          <div
            className={"match-button action enabled-" + freeThrowsDone}
            onClick={() => setDone(true)}
          >
            {formatMessage({
              id: "done",
              defaultMessage: "Done",
            })}
          </div>
        </div>
        <div className="right-panel player-select">
          <h4>
            {formatMessage({
              id: "player.shooting",
              defaultMessage: "Shooting player",
            })}
            :
          </h4>
          <PlayerSelect
            {...props}
            update={updateShooter}
            selectedPlayer={shooter}
          />
          {panel.options.showAssists && freeThrows > 1 && (
            <React.Fragment>
              <h4>
                {formatMessage({
                  id: "player.assist",
                  defaultMessage: "Assist player",
                })}
                :
              </h4>
              <PlayerSelect
                {...props}
                update={updateAssist}
                selectedPlayer={assister}
                excludedPlayer={shooter}
              />
            </React.Fragment>
          )}
          <div
            className="freethrow-fastbreak enabled-true"
            onClick={() => setFastBreak(!fastBreak)}
          >
            {fastBreak ? (
              <i className="far fa-check-square"></i>
            ) : (
              <i className="far fa-square"></i>
            )}
            <span>
              {formatMessage({
                id: "fastBreak",
                defaultMessage: "Fast Break",
              })}
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default injectIntl(Freethrows);
