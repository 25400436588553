import React from "react";
import Player from "./Player";

import "./Players.scss";

function Players(props) {
  const { players, entity, teamColor } = props;
  return (
    <div className="ice3-players-layout">
      {players.map((player) => (
        <Player
          key={player.personId}
          entity={entity}
          player={player}
          teamColor={teamColor}
          {...props}
        >
          {player.bib}
        </Player>
      ))}
    </div>
  );
}

export default Players;
