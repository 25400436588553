import { set, get, entries, del } from "idb-keyval";
import Paho from "paho-mqtt";
import { v1 as uuidv1 } from "uuid";

import defaultSetup from "../../../Config/defaultSetupConfiguration";

import { getFixtureStore } from "../Factories/getFixtureStore";
import { Tracing } from "../../../App.tracing";
import SequenceIdGenerator from "./SequenceIdGenerator";
import AckWatcher from "./AckWatcher";
import Logger from "./Logger";

const MIN_DELAY = 10; // in milliseconds
let lastEventTimeStamp = 0;

const idGenerator = SequenceIdGenerator.getInstance();

const ackWatcher = new AckWatcher();

const logger = Logger.getInstance();

/**
 * Returns next valid event time (at least 10msec after most recent new event)
 */
export const getEventTime = () => {
  const current = new Date();
  const currentMs = current.getTime();
  if (currentMs - lastEventTimeStamp > MIN_DELAY) {
    lastEventTimeStamp = currentMs;
    return current.toISOString();
  } else {
    const delayed = new Date(lastEventTimeStamp + MIN_DELAY);
    lastEventTimeStamp = delayed.getTime();
    return delayed.toISOString();
  }
};

export const getEventTimeForUpdatedDeletedEvents = (event) => {
  const eventTime = new Date(`${event.eventTime}${event.eventTime.includes("Z") ? "" : "Z"}`);
  const updatedEventTime = new Date(eventTime.getTime() + MIN_DELAY).toISOString();

  return updatedEventTime;
};

export async function sendEvent(action, props, manualEvent = null) {
  const eventStore = configureStore(props, "event");
  const playStore = configureStore(props, "play");
  let csi = props.currentState.insertMode;
  const clearOptions = [
    "foulOnShot",
    "successes",
    "wasStashed",
    "isCalledFromFT",
    "wasTechnical",
    "fromCircleEntry",
    "fromPenaltyStroke",
    "and1InPP",
  ];

  const clearFields = ["shouldSend"];
  const conditionallyClearOptions = [{ eventType: "freeThrow", field: "freeThrows" }];
  let event = {};
  // Scoring events to check on insert;
  let scoreTypes = defaultSetup.scoreTypes;
  let updateTypes = {
    timeOut: "timeouts",
    foul: "fouls",
    ignoreSubTypes: ["drawn", "benchTechnical", "coachDisqualifying", "coachTechnical"],
  };

  let originalEvent = null;
  if (manualEvent !== null) {
    event = manualEvent;
  } else {
    if (action.type !== "custom" && action.type !== "notification_response") {
      originalEvent = props.currentState.events[action.type ? action.type : "main"];

      event = JSON.parse(JSON.stringify(originalEvent));
    } else {
      event = JSON.parse(JSON.stringify(action.event));
    }
  }

  if (!props?.panel?.options?.immutableDateTime) {
    let timestamp = new Date();
    event.timestamp = action.value !== "new" ? timestamp.toISOString() : event.timestamp ?? timestamp.toISOString();
  }

  event.class = event.class ?? "sport";

  if (event?.options?.possible) {
    event.class = "internal";
  }

  if (!csi?.enabled) {
    event.status = event.status ?? "added";
  }

  let scores = {};
  props.currentState.entities.forEach((team) => {
    scores[team.entityId] = team.score;
  });

  if (event.status === "insert" || event.status === "deleted" || event.status === "added") {
    let tempEntities = JSON.parse(JSON.stringify(props.currentState.entities));

    if (event.entityId) {
      if (Object.keys(scoreTypes).includes(event.eventType) && event.success === true) {
        if (event.status === "insert" || event.status === "added") {
          scores[event.entityId] = scores[event.entityId] + scoreTypes[event.eventType];
        }
        if (!csi || (csi && !csi.enabled)) {
          if (event.status === "deleted") {
            scores[event.entityId] = scores[event.entityId] - scoreTypes[event.eventType];
          }
        }
      }
      // Update current Scores
      let teamIndex = tempEntities.findIndex((team) => team.entityId === event.entityId);
      tempEntities[teamIndex].score = scores[event.entityId];
    }
    if (Object.keys(updateTypes).includes(event.eventType)) {
      let entityIndex = tempEntities.findIndex((ent) => ent.entityId === event.entityId);
      if (entityIndex > -1 && !updateTypes.ignoreSubTypes.includes(event.subType)) {
        if (event.status === "insert") {
          tempEntities[entityIndex][updateTypes[event.eventType]] =
            tempEntities[entityIndex][updateTypes[event.eventType]] + 1;
        }
        if (csi && !csi.enabled) {
          if (event.status === "deleted") {
            tempEntities[entityIndex][updateTypes[event.eventType]] =
              tempEntities[entityIndex][updateTypes[event.eventType]] - 1;
          }
        }
      }
    }
    props.updateState("entities", tempEntities);
  }

  event.scores = scores;

  if (event.flagged === false) {
    delete event.flagged;
  }
  switch (action.value) {
    case "edit": {
      if (!props?.panel?.options?.immutableDateTime) {
        const nextEventTime = getEventTimeForUpdatedDeletedEvents(event);
        event.eventTime = nextEventTime;
        if (originalEvent) {
          originalEvent.eventTime = nextEventTime;
        }
      }
      event.status = "updated";
      break;
    }
    case "delete": {
      const nextEventTime = getEventTimeForUpdatedDeletedEvents(event);
      if (originalEvent) {
        originalEvent.eventTime = nextEventTime;
      }
      event.eventTime = getEventTimeForUpdatedDeletedEvents(event);
      event.status = "deleted";
      break;
    }
    case "new":
      event.status = "added";
      break;
    default:
      event.status = event.status ? event.status : "added";
      break;
  }
  if (event.status === "insert") {
    event.status = "added";
  }

  if (event.status === "deleted") {
    const { currentState, updateState } = props;
    const { deletedEventIds } = currentState;
    updateState("deletedEventIds", [...deletedEventIds, event.eventId]);
  }

  clearFields.forEach((fieldName) => {
    delete event[fieldName];
  });

  // Clean up invalid options
  if (event.options) {
    for (const [key] of Object.entries(event.options)) {
      if (clearOptions.includes(key)) {
        delete event.options[key];
      }
    }

    const clearOption = conditionallyClearOptions.find((option) => option.eventType === event.eventType);
    if (clearOption) {
      delete event.options[clearOption.field];
    }
  }

  delete event.bench;
  delete event.coach;
  delete event.temp;
  delete event.pending;
  delete event.blocked;
  delete event.back;

  // set(event.eventId, event, eventStore);

  if (csi && csi.enabled) {
    event.eventTime = csi.insertDate;
    event.clock = csi.clock;
    event.periodId = csi.play.periodId;
  }

  if (props.currentState.lastEvent) {
    if (props.currentState.lastEvent.playId === event.playId && event.status !== "updated") {
      let lastDate = new Date(props.currentState.lastEvent.eventTime);
      let eventDate = new Date(event.eventTime);
      if (eventDate <= lastDate && event.eventId !== props.currentState.lastEvent.eventId) {
        lastDate.setMilliseconds(lastDate.getMilliseconds() + 10);
        event.eventTime = lastDate.toISOString();
      }
      props.updateState("lastEvent", {
        playId: event.playId,
        eventId: event.eventId,
        eventTime: event.eventTime,
      });
    }
  }

  event.sequence = idGenerator.getId();

  let messageContent = JSON.stringify({
    type: "event",
    fixtureId: props.currentState.fixtureId,
    data: event,
    clientType: defaultSetup.options.clientType,
  });

  if (csi && csi.enabled) {
    storeInsert(event, props);
  } else {
    const message = new Paho.Message(messageContent);
    message.destinationName = props.currentState.mqtt.topic;
    if (props.currentState.connected && props.currentState.mqttConnected) {
      try {
        props.currentState.mqtt.client.send(message);
        ackWatcher.watchEvent(event);
      } catch (err) {
        Tracing.capture(err);
        console.error("Send Error", err);
      }
    }
    event.pending = true;
    props.manageEvents(event.eventId, event);
    set(event.eventId, event, eventStore);

    let playObject = {
      playId: event.playId,
      eventTime: event.eventTime,
    };

    if (event.class === "sport") {
      playObject.clock = event.clock;
      playObject.periodId = event.periodId;
    }

    props.managePlays(event.playId, playObject);
    set(event.playId, playObject, playStore);
  }
}

export function handleLinkedEvent(action, props) {
  let mainEvent = {};
  if (action.type !== "custom") {
    mainEvent = JSON.parse(JSON.stringify(props.currentState.events["main"]));
  } else {
    mainEvent = JSON.parse(JSON.stringify(action.event));
  }

  let linkedEvent = props.eventStore.find(
    (event) => event.playId === mainEvent.playId && event.eventType === props.currentState.linkedEventType.type,
  );

  if (linkedEvent) {
    linkedEvent[props.currentState.linkedEventType.value] = mainEvent[props.currentState.linkedEventType.value];
    sendEvent(action, props, linkedEvent);
  }
  props.updateState("linkedEventType", null);
}

export function sendUpdatedEvent(payload, props) {
  payload.sequence = idGenerator.getId();
  const messageContent = JSON.stringify({
    type: "event",
    fixtureId: props.currentState.fixtureId,
    data: payload,
    clientType: defaultSetup.options.clientType,
  });
  const message = new Paho.Message(messageContent);

  message.destinationName = props.currentState.mqtt.topic;

  if (props.currentState.mqttConnected) {
    try {
      props.currentState.mqtt.client.send(message);
    } catch (err) {
      Tracing.capture(err);
      console.error("Send Error", err);
    }
  }
}

export function sendNotification(props, payload, topic) {
  payload.data.sequence = idGenerator.getId();
  let messageContent = JSON.stringify(payload);
  var message = new Paho.Message(messageContent);

  message.destinationName = topic;

  props.manageEvents(payload.data.id, payload.data);

  if (props.currentState.mqttConnected) {
    try {
      props.currentState.mqtt.client.send(message);
    } catch (err) {
      Tracing.capture(err);
      console.error("Send Error", err);
    }
  }
}

export function sendHeartBeat(props) {
  let uuid = uuidv1();
  let messageContent = JSON.stringify({
    type: "event",
    fixtureId: props.currentState.fixtureId,
    data: {
      class: "heartbeat",
      eventType: "client",
      eventTime: new Date().toISOString(),
      timestamp: new Date().toISOString(),
      eventId: uuid,
    },
    clientType: defaultSetup.options.clientType,
  });
  var message = new Paho.Message(messageContent);
  message.destinationName = props.currentState.mqtt.topic;
  if (props.currentState.mqttConnected) {
    try {
      props.currentState.mqtt.client.send(message);
    } catch (err) {
      logger.log(err);
      Tracing.capture(err);
      console.error("Send Error", err);
    }
  }
  return uuid;
}

export async function confirmEvent(message, props) {
  const eventStore = configureStore(props, "event");
  const playStore = configureStore(props, "play");
  if (message.code === "SUCCESS") {
    let eventId = message.message.split(": ")[1];

    ackWatcher.ackReceivedForEventId(eventId);

    get(eventId, eventStore).then((val) => {
      if (val) {
        val.pending = false;
        props.manageEvents(val.eventId, val);
        props.updateState("updateLog", val.eventId + "a");
        let acks = props.currentState.acks ? props.currentState.acks : [];
        if (acks.findIndex((item) => item === val.eventId) === -1) {
          acks.push(val.eventId);
          props.updateState("acks", acks);
        }
        del(val.eventId, eventStore).then(() => {
          entries(eventStore).then((entries) => {
            let eventsRemaining = entries.filter((entry) => (entry.playId = val.playId));
            if (!eventsRemaining) {
              del(val.playId, playStore);
            }
          });
        });
        // set(val.eventId, val, eventStore).then(() => {});
      }
    });
  }
}

export async function retryEvents(props) {
  const eventStore = configureStore(props, "event");
  entries(eventStore).then((entries) => {
    entries.forEach((entry, i) => {
      setTimeout(function () {
        if (entry[1].pending) {
          delete entry[1].pending;
          const data = entry[1];

          if (data.sequence > idGenerator.readId()) {
            data.sequence = idGenerator.getId();
          }

          let messageContent = JSON.stringify({
            type: "event",
            fixtureId: props.currentState.fixtureId,
            data,
            clientType: defaultSetup.options.clientType,
          });

          var message = new Paho.Message(messageContent);
          message.destinationName = props.currentState.mqtt.topic;
          if (props.currentState.connected && props.currentState.mqttConnected) {
            try {
              props.currentState.mqtt.client.send(message);
            } catch (err) {
              Tracing.capture(err);
              console.error("Send Error", err);
            }
          }
        }
      }, i * 100);
    });
  });
}

export function configureStore(props, type) {
  return getFixtureStore(props.currentState.fixtureId, type);
}

export function storeInsert(event, props) {
  let insertStore = props.currentState.insertStore ? props.currentState.insertStore : [];
  if (event.status === "deleted") {
    insertStore = insertStore.filter((ev) => ev.eventId !== event.eventId);
  } else {
    insertStore.push(event);
  }
  props.updateState("insertStore", insertStore);
}

export function sendInternalEvent(eventType, currentState) {
  const messageContent = JSON.stringify({
    type: "event",
    fixtureId: currentState.fixtureId,
    data: {
      class: "internal",
      eventType: eventType,
      eventTime: new Date().toISOString(),
      timestamp: new Date().toISOString(),
      eventId: uuidv1(),
    },
    clientType: defaultSetup.options.clientType,
  });
  const message = new Paho.Message(messageContent);
  message.destinationName = currentState.mqtt.topic;
  if (currentState.mqttConnected) {
    try {
      currentState.mqtt.client.send(message);
    } catch (err) {
      logger.log(err);
      Tracing.capture(err);
      console.error("Send Error", err);
    }
  }
}
