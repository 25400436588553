import {
  TurnoverTypes,
  TeamTurnoverTypes,
} from "../../subTypes/EliteTurnoverTypes";

// Turnover Type Panel
export var TurnoverType = {
  name: "turnover-type",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "turnover.type",
  showOn: [{ state: "primary", value: "turnover-type", operator: "is" }],
  startEvent: false,
  startPlay: false,
  allowFlag: true,
  allowCancel: true,
  cancelActions: [
    {
      actions: [
        {
          action: "setPossession",
          value: { currentEntity: true },
          type: "main",
        },
      ],
      conditions: [
        {
          item: "options",
          value: "isDefensive",
          event: "main",
          operator: "is",
        },
      ],
    },
  ],
  allowBack: { state: "primary", value: "turnover-player", event: "main" },
  showDetails: { events: ["main"] },
  children: [
    // Player Turnover Types
    {
      name: "player-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.personId",
          value: null,
          operator: "not",
          event: "main",
        },
      ],
      startEvent: false,
      startPlay: false,
      options: {
        value: "subType",
        children: TurnoverTypes,
        layout: "type-button",
      },
      actionGroups: [
        {
          actions: [{ action: "stopClock", value: "stop", type: "main" }],
          conditions: [
            {
              item: "subType",
              value: [
                "outOfBounds",
                "travel",
                "3Sec",
                "5Sec",
                "backCourt",
                "offensiveGoalTending",
                "doubleDribble",
                "carrying",
              ],
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "clearData", value: ["options"], type: "main" },
            { action: "copyEvent", value: "turnover", type: "to" },
            { action: "sendEvent", value: "new", type: "main" },
          ],
          conditions: [],
        },
        {
          actions: [
            {
              action: "nextState",
              value: "steal-player",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "subType",
              value: "badPass",
              event: "main",
              operator: "is",
            },
            {
              item: "subType",
              value: "ballHandling",
              event: "main",
              operator: "is",
            },
          ],
          operator: "any",
        },
        {
          actions: [
            { action: "nextState", value: null, type: "primary" },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [
            {
              item: "subType",
              value: "ballHandling",
              event: "main",
              operator: "not",
            },
            {
              item: "subType",
              value: "badPass",
              event: "main",
              operator: "not",
            },
          ],
        },
      ],
    },
    // Team Turnover Types
    {
      name: "team-types",
      component: "ButtonGroup",
      layout: "button-group",
      mode: "visible",
      showOn: [
        {
          state: "event.personId",
          value: null,
          operator: "is",
          event: "main",
        },
      ],
      startEvent: false,
      startPlay: false,
      options: {
        value: "subType",
        children: TeamTurnoverTypes,
        layout: "type-button",
      },
      actionGroups: [
        {
          actions: [
            { action: "stopClock", value: "stop", type: "main" },
            {
              action: "clearData",
              value: ["personId", "options"],
              type: "main",
            },
            {
              action: "saveData",
              value: [{ option: "team", value: true }],
              type: "options",
            },
            { action: "copyEvent", value: "turnover", type: "to" },
            { action: "sendEvent", value: "new", type: "main" },
          ],
          conditions: [],
        },
        {
          actions: [
            { action: "clearData", value: ["options"], type: "main" },
            { action: "nextState", value: null, type: "primary" },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
