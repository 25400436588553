import React, { useState, useEffect, useContext } from "react";
import { injectIntl } from "react-intl";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";
import Button from "../../Base/Button/Button";
import { createBaseEvent } from "../../Base/Utilities/CreateBaseEvent";
import { sendEvent } from "../../Base/Utilities/SendEvent";
import "./ShootoutClockController.scss";

function ShootoutClockController(props) {
  const { intl, currentState } = props;
  const { formatMessage } = intl;
  const [miliseconds, setMiliseconds] = useState(8000);
  const [isActive, setIsActive] = useState(false);
  const { fixtureProfile } = useContext(FixtureDetailsContext);

  const sendShootoutClockEvent = (subType, newValue) => {
    const action = createBaseEvent(currentState);
    action.event.class = "clock";
    action.event.eventType = "shootOut";
    action.event.subType = subType;

    if (subType === "adjust") {
      action.event.options = {
        value: `00:0${newValue}`,
      };
    }

    sendEvent(action, props);
  };

  useEffect(() => {
    let interval;

    if (isActive && miliseconds > 0) {
      interval = setInterval(() => {
        setMiliseconds((prevSeconds) => prevSeconds - 100);
      }, 90);
    }

    if (miliseconds === 0) {
      if (isActive) {
        sendShootoutClockEvent("stop");
      }
      setIsActive(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, miliseconds]);

  const startTimer = () => {
    sendShootoutClockEvent("start");
    setIsActive(true);
  };

  const stopTimer = () => {
    sendShootoutClockEvent("stop");
    setIsActive(false);
  };

  const resetTimer = () => {
    setIsActive(false);
    setMiliseconds(fixtureProfile.shootoutSeconds * 1000);
    sendShootoutClockEvent("adjust", fixtureProfile.shootoutSeconds);
  };

  const renderClock = () => {
    const secs = Math.floor(miliseconds / 1000);
    const milisecs = miliseconds - secs * 1000;
    return `00:0${secs}:${String(milisecs).padStart(2, "0")}`;
  };

  return (
    <div className="shootout-clock">
      <p>{renderClock()}</p>
      <div className="shootout-clock-controls">
        {miliseconds !== 0 && (
          <>
            {!isActive ? (
              <Button variant="success" onClick={startTimer}>
                {formatMessage({
                  id: "clock.start",
                  defaultMessage: "Start",
                })}
              </Button>
            ) : (
              <Button variant="danger" onClick={stopTimer}>
                {formatMessage({
                  id: "clock.stop",
                  defaultMessage: "stop",
                })}
              </Button>
            )}
          </>
        )}
        {(miliseconds === 0 || !isActive) && (
          <Button onClick={resetTimer}>
            {formatMessage({
              id: "clock.reset",
              defaultMessage: "Reset",
            })}
          </Button>
        )}
      </div>
    </div>
  );
}

export default injectIntl(ShootoutClockController);
