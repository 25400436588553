export const getPlayingPositions = (sport) => {
  if (sport === "basketball") {
    return [
      { name: "GUARD", position: "G" },
      { name: "POINT_GUARD", position: "PG" },
      { name: "SHOOTING_GUARD", position: "SG" },
      { name: "FORWARD", position: "F" },
      { name: "SMALL_FORWARD", position: "SF" },
      { name: "POWER_FORWARD", position: "PF" },
      { name: "GUARD_FORWARD", position: "GF" },
      { name: "FORWARD_GUARD", position: "FG" },
      { name: "FORWARD_CENTER", position: "FC" },
      { name: "CENTER_FORWARD", position: "CF" },
      { name: "CENTER", position: "C" },
    ];
  }
  if (sport === "ice3") {
    return [
      { name: "SKATER", position: "S" },
      { name: "GOALIE", position: "G" },
    ];
  }
  if (sport === "hockey") {
    return [
      { name: "GK", position: "GK" },
      { name: "O", position: "O" },
      { name: "D", position: "D" },
      { name: "FB", position: "FB" },
      { name: "HB", position: "HB" },
      { name: "SW", position: "SW" },
      { name: "M", position: "M" },
      { name: "CH", position: "CH" },
      { name: "DM", position: "DM" },
      { name: "AM", position: "AM" },
      { name: "F", position: "F" },
      { name: "CF", position: "CF" },
      { name: "LF", position: "LF" },
      { name: "RF", position: "RF" },
      { name: "S", position: "S" },
    ];
  }
  if (sport === "handball") {
    return [
      { name: "GOALKEEPER", position: "G" },
      { name: "CENTRE_BACK", position: "CB" },
      { name: "PIVOT", position: "P" },
      { name: "LEFT_BACK", position: "LB" },
      { name: "LEFT_WING", position: "LW" },
      { name: "RIGHT_BACK", position: "RB" },
      { name: "RIGHT_WING", position: "RW" },
    ];
  }
};
