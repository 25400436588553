const isPowerPlayEnabled = (state, fixtureProfile) => {
  const isEnabledInThisPeriod =
    fixtureProfile?.powerPlayAllowedPeriods?.includes(state.period.periodId);
  return isEnabledInThisPeriod;
};

const clockToSeconds = (clock) => {
  if (clock[0] === "P") {
    const clockParts = clock.substring(2).split("M");
    const seconds =
      parseInt(clockParts[0]) * 60 + parseInt(clockParts[1].slice(0, -1));
    return seconds;
  }
  const clockParts = clock.split(":");
  const seconds = parseInt(clockParts[0]) * 60 + parseInt(clockParts[1]);
  return seconds;
};

const isPowerPlayActive = (state, fixtureProfile, events) => {
  // double check if PP is enabled for the current fixture
  if (!isPowerPlayEnabled(state, fixtureProfile)) return false;

  const ppEventsInCurrentPeriod = events.filter(
    (e) => e.eventType === "powerPlay" && e.periodId === state.period.periodId
  );
  const currentClock = clockToSeconds(state.clock.displayTime);
  const starts = ppEventsInCurrentPeriod
    .filter((e) => e.subType === "start")
    .map((e) => clockToSeconds(e.clock));
  const ends = ppEventsInCurrentPeriod
    .filter((e) => e.subType === "end")
    .map((e) => clockToSeconds(e.clock));
  for (let i = 0; i < starts.length; ++i) {
    if (currentClock <= starts[i]) {
      const possibleEnd = ends[i] ?? -1;
      if (currentClock > possibleEnd) return true;
    }
  }
  return false;
};

export { isPowerPlayActive, isPowerPlayEnabled };
