import { v1 as uuidv1 } from "uuid";
import { getEventTime } from "./SendEvent";

export function createBaseEvent(currentState, existingPlayId) {
  let playId = existingPlayId ?? uuidv1();
  let sendMode = "new";
  let event = {};

  let timestamp = new Date();
  event["class"] = "sport";
  event["timestamp"] = timestamp.toISOString();
  event["eventTime"] = getEventTime();
  event["periodId"] = currentState.period.periodId;
  event["playId"] = playId;
  event["eventId"] = uuidv1();

  return {
    action: "sendEvent",
    value: sendMode,
    type: "custom",
    event: event,
  };
}
