import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";

import { useIsVisible } from "../../Base/Hooks/IsVisible";
import { useDoActions } from "../../Base/Hooks/doActions";
import { allowedValues } from "./MiniLocationConfig";
import "./MiniLocation.scss";

const MiniLocation = (props) => {
  const { panel, updateState, currentState } = props;
  const [isVisible, isEnabled] = useIsVisible(panel, props);
  const [doAction, setDoAction] = useState(false);
  const [events, setEvents] = useState();
  const [newPos, setNewPos] = useState();
  const [actionsDone] = useDoActions(panel, props, doAction);

  useEffect(() => {
    if (actionsDone) {
      setDoAction(false);
    }
    // eslint-disable-next-line
  }, [actionsDone]);

  useEffect(() => {
    setEvents(currentState.events);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (newPos && events) {
      let tempEvents = events;
      tempEvents.edit.x = newPos.x;
      tempEvents.edit.y = newPos.y;
      tempEvents.edit.eventType =
        events.edit.eventType === "2pt" ? "3pt" : "2pt";
      tempEvents.edit = checkAllowedValues(tempEvents.edit);
      updateState("events", tempEvents);
      setDoAction(true);
    }
    // eslint-disable-next-line
  }, [newPos]);

  function checkAllowedValues(event) {
    Object.keys(event.options).forEach((option) => {
      if (!allowedValues[event.eventType].options.includes(option)) {
        delete event.options[option];
      }
    });
    if (!allowedValues[event.eventType].subTypes.includes(event.subType)) {
      delete event.subType;
    }
    return event;
  }

  function getPos(e) {
    let posX = parseFloat(
      ((e.nativeEvent.offsetX / e.target.offsetWidth) * 100).toFixed(2)
    );
    let posY = parseFloat(
      ((e.nativeEvent.offsetY / e.target.offsetHeight) * 100).toFixed(2)
    );
    setNewPos({ x: posX, y: posY });
  }

  return (
    <React.Fragment>
      {isVisible && (
        <div
          className={panel.layout + " mini-location enabled-" + isEnabled}
          onClick={(e) => getPos(e)}
        >
          <div
            className="mini-location-marker"
            style={{
              top: events.edit.y - 1.5 + "%",
              left: events.edit.x - 1.5 + "%",
            }}
          ></div>
        </div>
      )}
    </React.Fragment>
  );
};

export default injectIntl(MiniLocation);
