export const Rink = {
  name: "position-select",
  component: "NewPositionSelect",
  layout: "rink-map",
  mode: "visible",
  showOn: [true],
  actionGroups: [
    {
      actions: [
        // Show Floating Panel
        { action: "nextState", value: "main-buttons", type: "primary" },
      ],
      conditions: [],
    },
  ],
};
