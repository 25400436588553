import React from "react";
import { Link, withRouter } from "react-router-dom";

import { ENVIRONMENT } from "../../Config/Environment";

const FixtureRow = (props) => {
  const { itemIndex, location, result, showSport } = props;
  const noTimeLimitEnv = ["nonprod"];

  function checkTime(date, hoursDiff) {
    let nowDate = new Date();
    let compareDate = new Date(date + (date.charAt(-1) !== "Z" ? "Z" : ""));
    var diff = (compareDate.getTime() - nowDate.getTime()) / 3600000;
    if (diff < hoursDiff || noTimeLimitEnv.includes(ENVIRONMENT)) {
      return true;
    } else {
      return false;
    }
  }

  function getLinkURL() {
    const base = result.status === "IN_PROGRESS" ? "game" : "details";
    return {
      pathname: `/${base}/${result.fixtureId}`,
      search: location.search,
    };
  }

  return (
    <tr className="fixture-row" key={itemIndex}>
      <td>{result.status}</td>
      <td className="date-time">
        <span className="date">{result.startTimeLocal.split("T")[0]}</span>
        <span className="time">
          {result.startTimeLocal.split("T")[1].replace(":00", "")}
        </span>
      </td>
      <td className="logo-cell">
        {result.teamHomeLogo && (
          <img
            className="fixture-logo"
            src={result.teamHomeLogo}
            alt={result.teamHome}
          />
        )}
      </td>
      <td>{result.teamHome}</td>
      <td className="logo-cell">
        {result.teamAwayLogo && (
          <img
            className="fixture-logo"
            src={result.teamAwayLogo}
            alt={result.teamAwayHome}
          />
        )}
      </td>
      <td>{result.teamAway}</td>
      <td>{result.venueName}</td>
      <td>{result.seasonName}</td>
      {showSport && <td>{result.sport}</td>}
      <td>
        {checkTime(result.startTimeUTC, 4) && (
          <Link to={getLinkURL()} className="link btn btn-sm btn-success">
            <i className="fas fa-angle-right"></i>
          </Link>
        )}
        {!checkTime(result.startTimeUTC, 4) && (
          <div className="btn btn-sm btn-secondary enabled-false">
            <i className="far fa-clock"></i>
          </div>
        )}
      </td>
    </tr>
  );
};

export default withRouter(FixtureRow);
