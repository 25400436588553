export const ShotPlayerSelect = {
  name: "shot-player",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "shot.select-player",
  showOn: [{ state: "primary", value: "shot-player", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelEvent: "main",
  showDetails: { events: ["main"] },
  children: [
    {
      name: "shot-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      options: { currentEntity: true, showBench: false, showCoach: false },
      actionGroups: [
        {
          actions: [
            { action: "sendEvent", value: "edit", type: "main" },
            { action: "newEventId", value: null, type: "main" },
            {
              action: "nextState",
              value: "shot-primary-assist",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "success",
              value: true,
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "sendEvent", value: "edit", type: "main" },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [
            {
              item: "success",
              value: false,
              event: "main",
              operator: "is",
            },
            {
              item: "optionValue",
              event: "main",
              operator: "not",
              name: "failureReason",
              value: "BLOCK",
            },
          ],
        },
        {
          actions: [
            { action: "sendEvent", value: "edit", type: "main" },
            {
              action: "nextState",
              value: "shot-blocked-player",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "success",
              value: false,
              event: "main",
              operator: "is",
            },
            {
              item: "optionValue",
              event: "main",
              operator: "is",
              name: "failureReason",
              value: "BLOCK",
            },
          ],
        },
        {
          actions: [
            { action: "sendEvent", value: "edit", type: "main" },
            { action: "newEventId", value: null, type: "main" },
            { action: "saveData", value: "save", type: "eventType" },
            { action: "saveData", value: true, type: "success" },
            { action: "modifyEvent", value: "swapEntities", type: "main" },
            {
              action: "modifyEvent",
              value: "assignPlayerWithRole::Goalie",
              type: "main",
            },
            {
              action: "clearData",
              value: ["subType", "options"],
              type: "main",
            },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [
            {
              item: "success",
              value: false,
              event: "main",
              operator: "is",
            },
            {
              item: "optionValue",
              event: "main",
              operator: "is",
              name: "failureReason",
              value: "SAVE",
            },
          ],
        },
      ],
    },
  ],
};
