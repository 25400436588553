import React, { useMemo } from "react";

import { buildComponentForPanel } from "../WorkflowBuilder/WorkflowBuilder";
import { useIsVisible } from "../../Base/Hooks/IsVisible";

import "./StackingPanel.scss";

const StackingPanel = (props) => {
  const [isVisible] = useIsVisible(props.panel, props);

  const layout = useMemo(() => {
    if (props.panel && props.panel.children) {
      return props.panel.children.map((panel, index) => {
        return (
          <React.Fragment key={index}>
            {buildComponentForPanel(panel, props)}
          </React.Fragment>
        );
      });
    }
    return <>Panel</>;
  }, [props]);

  const buildExtraClassNames = () => {
    if (props.panel.layout) {
      return props.panel.layout
        .split(" ")
        .map((str) => `stacking-panel--${str}`)
        .join(" ");
    }
    return "";
  };

  return (
    <div className={`stacking-panel ${buildExtraClassNames()}`}>
      {isVisible && layout}
    </div>
  );
};

export default StackingPanel;
