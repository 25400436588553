import React, { useEffect, useState, useCallback, useContext } from "react";
import { BrowserRouter as Router, Route, Redirect, useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Container } from "reactstrap";
import { get, keys } from "idb-keyval";
import { injectIntl } from "react-intl";

import defaultSetupConfiguration from "./Config/defaultSetupConfiguration";
import { FixtureDetailsProvider } from "./Config/FixtureDetails";
import { ConnectionProvider } from "./Config/Connection";
import { InactivityTrackerProvider } from "./Config/InactivityTracker";
import { AuthContext, AuthProvider } from "./Config/Auth";

import BasicLogin from "./Components/Login/BasicLogin";
import Fixtures from "./Components/Fixtures/Fixtures";
import ExternalFixture from "./Components/ExternalFixture/ExternalFixture";
import TeamSetup from "./Components/TeamSetup/TeamSetup";
import GameDetails from "./Components/GameDetails/GameDetails";
import StreamConnect from "./Components/StreamConnect/StreamConnect";
import StreamDebug from "./Components/StreamDebug/StreamDebug";
import Game from "./Components/Game/Game";
import OnlineCheck from "./Components/Base/OnlineCheck/OnlineCheck";
import Menu from "./Components/Menu/Menu";
import LanguageSelect from "./Components/Base/LanguageSelect/LanguageSelect";
import PageLoader from "./Components/Base/PageLoader/PageLoader";
import logo from "./assets/logo.png";
import { checkOnlineStatus } from "./Components/Base/Utilities/Online";
import { getFixtureStore } from "./Components/Base/Factories/getFixtureStore";
import MessagePopup from "./Components/Base/MessagePopup";
import Notification from "./Components/Base/Notification/Notification";
import { FixtureIdHandler } from "./Config/FixtureIdHandler";
import Logger from "./Components/Base/Utilities/Logger";
import ErrorBoundary from "./Components/Base/ErrorBoundary/ErrorBoundary";
import ReportIssue from "./Components/Collect/ReportIssue/ReportIssue";
import InactivityWarning from "./Components/Base/InactivityWarning/InactivityWarning";

import "./App.scss";
import OpenBetting from "./Components/Base/OpenBetting/OpenBetting";
import LockScoreBanner from "./Components/Base/LockScoreBanner/LockScoreBanner";

const logger = Logger.getInstance();

const ProtectedRoute = ({ currentState, render, ...rest }) => {
  const location = useLocation();
  const { accessToken, loading, setRedirectUrl } = useContext(AuthContext);

  if (loading) {
    // TODO loading state
    return null;
  }

  const renderUnauthorizedAccessRedirect = () => {
    setRedirectUrl(location.pathname);

    return <Redirect to="/login" />;
  };

  const renderPathAndClearRedirectUrl = (cb) => {
    setRedirectUrl("");
    return cb();
  };

  return (
    <Route
      {...rest}
      render={() => {
        return accessToken ? renderPathAndClearRedirectUrl(render) : renderUnauthorizedAccessRedirect();
      }}
    />
  );
};

const App = (props) => {
  const { debug, locale, intl } = props;
  const { formatMessage } = intl;
  const title = "Synergy Stats";
  const setup = defaultSetupConfiguration;
  const [mainState, setMainState] = useState({
    notifications: [],
    deletedEventIds: [],
  });
  const [, updateAllState] = React.useState();
  const forceUpdate = useCallback(() => updateAllState({}), []);

  const [playStore, setPlayStore] = useState([]);
  const [eventStore, setEventStore] = useState([]);
  const [fixtureId, setFixtureId] = useState(null);
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);

  function clearFixtureSpecificDateFromState() {
    setMainState({
      notifications: [],
      deletedEventIds: [],
      authKey: mainState.authKey,
      catchupsDone: false,
    });
  }

  function managePlays(key, data) {
    const getOldestEventFromPlay = (playId) => {
      const playEvents = eventStore.filter((ev) => ev.playId === playId);
      return playEvents.reduce((oldestEvent, currentEvent) => {
        if (
          new Date(currentEvent.eventTime.replace("Z", "")) <
          new Date((oldestEvent?.eventTime ?? new Date().toISOString()).replace("Z", ""))
        ) {
          return currentEvent;
        }
        return oldestEvent;
      });
    };

    let plays = playStore;
    let playIndex = plays.findIndex((play) => play.playId === key);
    if (playIndex > -1) {
      // treat the oldest event in a play as the eventTime of the play
      plays[playIndex] = {
        ...plays[playIndex],
        ...data,
      };
      const oldestEvent = getOldestEventFromPlay(key);
      plays[playIndex].eventTime = oldestEvent.eventTime;
    } else {
      plays.push(data);
    }
    setPlayStore(
      plays.sort((a, b) => (new Date(a.eventTime.replace("Z", "")) > new Date(b.eventTime.replace("Z", "")) ? -1 : 1)),
    );
    forceUpdate();
  }

  function manageEvents(key, data) {
    let events = eventStore;
    let eventIndex = events.findIndex((event) => event.eventId === key);
    if (eventIndex > -1) {
      if (data.status === "deleted") {
        events.splice(eventIndex, 1);
      } else {
        events[eventIndex] = data;
      }
    } else {
      if (data.status !== "deleted") {
        events.push(data);
      }
    }
    setEventStore(events);
    updateState("updateLog", key + Math.random());
    forceUpdate();
  }

  const updateState = useCallback(
    (key, data) => {
      if (key !== "updateLog" && key !== "mqtt") {
        logger.log("Updating state", {
          key,
          data: JSON.parse(JSON.stringify(data)),
        });
      }
      let thisState = mainState;
      thisState[key] = data;
      setMainState(thisState);
      forceUpdate();
    },
    [mainState, forceUpdate],
  );

  useEffect(() => {
    if (localStorage.getItem("authKey")) {
      updateState("authKey", localStorage.getItem("authKey"));
    }
    // eslint-disable-next-line
  }, []);

  const logout = () => {
    setShowConfirmLogout(false);
    updateState("authKey", null);
    localStorage.removeItem("authKey");
  };

  async function handleOnlineCheckClick() {
    const wasOffline = !mainState.connected || !mainState.mqttConnected;
    const connectUrl = mainState.connectUrl;
    const online = await checkOnlineStatus(connectUrl);
    logger.log("Conn: Reconnect clicked", mainState.connected, mainState.mqttConnected, online);
    if (online && wasOffline) {
      updateState("forceReconnect", true);
    }
  }

  useEffect(() => {
    setPlayStore([]);
    setEventStore([]);
    if (fixtureId) {
      const eventDB = getFixtureStore(fixtureId, "event");
      const playDB = getFixtureStore(fixtureId, "play");
      keys(eventDB).then((eventKeys) => {
        eventKeys.map((eventId) => {
          get(eventId, eventDB).then((eventDetail) => {
            manageEvents(eventId, eventDetail);
          });
          return true;
        });
        keys(playDB).then((playKeys) => {
          playKeys.map((playId) => {
            get(playId, playDB).then((playDetail) => {
              managePlays(playId, playDetail);
            });
            return true;
          });
        });
        updateState("fixtureId", fixtureId);
        if (!mainState.events) {
          let events = [];
          events.main = {};
          updateState("events", events);
        }
        if (!mainState.queue) {
          let queue = [];
          updateState("queue", queue);
        }
      });
    }
    // eslint-disable-next-line
  }, [fixtureId]);

  useEffect(() => {
    logger.resetLogs();
  }, [fixtureId]);

  return (
    <Router>
      <ErrorBoundary currentState={mainState}>
        <InactivityTrackerProvider>
          <InactivityWarning fixtureId={fixtureId} />
          <FixtureIdHandler fixtureId={fixtureId} setFixtureId={setFixtureId} />
          <HelmetProvider>
            <AuthProvider currentState={mainState} updateState={updateState}>
              <FixtureDetailsProvider
                key={mainState.fixtureId}
                fixtureId={mainState.fixtureId}
                currentState={mainState}
              >
                <ConnectionProvider
                  key={mainState.fixtureId}
                  fixtureId={mainState.fixtureId}
                  currentState={mainState}
                  updateState={updateState}
                  manageEvents={manageEvents}
                  managePlays={managePlays}
                >
                  <PageLoader currentState={mainState} />
                  <div className="App">
                    <div className="top">
                      <img className="main-logo" src={logo} alt="main-logo" />
                      <span className="app-title">{title}</span>
                      {mainState.authKey && mainState.orgnizationId && (
                        <Menu
                          orgnizationId={mainState.orgnizationId}
                          clock={mainState.clock}
                          locale={locale}
                          currentState={mainState}
                          formatMessage={formatMessage}
                        />
                      )}
                      <div className="top-nav-right-group">
                        <LockScoreBanner currentState={mainState} updateState={updateState} />
                        {mainState.authKey && <OpenBetting currentState={mainState} eventStore={eventStore} />}
                        {mainState.authKey && (
                          <OnlineCheck
                            currentState={mainState}
                            updateState={updateState}
                            onClick={handleOnlineCheckClick}
                          />
                        )}
                        <Notification
                          {...props}
                          currentState={mainState}
                          updateState={updateState}
                          manageEvents={manageEvents}
                          managePlays={managePlays}
                        />
                        <ReportIssue currentState={mainState} />
                        <LanguageSelect setup={setup} locale={locale} />
                        {mainState.authKey && (
                          <div className="logout" onClick={() => setShowConfirmLogout(true)}>
                            <i className="fas fa-sign-out-alt"></i>
                          </div>
                        )}
                      </div>
                    </div>
                    <div id="modals-container"></div>
                    {showConfirmLogout && (
                      <MessagePopup
                        text={formatMessage({
                          id: "logout.confirmationMessage",
                          defaultMessage: "Are you sure you want to logout?",
                        })}
                        onConfirm={logout}
                        onDecline={() => setShowConfirmLogout(false)}
                      />
                    )}
                    <React.Fragment>
                      <Route
                        exact
                        path="/"
                        render={() => (mainState.authKey ? <Redirect to="/fixtures" /> : <Redirect to="/login" />)}
                      />
                      <Route
                        exact
                        path={"/login"}
                        render={(props) => (
                          <Container fluid={mainState.fluid} className="mainbody">
                            <BasicLogin {...props} updateState={updateState} title={title} currentState={mainState} />
                          </Container>
                        )}
                      />
                      <ProtectedRoute
                        exact
                        path={"/fixtures"}
                        render={(props) => (
                          <Fixtures
                            {...props}
                            updateState={updateState}
                            clearFixtureSpecificDateFromState={clearFixtureSpecificDateFromState}
                            title={title}
                          />
                        )}
                        currentState={mainState}
                      />
                      <ProtectedRoute
                        exact
                        path={"/external-fixture"}
                        render={(props) => <ExternalFixture {...props} currentState={mainState} />}
                        currentState={mainState}
                      />
                      <ProtectedRoute
                        exact
                        path={"/details/:fixtureId"}
                        render={(props) => (
                          <GameDetails
                            {...props}
                            updateState={updateState}
                            title={title}
                            fluid={mainState.fluid}
                            currentState={mainState}
                          />
                        )}
                        currentState={mainState}
                      />
                      <ProtectedRoute
                        exact
                        path={"/setup/:fixtureId"}
                        render={(props) => (
                          <TeamSetup
                            {...props}
                            updateState={updateState}
                            title={title}
                            fluid={mainState.fluid}
                            currentState={mainState}
                          />
                        )}
                        currentState={mainState}
                      />
                      <ProtectedRoute
                        exact
                        path={"/connect/:fixtureId"}
                        render={(props) => <StreamConnect {...props} updateState={updateState} title={title} />}
                        currentState={mainState}
                      />
                      <ProtectedRoute
                        exact
                        path={"/debug/:fixtureId"}
                        render={(props) => <StreamDebug {...props} updateState={updateState} title={title} />}
                        currentState={mainState}
                      />
                      <ProtectedRoute
                        exact
                        path={"/game/:fixtureId"}
                        render={(props) => (
                          <Game
                            {...props}
                            currentState={mainState}
                            updateState={updateState}
                            config={mainState.config}
                            updateLog={mainState.updateLog}
                            forceUpdate={forceUpdate}
                            debug={debug}
                            playStore={playStore}
                            eventStore={eventStore}
                            manageEvents={manageEvents}
                            managePlays={managePlays}
                          />
                        )}
                        currentState={mainState}
                      />
                    </React.Fragment>
                  </div>
                </ConnectionProvider>
              </FixtureDetailsProvider>
            </AuthProvider>
          </HelmetProvider>
        </InactivityTrackerProvider>
      </ErrorBoundary>
    </Router>
  );
};

export default injectIntl(App);
