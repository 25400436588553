import React from "react";
import { injectIntl } from "react-intl";
import { Tracing } from "../../../App.tracing";
import ReportIssue from "../../Collect/ReportIssue/ReportIssue";
import Logger from "../Utilities/Logger";
import "./ErrorBoundary.scss";

const logger = Logger.getInstance();

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    logger.log(error);
    Tracing.capture(error);
  }

  render() {
    const { intl, currentState } = this.props;
    const { formatMessage } = intl;
    if (this.state.hasError) {
      return (
        <div className="error-page">
          <h1>
            {formatMessage({
              id: "generalErrorMessage",
              defaultMessage: "Something went wrong.",
            })}
          </h1>
          <ReportIssue currentState={currentState} verboseVariant />
        </div>
      );
    }

    return this.props.children;
  }
}

export default injectIntl(ErrorBoundary);
