import { useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";

export function FixtureIdHandler({ fixtureId, setFixtureId }) {
  const location = useLocation();
  const matchFixture = matchPath(location.pathname, {
    path: "/*/:fixtureId",
  });
  const fixtureIdFromMatch = matchFixture?.params?.fixtureId;

  useEffect(() => {
    if (fixtureIdFromMatch !== fixtureId) {
      setFixtureId(fixtureIdFromMatch);
    }
  }, [fixtureIdFromMatch, fixtureId, setFixtureId]);

  return null;
}
