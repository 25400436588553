/*
 * Formats full player name into shortened one (John Smith -> J.Smith)
 */
export const formatPlayerShortenedName = (name = "") => {
  const strippedName = name.replace(/\s\s+/g, " ");
  const splitName = strippedName.split(" ", 2);
  const formattedName = splitName[0].substring(0, 1) + ". " + splitName[1];
  return formattedName.length > 12
    ? formattedName.substring(0, 10) + ".."
    : formattedName;
};

// If a player receives TF + TF, UF + UF, TF + UF or DQ it is an automatic disqualification according to basketball rules worldwide.
// TF = Technical Foul
// UF = Unsportsmanlike Foul
// DQ = Disqualifying Foul
export function getIsPlayerDisqualifiedBecauseOfFoulTypes(personId, events) {
  const relatedEvents = events.filter(
    (event) => event.eventType === "foul" && event.personId === personId
  );

  const hasDQFoul =
    relatedEvents.filter((ev) => ev.subType === "disqualifying").length > 0;

  // has two UF or two TF or
  // has at least one UF and at least one TF or
  // has at least one DQ foul
  return (
    relatedEvents.filter(
      (ev) => ev.subType === "unsportsmanlike" || ev.subType === "technical"
    ).length >= 2 || hasDQFoul
  );
}

export function clearDesiredActiveState(currentState, updateState) {
  // setup phase -> entities may not arrived yet
  if (!currentState.entities) {
    return;
  }

  updateState(
    "entities",
    currentState.entities.map((entity) => ({
      ...entity,
      persons: entity.persons.map((person) => ({
        ...person,
        desiredActiveState: undefined,
      })),
    }))
  );
}
