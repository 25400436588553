export const CentrePass = {
  name: "centre-pass",
  component: "MainPanel",
  layout: "main-panel padded align-center",
  mode: "visible",
  text: "centre-pass",
  showOn: [{ state: "primary", value: "centre-pass", operator: "is" }],
  allowCancel: true,
  children: [
    {
      name: "team-0",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: { team: "name" },
      showOn: [true],
      options: { team: 0 },
      startPlay: true,
      startEvent: true,
      actionGroups: [
        {
          actions: [
            {
              action: "startGame",
              type: "main",
            },
            { action: "saveData", value: "centrePass", type: "eventType" },
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "setPossession",
              value: { currentEntity: true },
              type: "main",
            },
            { action: "setPosTracking", value: "defensive" },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "team-1",
      component: "TypeButton",
      layout: "match-button",
      mode: "enable",
      text: { team: "name" },
      showOn: [true],
      options: { team: 1 },
      startPlay: true,
      startEvent: true,
      actionGroups: [
        {
          actions: [
            {
              action: "startGame",
              type: "main",
            },
            { action: "saveData", value: "centrePass", type: "eventType" },
            { action: "sendEvent", value: "new", type: "main" },
            {
              action: "setPossession",
              value: { currentEntity: true },
              type: "main",
            },
            { action: "setPosTracking", value: "defensive" },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
