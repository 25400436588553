export const BaseYellowCardReasons = ["drilling", "not_3m", "other"];
export const YellowCardReasons = [
  "breakdown_of_play",
  "back_stick",
  "deliberate_foot",
  "knocking_ball_away",
  "not_5m",
  "sliding_tackle",
  "verbal_abuse",
  "dangerous_play",
  "confirm_later",
  "other",
];

export const RedCardReasons = [
  "dangerous_play",
  "verbal_abuse",
  "other",
  "confirm_later",
];

export const IndoorCardTimeOption = ["2", "3", "4", "5", "6", "7"];
export const CardTimeOption = ["5", "6", "7", "8", "9", "10"];
