// vendors
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ENVIRONMENT } from "./Config/Environment";

// Wrapper object - for when tracing library cannot load due to ad-blockers
export const Tracing = {
  init: () => {
    try {
      const integrations = [
        new Integrations.BrowserTracing({
          // Prevent sending sentry-tracing header to all requests and create CORS issues
          tracingOrigins: ["::1"],
        }),
        new Sentry.Integrations.Breadcrumbs({
          // This avoids console hijacking
          console: false,
        }),
      ];
      // Sentry init must not fail
      Sentry.init({
        dsn: "https://52072e64750441de9e452cefaf079967@o1035515.ingest.sentry.io/6007413",
        release: "1.0.0", // TODO: Add application versioning
        tracesSampleRate: 1.0,
        // Auto-inject current environment
        environment: ENVIRONMENT,
        // Disable tracing for local domains unless enforced by the developer
        denyUrls: import.meta.env.TRACING_FORCE
          ? []
          : ["0.0.0.0", "localhost", "127.0.0.1", "::1"],
        debug: typeof import.meta.env.TRACING_DEBUG != "undefined",
        integrations,
      });
    } catch (error) {
      console.error("Tracing init - wrong setup", error);
    }
  },
  capture: (e) => {
    try {
      Sentry.captureException(e);
    } catch (error) {
      console.error(
        "Tracing capture error - wrong setup",
        error.message,
        "unable to handle",
        e
      );
    }
  },
  captureMessage: (m, cfg) => {
    try {
      Sentry.captureMessage(m, cfg);
    } catch (error) {
      console.error(
        "Tracing capture message - wrong setup",
        error.message,
        "unable to handle",
        m
      );
    }
  },
  setUser: (u) => {
    try {
      Sentry.setUser(u);
    } catch (error) {
      console.error(
        "Tracing user profile - wrong setup",
        error.message,
        "unable to handle",
        u
      );
    }
  },
};

export const AppTracingErrorBoundary = Sentry.ErrorBoundary;
