// InsertClock Panel
export var InsertClock = {
  name: "insert-clock",
  component: "InsertClock",
  layout: "insert-clock",
  mode: "visible",
  options: { disablePreMatch: true },
  showOn: [{ state: "insertMode.enabled", value: true, operator: "is" }],
  actionGroups: [
    {
      actions: [
        // Set Main Entity
        // {
        //   action: "saveData",
        //   value: ["hasArrow"],
        //   type: "variables",
        // },
        // {
        //   action: "setPossession",
        //   value: { currentEntity: true },
        //   type: "main",
        // },
        // {
        //   action: "setArrow",
        //   value: { currentEntity: false },
        //   type: "main",
        // },
      ],
      conditions: [
        // {
        //   item: "clock.clockStatus",
        //   value: "periodStart",
        //   event: "main",
        //   operator: "is",
        // },
      ],
    },
  ],
};
