export const ShotAttackTypes = [
  "FAST_BREAK",
  "PIVOT",
  "BREAK_THROUGH",
  "DIRECT_FREE_THROW",
];

export const ShotMissedOutcomeTypes = [
  "OFF_TARGET",
  "SAVE",
  "POST",
  "BAR",
  "SHOT_BLOCKED",
];

export const TechnicalFaultTypes = ["ruleFault", "ballFault", "steal"];
