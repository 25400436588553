export const ModifyGoalkeeperSelect = {
  name: "modify-goalkeeper",
  component: "MainPanel",
  layout: "main-panel large align-center",
  mode: "visible",
  text: "player.select",
  showOn: [{ state: "editState", value: "modify-goalkeeper", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  showDetails: { events: ["edit"] },
  children: [
    {
      name: "player-select",
      component: "ChangeGoalkeeper",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      options: {
        currentEntity: true,
        selectOnly: true,
      },
      actionGroups: [
        {
          actions: [
            { action: "sendEvent", value: "edit", type: "main" },
            { action: "nextState", value: null, type: "editState" },
          ],
          conditions: [],
        },
      ],
    },
  ],
};
