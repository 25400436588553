export const CountDownClock = {
  name: "countDownClock",
  component: "CountDownClock",
  layout: "count-down-clock",
  mode: "visible",
  options: {},
  showOn: [
    { state: "seasonDetails.discipline", value: "HOCKEY5S", operator: "not" },
  ],
  actionGroups: [],
};
