const createSuspensionClockEvent = () => [
  { action: "copyEvent", value: "card", type: "to" },
  { action: "newEvent", value: null, type: "main" },
  {
    action: "saveData",
    value: "clock",
    type: "class",
  },
  {
    action: "saveData",
    value: "suspension",
    type: "eventType",
  },
  {
    action: "saveData",
    value: "adjust",
    type: "subType",
  },
  {
    action: "saveData",
    value: [{ option: "value", value: "2" }],
    type: "options",
  },
  { action: "sendEvent", value: "new", type: "main" },
  { action: "copyEvent", value: "card-clock", type: "to" },
  { action: "copyEvent", value: "card", type: "from" },
];

const startSuspensionClockEvent = (srcEvent) => [
  { action: "copyEvent", value: srcEvent, type: "to" },
  { action: "copyEvent", value: "card-clock", type: "from" },
  { action: "copyData", value: "personId", type: srcEvent },
  { action: "sendEvent", value: "edit", type: "main" },
  { action: "newEventId", value: null, type: "main" },
  {
    action: "saveData",
    value: "start",
    type: "subType",
  },
  {
    action: "clearData",
    value: ["options"],
    type: "main",
  },
  { action: "sendEvent", value: "new", type: "main" },
  { action: "copyEvent", value: srcEvent, type: "from" },
];

export const PenaltySuspensionPlayer = {
  name: "penalty-suspension-player",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "penalty.suspension.select-player",
  showOn: [{ state: "primary", value: "penalty-suspension-player", operator: "is" }],
  allowFlag: true,
  allowCancel: true,
  allowCancelPlay: true,
  showDetails: { events: ["main"] },
  children: [
    {
      name: "penalty-suspension-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      startEvent: false,
      startPlay: false,
      showOn: [true],
      options: {
        currentEntity: true,
        showBench: false,
        showCoach: true,
      },
      actionGroups: [
        {
          actions: [
            { action: "newEvent", value: null, type: "main" },
            { action: "saveData", value: "redCard", type: "eventType" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "copyEvent", value: "suspension", type: "from" },
          ],
          conditions: [
            {
              item: "eventCountForPerson",
              eventType: "suspension",
              count: 2,
              event: "main",
            },
            {
              item: "eventType",
              value: "suspension",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "copyEvent", value: "card", type: "to" },
            { action: "newEvent", value: null, type: "main" },
            { action: "saveData", value: "redCard", type: "eventType" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "newEvent", value: null, type: "main" },
            { action: "saveData", value: "suspension", type: "eventType" },
            { action: "saveData", value: "twoMinutes", type: "subType" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "copyEvent", value: "card", type: "from" },
            ...createSuspensionClockEvent(),
            ...startSuspensionClockEvent("card"),
            { action: "copyEvent", value: "card", type: "from" },
          ],
          conditions: [
            {
              item: "eventCountForPerson",
              eventType: "yellowCard",
              count: 1,
              event: "main",
            },
            {
              item: "eventType",
              value: "yellowCard",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            ...createSuspensionClockEvent(),
            ...startSuspensionClockEvent("card"),
          ],
          conditions: [
            {
              item: "eventType",
              value: "redCard",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            ...startSuspensionClockEvent("suspension"),
          ],
          conditions: [
            {
              item: "eventType",
              value: "suspension",
              event: "main",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            { action: "sendEvent", value: "edit", type: "main" },
            { action: "copyEvent", value: "penalty", type: "from" },
            {
              action: "nextState",
              value: "penalty-shot-player",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
    },
    {
      name: "confirm-later-penalty-suspension-player-select",
      component: "ButtonPanel",
      layout: "button-group centered",
      mode: "visible",
      showOn: [true],
      children: [
        {
          name: "confirm-later",
          component: "TypeButton",
          layout: "match-button",
          mode: "enable",
          text: "confirmLater",
          showOn: [true],
          startEvent: false,
          startPlay: false,
          actionGroups: [
            {
              actions: [
                { action: "sendEvent", value: "edit", type: "main" },
                { action: "copyEvent", value: "penalty", type: "from" },
                {
                  action: "nextState",
                  value: "penalty-shot-player",
                  type: "primary",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}