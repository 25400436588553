export const hexColorSplitRegex = /^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i;

export function hexToRgba(hex, opacity = 1) {
  const result = hexColorSplitRegex.exec(hex);

  if (!result) {
    return "";
  }

  return `rgba(${parseInt(result[1], 16)}, ${parseInt(
    result[2],
    16
  )}, ${parseInt(result[3], 16)}, ${opacity})`;
}

function hexToRgb(hex) {
  const result = hexColorSplitRegex.exec(hex);

  if (!result) {
    return "";
  }

  return `rgb(${parseInt(result[1], 16)}, ${parseInt(
    result[2],
    16
  )}, ${parseInt(result[3], 16)})`;
}

function ensureRgbValue(str) {
  if (str.includes("#")) {
    return hexToRgb(str);
  }

  if (str.includes("rgb")) {
    return str;
  }

  // invalid color
  return null;
}

function convertRgbaStringToArray(str) {
  const currentColorStr = ensureRgbValue(str);

  if (!currentColorStr) {
    return [];
  }

  return currentColorStr
    .match(/\(.*?\)/g)
    .map((rgbValue) => rgbValue.replace(/[()]/g, ""))
    .pop()
    .split(", ")
    .map((rgbValue) => parseInt(rgbValue, 10));
}

export function getDistanceBetweenTwoColors(rgb1, rgb2) {
  const rgb1AsArray = convertRgbaStringToArray(rgb1);
  const rgb2AsArray = convertRgbaStringToArray(rgb2);

  if (rgb1AsArray?.length !== 3 || rgb2AsArray?.length !== 3) {
    // we don't do anything if the colors can not be parsed
    return Infinity;
  }

  return redMean(rgb1AsArray, rgb2AsArray);
}

export function getContrastBetweenTwoColors(rgb1, rgb2) {
  const rgb1AsArray = convertRgbaStringToArray(rgb1);
  const rgb2AsArray = convertRgbaStringToArray(rgb2);

  if (rgb1AsArray?.length !== 3 || rgb2AsArray?.length !== 3) {
    // we don't do anything if the colors can not be parsed
    return Infinity;
  }

  var lum1 = luminance(rgb1AsArray[0], rgb1AsArray[1], rgb1AsArray[2]);
  var lum2 = luminance(rgb2AsArray[0], rgb2AsArray[1], rgb2AsArray[2]);

  var brightest = Math.max(lum1, lum2);
  var darkest = Math.min(lum1, lum2);

  return (brightest + 0.05) / (darkest + 0.05);
}

// If you want to understand how this work, please follow the SO link below
// https://stackoverflow.com/questions/9733288/how-to-programmatically-calculate-the-contrast-ratio-between-two-colors
function luminance(r, g, b) {
  var a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });

  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

// https://stackoverflow.com/questions/2103368/color-logic-algorithm#answer-2103608
function redMean(color1, color2) {
  const [c1Red, c1Green, c1Blue] = color1;
  const [c2Red, c2Green, c2Blue] = color2;

  const rmean = (c1Red + c2Red) / 2;
  const red = c1Red - c2Red;
  const green = c1Green - c2Green;
  const blue = c1Blue - c2Blue;
  const weightR = 2 + rmean / 256;
  const weightG = 4.0;
  const weightB = 2 + (255 - rmean) / 256;
  return Math.sqrt(
    weightR * red * red + weightG * green * green + weightB * blue * blue
  );
}
