import { useMemo } from "react";

function useEntityDataByPanelName(panelName, currentState, useScoreBanner = false) {
  const data = useMemo(() => {
    const getEntityById = (id) => {
      return currentState?.entities?.[0]?.entityId === id ? currentState.entities[0] : currentState?.entities?.[1];
    };

    const entityIndex = getEntityIndexFromPanelName(panelName);
    let entity;
    if (useScoreBanner && !!currentState?.entityScoreBanner) {
      entity = getEntityById(currentState.entityScoreBanner[entityIndex]);
    } else {
      entity = currentState?.entities?.[entityIndex];
    }

    return {
      entity,
      entityIndex,
      players: entity?.persons ?? [],
      teamColor: entity?.primaryColor ?? "",
    };
  }, [panelName, currentState.entities, currentState.entityScoreBanner]);

  return data;
}

export default useEntityDataByPanelName;

function getEntityIndexFromPanelName(name) {
  if (name.includes("team-0")) {
    return 0;
  }

  return 1;
}
