export const FaceOffButton = {
  name: "faceoff-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "faceOff",
  showForInsert: true,
  showOn: [true],
  actionGroups: [
    {
      actions: [
        { action: "stopClock", value: "stop", type: "main" },
        { action: "nextState", value: "faceOff", type: "primary" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const SwitchTeamsButton = {
  name: "switch-teams-button",
  component: "TeamSwitch",
  layout: "match-button small",
  mode: "enable",
  sendUpdateArrow: true,
  showOn: [{ state: "clock.clockRunning", value: false, operator: "is" }],
  startEvent: false,
  startPlay: false,
};

export const VideoReviewButton = {
  name: "video-review-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "videoReview",
  showForInsert: true,
  showOn: [
    { state: "period.periodStatus", value: "inProgress", operator: "is" },
  ],
  actionGroups: [
    {
      actions: [
        { action: "nextState", value: "video-review", type: "primary" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const TimeoutButton = {
  name: "timeout-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "timeout",
  showForInsert: true,
  showOn: [true],
  actionGroups: [
    {
      actions: [
        { action: "stopClock", value: "stop", type: "main" },
        { action: "saveData", value: "timeOut", type: "eventType" },
        {
          action: "clearData",
          value: ["options", "personId", "subType"],
          type: "main",
        },
        { action: "sendEvent", value: "new", type: "main" },
        { action: "nextState", value: null, type: "primary" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};

export const OffsideButton = {
  name: "offside-button",
  component: "TypeButton",
  layout: "match-button",
  mode: "enable",
  text: "offside",
  showForInsert: true,
  showOn: [true],
  actionGroups: [
    {
      actions: [
        { action: "stopClock", value: "stop", type: "main" },
        { action: "saveData", value: "offside", type: "eventType" },
        {
          action: "clearData",
          value: ["options", "personId", "subType"],
          type: "main",
        },
        { action: "sendEvent", value: "new", type: "main" },
        { action: "nextState", value: null, type: "primary" },
      ],
      conditions: [],
    },
  ],
  startEvent: true,
  startPlay: true,
};
