import React, { useContext } from "react";
import { Spinner } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { FixtureDetailsContext } from "../../../Config/FixtureDetails";
import { ConnectionContext } from "../../../Config/Connection";

import "./PageLoader.scss";

const Loader = ({ currentState }) => {
  const { hasLoaded: hasFixtureConfigLoaded } = useContext(
    FixtureDetailsContext
  );
  const { hasLoaded: hasConnectionDataLoaded } = useContext(ConnectionContext);

  const shouldShowLoader =
    !currentState.hasInitiallyLoaded &&
    !!currentState.authKey &&
    !!currentState.fixtureId;

  const isLoading = !hasFixtureConfigLoaded || !hasConnectionDataLoaded;

  return (
    <React.Fragment>
      {shouldShowLoader && (
        <React.Fragment>
          {(isLoading || !currentState.mqtt || currentState.gettingTeams) && (
            <div className="loader-container">
              {!hasConnectionDataLoaded && (
                <div className="loader loading-data">
                  <Spinner color="white" size="lg" />
                  <span>
                    <FormattedMessage
                      id="loader.stream"
                      defaultMessage="Getting connection information"
                    />
                  </span>
                </div>
              )}
              {hasConnectionDataLoaded &&
                (!hasFixtureConfigLoaded || !currentState.mqtt) &&
                !currentState.reconnecting && (
                  <div className="loader loading-stream">
                    <Spinner color="white" size="lg" />
                    <span>
                      <FormattedMessage
                        id="loader.connection"
                        defaultMessage="Connecting to stream"
                      />
                    </span>
                  </div>
                )}
              {currentState.gettingTeams && (
                <div className="loader loading-Teams">
                  <Spinner color="white" size="lg" />
                  <span>
                    <FormattedMessage
                      id="loader.final"
                      defaultMessage="Final setup"
                    />
                  </span>
                </div>
              )}
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Loader;
