import React, { useContext, useEffect, useState } from "react";
import { Tracing } from "../../../App.tracing";
import { differenceInSeconds } from "../../Base/Utilities/Date";
import { createBaseEvent } from "../../Base/Utilities/CreateBaseEvent";
import { sendEvent } from "../../Base/Utilities/SendEvent";
import { useIsVisible } from "../../Base/Hooks/IsVisible";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails";
import { convertDisplayTimeToDataTime } from "../../Base/Utilities/ConvertClock";
import "./CountdownClock.scss";

const processedEventsIds = [];

const isGoalEvent = (event) => event.eventType === "goal" && event.success;
const isPenaltyCornerEvent = (event) => event.eventType === "penaltyCorner";
const isPeriodEndEvent = (event) => event.eventType === "period" && event.subType === "end" && event.periodId !== 4;

const isVideoReviewEventAfterPC = (event, idx, events) => {
  const videoReviewEvent = event.eventType === "videoReview";

  if (!videoReviewEvent) {
    return false;
  }

  const lastEvent = events[idx - 1];
  const eventBeforeLastEvent = events[idx - 2];
  return isPenaltyCornerEvent(lastEvent) || isPenaltyCornerEvent(eventBeforeLastEvent);
};

const getLastObservedEvent = (eventStore) => {
  const relatedEvents = eventStore
    .filter((event) => event.status !== "deleted")
    .filter(
      (event, idx, arr) =>
        isGoalEvent(event) ||
        isPenaltyCornerEvent(event) ||
        isPeriodEndEvent(event) ||
        isVideoReviewEventAfterPC(event, idx, arr),
    )
    .sort((aEvent, bEvent) => new Date(bEvent.eventTime).getTime() - new Date(aEvent.eventTime).getTime());

  return relatedEvents[0] ?? null;
};

const getDurationForEvent = (event, fixtureProfile) => {
  if (event.eventType === "goal" || event.eventType === "penaltyCorner" || event.eventType === "videoReview") {
    return fixtureProfile.countdownTimer;
  }

  if (event.eventType === "period" && (event.periodId === 1 || event.periodId === 3)) {
    return 120;
  }

  if (event.eventType === "period" && event.periodId === 2) {
    return (fixtureProfile.halfTimeDuration ?? 10) * 60;
  }

  return 0;
};

const normalizeTimestamp = (timestamp) => {
  return `${timestamp}Z`;
};

const getClockFromSeconds = (seconds) => {
  const remainingMinutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return `${String(remainingMinutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
};

function CountDownClock(props) {
  const { currentState, panel } = props;
  const { clock, mqtt } = currentState;
  const lastObservedEvent = getLastObservedEvent(mqtt?.plays ?? []);
  const [lastEventTime, setLastEventTime] = useState("");
  const [secondsElapsed, setTimeElapsed] = useState(0);
  const [isVisible] = useIsVisible(panel, props);
  const { fixtureProfile } = useContext(FixtureDetailsContext);

  // reset the countdown clock if main clock is running
  useEffect(() => {
    if (clock?.clockRunning && lastEventTime) {
      setTimeElapsed(0);
      setLastEventTime("");
      sendCountdownClockEvent("stop");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clock?.clockRunning, lastEventTime]);

  useEffect(() => {
    if (
      (!clock?.clockRunning || normalizeTimestamp(lastObservedEvent?.eventTime) !== lastEventTime) &&
      lastObservedEvent
    ) {
      // provide zero timezone to resolve timezone issues
      const eventTime = normalizeTimestamp(lastObservedEvent.eventTime);
      const diff = differenceInSeconds(new Date(), new Date(eventTime));
      const eventDuration = getDurationForEvent(lastObservedEvent, fixtureProfile);
      if (diff < eventDuration && !processedEventsIds.includes(lastObservedEvent.eventId)) {
        processedEventsIds.push(lastObservedEvent.eventId);
        setLastEventTime(eventTime);
        sendCountdownClockEvent("adjust", getClockFromSeconds(eventDuration));
        sendCountdownClockEvent("start");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastObservedEvent, clock?.clockRunning]);

  useEffect(() => {
    if (lastEventTime) {
      const handler = () => {
        const diff = differenceInSeconds(new Date(), new Date(lastEventTime));

        if (diff < getDurationForEvent(lastObservedEvent, fixtureProfile)) {
          setTimeElapsed(diff);
        } else {
          setTimeElapsed(0);
          setLastEventTime("");
          sendCountdownClockEvent("stop");
        }
      };
      const timeoutId = window.setTimeout(handler, 1000);

      return () => window.clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastEventTime, secondsElapsed]);

  const sendCountdownClockEvent = (subType, newValue) => {
    const action = createBaseEvent(currentState);
    action.event.class = "clock";
    action.event.eventType = "countDown";
    action.event.subType = subType;
    action.event.clock = convertDisplayTimeToDataTime(currentState.clock.displayTime);

    if (subType === "adjust") {
      action.event.options = {
        value: newValue,
      };
    }

    sendEvent(action, props);
  };

  const renderClock = () => {
    // in some mysterious cases the lastObservedEvent is null here, and the application is crashing
    if (!lastObservedEvent) {
      Tracing.captureMessage(`Countdown clock crashed, events: ${JSON.stringify(mqtt.plays)}`, {
        level: "info",
      });
      return "--:--";
    }

    const duration = getDurationForEvent(lastObservedEvent, fixtureProfile);
    const remainingTimeInSeconds = Math.ceil(duration - secondsElapsed);
    return getClockFromSeconds(remainingTimeInSeconds);
  };

  if (!isVisible) {
    return null;
  }

  return <div className="countdown-clock">{secondsElapsed ? renderClock() : "00:00"}</div>;
}

export default CountDownClock;
