// Block Player Select
export var BlockPlayer = {
  name: "shot-block",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "block.select-player",
  showOn: [{ state: "primary", value: "shot-block", operator: "is" }],
  allowFlag: true,
  allowCancel: false,
  showDetails: { events: ["shot"] },
  children: [
    // Select Player
    {
      name: "block-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      startEvent: true,
      startPlay: false,
      options: {
        currentEntity: false,
        showBench: false,
        showCoach: false,
        // excludePlayer: false,
        // excludeFrom: "shot",
      },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "block", type: "eventType" },
            {
              action: "clearData",
              value: [
                "x",
                "y",
                "options",
                "subType",
                "scores",
                "success",
                "blocked",
              ],
              type: "main",
            },
            { action: "copyEvent", value: "block", type: "to" },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "copyEvent", value: "shot", type: "from" },
            {
              action: "saveData",
              value: [{ option: "blocked", value: true }],
              type: "options",
            },
            {
              action: "clearData",
              value: ["blocked"],
              type: "shot",
            },
            { action: "sendEvent", value: "edit", type: "main" },
            { action: "copyEvent", value: "shot", type: "to" },
            { action: "nextState", value: "shot-rebound", type: "primary" },
          ],
        },
      ],
      conditions: [],
    },
  ],
};
