import React from "react";

import "./GridTableDisplay.scss";

const GridTableDisplay = ({
  columns,
  rows,
  headerClass = "",
  rowCellClass = "",
  tableKeyPrefix = "table",
}) => (
  <div
    className="stats-grid-table-display"
    style={{
      gridTemplateColumns: `repeat(${columns.length}, auto)`,
    }}
  >
    {columns.map((columnName) => (
      <span className={headerClass} key={`${tableKeyPrefix}_${columnName}`}>
        {columnName}
      </span>
    ))}
    {rows &&
      rows.map((row, rowIndex) => {
        return row.map((cell, cellIndex) => (
          <span
            className={rowCellClass}
            key={`${tableKeyPrefix}_${rowIndex}_${cellIndex}`}
          >
            {cell}
          </span>
        ));
      })}
  </div>
);
export default GridTableDisplay;
