const awardPPActions = (type, isShooting) => [
  { action: "newEvent", value: null, type: "main" },
  { action: "saveData", value: type, type: "eventType" },
  { action: "saveData", value: "awarded", type: "subType" },
  { action: "saveData", value: true, type: "success" },
  {
    action: "saveData",
    value: [{ option: "powerPlay", value: true }],
    type: "options",
  },
  { action: "copyData", value: "entityId", type: "fouldrawn" },
  { action: "copyData", value: "personId", type: "fouldrawn" },
  { action: "sendEvent", value: "new", type: "main" },
  {
    action: "setPossession",
    value: { currentEntity: false },
    type: "main",
  },
  isShooting
    ? { action: "nextState", value: "shot-assist", type: "primary" }
    : { action: "nextState", value: null, type: "primary" },
];

// Foul Drawn Player Select
export const FoulDrawnPlayer = {
  name: "foul-drawn-player",
  component: "MainPanel",
  layout: "main-panel large",
  mode: "visible",
  text: "fouldrawn.select-player",
  allowFlag: true,
  allowCancel: false,
  showDetails: { events: ["foul", "fouldrawn"] },
  showOn: [{ state: "primary", value: "foul-drawn-player", operator: "is" }],
  // allowBack: { state: "primary", value: "foul-type", event: "foul" },
  children: [
    // Select Player
    {
      name: "foul-drawn-player-select",
      component: "PlayerSelect",
      layout: "player-select",
      mode: "visible",
      showOn: [true],
      startEvent: true,
      startPlay: false,
      options: {
        currentEntity: false,
        currentEntityEvent: "foul",
        showBench: false,
        showCoach: false,
      },
      actionGroups: [
        {
          actions: [
            { action: "saveData", value: "foul", type: "eventType" },
            { action: "saveData", value: "drawn", type: "subType" },
            {
              action: "clearData",
              value: ["options", "scores", "success"],
              type: "main",
            },
            { action: "sendEvent", value: "new", type: "main" },
            { action: "copyEvent", value: "fouldrawn", type: "to" },
          ],
          conditions: [],
        },
        {
          actions: awardPPActions("2pt", true),
          conditions: [
            { item: "fixture.isPowerPlayEnabled", operator: "is" },
            { item: "fixture.isPowerPlayActive", operator: "is" },
            {
              item: "subType",
              value: "personal",
              event: "foul",
              operator: "is",
            },
            {
              item: "options",
              value: "shooting",
              event: "foul",
              operator: "is",
            },
            {
              item: "region",
              value: "2pt",
              event: "fouldrawn",
              operator: "is",
            },
          ],
        },
        {
          actions: awardPPActions("3pt", true),
          conditions: [
            { item: "fixture.isPowerPlayEnabled", operator: "is" },
            { item: "fixture.isPowerPlayActive", operator: "is" },
            {
              item: "subType",
              value: "personal",
              event: "foul",
              operator: "is",
            },
            {
              item: "options",
              value: "shooting",
              event: "foul",
              operator: "is",
            },
            {
              item: "region",
              value: "3pt",
              event: "fouldrawn",
              operator: "is",
            },
          ],
        },
        {
          actions: awardPPActions("2pt", false),
          conditions: [
            { item: "fixture.isPowerPlayEnabled", operator: "is" },
            { item: "fixture.isPowerPlayActive", operator: "is" },
            {
              item: "options",
              value: "shooting",
              event: "foul",
              operator: "not",
            },
          ],
        },
        {
          actions: [
            { action: "copyEvent", value: "foul", type: "from" },
            {
              action: "saveData",
              value: [{ option: "freeThrows", value: 1 }],
              type: "options",
            },
            { action: "saveData", value: "personal", type: "subType" },
            { action: "sendEvent", value: "edit", type: "main" },
            {
              action: "nextState",
              value: "free-throws",
              type: "secondary",
            },
            { action: "nextState", value: null, type: "primary" },
          ],
          conditions: [
            {
              item: "subType",
              value: "throwIn",
              event: "foul",
              operator: "is",
            },
          ],
        },
        {
          actions: [
            {
              action: "nextState",
              value: "free-throw-buttons",
              type: "primary",
            },
          ],
          conditions: [
            {
              item: "subType",
              value: ["offensive"],
              event: "foul",
              operator: "not",
            },
          ],
        },
        {
          actions: [
            {
              action: "nextState",
              value: null,
              type: "primary",
            },
            { action: "processInserts", value: "all", type: "inserts" },
          ],
          conditions: [
            {
              item: "subType",
              value: "offensive",
              event: "foul",
              operator: "is",
            },
          ],
        },
      ],
      conditions: [],
    },
    {
      name: "no-foul-drawn",
      component: "TypeButton",
      layout: "match-button center",
      mode: "visible",
      text: "foul.drawn.none",
      // showOn: [true],
      showOn: [
        {
          state: "event.subType",
          value: "offensive",
          operator: "not",
          event: "foul",
        },
        {
          state: "event.subType",
          value: "personal",
          operator: "not",
          event: "foul",
        },
        {
          state: "event.subType",
          value: "throwIn",
          operator: "not",
          event: "foul",
        },
      ],
      actionGroups: [
        {
          actions: [
            { action: "copyEvent", value: "foul", type: "to" },
            {
              action: "nextState",
              value: "free-throw-buttons",
              type: "primary",
            },
          ],
          conditions: [],
        },
      ],
      startEvent: false,
      startPlay: false,
    },
  ],
};
