import React, { useEffect, useState, useMemo, useContext } from "react";
import { injectIntl } from "react-intl";

import { sendEvent } from "../../Base/Utilities/SendEvent";

import InsertListItem from "./InsertListItem/InsertListItem";
import { convertClock } from "../../Base/Utilities/ConvertClock";

import "./InsertPanel.scss";
import { FixtureDetailsContext } from "../../../Config/FixtureDetails.jsx";

const InsertPanel = (props) => {
  const { currentState, updateState, intl } = props;
  const { formatMessage } = intl;
  const [inOT, setInOT] = useState(false);
  const [insertEvent, setInsertEvent] = useState();
  const { fixtureProfile } = useContext(FixtureDetailsContext);

  useEffect(() => {
    if (currentState.processInserts) {
      if (
        props.currentState.insertStore &&
        props.currentState.insertStore.length > 0
      ) {
        setInsertEvent(props.currentState.insertStore[0]);
        if (props.currentState.insertStore[0].periodId >= fixtureProfile.initialExtraPeriodId) {
          setInOT(true);
        }
      }
    }
    // eslint-disable-next-line
  }, [props]);

  const InsertPanels = useMemo(() => {
    if (currentState.insertStore) {
      return uniqueEvents(currentState.insertStore, (ev) => ev.eventId).map(
        (item) => (
          <InsertListItem
            className="insertEventListItem"
            key={item.eventId}
            event={item}
            {...props}
          />
        )
      );
    }
    // eslint-disable-next-line
  }, [props]);

  function cancelInsert() {
    // Clean Up and Eject
    updateState("insertStore", null);
    updateState("processInserts", null);
    updateState("insertMode", { enabled: false });
  }

  function doInserts() {
    updateState("insertMode", { enabled: false });
    const initialInsertEventTime = new Date(
      currentState.insertStore[0].eventTime
    );
    uniqueEvents(currentState.insertStore, (ev) => ev.eventId)
      .sort((a, b) =>
        new Date(a.timestamp.replace("Z", "")) <
        new Date(b.timestamp.replace("Z", ""))
          ? -1
          : 1
      )
      .map((event, idx) => {
        return {
          ...event,
          eventTime: new Date(
            initialInsertEventTime.getTime() + 10 * idx
          ).toISOString(),
        };
      })
      .forEach((event) => {
        event.status = "insert";
        let action = {
          action: "sendEvent",
          event: event,
          type: "custom",
        };
        sendEvent(action, props);
      });
    updateState("confirmArrows", true);
    updateState("insertStore", null);
    updateState("processInserts", null);
  }

  function uniqueEvents(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  return (
    <React.Fragment>
      {currentState.processInserts && insertEvent && (
        <div className="insert-panel">
          <div>
            <h3>
              {formatMessage({
                id: "events.insert",
                defaultMessage: "Insert Events",
              })}
              :
              <span className="period-details">
                {!inOT && (
                  <span>
                    {formatMessage({
                      id: "period.abbreviation",
                      defaultMessage: "P",
                    })}
                  </span>
                )}
                {inOT && (
                  <span>
                    {formatMessage({
                      id: "period.overtime.abbreviation",
                      defaultMessage: "OT",
                    })}
                  </span>
                )}
                {insertEvent.periodId}
              </span>
              <span className="play-clock">
                {insertEvent.clock && convertClock(insertEvent.clock)}
              </span>
            </h3>
            <h4>
              {formatMessage({
                id: "events.insert.description",
                defaultMessage: "Are you sure? ",
              })}
            </h4>
            <div className="event-list">{InsertPanels}</div>
            <div className="match-buttons">
              <div
                className="enabled-true match-button missed"
                onClick={() => cancelInsert()}
              >
                {formatMessage({
                  id: "cancel",
                  defaultMessage: "Cancel",
                })}
              </div>
              <div
                className="enabled-true match-button made"
                onClick={() => doInserts()}
              >
                {formatMessage({
                  id: "continue",
                  defaultMessage: "Continue",
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default injectIntl(InsertPanel);
