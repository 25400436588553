export var JumpballStartGame = {
  name: "jumpball-start",
  component: "MainPanel",
  layout: "jumpball-start main-panel x-large",
  mode: "visible",
  text: "jumpball.start",
  showOn: [{ state: "primary", value: "jumpball-start", operator: "is" }],
  startEvent: false,
  startPlay: false,
  allowFlag: false,
  allowCancel: false,
  children: [
    // Jumpball
    {
      name: "jumpball-start-match",
      component: "Jumpball",
      layout: "jumpball",
      mode: "visible",
      showOn: [true],
      startEvent: false,
      startPlay: false,
      options: {
        value: "subType",
        children: ["full", "short", "commercial"],
        layout: "type-button",
        prefix: "timeout",
      },
      actionGroups: [],
    },
  ],
};
