import React from "react";
import "./CounterInput.scss";

/*
 * min: number;
 * max: number;
 * value: number;
 * teamName: string;
 * onChange: (value: number) => void;
 * arrowSide: 'left' | 'right'
 */
function CounterInput(props) {
  const {
    value,
    onChange,
    teamName,
    arrowSide,
    min = 0,
    max = Infinity,
    autoFocus,
  } = props;

  const onKeyDown = (ev) => {
    ev.stopPropagation();
    const { key } = ev;

    if (key === "ArrowUp") {
      onChange(value + 1);
    } else if (key === "ArrowDown") {
      onChange(Math.max(0, value - 1));
    }
  };

  const isValueValid = (num) => {
    if (isNaN(num)) {
      return false;
    }

    if (num < min) {
      return false;
    }

    if (num > max) {
      return false;
    }

    return true;
  };

  const onInputChange = (ev) => {
    const rawValue = ev.target.value;

    if (rawValue === "") {
      onChange(0);
      return;
    }

    const newValue = parseInt(rawValue, 10);
    const isValid = isValueValid(ev.target.value);

    if (isValid) {
      onChange(newValue);
    }
  };

  return (
    <div className={`counter-input-container direction--${arrowSide}`}>
      <div className="counter-input__buttons">
        <button
          className="counter-input__button"
          onClick={() => onChange(Math.min(max, value + 1))}
          tabIndex="-1"
        >
          <i className="fas fa-plus" />
        </button>
        <button
          className="counter-input__button"
          onClick={() => onChange(Math.max(min, value - 1))}
          tabIndex="-1"
        >
          <i className="fas fa-minus" />
        </button>
      </div>
      <div className="counter-input__display">
        <input
          tabIndex="0"
          autoFocus={autoFocus}
          value={value}
          type="text"
          className="counter-input__input"
          onChange={onInputChange}
          onKeyDown={onKeyDown}
        />
        <span className="counter-input__name">{teamName}</span>
      </div>
    </div>
  );
}

export default CounterInput;
