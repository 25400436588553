import React, { useContext, useEffect } from "react";
import { v1 as uuidv1 } from "uuid";
import { FixtureDetailsContext } from "../../../../Config/FixtureDetails";
import { createBaseEvent } from "../../../Base/Utilities/CreateBaseEvent";
import { checkGameStarted } from "../../../Base/Utilities/Game";
import { lightOrDark } from "../../../Base/Utilities/LightOrDark";
import { clone } from "../../../Base/Utilities/Object";
import { getEventTime, sendEvent } from "../../../Base/Utilities/SendEvent";
import {
  getLastCardEventForPersonId,
  getRelatedClockEventsByPlayId,
  getRemainingTimeFromSuspension,
} from "../../../Base/Utilities/Suspension";

const HockeyPlayer = (props) => {
  const { player, teamColor, entity, updateState, currentState, variant } =
    props;
  const { mqtt } = currentState;
  const { fixtureProfile } = useContext(FixtureDetailsContext);

  const isGameStarted = checkGameStarted(props);

  const sendPlayerEvent = () => {
    let timestamp = new Date();
    const clockParts = currentState.clock.displayTime.split(":");
    const msg = {
      class: "sport",
      eventType: "substitution",
      subType: player.active ? "out" : "in",
      entityId: entity.entityId,
      personId: player.personId,
      eventId: uuidv1(),
      playId: uuidv1(),
      periodId: currentState.period.periodId,
      timestamp: timestamp.toISOString(),
      eventTime: getEventTime(),
      clock:
        "PT" + parseInt(clockParts[0]) + "M" + parseInt(clockParts[1]) + "S",
    };

    const subAction = {
      action: "sendEvent",
      event: msg,
      type: "custom",
    };
    sendEvent(subAction, props);

    if (currentState.insertStore && currentState.insertStore.length > 0) {
      props.updateState("processInserts", true);
    }
  };

  const sendClockEvent = (subType, playId) => {
    const action = createBaseEvent(currentState);
    const clockParts = currentState.clock.displayTime.split(":");
    action.event.class = "clock";
    action.event.eventType = latestCardEvent.eventType;
    action.event.subType = subType;
    action.event.entityId = entity.entityId;
    action.event.personId = player.personId;
    action.event.clock = `PT${parseInt(clockParts[0])}M${parseInt(
      clockParts[1]
    )}S`;

    if (playId) {
      action.event.playId = playId;
    }

    sendEvent(action, props);
  };

  const updatePlayersState = () => {
    const updatedEntities = clone(props.currentState.entities);

    const teamIndex = updatedEntities.findIndex(
      (oneEntity) => oneEntity.entityId === entity.entityId
    );

    if (teamIndex < -1) {
      console.warn("Team not found");
      return;
    }

    const team = updatedEntities[teamIndex];
    const currentPlayer = team.persons.find(
      (el) => el.personId === player.personId
    );

    if (!currentPlayer) {
      console.warn("Player not found");
      return;
    }

    currentPlayer.active = !currentPlayer.active;

    updateState("entities", updatedEntities);
  };

  const onPlayerClick = () => {
    if (!isGameStarted) {
      return;
    }
    sendPlayerEvent();
    updatePlayersState();
  };

  const latestCardEvent = getLastCardEventForPersonId({
    entity,
    personId: player.personId,
    events: mqtt?.plays ?? [],
  });

  const clockEvents = getRelatedClockEventsByPlayId({
    playId: latestCardEvent?.playId,
    events: mqtt.plays ?? [],
  });

  const remainingTime = getRemainingTimeFromSuspension(
    latestCardEvent && clockEvents.start
      ? {
          ...latestCardEvent,
          clock: clockEvents.start.clock,
        }
      : null,
    currentState.clock?.displayTime,
    currentState.period.periodId,
    fixtureProfile
  );

  useEffect(() => {
    if (remainingTime === 1) {
      sendClockEvent("stop");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingTime]);

  const renderCard = () => {
    if (latestCardEvent.eventType === "redCard") {
      return (
        <div className="player__card">
          <div className="player__card-box player__card-box--red" />
          <span className="player__card-text">SUS</span>
        </div>
      );
    }

    if (!clockEvents.start) {
      return (
        <div className="player__card">
          <div
            className={`player__card-box player__card-box--${
              latestCardEvent.eventType === "yellowCard" ? "yellow" : "green"
            }`}
          />
          <button
            className="player-button"
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
              sendClockEvent("start", latestCardEvent.playId);
            }}
          >
            START
          </button>
        </div>
      );
    }

    if (
      remainingTime > 0 &&
      (latestCardEvent.eventType === "yellowCard" ||
        latestCardEvent.eventType === "greenCard")
    ) {
      const minutes = Math.floor(remainingTime / 60);
      const seconds = remainingTime - minutes * 60;

      return (
        <div className="player__card">
          <div
            className={`player__card-box player__card-box--${
              latestCardEvent.eventType === "yellowCard" ? "yellow" : "green"
            }`}
          />
          <span className="player__card-text">
            {minutes}:{String(seconds).padStart(2, "0")}
          </span>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={`player ${
        player.active ? "active" : ""
      } player--${variant} ${lightOrDark(teamColor)}${
        !isGameStarted ? " player--disabled" : ""
      }${
        remainingTime > 0 || latestCardEvent?.eventType === "redCard"
          ? " player--suspended"
          : ""
      }`}
      style={{
        backgroundColor: player.active ? teamColor : "#3B444C",
      }}
      onClick={onPlayerClick}
    >
      {player.position === "GK" ? (
        <div className="player--goalie">
          <div className={`player--goalie-icon ${lightOrDark(teamColor)}`} />
          <div>
            {player.bib}
            {player.captain && " (C)"}
          </div>
        </div>
      ) : (
        <>
          {player.bib}
          {player.captain && " (C)"}
        </>
      )}
      {latestCardEvent && renderCard()}
    </div>
  );
};

export default HockeyPlayer;
